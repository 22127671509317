<template>
  <v-container id="employees" grid-list-xl>
    <v-layout row wrap justify-center>
      <v-flex sm6 xs12>
        <employee-list :employees="employees"></employee-list>
      </v-flex>
      <v-flex sm6 xs12>
        <manage-entity
          v-if="employee"
          title="Employee" color="cyan darken-1" icon="people"
          :entity="entity" :loading="isLoading" :path="path" :query="query" :details="showDetails" :back="'/employee'"
          @save="saveEmployee" @update:details="handleShowDetailsUpdate" no-email
        >
          <template #form>
            <v-container pa-0>
              <v-text-field label="Name" v-model="employee.name" outline dark hide-details color="white"></v-text-field>
            </v-container>
          </template>

          <template #preContent>
            <v-text-field
              v-model="employee.user.email" label="Email" color="amber darken--2"
              full-width hide-details readonly
            >
              <template #append>
                <v-btn flat small color="amber darken--2" class="mx-0">Reset Password</v-btn>
              </template>
            </v-text-field>
            <v-text-field
              v-model="employee.title" label="Job Title"
              full-width hide-details
            >
              <template #append>
                <v-switch
                  v-model="employee.user.inactive"
                  :false-value="true" :true-value="false"
                  :label="employee.user.inactive ? 'Inactive' : 'Active'"
                  class="mt-0" hide-details
                ></v-switch>
              </template>
            </v-text-field>
            <v-divider></v-divider>
            <div style="display: flex; flex-wrap: nowrap;">
              <monetary-input v-model="employee.wage" label="Wage" style="width: 50%;" full-width hide-details></monetary-input>
              <monetary-input v-model="employee.rate" label="Rate" style="width: 50%;" full-width hide-details></monetary-input>
            </div>
            <v-divider></v-divider>

            <v-layout ma-0 pa-4 v-show="isDirty">
              <v-btn small class="ma-0" @click="resetEmployee">cancel</v-btn>
              <v-spacer></v-spacer>
              <v-btn small dark class="ma-0" @click="saveEmployee" color="primary">save</v-btn>
            </v-layout>
            <v-divider v-show="isDirty"></v-divider>
          </template>

          <template #bottom>
            <note-list :notableId="parseInt(employeeId, 10)" notableType="App\Employee" :path="path" :notes="messages" :query="query"></note-list>
          </template>
        </manage-entity>
      </v-flex>
    </v-layout>
    <v-snackbar v-model="showSnackbar" :color="snackbarColor" top>
      {{ snackbarText }}
      <v-btn dark flat @click="showSnackbar = false">
        Close
      </v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
/* eslint-disable handle-callback-err */
/* eslint-disable eqeqeq */

import { EMPLOYEE, USER } from '@/graphql/models'
import { EmployeeList, ManageEntity, MonetaryInput, NoteList } from '@/components'
import Cent$ from '@/cents.js'
import { cloneOnly, equivalent } from '@/utils'

export default {
  name: 'employees',
  components: { EmployeeList, ManageEntity, MonetaryInput, NoteList },
  props: { company: {} },

  data () {
    return {
      original: {},
      rate: '0',
      showDetails: true,
      showSnackbar: false,
      snackbarText: '',
      snackbarColor: '',
      wage: '0'
    }
  },

  computed: {
    entity () { return this.employee ? { ...this.employee, type: EMPLOYEE.name } : {} },
    employee () { return this.employees?.find(employee => employee.id === this.employeeId) },
    employeeId () { return this.$route.params.employeeId },
    employees () { return this.$oxide.store.apollo.employees ? this.$oxide.store.apollo.employees : [] },
    isLoading () { return !this.$oxide.store.apollo.company },
    isDirty () {
      let dirty = false
      if (this.employee?.id && this.employee?.user?.id) {
        dirty = !equivalent(this.original, { inactive: this.employee.user.inactive, ...cloneOnly(this.employee, ['id', 'name', 'rate', 'title', 'wage']) })
      }
      return dirty
    },
    messages () { return this.employee?.notes?.filter(note => note.type === 'IMAGE' || note.type === 'LINK' || note.type === 'TEXT').sort((a, b) => b.id - a.id) },
    path () { return `employees/${this.employeeId}` },
    query () { return EMPLOYEE.queries.index }
  },

  methods: {

    resetEmployee () {
      this.employee.name = this.original.name
      this.employee.title = this.original.title
      this.employee.rate = this.original.rate
      this.employee.wage = this.original.wage
      this.employee.user.inactive = this.original.inactive
    },

    saveEmployee () {
      if (this.employee.title === null) {
        this.employee.title = ''
      }

      const employee = cloneOnly(this.employee, ['id', 'name', 'rate', 'title', 'wage'])

      const userUpdateRequired = this.employee.name !== this.original.name || this.employee.user.inactive !== this.original.inactive

      if (userUpdateRequired) {
        this.$apollo.mutate({
          mutation: USER.mutations.update.mutation,
          variables: { user: { id: this.employee.user.id, name: employee.name, inactive: this.employee.user.inactive } }
        })
      }

      this.$apollo.mutate({
        mutation: EMPLOYEE.mutations.update.mutation,
        variables: { employee }
      }).then(({ data: { updateEmployee } }) => {
        this.snackbarText = 'Employee updated'
        this.snackbarColor = 'cyan darken-1'
        this.showSnackbar = true
        this.updateOriginal()
      })
    },

    updateOriginal () {
      if (this.employee && this.employee.id && this.employee.user?.id) {
        this.original = { inactive: this.employee.user.inactive, ...cloneOnly(this.employee, ['id', 'name', 'rate', 'title', 'wage']) }
      } else {
        this.original = {}
      }
    },
    updateRate () {
      if (this.employee && this.employee.rate !== this.rate.replace('.', '')) {
        this.rate = Cent$.fromCents(this.employee.rate).asDollars
      }
    },
    updateWage () {
      if (this.employee && this.employee.wage !== this.wage.replace('.', '')) {
        this.wage = Cent$.fromCents(this.employee.wage).asDollars
      }
    },
    handleShowDetailsUpdate (e) {
      this.showDetails = e
    }
  },
  watch: {
    'employee.id' () {
      this.showDetails = true
      this.updateOriginal()
      this.updateRate()
      this.updateWage()
      document.scrollingElement.scrollTop = 0
    }
  },
  created () {
    this.updateOriginal()
    this.updateRate()
    this.updateWage()
  },
  mounted () {
    this.updateOriginal()
    this.updateRate()
    this.updateWage()
  },
  beforeDestroy () {
    //
  }
}
</script>

<style lang="scss">
#employees {
  margin: 0;
}
</style>
