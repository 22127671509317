
if (
  !window.matchMedia('(display-mode: standalone)').matches &&
  window.navigator.standalone !== true &&
  !window.localStorage.getItem('oxide_install_denied')
) {
  window.addEventListener('beforeinstallprompt', (e) => {
    window.$oxide.bus.beforeinstallprompt = e
    console.log('pwa installable')
  })

  window.addEventListener('appinstalled', (e) => {
    window.$oxide.bus.appinstalled = e
    console.log('a2hs installed')
  })
} else {
  console.log('display-mode is standalone')
}

// page visibility
let hidden, visibilityChange

if (typeof document.hidden !== 'undefined') {
  hidden = 'hidden'
  visibilityChange = 'visibilitychange'
} else if (typeof document.msHidden !== 'undefined') {
  hidden = 'msHidden'
  visibilityChange = 'msvisibilitychange'
} else if (typeof document.webkitHidden !== 'undefined') {
  hidden = 'webkitHidden'
  visibilityChange = 'webkitvisibilitychange'
}

function handleVisibilityChange () {
  window.$oxide.bus.hidden = document[hidden]
}

if (typeof document.addEventListener !== 'undefined' || hidden !== undefined) {
  document.addEventListener(visibilityChange, handleVisibilityChange, false)
}

// connectivity
function handleOfflineEvent (event) {
  window.$oxide.bus.offline = true
}
function handleOnlineEvent (event) {
  window.$oxide.bus.offline = false
}

if (window) {
  window.addEventListener('offline', handleOfflineEvent)
  window.addEventListener('online', handleOnlineEvent)
}

window.addEventListener('error', function (e) {
  console.log('Error occurred: ' + e?.error?.message, e)
  return false
}, true)

window.addEventListener('unhandledrejection', event => {
  console.log(`MANHANDLED PROMISE REJECTION: ${event.reason}`, event)
  console.trace()
  window.$oxide.bus.errors.push(event.reason)
})

if (navigator.storage && navigator.storage.persist) {
  navigator.storage.persist().then(function (persistent) {
    if (persistent) {
      console.log('Storage will not be cleared except by explicit user action')
    } else {
      console.log('Storage may be cleared by the UA under storage pressure.')
    }
  })
}
