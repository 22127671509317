<template>
  <v-flex class="time-card">
    <v-card v-if="employee">
      <v-toolbar dark color="blue-grey">
        <v-toolbar-title style="display: flex; width: 100%; min-height: 33px;">
          <v-progress-circular v-if="loading" indeterminate></v-progress-circular>
          <v-select v-else-if="admin"
            densee hide-details single-line class="mt-0 pt-0"
            :value="employee.id" :items="sortedEmployees" item-text="name" item-value="id"
            :menu-props="{ closeOnClick: false, closeOnContentClick: false, openOnClick: false, maxHeight: 300, contentClass: 'time-card--employee-select-items' }"
            @input="handleEmployeeSelect"
          >
          </v-select>
          <span v-else class="font-weight-regular">
            {{ employee.name }}
          </span>
          <v-spacer></v-spacer>
          <span>Time Card</span>
        </v-toolbar-title>

        <template v-slot:extension>
          <v-select dense hide-details color="deep-orange darken-3" label="Pay Period" :items="payPeriodItems" v-model="payPeriod">
            <template #prepend-item>
              <!-- <span>current</span> -->
            </template>
            <template #item="{ item }">
              <span>{{ item.text }}<span v-if="!item.index" class="grey--text text--darken-2"> - current</span></span>
            </template>
          </v-select>
        </template>

      </v-toolbar>

      <v-data-table must-sort hide-actions
        :headers="headers" :items="dayTotaledHours" :pagination.sync="pagination" :loading="loading">
        <template v-slot:items="props">
          <tr class="" style="line-height: 1.1;">

            <template v-if="props.item.emptyDay">
              <td class="pl-2 pr-1 py-1">
                <div>{{ getWeekday(props.item.date) }}</div>
                <div class="caption font-weight-thin pt-1 text-no-wrap">{{ props.item.date }}</div>
              </td>
              <td class="text-xs-center caption grey--text text--lighten-1">no time added</td>
              <td></td>
              <td></td>
            </template>

            <template v-else>
              <td v-if="props.item._dayTotalMeta" :rowspan="props.item._dayTotalMeta.entries" class="pl-2 pr-1 py-1">
                <div>{{ getWeekday(props.item.date) }}</div>
                <div class="caption font-weight-thin pt-1 text-no-wrap">{{ props.item.date }}</div>
              </td>
              <td class="px-1 text-xs-center py-1">
                <div><router-link :to="'/crm/client/' + props.item.job.client.id + '/job/' + props.item.job.id + '?a=2'">{{ props.item.job.title }}</router-link></div>
                <div class="caption font-weight-thin pt-1"><router-link :to="'/crm/client/' + props.item.job.client.id + '?a=1'" style="text-decoration: none;">{{ props.item.job.client.name }}</router-link></div>
              </td>
              <td class="px-1 text-xs-center py-1">
                <v-tooltip v-if="props.item.description" left>
                  <template v-slot:activator="{ on }">
                    <span class="font-mono" style="text-decoration: underline;" v-on="on">{{ formatHour(props.item.time) }}</span>
                  </template>
                  <pre>{{ props.item.description }}</pre>
                </v-tooltip>
                <span v-else class="font-mono">{{ formatHour(props.item.time) }}</span>
              </td>
              <td
                v-if="props.item._dayTotalMeta" :rowspan="props.item._dayTotalMeta.entries"
                class="pr-2 pl-1 text-xs-right font-mono font-weight-bold py-1">
                {{ formatHour(props.item._dayTotalMeta.total) }}
              </td>
            </template>
          </tr>
        </template>
        <template v-slot:footer>
          <td :colspan="headers.length" class="title px-2">
            <div class="d-flex text-xs-center">
              <v-flex  class=" font-weight-light">pay period total:</v-flex>
              <v-spacer></v-spacer>
              <v-flex class="font-mono">{{ formatHour(totalFilteredHours) }}</v-flex>
            </div>
          </td>
        </template>
      </v-data-table>
    </v-card>

    <v-card v-if="admin">

    </v-card>
  </v-flex>
</template>

<script>
/* eslint-disable camelcase */
/* eslint-disable handle-callback-err */

import { EMPLOYEE } from '@/graphql/models'
import { clone, formatDecimal, getDate, navigate } from '@/utils'
import {
  addWeeks,
  differenceInDays,
  eachDayOfInterval,
  endOfWeek,
  format,
  formatRelative,
  isPast,
  isSameMonth,
  isSameYear,
  isWeekend,
  isWithinInterval,
  parseISO,
  startOfWeek,
  startOfWeekYear
} from 'date-fns'

// import DateInterval from '@/components/DateInterval.vue'

export default {
  name: 'time-card',
  // components: { DateInterval },
  props: {
    config: {}, id: {}, me: {}
  },
  data () {
    return {
      employee: {},
      employees: [],
      headers: [
        { text: 'Date', align: 'left', value: 'date', class: 'pl-2 pr-1', sortable: false },
        { text: 'Job', align: 'center', value: 'job.title', class: 'px-1', sortable: false },
        { text: 'Hours', align: 'center', value: 'time', width: '10', class: 'px-1', sortable: false },
        { text: 'Day Total', align: 'right', value: 'time', width: '10', class: 'pr-2 pl-1', sortable: false }
      ],
      pagination: {
        descending: false,
        rowsPerPage: -1,
        sortBy: 'date'
      },
      payPeriod: null
    }
  },
  apollo: {
    employee: {
      query: EMPLOYEE.queries.show.query,
      pollInterval: 1000 * 60 * 1, // 30 seconds
      variables () { return { id: this.employeeId } },
      fetchPolicy: 'no-cache'
    },
    employees: { query: EMPLOYEE.queries.index.query }
  },
  computed: {
    admin () {
      return this.me?.is_admin
    },
    loading () { return this.$apollo.loading },
    employeeId () { return this.id },

    firstPayPeriodStart () {
      const payPeriod = this.config?.accounting?.firstPayPeriod
      return payPeriod ? startOfWeek(parseISO(payPeriod)) : startOfWeekYear(new Date())
    },

    hours () {
      if (this.loading) {
        return []
      }
      const hours = this.employee?.hours
      if (hours) {
        return [...hours].sort((a, b) => Date.parse(b.date) - Date.parse(a.date))
      } else {
        return []
      }
    },

    filteredHours () {
      if (this.payPeriod) {
        const start = parseISO(this.payPeriod.start)
        const end = parseISO(this.payPeriod.end)
        return this.hours.filter(hour => isWithinInterval(parseISO(hour.date), { start, end }))
      } else {
        return []
      }
    },

    emptyWorkDays () {
      if (this.payPeriod) {
        const start = parseISO(this.payPeriod.start)
        const end = parseISO(this.payPeriod.end)
        const days = new Map()
        this.filteredHours.forEach(hour => {
          days.set(hour.date, null)
        })
        const daysWorked = Array.from(days.keys())
        return eachDayOfInterval({ start, end })
          .filter(day => !isWeekend(day))
          .map(d => getDate(d))
          .filter(day => { return !daysWorked.some(workedDay => day === workedDay) })
      } else {
        return []
      }
    },

    dayTotaledHours () {
      let currentDate = ''
      let index = 0
      const hours = [...clone(this.filteredHours), ...clone(this.emptyWorkDays).map(d => ({ date: d, emptyDay: true }))]

      for (let i = 0; i < hours.length; i++) {
        if (hours[i].date !== currentDate) {
          index = i
          currentDate = hours[i].date
          hours[i]._dayTotalMeta = { total: 0, entries: 0 }
        }
        hours[index]._dayTotalMeta.total += hours[i].time
        hours[index]._dayTotalMeta.entries += 1
      }

      return hours
    },

    payPeriods () {
      const periods = []
      let start = this.firstPayPeriodStart
      while (isPast(start)) {
        periods.push({
          start: getDate(start),
          end: getDate(endOfWeek(addWeeks(start, 1)))
        })
        start = addWeeks(start, 2)
      }
      return periods
    },
    payPeriodItems () {
      let i = 0
      return clone(this.payPeriods).reverse().map(
        p => {
          const start = parseISO(p.start)
          const end = parseISO(p.end)
          let text = format(start, 'MMM do')
          if (!isSameYear(start, end)) {
            text += ' ' + format(start, 'y')
          }
          text += ' to '
          if (!isSameMonth(start, end)) {
            text += format(end, 'MMM ')
          }
          text += format(end, 'do y')
          return { index: i++, text, value: p }
        }
      )
    },
    totalHours () {
      return this.hours.reduce((total, hour) => total + hour.time, 0)
    },

    totalFilteredHours () {
      return this.filteredHours.reduce((total, hour) => total + hour.time, 0)
    },

    sortedEmployees () {
      const collator = new Intl.Collator(
        undefined,
        {
          caseFirst: 'false',
          ignorePunctuation: false,
          localeMatcher: 'best fit',
          numeric: true,
          sensitivity: 'base',
          usage: 'sort'
        }
      )

      const employees = clone(this.employees).sort((a, b) => collator.compare(a.name, b.name))
      const activeEmployees = []
      const inactiveEmployees = []
      for (const employee of employees) {
        if (employee.user.inactive) {
          inactiveEmployees.push(employee)
        } else {
          activeEmployees.push(employee)
        }
      }
      if (inactiveEmployees.length) {
        return [...activeEmployees, { id: '-1', name: 'Inactive Employees', disabled: true }, ...inactiveEmployees]
      } else {
        return employees
      }
    }
  },
  methods: {
    formatHour (h) {
      return formatDecimal(h, 2)
    },

    getWeekday (date) {
      return format(parseISO(date), 'EEEE')
    },

    getFormattedDate (date) {
      const now = new Date()
      const then = parseISO(date)
      const diff = Math.abs(differenceInDays(now, then))

      if (diff > 5) {
        return format(then, 'MMM do')
      } else {
        return formatRelative(then, now).split(' at ')[0].split('last ').pop()
      }
    },

    handleEmployeeSelect (id) {
      navigate({
        name: 'reports',
        params: { subject: 'employee', id, report: 'time-card' }
      }, this.$router)
    }
  },
  watch: {
    id (val) {
      this.$nextTick(() => {
        this.$apollo.queries.employee.refetch()
      })
    }
  },
  created () {
    if (!this.payPeriod && this.payPeriodItems) {
      this.payPeriod = this.payPeriodItems[0].value
    }
  },
  mounted () {
    if (!this.$apollo.queries.employee.loading) {
      this.$nextTick(() => {
        this.$apollo.queries.employee.refetch()
      })
    } else {
      setTimeout(() => {
        this.$apollo.queries.employee.refetch()
      }, 1000)
    }
  }
}
</script>

<style lang="scss">
.time-card {
  .theme--dark.v-table tbody tr:hover:not(.v-datatable__expand-row) {
    background-color: inherit;
  }
}
#app.theme--dark .time-card--employee-select-items {
  .v-select-list {
    [role=listitem] {
      a.v-list__tile.v-list__tile--link {
        text-decoration: none;
        font-weight: 300;
      }
      &[disabled=disabled] {
        a.v-list__tile.v-list__tile--link {
          .v-list__tile__content {
            .v-list__tile__title {
              text-align: center;
              font-weight: 600;
              color: #ccc;
              text-decoration: underline;
            }
          }
        }
        & + div {
          a.v-list__tile.v-list__tile--link .v-list__tile__content .v-list__tile__title {
            color: #bbb;
          }
        }
      }
    }
  }
}
@media (min-width: 601px) {
  .time-card {
    max-width: 500px;
  }
}
</style>
