<template>
  <v-card dark id="edit-materials">
    <v-card-title>
      <v-text-field
        :value="search" @input="handleSearch"
        prepend-icon="search" label="Search"
        single-line hide-details expand
      ></v-text-field>
      <v-spacer></v-spacer>

      <v-menu auto offset-y>
        <template #activator="{ on }">
          <v-btn outline color="light-blue" v-on="on">
            <v-icon left>publish</v-icon> Import Data
          </v-btn>
        </template>
        <div style="display: flex; flex-direction: column; background-color: #163c56; padding-top: 12px;">
          <v-btn outline color="cyan" @click.stop="showImportBillables = true">
            <v-icon left>add_box</v-icon> Import Billables
          </v-btn>
          <v-btn outline color="orange" @click.stop="showImportPrices = true">
            <v-icon left>monetization_on</v-icon> Update Prices
          </v-btn>
        </div>
      </v-menu>
      <v-btn outline color="green" @click.stop="addBillable">
        <v-icon left>add_circle</v-icon> Add Item
      </v-btn>
    </v-card-title>

    <v-data-table
        v-bind:headers="headers" :items="filteredBillables" :loading="isLoading"
        :no-data-text="isLoading ? 'Loading...' : 'Nothing added yet...'" class="elevation-1"
        no-results-text="No results for the current search"
        :rows-per-page-items="[25,50,100,{text:'$vuetify.dataIterator.rowsPerPageAll',value:-1}]"
        :pagination.sync="pagination"
      >
      <template slot="items" slot-scope="props">
        <tr :active="props.item.id === billable.id" :class="{ 'scroll-target': parseInt(props.item.id, 10) === scrollingTo }">
          <td class="text-xs-right pl-1 pr-2">
            <v-progress-circular
                :value="Math.min(props.item._relevance.total, 4) * 25"
                :color="'rgba(3, 169, ' + (Math.min(props.item._relevance.total, 4) * 0.25 * 222) + ', 1)'"
                size="20"
              ></v-progress-circular>
          </td>

          <td class="font-weight-medium text-xs-center px-1">
            {{ props.item.category ? (props.item.category.name ? props.item.category.name : props.item.category) : '' }}
          </td>

          <td class="edit-materials-table-tags px-1">
            <v-chip v-for="(tag, index) in props.item.tags" :key="index" :color="getColor(tag, props.item)" label small outline class="">{{ tag }}</v-chip>
          </td>

          <td class="text-xs-left font-weight-medium px-1">
            {{ props.item.name }}
          </td>

          <td class="text-xs-center px-1">
            {{ props.item.size }}
          </td>

          <td class="text-xs-center px-1">
            {{ props.item.unit }}
          </td>

          <td class="text-xs-center px-1">
            {{ props.item.supplier }}
          </td>

          <td class="text-xs-right px-1 red--text text--lighten-2 font-weight-medium">
            ${{ props.item.cost | monetaryPreScaled }}
          </td>

          <td class="text-xs-center px-1 grey--text text--lighten-1 caption" style="line-height: 1.2;">
            {{ (props.item.updated_at ? props.item.updated_at : '') | timespan }}
          </td>

          <td class="text-xs-center px-1">
            <v-btn icon small class="ma-0" @click="editBillable(props.item.id)">
              <v-icon small color="accent">mode_edit</v-icon>
            </v-btn>

            <v-btn icon small class="ma-0" @click="duplicateBillable(props.item)">
              <v-icon small color="amber">file_copy</v-icon>
            </v-btn>

            <confirm-action @confirm="deleteBillable(props.item.id)">
              <template v-slot:act="{ on }">
                <v-btn icon small class="ma-0" v-on="on">
                  <v-icon small color="primary lighten-1">delete</v-icon>
                </v-btn>
              </template>
            </confirm-action>

          </td>
        </tr>
      </template>
    </v-data-table>

    <v-bottom-sheet v-if="billables" lazy v-model="showForm">
      <v-card tile>
        <v-card-title primary-title>
          <h3 class="headline">{{ billableExists ? 'Edit Item' : 'Add New Item' }}</h3>
        </v-card-title>
        <v-container>
          <v-layout>
            <v-flex class="px-2">
              <v-select v-if="billable.category"
                v-model="billable.category" :items="categories"
                label="Category" item-text="name" item-value="id"
                return-object required
              ></v-select>
            </v-flex>
            <v-flex class="px-2">
              <v-combobox
                v-model="billable.tags" :items.sync="allTags"
                placeholder="Trade Names"
                multiple tags dense chips hide-selected deletable-chips required
              ></v-combobox>
            </v-flex>
            <v-flex class="px-2">
              <v-text-field v-model="billable.name" label="Part Number" required></v-text-field>
            </v-flex>
          </v-layout>
          <v-layout>
            <v-flex class="px-2">
              <v-text-field v-model="billable.size" label="Description"></v-text-field>
            </v-flex>
            <v-flex class="px-2">
              <v-text-field v-model="billable.unit" label="Unit"></v-text-field>
            </v-flex>
            <v-flex class="px-2">
              <v-text-field label="Cost" :value="billable.cost | monetaryPreScaled" @blur="handleCostInput(billable, $event)"></v-text-field>
            </v-flex>
            <v-flex class="px-2">
              <v-text-field v-model="billable.supplier" label="Supplier"></v-text-field>
            </v-flex>
          </v-layout>
          <v-btn @click="save" :disabled="!(billable.category && billable.category.id && billable.tags.length && billable.name.trim().length)">
            {{ billableExists ? 'Update' : 'Create' }}
          </v-btn>
          <v-btn @click="reset">
            Reset
          </v-btn>
          <v-btn @click="cancel">
            Cancel
          </v-btn>
        </v-container>
      </v-card>
    </v-bottom-sheet>

    <v-dialog v-model="showImportPrices" fullscreen hide-overlay transition="dialog-bottom-transition" lazy>
      <v-card style="background: none;">
        <v-toolbar color="orange">
          <v-toolbar-title>Import Pricing Data</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click.stop="showImportPrices = false"><v-icon>close</v-icon></v-btn>
        </v-toolbar>
        <import-prices v-if="true"></import-prices>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showImportBillables" fullscreen hide-overlay transition="dialog-bottom-transition" lazy>
      <v-card style="background: none;">
        <v-toolbar color="cyan">
          <v-toolbar-title>Import Billables</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click.stop="showImportBillables = false"><v-icon>close</v-icon></v-btn>
        </v-toolbar>
        <import-billables v-if="true"></import-billables>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { debounce } from 'lodash'
import { LIGHT_COLORS as COLORS } from '@/constants'
import { BILLABLE } from '@/graphql/models'
import { BILLABLES_QUERY, BILLABLE_TAGS_QUERY } from '@/graphql/queries'
import ConfirmAction from '@/components/ConfirmAction.vue'
import ImportBillables from '@/components/ImportBillables.vue'
import ImportPrices from '@/components/ImportPrices.vue'
import { clone, cloneExcept, getNestedValue, getUTCTimestamp, itemFromString } from '@/utils'
import { search } from '@/search'

const REPLACEMENT_PATTERNS = {
  black: /\b(?:blk|bl)\b/g,
  white: /\b(?:wht|wh)\b/g,
  ' ': /,/g
}

const UNIT_PATTERNS = {
  mm: /^( ?millimeters| ?milimeters| ?millimeter| ?milimeter| ?mm)\b/,
  cm: /^( ?centemeters| ?centemeter| ?cm)\b/,
  m: /^( ?meters| ?meter| ?m)\b/,
  // quotes don't play well with word boundries
  in: /^( ?inches\b| ?inch\b| ?in\b|["“”](?= |$))/, // double quote, left double, right double
  ft: /^( ?feet\b| ?foot\b| ?ft\b|['‘’](?= |$))/, // apostrophe, left single, right single
  //
  amp: /^( ?amperes| ?ampere| ?amps| ?amp|a)\b/,
  volt: /^( ?volts| ?volt|v)\b/,
  watt: /^( ?watts| ?watt|w)\b/,
  kw: /^( ?kilowatts| ?kilowatt| ?kw)\b/
}

export default {
  name: 'edit-billables',
  components: { ConfirmAction, ImportBillables, ImportPrices },
  props: {
    company: {}
  },
  data () {
    return {
      headers: [
        { text: 'Rel', align: 'right', value: '_relevance.total', width: '50px', class: 'px-1' },
        { text: 'Category', align: 'center', value: 'category.name', class: 'px-1' },
        { text: 'Trade Names', align: 'left', value: 'tags', sortable: false, class: 'px-1' },
        { text: 'Part #', align: 'left', value: 'name', class: 'px-1' },
        { text: 'Desc', align: 'center', value: 'size', class: 'px-1' },
        { text: 'Unit', align: 'center', value: 'unit', class: 'px-1' },
        { text: 'Supplier', align: 'left', value: 'supplier', size: '30px', class: 'px-1' },
        { text: 'Cost', align: 'center', value: 'cost', class: 'px-1' },
        { text: 'Updated', align: 'center', value: 'updated_at', class: 'px-1' },
        { text: 'Actions', align: 'center', value: '', sortable: false, class: 'pl-1 pr-3' }
      ],
      pagination: {
        sortBy: '_relevance.total',
        descending: true,
        page: 1,
        rowsPerPage: 25
      },
      quantity: 1,
      search: '',
      colors: COLORS,
      showForm: false,
      billable: null,
      tags: [],
      scrollingTo: -1,
      showImportPrices: false,
      showImportBillables: false
    }
  },
  apollo: {
    tags: {
      query: BILLABLE_TAGS_QUERY,
      pollInterval: 1000 * 60 * 1 // 1 minute
    }
  },
  computed: {
    billables () {
      const collator = new Intl.Collator(
        undefined,
        {
          caseFirst: 'false',
          ignorePunctuation: false,
          localeMatcher: 'best fit',
          numeric: true,
          sensitivity: 'base',
          usage: 'sort'
        }
      )
      const billables = [...this.$oxide.store.apollo.billables]
      return billables.sort((a, b) => {
        const comparison = collator.compare(a.category.name, b.category.name)
        if (comparison) {
          return comparison
        } else {
          return collator.compare(a.name, b.name)
        }
      })
    },
    categories () { return this.$oxide.store.apollo.categories },
    config () { return this.company && this.company.config ? this.company.config : [] },
    billableExists () { return this.billable?.id > 0 },
    allTags: {
      get () {
        return this.tags
      },
      set (value) {
        this.$emit('update:tags', value)
      }
    },
    allCategories: {
      get () {
        return this.categories
      },
      set (value) {
        this.$emit('update:categories', value)
      }
    },
    isLoading () { return this.billables.length === 0 && !this.isFiltered },
    isFiltered () { return this.search.trim().length !== 0 },
    billableCatalog () {
      return search(
        this.billables,
        {
          using: ['category.name', 'name', 'tags', 'size', 'supplier_name'],
          replacing: REPLACEMENT_PATTERNS,
          units: UNIT_PATTERNS
        }
      )
    },
    filteredBillables () {
      let query = this.search
      if (!query || query.trim() === '') {
        query = ''
      }
      return this.billableCatalog.findMatchesFor(query, { matchAllIfBlank: true }).getAll({ withRelevanceAs: '_relevance' })
      // return this.billableCatalog.resultsFor(query)
    },
    totalFilteredMatches () {
      if (this.isFiltered) {
        return this.filteredBillables.length
      } else {
        return undefined
      }
    }
  },
  methods: {
    getBillable (id) {
      let targetId = parseInt(id, 10)
      if (Number.isNaN(targetId)) {
        targetId = 0
      }
      return this.billables ? this.billables.find(billable => parseInt(billable.id, 10) === targetId) : undefined
    },
    setBillable (id = 0) {
      if (id) {
        this.billable = clone(this.getBillable(id))
      } else {
        this.billable = BILLABLE.blank
      }
    },

    handleCostInput (billable, event) {
      const value = typeof event.target.value === 'function' ? event.target.value() : event.target.value
      billable.cost = this.$Cent$.fromDollars(value).asCents
    },

    addBillable () {
      this.setBillable(0)
      this.showForm = true
    },
    editBillable (id) {
      this.setBillable(id)
      this.showForm = true
    },
    duplicateBillable (billable) {
      this.billable = cloneExcept(billable, ['id'])
      this.showForm = true
    },

    cancel () {
      this.clearForm()
      this.showForm = false
    },

    reset () {
      if (this.billableExists) {
        this.setBillable(this.billable.id)
      } else {
        this.setBillable(0)
      }
    },

    clearForm () { this.setBillable(0) },

    async save () {
      const billable = cloneExcept(
        this.billable,
        ['__typename', 'company_id', 'category', 'category_id', 'created_at', 'updated_at', 'relevance', '_relevance']
      )

      if (Array.isArray(billable.tags)) {
        billable.tags = JSON.stringify(billable.tags)
      }

      if (this.billableExists) {
        // update
        const previousVersion = this.getBillable(this.billable.id)
        let category = null
        if (this.billable.category.id !== previousVersion.category.id) {
          category = { connect: this.billable.category.id }
        }
        const vars = category ? { ...billable, category } : { ...billable }
        this.$apollo.mutate({
          mutation: BILLABLE.mutations.update.mutation,
          variables: { billable: vars },
          update: (store, { data: { updateBillable } }) => {
            const data = store.readQuery({ query: BILLABLES_QUERY })
            const id = parseInt(updateBillable.id, 10)
            const index = data.billables.findIndex(b => parseInt(b.id, 10) === id)
            data.billables[index] = clone(updateBillable)
            store.writeQuery({ query: BILLABLES_QUERY, data })
            this.scrollToBillable(updateBillable)
          },
          optimisticResponse: {
            __typename: 'Mutation',
            updateBillable: {
              __typename: 'Billable',
              ...billable,
              category: this.billable.category,
              created_at: this.billable.created_at,
              updated_at: getUTCTimestamp()
            }
          }
        })
      } else {
        // create
        const timestamp = getUTCTimestamp()
        this.$apollo.mutate({
          mutation: BILLABLE.mutations.create.mutation,
          variables: {
            billable: {
              ...billable,
              company: { connect: this.company.id },
              category: { connect: this.billable.category.id }
            }
          },
          update: (store, { data: { createBillable } }) => {
            const data = store.readQuery({ query: BILLABLES_QUERY })
            const tempBillable = clone(createBillable)
            data.billables.push(tempBillable)
            store.writeQuery({ query: BILLABLES_QUERY, data })
            this.$nextTick(() => { this.scrollToBillable(tempBillable) })
          },
          optimisticResponse: {
            __typename: 'Mutation',
            createBillable: {
              __typename: 'Billable',
              id: -1,
              ...billable,
              created_at: timestamp,
              updated_at: timestamp,
              company: this.company,
              category: this.billable.category
            }
          }
        })
      }
      this.clearForm()
      this.showForm = false
    },

    async deleteBillable (id) {
      this.$apollo.mutate({
        mutation: BILLABLE.mutations.delete.mutation,
        variables: { id },
        update: BILLABLE.mutations.delete.update
      })
    },

    async scrollToBillable (billable) {
      const id = parseInt(billable.id, 10)
      if (this.scrollingTo !== id) {
        this.scrollingTo = id
        let index = null
        if ((!Number.isNaN(id)) && id > 0) {
          index = this.billables.findIndex(billable => parseInt(billable.id, 10) === id)
        } else {
          index = this.billables[this.billables.length - 1]
        }
        const page = this.pagination.rowsPerPage > 0 ? Math.ceil(index / this.pagination.rowsPerPage) : 1
        console.log(index, this.pagination.rowsPerPage, page)

        this.pagination.page = page
        this.$nextTick(() => {
          try {
            this.$vuetify.goTo('.scroll-target', { offset: 200 })
            setTimeout(() => {
              this.scrollingTo = -1
            }, 5000)
          } catch (e) {
            console.log(e)
          }
        })
      }
    },

    getColor (str) { return itemFromString(COLORS, str) },

    getCategoryName (id) {
      if (!this.$apollo.queries?.categories?.loading) {
        const category = this.categories.find(category => parseInt(category.id, 10) === parseInt(id, 10))
        if (category) {
          return category.name
        }
      }
      return ''
    },
    nomenclature (str) {
      return getNestedValue(this.config, `nomenclature/${str}`, { defaultValue: str })
    },
    handleSearch: debounce(function (query) {
      this.search = query
    }, 350)
  },
  watch: {
    billable (val, old) {
      if (typeof val.category === 'undefined') {
        if (!this.categories.length) {
          val.category = {}
        } else {
          val.category = { id: this.categories[0].id, name: this.categories[0].name }
        }
        return val
      }
    }
  },
  created () {
    this.billable = BILLABLE.blank
  }
}
</script>

<style lang="scss">
#edit-materials {
  .edit-materials-table-tags {
    .v-chip.v-chip--small {
      height: 17px !important;
      margin: 2px;
      box-shadow: inset 0 0 0 9px rgba(0,0,0,0.15);
    }
    .v-chip__content {
      font-size: 11px;
      padding: 0 6px;
      height: 20px;
    }
  }
  table.v-table {
    thead,
    tbody {
      tr {
        &[active],
        &.scroll-target {
          background-color: #B2EBF2;
        }
      }
      th,
      td {
        &:first-child,
        &:not(:first-child) {
          padding: 0 16px;
        }
      }
    }
  }
}
</style>
