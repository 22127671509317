<template>
  <v-container class="manage-job" grid-list-xl fluid pa-0 ma-0 v-if="loaded">
    <v-layout row wrap justify-center ma-0 pt-3 py-0>
      <v-flex :class="{'is-loading': loading}" class="py-0">

        <manage-entity
          title="Job" icon="assignment" color="deep-orange darken-2"
          :entity="entity" :loading="loading" :path="path" :query="query"
          @save="saveTitle" :backText="client ? client.name : ''" :details-count="detailsCount" :details="showDetailsOnMount" @update:details="updateDescriptionHeight"
        >

          <template #details-toolbar="{ expanded }">
            <template v-if="admin && expanded">
              <v-spacer></v-spacer>
              <confirm-action v-if="deletable" @confirm="deleteJob" confirm-text="Archive this job" action-text="Confirm" align="left">
                <template v-slot:act="{ on }">
                  <v-btn icon v-on="on">
                    <v-tooltip bottom nudge-left="10">
                      <v-icon slot="activator" color="red">archive</v-icon>
                      <span>Archive Job</span>
                    </v-tooltip>
                  </v-btn>
                </template>
              </confirm-action>
              <v-btn v-else icon disabled>
                <v-tooltip left nudge-left="10">
                  <v-icon slot="activator" color="red">archive</v-icon>
                  <div class="subheading">Cannot Archive Because:</div>
                  <div v-if="isDirty">- there are unsaved changes</div>
                  <div v-if="hoursCount > 0">- there {{ hoursCount === 1 ? 'is' : 'are' }} {{ hoursCount }} time entries</div>
                  <div v-if="invoicesCount > 0">- there {{ invoicesCount === 1 ? 'is' : 'are' }} {{ invoicesCount }} invoices</div>
                </v-tooltip>
              </v-btn>
            </template>
          </template>

          <template #preContent>
            <v-layout row wrap pa-0 ma-0 v-if="local">
              <v-flex sm12 xs12>
                <v-text-field
                  v-if="nomenclature('job_identifier')" v-model="local.identifier"
                  :label="nomenclature('job_identifier')"
                  hide-details clearable
                ></v-text-field>
              </v-flex>

              <v-flex sm12 xs12 v-if="billingTypeKeys.length" style="display: flex; align-items: flex-end;">
                <template v-if="billingTypeKeys.length === 2">
                  <v-switch hide-details v-model="local.billing_type" :value-comparator="(a,b) => a == b" :true-value="billingTypeKeys[1]" :false-value="billingTypeKeys[0]" :readonly="!canChangeBillingType" color="cyan" class="billing_type_switch">
                    <template #prepend>
                      <span class="green--text font-weight-medium" :class="{ 'text--darken-1': local.billing_type === billingTypeKeys[0], 'text--lighten-4': local.billing_type !== billingTypeKeys[0] }">
                        {{ billingTypes[billingTypeKeys[0]].name }}
                      </span>
                    </template>
                    <template #append>
                      <span class="cyan--text font-weight-medium" :class="{ 'text--darken-1': local.billing_type === billingTypeKeys[1], 'text--lighten-4': local.billing_type !== billingTypeKeys[1] }">
                        {{ billingTypes[billingTypeKeys[1]].name }}
                      </span>
                    </template>
                  </v-switch>
                </template>
                <template v-else>
                  <v-spacer></v-spacer>
                  <v-radio-group
                    v-model="local.billing_type" :readonly="!canChangeBillingType"
                    :value-comparator="(a,b) => a == b"
                    mandatory row hide-details class="pt-0 mt-0" style="width: auto;"
                  >
                    <template v-for="(type, key) of billingTypes">
                      <v-radio v-if="!type.deleted" :key="key" :label="type.name" :value="parseInt(key, 10)"></v-radio>
                    </template>
                  </v-radio-group>
                  <v-spacer></v-spacer>
                </template>
              </v-flex>

              <v-flex xs12>
                <v-select
                  multiple chips deletable-chips small-chips dense hide-details
                  label="Job Status" :menu-props="{ maxHeight: 250 }"
                  :items="statuses" v-model="localStatus">
                </v-select>
              </v-flex>

              <v-flex xs12>
                <v-textarea outline hide-details auto-grow rows="1" style="font-size: 14px;" ref="jobDescription"
                  v-model="local.description" label="description" color="deep-orange darken-2"
                ></v-textarea>
              </v-flex>
            </v-layout>
          </template>

          <template #postContent>
            <v-expansion-panel>
              <v-expansion-panel-content lazy>
                <template #header>
                  <div class="body-2">Timespan</div>
                </template>
                <v-list subheader class="date-pickers">
                  <v-subheader>Start Date</v-subheader>
                  <v-list-tile>
                    <v-list-tile-action>
                      <v-icon>event</v-icon>
                    </v-list-tile-action>
                    <v-list-tile-content>
                      <v-layout ma-0 pa-0>
                        <v-flex xs6 pa-0 pr-2>
                          <v-menu v-model="startPickerOpen" :close-on-content-click="false" min-width="290px" offset-y lazy>
                            <template v-slot:activator="{ on }">
                              <v-text-field v-model="local.start" v-on="on" label="Planned" color="deep-orange darken-2" readonly hide-details clearable></v-text-field>
                            </template>
                            <v-date-picker v-model="local.start" @input="startPickerOpen = false" no-title scrollable></v-date-picker>
                          </v-menu>
                        </v-flex>

                        <v-flex xs6 pa-0 pl-2>
                          <v-menu v-model="startedPickerOpen" :close-on-content-click="false" min-width="290px" offset-y lazy>
                            <template v-slot:activator="{ on }">
                              <v-text-field v-model="local.started" v-on="on" label="Actual" color="deep-orange darken-2" readonly hide-details clearable></v-text-field>
                            </template>
                            <v-date-picker v-model="local.started" @input="startedPickerOpen = false" no-title scrollable></v-date-picker>
                          </v-menu>
                        </v-flex>
                      </v-layout>
                    </v-list-tile-content>
                  </v-list-tile>

                  <v-subheader>Finish Date</v-subheader>
                  <v-list-tile>
                    <v-list-tile-action>
                      <v-icon>event_available</v-icon>
                    </v-list-tile-action>
                    <v-list-tile-content>
                      <v-layout ma-0 pa-0>
                        <v-flex xs6 pa-0 pr-2>
                          <v-menu v-model="finishPickerOpen" :close-on-content-click="false" min-width="290px" offset-y lazy>
                            <template v-slot:activator="{ on }">
                              <v-text-field v-model="local.finish" v-on="on" label="Planned" color="deep-orange darken-2" readonly hide-details clearable></v-text-field>
                            </template>
                            <v-date-picker v-model="local.finish" @input="finishPickerOpen = false" no-title scrollable></v-date-picker>
                          </v-menu>
                        </v-flex>

                        <v-flex xs6 pa-0 pl-2>
                          <v-menu v-model="finishedPickerOpen" :close-on-content-click="false" min-width="290px" offset-y lazy>
                            <template v-slot:activator="{ on }">
                              <v-text-field v-model="local.finished" v-on="on" label="Actual" color="deep-orange darken-2" readonly hide-details clearable></v-text-field>
                            </template>
                            <v-date-picker v-model="local.finished" @input="finishedPickerOpen = false" no-title scrollable></v-date-picker>
                          </v-menu>
                        </v-flex>
                      </v-layout>
                    </v-list-tile-content>
                  </v-list-tile>
                </v-list>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <!-- <v-layout ma-0 px-3 pb-4 pt-1>
              <v-select label="Status" v-model="local.status" :items="statuses" hide-details></v-select>
            </v-layout> -->

            <v-layout ma-0 pa-4 v-show="isDirty">
              <v-btn small class="ma-0" @click="reset">cancel</v-btn>
              <v-spacer></v-spacer>
              <v-btn small dark class="ma-0" @click="save" color="primary">save</v-btn>
            </v-layout>
            <!-- <v-layout v-if="admin && !isDirty" row ma-0 pa-4>
              <v-spacer></v-spacer>
              <confirm-action @confirm="deleteJob" confirm-text="Did I stutter?" action-text="Sorry Sir, right away" align="left">
                <template v-slot:act="{ on }">
                  <v-btn small outline dark color="grey" v-on="on">delete job</v-btn>
                </template>
              </confirm-action>
            </v-layout> -->

          </template>

          <template #bottom>
            <note-list :notableId="id" notableType="App\Job" :path="path" :notes="messages" :query="query"></note-list>
          </template>
        </manage-entity>
      </v-flex>

      <v-flex class="py-0">
        <div class="elevation-1">
          <time-list
            :jobId="id" :path="path" :hours="hours"
            class="is-loading-overlay" :class="{'is-loading': loading}"
            @create:invoice="showCreateInvoice = true"
            ref="timeList"></time-list>
          <manage-lists v-if="false" :notableId="id" notableType="App\Job" :parentPath="path" :lists="lists" class="is-loading-overlay" :class="{'is-loading': loading}"></manage-lists>
          <manage-list-sets v-if="true" :job="job" :lists="listSets" class="is-loading-overlay" :class="{'is-loading': loading}"></manage-list-sets>
          <manage-billables
            :job="job" :set="billablesSet"
            @create:invoice="showCreateInvoice = true"
            class="is-loading-overlay" :class="{'is-loading': loading}" ref="manageBillables">
          </manage-billables>
          <invoice-list
            v-if="admin"
            :parentId="id" parentType="App\Job" :parentPath="path"
            :invoices="job.invoices"
            :unbilled-items="unbilledBillables.length > 0 || unbilledHours.length > 0" @create:invoice="showCreateInvoice = true"
          ></invoice-list>
          <manage-quote
            v-if="true"
            :jobId="id" :path="path" :quotes="quotes"
            ref="manageQuote"
          ></manage-quote>
        </div>
      </v-flex>

    </v-layout>
    <!-- <v-btn @click="getLoc">Toast</v-btn> -->

    <v-dialog v-model="showCreateInvoice" max-width="700px" lazy persistent>
      <v-card>
        <v-btn
          :disabled="addingToInvoice" @click="showCreateInvoice = false"
          absolute dark icon small style="top: 10px; right: 10px;">
          <v-icon>close</v-icon>
        </v-btn>
        <v-card-title>
          <v-spacer></v-spacer>
          <div class="display-1 font-weight-light text-xs-center">Add To Invoice</div>
          <v-spacer></v-spacer>
        </v-card-title>
        <div class="title px-4">Unbilled Items</div>
        <v-expansion-panel class="elevation-1" v-model="unbilled.expansionPanelOpened">
          <v-expansion-panel-content lazy :disabled="unbilledHours.length === 0 && parseFloat(unbilledHoursTotalTime) === 0">
            <template #header>
              <span class="subheading" :class="{ 'blue--text': unbilled.expansionPanelOpened === 0 }">
                <v-icon :color="unbilled.expansionPanelOpened === 0 ? 'blue' : ''">timer</v-icon> Hours
              </span>
              <span class="text-xs-right pr-4">
                <span class="font-mono">{{ formatHour(unbilledHoursTotalTimeSelected) }}</span>
                <span class="grey--text text--darken-1"> of </span>
                <span class="font-mono">{{ formatHour(unbilledHoursTotalTime) }}</span>
              </span>
            </template>

            <v-data-table
              v-model="unbilled.hours.selected" :headers="unbilled.hours.headers"
              :items="unbilledHours" :total-items="unbilledHours.length"
              item-key="id" style="max-height: 300px; overflow-y: scroll;"
              hide-actions disable-initial-sort select-all>
              <template v-slot:items="props">
                <td><v-checkbox v-model="props.selected" color="blue" hide-details></v-checkbox></td>
                <td class="px-1">{{ getFormattedDate(props.item.date) }}<br><span class="caption font-weight-thin">{{ props.item.date }}</span></td>
                <td class="px-1 text-xs-center">
                  <span>{{ props.item.employee.name }}</span><br>
                  <span class="font-italic">{{ props.item.role }}</span>
                </td>
                <!-- <td class="px-1 text-xs-right font-mono">{{ formatHour(props.item.time) }}</td> -->
                <td class="px-3 text-xs-right font-mono">
                  {{ formatHour(props.item.time) }}
                  <span class="pink--text"> @</span>
                  <span v-html="props.item.rate < 1000 ? '&nbsp;&nbsp;$' : '&nbsp;$'"></span>{{ props.item.rate | monetaryPreScaled }}
                </td>
              </template>
            </v-data-table>
          </v-expansion-panel-content>

          <v-expansion-panel-content lazy :disabled="unbilledBillables.length === 0 && unbilledBillablesTotalQuantity === 0">
            <template #header>
              <span class="subheading" :class="{ 'teal--text': unbilled.expansionPanelOpened === 1 }">
                <v-icon :color="unbilled.expansionPanelOpened === 1 ? 'teal' : ''">receipt</v-icon> Billables
              </span>
              <span class="text-xs-right pr-4">
                <span class="font-mono">{{ unbilled.billables.selected.length }}</span>
                <span class="grey--text text--darken-1"> of </span>
                <span class="font-mono">{{ unbilledBillables.length }}</span>
              </span>
            </template>
            <v-data-table
              v-model="unbilled.billables.selected" :headers="unbilled.billables.headers"
              :items="unbilledBillables" :total-items="unbilledBillables.length"
              item-key="uuid" style="max-height: 300px; overflow-y: scroll;"
              hide-actions disable-initial-sort select-all>
              <template v-slot:items="props">
                <td><v-checkbox v-model="props.selected" color="teal" hide-details></v-checkbox></td>
                <td class="px-1">
                  <span
                    :class="{ 'deep-orange--text': !props.item.data.id, 'subheading': !props.item.data.size }"
                    v-text="props.item.data.name"></span>
                  <span v-if="props.item.data.supplier" class="caption">
                    <span class="font-italic grey--text text--darken-1"> from </span>
                    <span class="grey--text text--darken-2">{{ props.item.data.supplier }}</span>
                  </span>
                  <template v-if="props.item.data.size">
                    <br><span class="font-weight-light">{{ props.item.data.size }}</span>
                  </template>
                </td>
                <td class="px-3 text-xs-right">
                  <span class="font-all-small-caps">{{ props.item.data.unit }}</span>
                  <span class="grey--text">x</span>
                  <span class="font-mono" :class="{ 'red--text': !props.item.value_1 }">{{ props.item.value_1 ? props.item.value_1 : '0' }}</span>
                </td>
              </template>
            </v-data-table>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-card-actions>

          <v-btn :disabled="addingToInvoice" @click="showCreateInvoice = false">Cancel</v-btn>
          <v-spacer></v-spacer>
          <v-select label="Invoice" v-model="addToInvoiceSelectedInvoice" :items="addToInvoiceInvoiceList"></v-select>
          <v-spacer></v-spacer>
          <v-btn :disabled="addingToInvoice" color="green" :dark="!addingToInvoice" @click="createInvoice">
            <v-icon>vertical_split</v-icon> {{ addingToInvoiceStatus ? addingToInvoiceStatus : (addToInvoiceSelectedInvoice === -1 ? 'Create Invoice' : 'Add To Invoice') }}
          </v-btn>
        </v-card-actions>
        <v-progress-linear class="my-0" :active="addingToInvoice" :value="addingToInvoiceProgressPercentage" :color="addingToInvoiceProgressColor"></v-progress-linear>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="showSnackbar" :color="snackbarColor" top>
      {{ snackbarText }}
      <v-btn dark flat @click="showSnackbar = false">
        Close
      </v-btn>
    </v-snackbar>

    <v-footer style="background: none; opacity: 0.5;">
      <v-spacer></v-spacer>
      <div class="caption"><span style="opacity: 0.75;">updated </span>{{ queryMetaUpdated }}<span style="opacity: 0.6;"> as of </span>{{ queryMetaFetched }}</div>
      <v-spacer></v-spacer>
    </v-footer>
  </v-container>
</template>

<script>
/* eslint-disable camelcase */
/* eslint-disable handle-callback-err */

import { equivalent, clone, cloneExcept, cloneOnly, dataToString, findKeyByProperty, formatDecimal, getDate } from '@/utils'
import { JOB_STATUSES } from '@/constants'
import { ENTRY, INVOICE, JOB, SET } from '@/graphql/models'
import { add, differenceInDays, format, formatDistance, formatRelative, isBefore, parseISO } from 'date-fns/esm'
import { ConfirmAction, InvoiceList, ManageBillables, ManageEntity, ManageLists, ManageListSets, ManageQuote, NoteList, TimeList } from '@/components'

export default {
  name: 'manage-job',
  components: { ConfirmAction, InvoiceList, ManageBillables, ManageEntity, NoteList, ManageLists, ManageListSets, ManageQuote, TimeList },
  data () {
    return {
      local: JOB.blank,
      startPickerOpen: false,
      startedPickerOpen: false,
      finishPickerOpen: false,
      finishedPickerOpen: false,
      showDetails: false,
      showCreateInvoice: false,
      statuses: JOB_STATUSES,
      unbilled: {
        billables: {
          headers: [
            { text: 'Item', align: 'left', value: 'name', class: 'px-1' },
            { text: 'Quantity', align: 'right', value: 'value_1', class: 'px-3' }
          ],
          selected: []
        },
        hours: {
          headers: [
            { text: 'Date', align: 'left', value: 'date', class: 'px-1' },
            { text: 'Employee', align: 'center', value: 'employee.name', class: 'px-1' },
            { text: 'Hours / Rate', align: 'right', value: 'time', class: 'px-3' }
          ],
          selected: []
        },
        expansionPanelOpened: null
      },
      addingToInvoice: false,
      saving: false,
      addingToInvoiceStatus: '',
      addingToInvoiceProgressColor: 'green',
      addingToInvoiceProgressPercentage: 0,
      addToInvoiceSelectedInvoice: -1,
      showSnackbar: false,
      snackbarText: '',
      snackbarColor: '',
      creatingBillablesSet: false
    }
  },
  props: {
    modelId: {},
    company: {}
  },
  computed: {
    me () { return this.$oxide.store.apollo.user },
    client () { return this.$oxide.store.apollo.client },
    admin () { return !!this.me?.is_admin },
    config () { return this.company && this.company.config ? this.company.config : [] },
    loaded () { return !!(!this.loading && this.client?.id && this.job?.id) },
    loading () { return !(this.client !== null && typeof this.client !== 'undefined') },
    path () { return `client/jobs/${this.id}` },
    id () { return parseInt(this.modelId, 10) },
    activeClientId () { return this.$oxide.store.activeClientId },
    job () { return this.client?.jobs?.find(job => parseInt(job.id, 10) === this.id) },
    quotes () { return this.job?.quotes },
    sets () { return this.job?.sets ? this.job.sets : [] },
    now () { return this.$oxide.bus.now },
    queryMeta () { return this.$oxide.store.apollo._meta[`client:${this.client?.id}`] },
    queryMetaFetched () { return (this.now && this.queryMeta?.fetched) ? formatDistance(this.queryMeta?.fetched, this.now, { includeSeconds: true, addSuffix: true }) : '' },
    queryMetaUpdated () { return (this.now && this.queryMeta?.updated) ? formatDistance(this.queryMeta?.updated, this.now, { includeSeconds: true, addSuffix: true }) : '' },

    billablesSet () { return this.sets.find(set => set.type === 'Job/Billable') },

    hours () { return this.job?.hours },
    sortedHours () { return [...this.hours].sort((a, b) => Date.parse(b.date) - Date.parse(a.date)) },
    unbilledHours () {
      const hours = this.sortedHours.filter(hour => !hour.invoice)
      hours.forEach(hour => {
        if (!hour.role) {
          hour.role = hour.employee.title
        }
        if (!hour.rate) {
          hour.rate = hour.employee.rate
        }
      })
      return hours
    },
    unbilledHoursTotalTime () { return this.unbilledHours?.reduce((total, hour) => total + hour.time, 0) },
    unbilledHoursTotalTimeSelected () { return this.unbilled.hours.selected?.reduce((total, hour) => total + hour.time, 0) },

    notes () { return this.job?.notes },
    lists () { return this.job?.notes?.filter(note => note.type === 'LIST' || note.type === 'MANIFEST') },
    listSets () { return this.sets.filter(set => set.type.startsWith('Job/List/')) },

    billables () { return this.billablesSet?.entries ? this.billablesSet.entries : [] },
    unbilledBillables () { return this.billables.filter(line => !line.value_3) },
    unbilledBillablesTotalQuantity () { return this.unbilledBillables.reduce((total, line) => total + parseInt((line.value_1 ? line.value_1 : '0'), 10), 0) },
    unbilledBillablesTotalQuantitySelected () { return this.unbilled.billables.selected.reduce((total, line) => total + parseInt((line.value_1 ? line.value_1 : '0'), 10), 0) },

    messages () { return this.notes?.filter(note => note.type === 'IMAGE' || note.type === 'LINK' || note.type === 'TEXT').sort((a, b) => b.id - a.id) },
    entity () { return { id: this.job?.id, title: this.job?.title, type: JOB.name, addresses: this.job?.addresses, contacts: this.job?.contacts } },

    isDirty () {
      return this.loaded && typeof this.local !== 'undefined' && !equivalent(
        this.job,
        this.local,
        {
          ignore: [
            '__typename',
            '_relevance',
            'addresses',
            'contacts',
            'created_at',
            'hours',
            'invoices',
            'notes',
            'quotes',
            'sets',
            'updated_at'
          ]
        }
      )
    },

    dirtyFields () {
      if (this.isDirty) {
        const fields = ['billing_type', 'description', 'finish', 'finished', 'identifier', 'start', 'started', 'title', 'status']
        const dirtyFields = []
        for (const field of fields) {
          if (!equivalent(this.job[field], this.local[field])) {
            dirtyFields.push(field)
          }
        }
        return dirtyFields
      } else {
        return []
      }
    },

    // billingTypes () { return Object.values(this.config?.job_billing_types ? this.config.job_billing_types : {}) },
    billingTypes () { return this.config?.job_billing_types ? this.config.job_billing_types : {} },
    billingTypeKeys () { return Object.keys(this.billingTypes) },

    query () { return this.$oxide.store.clientQuery },

    localStatus: {
      get () {
        if (this.local !== null && typeof this.local === 'object' && typeof this.local.status !== 'string') {
          return Array.isArray(this.local.status) ? this.local.status : []
        } else {
          try {
            const status = JSON.parse(this.local.status)
            return Array.isArray(status) ? status : []
          } catch (e) {
            return []
          }
        }
      },
      set (val) {
        this.local.status = JSON.stringify(Array.isArray(val) ? val : [])
      }
    },

    detailsCount () { return this.job.addresses.length + (this.job.description ? 1 : 0) + (this.job.status?.length ? 1 : 0) },
    hoursCount () { return Array.isArray(this.job.hours) ? this.job.hours.length : 0 },
    invoicesCount () { return Array.isArray(this.job.invoices) ? this.job.invoices.length : 0 },
    deletable () { return !(this.isDirty || this.hoursCount > 0 || this.invoicesCount > 0) },
    showDetailsOnMount () { return this.detailsCount > 0 && (window.innerWidth > 1174 || window.innerHeight > 999) },
    addToInvoiceInvoiceList () { return [{ value: -1, text: 'new invoice' }, ...this.job.invoices.map(invoice => ({ value: invoice.id, text: invoice.name }))] },

    canChangeBillingType () { return this.admin || isBefore(this.now, add(parseISO(this.job.created_at + 'Z'), { minutes: 15 })) }
  },
  methods: {
    getLoc () { this.$getLocation({ enableHighAccuracy: true }).then(coordinates => { console.log(coordinates) }) },

    async saveTitle ({ title }) {
      this.local.title = title
      await this.save()
    },

    reset () { this.local = clone(this.job) },

    async save () {
      this.saving = true
      const job = cloneOnly(this.local, ['id', 'title', 'description', 'identifier', 'billing_type', 'start', 'started', 'finish', 'finished', 'status'])
      this.$apollo.mutate({
        mutation: JOB.mutations.update.mutation,
        variables: { job }
      }).then(({ data: { updateJob } }) => {
        this.saving = false
        this.snackbarText = 'Job saved'
        this.snackbarColor = 'deep-orange darken-2'
        this.showSnackbar = true
      })
    },

    async deleteJob () {
      if (this.id) {
        await this.$apollo.mutate({
          mutation: JOB.mutations.delete.mutation,
          variables: { id: this.id },
          update: (store, { data: { deleteJob } }) => {
            const data = store.readQuery(this.$oxide.store.clientQuery)
            const index = findKeyByProperty(data.client.jobs, 'id', this.id, { castToInt: true })
            data.client.jobs.splice(index, 1)
            store.writeQuery({ ...this.$oxide.store.clientQuery, data })
          }
        })
        this.$router.go(-1)
      }
    },

    nomenclature (str) {
      if (this.config?.nomenclature) {
        return this.config?.nomenclature[str]
      } else {
        return ''
      }
    },

    async createInvoice () {
      this.addingToInvoice = true

      if (this.dirty || this.saving) {
        this.addingToInvoiceStatus = 'Saving Job'
        if (this.saving) {
          return setTimeout(this.createInvoice, 500)
        } else {
          await this.save()
          return setTimeout(this.createInvoice, 500)
        }
      }

      const creatingInvoice = this.addToInvoiceSelectedInvoice === -1

      let step = 1
      const totalSteps = 1 + (this.unbilled.billables.selected.length ? 1 : 0) + this.unbilled.hours.length
      this.addingToInvoiceStatus = 'Creating Invoice'
      this.addingToInvoiceProgressColor = 'green'
      this.addingToInvoiceProgressPercentage = (step / totalSteps) * 100

      const invoice = creatingInvoice ? cloneExcept(INVOICE.blank, ['id', 'client_id', 'job_id']) : cloneExcept(this.job.invoices.find(invoice => invoice.id === this.addToInvoiceSelectedInvoice), ['__typename', 'created_at', 'updated_at', ''])
      if (invoice.name === '') {
        invoice.name = `${this.client.name} - ${this.job.title}`
      }
      const lines = [...this.unbilled.billables.selected].map(line => ({
        billable: line.data,
        quantity: line.value_1,
        cost: null
      }))
      const hours = Object.create(null)
      for (let i = 0; i < this.unbilled.hours.selected.length; i++) {
        hours[this.unbilled.hours.selected[i].rate] = (typeof hours[this.unbilled.hours.selected[i].rate] !== 'undefined' ? hours[this.unbilled.hours.selected[i].rate] : 0) + this.unbilled.hours.selected[i].time
      }
      for (const rate of Object.keys(hours)) {
        lines.push({
          billable: { id: 0, name: 'Labour', unit: 'hours', cost: rate },
          quantity: hours[rate] < 1 ? 1 : hours[rate]
        })
      }
      const existingLines = (creatingInvoice || !invoice.lines) ? [] : JSON.parse(invoice.lines)
      invoice.lines = JSON.stringify([...existingLines, ...lines])

      const options = creatingInvoice ? {
        mutation: INVOICE.mutations.create.mutation,
        variables: { invoice: { ...invoice, company: { connect: this.company.id }, job: { connect: this.job.id } } },
        update: INVOICE.mutations.create.update
      } : {
        mutation: INVOICE.mutations.update.mutation,
        variables: { invoice },
        update: INVOICE.mutations.update.update
      }

      this.$apollo.mutate(options).then(async ({ data }) => {
        if (creatingInvoice ? typeof data?.createInvoice?.id !== 'undefined' : typeof data?.updateInvoice?.id !== 'undefined') {
          if (this.unbilled.billables.selected.length) {
            this.addingToInvoiceStatus = 'Linking Billables'
            this.addingToInvoiceProgressColor = 'teal'
            this.addingToInvoiceProgressPercentage = (++step / totalSteps) * 100

            const promises = []
            for (const unbilled of this.unbilled.billables.selected) {
              const updateEntry = cloneExcept(unbilled, ['__typename', 'created_at', 'updated_at'])
              updateEntry.data = dataToString(unbilled.data)
              updateEntry.value_3 = creatingInvoice ? data.createInvoice.id : data.updateInvoice.id
              promises.push(this.$apollo.mutate({
                mutation: ENTRY.mutations.update.mutation,
                variables: { entry: updateEntry }
              }).then(() => {
                step++
                this.addingToInvoiceStatus = `Linking Billables (${step - 1}/${this.unbilled.hours.selected.length}`
                this.addingToInvoiceProgressPercentage = ((step) / totalSteps) * 100
              }))
            }
            await Promise.all(promises)
          }

          if (this.unbilled.hours.selected.length) {
            this.addingToInvoiceProgressColor = 'blue'
            for (let i = 0; i < this.unbilled.hours.selected.length; i++) {
              this.addingToInvoiceStatus = `Linking Hours (${i}/${this.unbilled.hours.selected.length}`
              this.addingToInvoiceProgressPercentage = ((step + i + 1) / totalSteps) * 100
              await this.$refs.timeList.saveHour(this.unbilled.hours.selected[i], null, creatingInvoice ? data.createInvoice : data.updateInvoice)
            }
          }
          this.addingToInvoiceProgressPercentage = 100
          this.addingToInvoice = false
          this.addingToInvoiceStatus = ''
          this.showCreateInvoice = false
          const route = { params: { invoiceId: creatingInvoice ? data.createInvoice.id : data.updateInvoice.id } }
          if (this.$route.name !== 'invoice') {
            route.name = 'invoice'
          }
          this.$router.push(route)
        }
      })
    },
    formatHour (h) {
      return formatDecimal(h, 2)
    },

    getDate () {
      return getDate()
    },

    getFormattedDate (date) {
      const now = new Date()
      const then = parseISO(date)
      const diff = Math.abs(differenceInDays(now, then))

      if (diff > 5) {
        return format(then, 'MMM do y')
      } else {
        return formatRelative(then, now).split(' at ')[0].split('last ').pop()
      }
    },

    updateDescriptionHeight (caller) {
      this.$nextTick(() => {
        if (this.$refs.jobDescription) {
          this.$refs.jobDescription.calculateInputHeight()
        }
      })
    },

    createBillablesSet () {
      if (!this.job?.id || this.creatingBillablesSet || this.billablesSet) {
        return false
      }
      this.creatingBillablesSet = true
      this.$apollo.mutate({
        mutation: SET.mutations.create.mutation,
        variables: { set: { title: 'Billables', type: 'Job/Billable', order: 1, setable_type: 'App\\Job', setable_id: this.job.id } }
      }).then(() => {
        setTimeout(() => {
          this.creatingBillablesSet = false
        }, 5000)
      })
    }
  },
  mounted () {
    this.local = clone(this.job)
    this.updateDescriptionHeight('mounted')
    this.$nextTick(() => {
      const app = this.$root.$children[0]
      if (typeof app?.$apollo?.client?.refetch === 'function') {
        console.log('refetch')
        app.$apollo.client.refetch()
      }
    })
    if (this.job && !this.billablesSet) {
      this.createBillablesSet()
    }
  },
  watch: {
    job (changed, old) {
      if (this.isDirty) {
        const dirty = {}
        for (const field of this.dirtyFields) {
          dirty[field] = this.local[field]
        }
        this.local = clone(changed)
        for (const field of Object.keys(dirty)) {
          this.local[field] = dirty[field]
        }
      } else {
        this.local = clone(changed)
      }
    },
    'job.id' (id) {
      let previouslyViewedJobs = this.$cache.get('previouslyViewedJobs', { defaultValue: [] })
      previouslyViewedJobs = previouslyViewedJobs.filter(jobId => jobId !== id)
      previouslyViewedJobs.unshift(id)
      if (previouslyViewedJobs.length > 5) {
        previouslyViewedJobs = previouslyViewedJobs.slice(0, 5)
      }
      this.$cache.set('previouslyViewedJobs', previouslyViewedJobs)
      this.$nextTick(() => {
        const app = this.$root.$children[0]
        if (typeof app?.$apollo?.client?.refetch === 'function') {
          console.log('refetch')
          app.$apollo.client.refetch()
        }
      })
      this.updateDescriptionHeight('job.id')
    },
    showCreateInvoice (show) {
      this.addToInvoiceSelectedInvoice = -1
      if (show) {
        this.unbilled.billables.selected = [...this.unbilledBillables]
        this.unbilled.hours.selected = [...this.unbilledHours]
      } else {
        this.unbilled.billables.selected = []
        this.unbilled.hours.selected = []
      }
    }
  }
}
</script>

<style lang="scss">
.manage-job {
  // width: 100%;
  .v-input--is-readonly,
  .v-input--is-readonly * {
    cursor: not-allowed;
  }
  margin-bottom: 75vh !important;
  & > .layout > .flex {
    width: 100%;
    max-width: 580px;
  }
  .date-pickers {
    .v-subheader {
      height: 36px;
      align-items: flex-end;
    }
    .v-list__tile__action {
      min-width: 40px;
    }
    .v-text-field {
      margin-top: 0;
      .v-input__icon--clear {
        width: 16px;
        min-width: 16px;
        .theme--light.v-icon {
          color: rgba(0,0,0,.33);
          font-size: 16px;
        }
        &:hover .theme--light.v-icon {
          color: rgba(0,0,0,.54);
        }
      }
    }
  }
  .billing_type_switch {
    margin-top: 0;
    justify-content: center;
    .v-input--switch__thumb,
    .v-input--selection-controls__ripple,
    .v-input--switch__track {
      color: #4caf50;
    }
    .v-input__slot {
      margin-bottom: 0;
    }
    .v-input--selection-controls__input {
      margin-right: 0;
    }
    & > div:first-child,
    & > div:last-child {
      // width: calc(50% - 19px);
      padding-top: 3px;
    }
    & > div:first-child {
      justify-content: flex-end;
    }
  }
}
@media (max-width: 600px) {
  .manage-job {
    & > .layout > .flex {
      padding: 0 !important;
    }
  }
}
// @media (min-width: 901px) {
  // .manage-job {
    // & > .layout > .flex {
      // max-width: 40%;
    // }
  // }
// }
@media (min-width: 601px) {
  .manage-job {
    & > .layout > .flex {
      padding-right: 16px !important;
      padding-left: 16px !important;
    }
    .billing_type_switch {
      justify-content: flex-end;
    }
  }
}
</style>
