import { defineModel, recordQueryMeta, store } from '@/utils'
import { CATEGORIES_QUERY } from '../queries'
import { CATEGORY_CREATE, CATEGORY_UPDATE, CATEGORY_DELETE } from '../mutations'
import { NetworkStatus } from 'apollo-client'

const CATEGORY = defineModel({
  name: 'Category',
  fields: { id: 0, name: '' },
  queries: {
    index: {
      query: CATEGORIES_QUERY,
      update ({ categories }) {
        store(categories, 'categories', 'apollo')
        return categories
      },
      result ({ data, loading, networkStatus }) {
        if (!loading && networkStatus === NetworkStatus.ready) {
          recordQueryMeta('categories', data.categories)
        }
      }
    }
  },
  mutations: {
    create: { mutation: CATEGORY_CREATE },
    update: { mutation: CATEGORY_UPDATE },
    delete: { mutation: CATEGORY_DELETE }
  }
})

export default CATEGORY
