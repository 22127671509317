<template>
  <section class="import-prices">
    <v-stepper v-model="step" non-linear>
      <v-stepper-header>
        <v-stepper-step color="purple" :editable="!headersMapped" :complete="file && headersMapped" step="1">Choose Settings</v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step color="teal" :editable="file && headersMapped" step="2">Filter Billables<small>optional</small></v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step color="green" :editable="file && headersMapped && canStart" step="3">Match Data to Billables</v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <v-container pb-2>

            <v-layout row ma-0>
              <v-flex grow>
                <input type="file" accept=".csv,application/pdf,.pdf" @change="handleFileInputChange" class="upload__input" ref="csvInput" style="width: 50%; margin-top: 4px; padding-top: 12px;">
              </v-flex>
              <v-flex shrink pl-4>
                <v-checkbox v-model="firstRowIsHeader" hide-details label="First row is header" class=""></v-checkbox>
              </v-flex>
            </v-layout>

            <v-layout row ma-0>
              <v-flex xs6 sm4 md2>
                <v-text-field label="Distributor" v-model="distributor" class=""></v-text-field>
              </v-flex>
              <v-spacer></v-spacer>
              <v-flex shrink>
                <v-checkbox v-model="autoSelect" hide-details label="Automatically select close matches" class=""></v-checkbox>
              </v-flex>
              <v-spacer></v-spacer>
              <v-flex shrink>
                <v-checkbox v-model="autoSkip" hide-details label="Skip rows with close matches and matching costs " class=""></v-checkbox>
              </v-flex>
            </v-layout>

            <v-layout row ma-0 pt-4 v-if="preview.rows.length">
              <v-flex text-xs-left headline>Preview</v-flex>
              <v-flex text-xs-right d-flex style="align-items: flex-end;">
                <div>Rows 1 - {{ preview.totalRows > 5 ? 5 : preview.totalRows }} of {{ preview.totalRows }}</div>
              </v-flex>
            </v-layout>
          </v-container>

          <v-container fluid v-if="dataType === 'fixed-width' && preview.totalRows === 0" style="max-height: 33vh; overflow-y: scroll;">
            <v-layout row>Page 1 of {{ fixedWidthPages }}</v-layout>
            <v-layout v-for="(row, index) of fixedWidthRowsRaw" :key="index" row ma-0 pa-0>
              <v-flex shrink>
                <v-btn v-if="index === fixedWidthHeaderIndex" small icon class="ma-0" @click="fixedWidthHeaderIndex = -1"><v-icon>check_box</v-icon></v-btn>
                <v-btn v-else small icon class="ma-0" @click="fixedWidthHeaderIndex = index" :disabled="row.trim() === ''"><v-icon>check_box_outline_blank</v-icon></v-btn>
              </v-flex>
              <v-flex d-flex align-center><pre>{{ row }}</pre></v-flex>
            </v-layout>
          </v-container>

          <table v-else class="v-table theme--dark preview--table" :class="{ 'truncated': preview.totalRows > 5 }">
            <thead v-if="preview.headers.length">
              <tr>
                <th
                  v-for="(header, index) of preview.headers" :key="index"
                  role="columnheader" scope="col" class="column text-xs-center"
                >{{ header }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(row, index) of preview.rows" :key="index">
                <td v-for="(field, header) of row" :key="index + ':' + header" class="text-xs-center">{{ field }}</td>
              </tr>
            </tbody>
          </table>

          <v-container py-2 v-if="preview.headers.length">
            <div class="headline pb-1">Column Mapping</div>
            <v-layout row wrap>
              <v-flex xs6 sm3>
                <v-select v-model="mapping.actual.name" :items="actualHeaders" label="Part Name"></v-select>
              </v-flex>
              <v-flex xs6 sm3>
                <v-select v-model="mapping.actual.description" :items="actualHeaders" label="Description"></v-select>
              </v-flex>
              <v-flex xs6 sm3>
                <v-select v-model="mapping.actual.cost" :items="actualHeaders" label="Cost"></v-select>
              </v-flex>
              <v-flex xs6 sm3>
                <v-select v-model="mapping.actual.unit" :items="actualHeaders" label="Unit"></v-select>
              </v-flex>
            </v-layout>
            <v-layout row>
              <v-spacer></v-spacer>
              <v-flex shrink>
                <v-btn small color="teal" @click="step = '2'" :disabled="!(file && headersMapped)">
                  Next
                  <v-icon small right>arrow_forward</v-icon>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-container>
        </v-stepper-content>

        <v-stepper-content step="2">
          <v-container pb-2>
            <v-layout row>
              <v-flex shrink headline>Filters</v-flex>
              <v-spacer></v-spacer>
              <v-flex shrink subtitle>
                find matches in {{ filteredBillables.length }} of {{ allBillables.length }} billables
              </v-flex>
            </v-layout>
            <v-layout row v-for="(filter, index) of filters" :key="index">
              <v-flex>
                <v-select v-model="filter.field" :items="fields" label="Field"></v-select>
              </v-flex>
              <v-flex>
                <v-select v-model="filter.operator" :items="operators" label="Type"></v-select>
              </v-flex>
              <v-flex>
                <v-text-field v-model="filter.value" label="Value"></v-text-field>
              </v-flex>
              <v-flex shrink mt-2>
                <v-btn icon @click="removeFilter(index)"><v-icon>clear</v-icon></v-btn>
              </v-flex>
            </v-layout>
            <v-layout mb-4>
              <v-spacer></v-spacer>
              <v-flex shrink>
                <v-btn flat color="teal" @click="addFilter">
                  <v-icon left>filter_alt</v-icon> Add Filter
                </v-btn>
              </v-flex>
              <v-spacer></v-spacer>
            </v-layout>
            <v-layout pt-4>
              <v-flex shrink>
                <v-btn small color="purple" @click="step = '1'">
                  <v-icon small left>arrow_back</v-icon> Previous
                </v-btn>
              </v-flex>
              <v-spacer></v-spacer>
              <v-flex shrink>
                <v-btn small color="green" @click="step = '3'">
                  Next <v-icon small right>arrow_forward</v-icon>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-container>
        </v-stepper-content>

        <v-stepper-content step="3">
          <template v-if="row < rows.length">
            <v-container v-if="selectedBillable === null && step === '3'" v-show="!hideBillables">
              <billables-search
                label="Search for a matching billable" show-price
                ref="billablesSearch" theme="dark"
                inject-relevance-as="_rel_"
                :filtered-billables="filteredBillables"
                :value="query"
                @input="query = $event"
                @change="handleBillableSelected"
                @update:results="handleResultsUpdate"
              ></billables-search>
              <v-layout>
                <v-spacer></v-spacer>
                <v-flex>
                  <v-btn color="purple" @click="skipRow">Skip Row</v-btn>
                </v-flex>
              </v-layout>
            </v-container>
            <template v-else-if="selectedBillable !== null">
              <div class="title text-xs-center pb-3" v-show="!hideBillables">Current Data</div>
              <table class="v-table theme--dark mb-4" v-show="!hideBillables">
                <thead>
                  <tr>
                    <th class="px-1 text-xs-center">Category</th>
                    <th class="px-1 text-xs-left">Trade Names</th>
                    <th class="px-1 text-xs-left">Part #</th>
                    <th class="px-1 text-xs-center">Desc</th>
                    <th class="px-1 text-xs-center">Unit</th>
                    <th class="px-1 text-xs-left" style="width: 30px;">Supplier</th>
                    <th class="px-1 text-xs-center">Cost</th>
                    <th class="px-1 text-xs-center">Updated</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="font-weight-medium text-xs-center px-1">{{ selectedBillable.category.name }}</td>
                    <td class="edit-materials-table-tags px-1">
                      <v-chip v-for="(tag, index) in selectedBillable.tags" :key="index" :color="getColor(tag, selectedBillable)" label small outline class="">{{ tag }}</v-chip>
                    </td>
                    <td class="text-xs-left font-weight-medium px-1">{{ selectedBillable.name }}</td>
                    <td class="text-xs-center px-1">{{ selectedBillable.size }}</td>
                    <td class="text-xs-center px-1">{{ selectedBillable.unit }}</td>
                    <td class="text-xs-center px-1">{{ selectedBillable.supplier }}</td>
                    <td class="text-xs-right px-1 red--text text--lighten-2 font-weight-medium">${{ selectedBillable.cost | monetaryPreScaled }}</td>
                    <td class="text-xs-center px-1 grey--text text--lighten-1 caption" style="line-height: 1.2;">
                      {{ (selectedBillable.updated_at ? selectedBillable.updated_at : '') | timespan }}
                    </td>
                  </tr>
                </tbody>
              </table>
              <v-container style="background-color: rgba( 255, 255, 255, 0.1);" v-show="!hideBillables">
                <v-layout row>
                  <v-spacer></v-spacer>
                  <v-flex xs3 sm2 md1 pr-1>
                    <v-text-field label="Current Price" :value="formatMonetaryPreScaled(selectedBillable.cost)" :messages="selectedBillable.unit" readonly></v-text-field>
                  </v-flex>
                  <v-flex xs3 sm2 md1 pl-1>
                    <v-text-field label="Imported Price" :value="formatMonetary(currentRow[mapping.actual.cost])" :messages="currentRow[mapping.actual.unit]" suffix="X" readonly></v-text-field>
                  </v-flex>
                  <v-flex xs3 sm2 md1 pr-1>
                    <v-autocomplete label="Multiplier" v-model="multiplier" :items="multipliers"></v-autocomplete>
                  </v-flex>
                  <v-flex xs3 sm2 md1 pl-2>
                    <v-text-field
                    label="Difference" readonly
                    :prefix="difference > 0 ? '+' : ''"
                    :success="difference < 0" :error="difference > 0"
                    :value="formatMonetaryPreScaled(difference)"
                    :hide-details="difference === 0"
                    :messages="percentDifference + '% ' + (difference > 0 ? ' increase' : 'decrease')"
                  ></v-text-field>
                  </v-flex>
                  <v-flex pl-4 ml-4 pt-3>
                    <v-btn color="green" :disabled="difference === 0" @click="updateBillable(importedCost * numericMultiplier)">Update Price</v-btn>
                    <v-btn :color="difference ? 'red' : 'blue'" @click="updateBillable()">{{ difference ? 'Ignore Change' : 'Next Row'}}</v-btn>
                    <v-btn color="grey darken-1" @click="selectedBillable = null">Not A Match</v-btn>
                    <v-btn color="purple" @click="skipRow">Skip Row</v-btn>
                  </v-flex>
                </v-layout>
              </v-container>
            </template>

            <div class="title text-xs-center py-3">Imported Data</div>
            <v-progress-linear v-if="rows.length" :value="row / rows.length * 100"></v-progress-linear>
            <table class="v-table theme--dark visible--table">
              <thead v-if="preview.headers.length">
                <tr>
                  <th v-for="(header, index) of preview.headers" :key="index" role="columnheader" scope="col" class="column text-xs-center">{{ header }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(row, index) of visibleRows" :key="index">
                  <td v-for="(field, header) of row" :key="index + ':' + header" class="text-xs-center">{{ field }}</td>
                </tr>
              </tbody>
            </table>
            <!-- <v-container>
              <v-layout row>
                <v-flex shrink>
                  <v-btn icon :disabled="row < 2" @click="row--"><v-icon>expand_less</v-icon></v-btn>
                </v-flex>
                <v-flex shrink>
                  <v-btn icon :disabled="row > rows.length - 1" @click="row++"><v-icon>expand_more</v-icon></v-btn>
                </v-flex>
              </v-layout>
            </v-container> -->
          </template>
          <template v-else>
            <div class="title text-xs-center pb-3">Results</div>
            <v-container>
              <v-layout ma-0 text-xs-center>
                <v-flex display-1><small>Skipped</small><br>{{ this.stats.skipped.length }}</v-flex>
                <v-flex display-1><small>Ignored</small><br>{{ this.stats.ignored.length }}</v-flex>
                <v-flex display-1><small>Updated</small><br>{{ this.stats.updated.length }}</v-flex>
              </v-layout>
            </v-container>
          </template>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </section>
</template>

<script>
import Papa from 'papaparse'
import { COLORS } from '@/constants'
import { clone, cloneOnly, formatMonetary, formatMonetaryPreScaled, itemFromString } from '@/utils'
import BillablesSearch from '@/components/BillablesSearch.vue'
import Cent$ from '@/cents'
import { BILLABLE } from '@/graphql/models'
// import pdfjs from 'pdfjs-dist/webpack'

const pdfjsLib = require('pdfjs-dist/webpack')

const indigoIndex = COLORS.indexOf('indigo')
COLORS[indigoIndex] = 'yellow'

export default {
  name: 'import-prices',
  components: { BillablesSearch },
  data () {
    return {
      step: '1',
      file: null,
      extension: '',
      firstRowIsHeader: true,
      distributor: '',
      errors: [],
      fatalError: false,
      preview: { parsing: false, headers: [], rows: [], totalRows: 0 },
      mapping: {
        guesses: { name: null, description: null, cost: null, unit: null },
        actual: { name: null, description: null, cost: null, unit: null }
      },
      filters: [],
      fields: ['name', 'size', 'unit', 'cost', 'supplier', 'updated_at'],
      operators: ['>', '>=', '=', '<=', '<'],
      row: 0,
      rows: [],
      matches: [],
      selectedBillable: null,
      query: '',
      multiplier: '1',
      multipliers: ['0.001', '0.01', '0.1', '1', '10', '100', '1000'],
      results: [],
      autoSelect: true,
      autoSkip: true,
      autoSelected: false,
      hideBillables: false,
      stats: {
        skipped: [],
        ignored: [],
        updated: []
      },
      dataType: 'csv',
      fixedWidthRowsRaw: [],
      fixedWidthRows: [],
      fixedWidthHeaderIndex: -1,
      fixedWidthHeadersRaw: '',
      fixedWidthColumns: [],
      fixedWidthPages: 0
    }
  },
  computed: {
    actualHeaders () { return this.preview.headers.slice(this.firstRowIsHeader ? 1 : 0) },
    canStart () { return (!this.fatalError) && this.file && (!this.preview.parsing) && (this.preview.totalRows > (this.firstRowIsHeader ? 1 : 0)) },
    headersMapped () { return Object.values(this.mapping.actual).every(map => map !== null) },
    allBillables () { return [...this.$oxide.store.apollo.billables] },
    filteredBillables () {
      const filters = this.filters.filter(filter => filter.field !== null && filter.operator !== null && filter.value !== null)
      const billables = [...this.$oxide.store.apollo.billables]
      const collator = new Intl.Collator(undefined, { caseFirst: 'false', ignorePunctuation: false, localeMatcher: 'best fit', numeric: true, sensitivity: 'base', usage: 'sort' })
      return billables.filter(billable => filters.every(filter => {
        const comparison = collator.compare(`${billable[filter.field]}`, filter.value)
        switch (filter.operator) {
          case '>': return comparison > 0
          case '>=': return comparison >= 0
          case '=': return comparison === 0
          case '<=': return comparison <= 0
          case '<': return comparison < 0
          default: return true
        }
      }))
    },
    currentRow () { return this.row > 0 ? this.rows[this.row - 1] : {} },
    visibleRows () {
      if (this.row > 0) {
        const rows = this.rows.slice(this.row - 1, this.row + 1)
        if (this.firstRowIsHeader) {
          return rows.map((row, index) => ({ 'Row #': this.row + index, ...row }))
        } else {
          return rows.map((row, index) => [this.row + index, ...row])
        }
      } else {
        return []
      }
    },
    importedCost () { return this.currentRow ? Cent$.fromDollars(this.currentRow[this.mapping.actual.cost]).asCents : 0 },
    existingCost () { return this.selectedBillable ? Cent$.fromCents(this.selectedBillable.cost).asCents : 0 },
    numericMultiplier () {
      const multiplier = Number(this.multiplier)
      return Number.isNaN(multiplier) ? 0 : multiplier
    },
    difference () { return (this.selectedBillable && this.currentRow) ? this.importedCost * this.numericMultiplier - this.existingCost : 0 },
    percentDifference () {
      return this.difference ? Math.round((this.difference > 0 ? (this.numericMultiplier * this.importedCost) / this.existingCost : this.existingCost / (this.numericMultiplier * this.importedCost)) * 100) / 100 : 0
    }
  },
  methods: {
    getColor (str) { return itemFromString(COLORS, str) },
    handleFileInputChange (e) {
      if (e && e.target && e.target.files && e.target.files.length && e.target.files[0].name && e.target.files[0].size) {
        const name = e.target.files[0].name.trim()
        const extensionSeparator = name.lastIndexOf('.')
        if (extensionSeparator !== -1) {
          this.extension = name.substring(extensionSeparator + 1).toLowerCase()
          this.file = e.target.files[0]
          console.log(this.file, this.extension)
          this.updatePreview(this.file)
        } else {
          console.log('invalid file', e.target.files[0])
        }
      } else {
        console.log('invalid file', e.target.files[0])
      }
    },
    handleBillableSelected (billable) { this.selectedBillable = billable },
    updatePreview (file) {
      this.preview.headers = []
      this.preview.rows = []
      this.preview.totalRows = 0
      this.preview.parsing = true
      this.errors = []
      this.row = 0

      if (this.extension === 'csv') {
        this.dataType = 'csv'
        Papa.parse(file, {
          header: this.firstRowIsHeader,
          skipEmptyLines: true,
          worker: true,
          step: row => {
            this.preview.totalRows++
            if (this.preview.totalRows < 6) {
              if (this.preview.totalRows === 1) {
                if (this.firstRowIsHeader && row.meta?.fields) {
                  this.preview.headers = ['Row #', ...row.meta.fields]
                }
              }
              if (this.firstRowIsHeader && this.preview.totalRows === 1 && row.meta?.fields) {
                this.preview.headers = ['Row #', ...row.meta.fields]
              }
              if (this.firstRowIsHeader) {
                this.preview.rows.push({ 'Row #': this.preview.totalRows, ...row.data })
              } else {
                this.preview.rows.push([this.preview.totalRows, ...row.data])
              }
            }
          },
          error: (error, file) => {
            this.errors.push(error)
            this.preview.parsing = false
            this.fatalError = true
          },
          complete: () => {
            this.preview.parsing = false
            const fieldCriteria = {
              name: ['name', 'part', 'part name', 'part #', 'part num', 'part number', 'item', 'item name', 'item #', 'item num', 'item number', 'number'],
              description: ['desc', 'description'],
              cost: ['cost', 'price'],
              unit: ['unit', 'uom']
            }
            if (this.firstRowIsHeader) {
              const headers = this.preview.headers.slice(1).map(h => h.trim().toLowerCase())
              for (const [field, criteria] of Object.entries(fieldCriteria)) {
                const guessIndex = headers.findIndex(h => criteria.some(c => h === c))
                if (guessIndex !== -1) {
                  this.mapping.guesses[field] = this.preview.headers[guessIndex + 1]
                }
              }
              this.$set(this.mapping, 'actual', clone(this.mapping.guesses))
            }
          }
        })
      } else if (this.extension === 'pdf') {
        this.dataType = 'fixed-width'
        this.firstRowIsHeader = true
        const fileReader = new FileReader()
        const componentThis = this
        fileReader.onload = function () {
          const typedArray = new Uint8Array(this.result)
          const document = pdfjsLib.getDocument(typedArray)
          document.promise.then(function (pdf) {
            componentThis.fixedWidthPages = pdf.numPages
            componentThis.fixedWidthRowsRaw = []
            for (let pageNumber = 1; pageNumber <= pdf.numPages; pageNumber++) {
              pdf.getPage(pageNumber).then(function (page) {
                const pageText = page.getTextContent()
                pageText.then(function (text) {
                  const lines = text.items.map(item => item.str)
                  // console.log(text.items, lines, lines.join('\n'))
                  componentThis.fixedWidthRowsRaw = componentThis.fixedWidthRowsRaw.concat(lines)
                })
              })
            }
          })
        }
        fileReader.readAsArrayBuffer(this.file)
      }
    },
    parseCSV (file) {
      Papa.parse(file, {
        header: this.firstRowIsHeader,
        skipEmptyLines: true,
        worker: true,
        complete: ({ data }) => {
          this.rows = [...data]
        }
      })
    },
    addFilter () { this.filters.push({ field: null, operator: null, value: null }) },
    removeFilter (index) { this.filters.splice(index, 1) },

    start () {
      if (this.dataType === 'csv') {
        this.parseCSV(this.file)
      }
      this.row = 1
    },
    formatMonetary (val) { return formatMonetary(val) },
    formatMonetaryPreScaled (val) { return formatMonetaryPreScaled(val) },

    skipRow () {
      this.stats.skipped.push({ index: this.row, row: this.currentRow })
      if (this.row < this.rows.length) { this.row++ }
    },

    updateBillable (price = null) {
      const billable = cloneOnly(this.selectedBillable, ['id', 'cost', 'name', 'size', 'supplier', 'supplier_name', 'supplier_desc', 'tags', 'unit'])

      billable.tags = JSON.stringify(billable.tags)
      let saveRequired = false
      if (price !== null) {
        billable.cost = price
        saveRequired = true
      }
      if (this.distributor) {
        if (billable.supplier !== this.distributor) {
          billable.supplier = this.distributor
          saveRequired = true
        }

        if (this.mapping.actual.name && this.currentRow[this.mapping.actual.name] && billable.supplier_name !== this.currentRow[this.mapping.actual.name]) {
          billable.supplier_name = this.currentRow[this.mapping.actual.name]
          saveRequired = true
        }
        if (this.mapping.actual.description && this.currentRow[this.mapping.actual.description] && billable.supplier_desc !== this.currentRow[this.mapping.actual.description]) {
          billable.supplier_desc = this.currentRow[this.mapping.actual.description]
          saveRequired = true
        }
      }
      if (saveRequired) {
        this.$apollo.mutate({
          mutation: BILLABLE.mutations.update.mutation, variables: { billable }
        }).then(({ data: { updateBillable } }) => {
          if (typeof updateBillable.tags === 'string' && updateBillable.tags) {
            updateBillable.tags = JSON.parse(updateBillable.tags)
          }
          this.stats[updateBillable.cost !== this.selectedBillable.cost ? 'updated' : 'ignored'].push({
            index: this.row,
            row: this.currentRow,
            beforeBillable: cloneOnly(this.selectedBillable, ['id', 'cost', 'name', 'size', 'supplier', 'supplier_name', 'supplier_desc', 'tags', 'unit']),
            afterBillable: cloneOnly(updateBillable, ['id', 'cost', 'name', 'size', 'supplier', 'supplier_name', 'supplier_desc', 'tags', 'unit']),
            multiplier: this.multiplier
          })
          const index = this.$oxide.store.apollo.billables.findIndex(b => b.id === updateBillable.id)
          this.$oxide.store.apollo.billables[index] = clone(updateBillable)
          this.selectedBillable = null
          if (this.row < this.rows.length) { this.row++ }
        })
      } else {
        this.stats.ignored.push({
          index: this.row,
          row: this.currentRow,
          beforeBillable: cloneOnly(this.selectedBillable, ['id', 'cost', 'name', 'size', 'supplier', 'supplier_name', 'supplier_desc', 'tags', 'unit'])
        })
        this.selectedBillable = null
        if (this.row < this.rows.length) { this.row++ }
      }
    },

    handleResultsUpdate (results) {
      this.results = results
    },

    selectClosestMatch (row = null) {
      if (row === null) { row = this.currentRow }
      if (this.autoSelected) { this.autoSelected = false }

      if (this.autoSelect && this.results?.length) {
        if (
          (
            this.distributor === '' || this.distributor === this.results[0].supplier
          ) &&
          (
            this.results[0].name === row[this.mapping.actual.name] || this.results[0].supplier_name === row[this.mapping.actual.name] || (this.results.length === 1 && this.results[0]._rel_.total > 4)
          )
        ) {
          this.autoSelected = true
          this.selectedBillable = this.results[0]
        } else if (this.results.length > 1) {
          const index = this.results.findIndex(result => result.name === row[this.mapping.actual.name] || result.supplier_name === row[this.mapping.actual.name])

          if (index > -1) {
            this.autoSelected = true
            this.selectedBillable = this.results[index]
          } else {
            this.hideBillables = false
          }
        } else {
          this.hideBillables = false
        }
      } else {
        this.hideBillables = false
      }
    },
    parseFixedWidthRow (row) {
      const values = Object.create(null)
      if (this.fixedWidthColumns.length === 0) {
        return null
      } else if (typeof row !== 'string' || row.length !== this.fixedWidthHeadersRaw.length || row.trim().length === 0 || row === this.fixedWidthHeadersRaw) {
        return null
      } else {
        let valid = true
        let lastCharacter = ' '
        for (const column of this.fixedWidthColumns) {
          const raw = row.substring(column.start, column.end)
          const val = raw.trim()
          values[column.name] = val
          // console.log({ raw, lastCharacter, column })
          if (val === '') {
            valid = false
          }
          if (!raw.startsWith(' ') && lastCharacter !== ' ') {
            valid = false
          }
          lastCharacter = row.substring(column.end - 1, column.end)
        }
        return valid ? values : null
      }
    },
    parseFixedWidthRows (rows, start = 0) {
      const parsedRows = []
      if (this.fixedWidthColumns.length > 0) {
        for (let index = start; index < rows.length; index++) {
          const row = this.parseFixedWidthRow(rows[index])
          if (row !== null) {
            parsedRows.push(row)
          }
        }
      }
      return parsedRows
    }
  },
  watch: {
    firstRowIsHeader (val) {
      if (this.$refs.csvInput.files?.length) {
        this.updatePreview(this.$refs.csvInput.files[0])
      }
    },
    step (val, old) {
      console.log(val, old, this.row)
      if (this.row === 0) { this.start() }
      if (old === '1' && val === '2' && this.filters.length === 0 && this.distributor) {
        this.filters.push({ field: 'supplier', operator: '=', value: this.distributor })
      }
      if (val === '3') {
        setTimeout(() => { this.selectClosestMatch(this.currentRow) }, 500)
      }
    },
    currentRow (row) {
      this.hideBillables = true
      this.selectedBillable = null
      if (row) {
        this.query = `${row[this.mapping.actual.name]} ${row[this.mapping.actual.description]}`.trim()
        setTimeout(this.selectClosestMatch.bind(this, row), 500)
      }
    },

    autoSelect (val) { if (!val && this.autoSkip) { this.autoSkip = false } },
    autoSkip (val) { if (val && !this.autoSelect) { this.autoSelect = true } },

    selectedBillable (billable) {
      this.multiplier = '1'
      if (billable === null || !this.currentRow) { return billable }
      const importedCost = Cent$.fromDollars(this.currentRow[this.mapping.actual.cost]).asCents
      const existingCost = Cent$.fromCents(billable.cost).asCents

      let closestMultiplier = null
      let smallestDifference = Number.MAX_SAFE_INTEGER
      let difference = null

      if (!billable.unit || !this.mapping.actual.unit || billable.unit === this.currentRow[this.mapping.actual.unit]) {
        //
      } else {
        for (const multiplier of this.multipliers) {
          const numericMultiplier = Number(multiplier)
          difference = Math.abs(importedCost * numericMultiplier - existingCost)
          if (difference < smallestDifference) {
            closestMultiplier = multiplier
            smallestDifference = difference
          } else {
            break
          }
        }

        this.multiplier = closestMultiplier
      }
      difference = Math.abs(importedCost * Number(this.multiplier) - existingCost)
      if (difference === 0 && this.autoSelected && this.autoSkip) {
        this.updateBillable()
      } else {
        this.hideBillables = false
      }
    },
    fixedWidthHeaderIndex (index) {
      if (index !== -1) {
        this.fixedWidthHeadersRaw = this.fixedWidthRowsRaw[index]
        const exp = /(^\s*)?(\S+\s?)+(\s{2,}|\s*$)/g
        const headers = this.fixedWidthHeadersRaw.matchAll(exp)

        for (const header of headers) {
          this.fixedWidthColumns.push({
            raw: header[0],
            name: header[0].trim(),
            start: header.index,
            end: header.index + header[0].length
          })
        }

        const rows = this.parseFixedWidthRows(this.fixedWidthRowsRaw, index + 1)
        console.log(rows)
        this.preview.headers = ['Row #', ...this.fixedWidthColumns.map(col => col.name)]
        this.preview.rows = rows.slice(0, 6).map((row, index) => ({ 'Row #': index + 1, ...row }))
        this.preview.totalRows = rows.length

        this.preview.parsing = false
        const fieldCriteria = {
          name: ['name', 'part', 'part name', 'part #', 'part num', 'part number', 'item', 'item name', 'item #', 'item num', 'item number', 'number'],
          description: ['desc', 'description'],
          cost: ['cost', 'price'],
          unit: ['unit', 'uom']
        }
        const guessHeaders = this.preview.headers.slice(1).map(h => h.trim().toLowerCase())
        for (const [field, criteria] of Object.entries(fieldCriteria)) {
          const guessIndex = guessHeaders.findIndex(h => criteria.some(c => h === c))
          if (guessIndex !== -1) {
            this.mapping.guesses[field] = this.preview.headers[guessIndex + 1]
          }
        }
        this.$set(this.mapping, 'actual', clone(this.mapping.guesses))
        this.rows = rows
      } else {
        this.fixedWidthHeadersRaw = ''
        this.fixedWidthColumns = []
        this.rows = []
      }
    }
  },
  mounted () {
    // console.log(Papa)
  }
}
</script>

<style lang="scss">
.import-prices {
  height: calc(100vh - 64px);
  .v-stepper {
    background: none;
    height: 100%;
    .v-stepper__header {
      box-shadow: none;
      .v-divider {
        flex-grow: 1;
        margin: 0;
      }
      .v-stepper__step {
        .v-stepper__step__step {
          padding-right: 1px;
          padding-bottom: 4px;
        }
      }
    }
    .v-stepper__items {
      box-shadow: none;
      height: calc(100% - 72px);
      .v-stepper__content {
        height: 100%;
        position: relative;
        &:last-child {
          overflow: visible;
          display: flex;
          align-items: flex-end;
          .v-stepper__wrapper {
            width: 100%;
            overflow: visible;
          }
        }
      }
    }
  }

  table.truncated tbody {
    tr {
      pointer-events: none;
    }
    tr:last-child {
      opacity: 0.5;
      &::after {
        content: " ";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 48px;
        background: linear-gradient(0deg, rgba(10,53,80,1) 0%, rgba(10,53,80,0) 100%);
      }
    }
  }

  table.visible--table tbody {
    tr {
      pointer-events: none;
      padding-top: 2px;
      padding-bottom: 2px;
    }
    tr:first-child {
      margin-top: 24px;
      &::after {
        content: " ";
        position: absolute;
        left: 0;
        width: 100%;
        height: 48px;
        background: linear-gradient(180deg, rgba(10,53,80,1) 0%, rgba(10,53,80,0) 60%);
      }
    }
    tr:last-child {
      opacity: 0.5;
      &::after {
        content: " ";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 48px;
        background: linear-gradient(0deg, rgba(10,53,80,1) 40%, rgba(10,53,80,0) 100%);
      }
    }
  }

}
</style>
