import gql from 'graphql-tag'
import fragments from './fragments'

const ADDRESS_CREATE = gql`
  mutation ($address: CreateAddressInput!) {
    createAddress(input: $address) {
      ...addressFields
    }
  }
  ${fragments.address.fields}
`

const ADDRESS_UPDATE = gql`
  mutation ($address: UpdateAddressInput!) {
    updateAddress(input: $address) {
      ...addressFields
    }
  }
  ${fragments.address.fields}
`
const ADDRESS_DELETE = gql`
  mutation ($id: ID!) {
    deleteAddress(id: $id) {
      ...addressFields
    }
  }
  ${fragments.address.fields}
`

const BILLABLE_CREATE = gql`
  mutation ($billable: CreateBillableInput!) {
    createBillable(input: $billable) {
      ...billableFields
      category {
        ...categoryFields
      }
    }
  }
  ${fragments.billable.fields}
  ${fragments.category.fields}
`

const BILLABLE_UPDATE = gql`
  mutation ($billable: UpdateBillableInput!) {
    updateBillable(input: $billable) {
      ...billableFields
      category {
        ...categoryFields
      }
    }
  }
  ${fragments.billable.fields}
  ${fragments.category.fields}
`

const BILLABLE_DELETE = gql`
  mutation ($id: ID!) {
    deleteBillable(id: $id) {
      ...billableFields
      category {
        ...categoryFields
      }
    }
  }
  ${fragments.billable.fields}
  ${fragments.category.fields}
`

const CATEGORY_CREATE = gql`
  mutation ($category: CreateCategoryInput!) {
    createCategory(input: $category) {
      ...categoryFields
    }
  }
  ${fragments.category.fields}
`

const CATEGORY_UPDATE = gql`
  mutation ($category: UpdateCategoryInput!) {
    updateCategory(input: $category) {
      ...categoryFields
    }
  }
  ${fragments.category.fields}
`

const CATEGORY_DELETE = gql`
  mutation ($id: ID!) {
    deleteCategory(id: $id) {
      ...categoryFields
    }
  }
  ${fragments.category.fields}
`

const CLIENT_CREATE = gql`
  mutation ($client: CreateClientInput!) {
    createClient(input: $client) {
      ...clientFields
      addresses {
        ...addressFields
      }
      contacts {
        ...contactFields
      }
      notes {
        ...noteFields
      }
    }
  }
  ${fragments.address.fields}
  ${fragments.client.fields}
  ${fragments.contact.fields}
  ${fragments.note.fields}
`

const CLIENT_UPDATE = gql`
  mutation ($client: UpdateClientInput!) {
    updateClient(input: $client) {
      ...clientFields
      addresses {
        ...addressFields
      }
      contacts {
        ...contactFields
      }
      notes {
        ...noteFields
      }
    }
  }
  ${fragments.address.fields}
  ${fragments.client.fields}
  ${fragments.contact.fields}
  ${fragments.note.fields}
`

const CLIENT_DELETE = gql`
  mutation ($id: ID!) {
    deleteClient(id: $id) {
      ...clientFields
      addresses {
        ...addressFields
      }
      contacts {
        ...contactFields
      }
      notes {
        ...noteFields
      }
    }
  }
  ${fragments.address.fields}
  ${fragments.client.fields}
  ${fragments.contact.fields}
  ${fragments.note.fields}
`

const COMPANY_UPDATE = gql`
  mutation ($company: UpdateCompanyInput!) {
    updateCompany(input: $company) {
      ...companyFields
    }
  }
  ${fragments.company.fields}
`

const CONTACT_CREATE = gql`
  mutation ($contact: CreateContactInput!) {
    createContact(input: $contact) {
      ...contactFields
    }
  }
  ${fragments.contact.fields}
`

const CONTACT_UPDATE = gql`
  mutation ($contact: UpdateContactInput!) {
    updateContact(input: $contact) {
      ...contactFields
    }
  }
  ${fragments.contact.fields}
`

const CONTACT_DELETE = gql`
  mutation ($id: ID!) {
    deleteContact(id: $id) {
      ...contactFields
    }
  }
  ${fragments.contact.fields}
`

const EMPLOYEE_CREATE = gql`
  mutation ($employee: CreateEmployeeInput!) {
    createEmployee(input: $employee) {
      ...employeeFields
      addresses {
        ...addressFields
      }
      contacts {
        ...contactFields
      }
      notes {
        ...noteFields
        user {
          id
          name
        }
      }
      user {
        ...userFields
      }
    }
  }
  ${fragments.address.fields}
  ${fragments.contact.fields}
  ${fragments.employee.fields}
  ${fragments.note.fields}
  ${fragments.user.fields}
`

const EMPLOYEE_UPDATE = gql`
  mutation ($employee: UpdateEmployeeInput!) {
    updateEmployee(input: $employee) {
      ...employeeFields
      addresses {
        ...addressFields
      }
      contacts {
        ...contactFields
      }
      notes {
        ...noteFields
        user {
          id
          name
        }
      }
      user {
        ...userFields
      }
    }
  }
  ${fragments.address.fields}
  ${fragments.contact.fields}
  ${fragments.employee.fields}
  ${fragments.note.fields}
  ${fragments.user.fields}
`

const EMPLOYEE_DELETE = gql`
  mutation ($id: ID!) {
    deleteEmployee(id: $id) {
      ...employeeFields
      addresses {
        ...addressFields
      }
      contacts {
        ...contactFields
      }
      notes {
        ...noteFields
        user {
          id
          name
        }
      }
      user {
        ...userFields
      }
    }
  }
  ${fragments.address.fields}
  ${fragments.contact.fields}
  ${fragments.employee.fields}
  ${fragments.note.fields}
  ${fragments.user.fields}
`

const ENTRY_CREATE = gql`
  mutation ($entry: CreateEntryInput!) {
    createEntry(input: $entry) {
      ...entryFields
    }
  }
  ${fragments.entry.fields}
`
const ENTRY_UPDATE = gql`
  mutation ($entry: UpdateEntryInput!) {
    updateEntry(input: $entry) {
      ...entryFields
    }
  }
  ${fragments.entry.fields}
`
const ENTRY_DELETE = gql`
  mutation ($id: ID!) {
    deleteEntry(id: $id) {
      ...entryFields
    }
  }
  ${fragments.entry.fields}
`

const HOUR_CREATE = gql`
  mutation ($hour: CreateHourInput!) {
    createHour(input: $hour) {
      ...hourFields
      employee {
        ...employeeFields
      }
      invoice {
        ...invoiceFields
      }
    }
  }
  ${fragments.employee.fields}
  ${fragments.hour.fields}
  ${fragments.invoice.fields}
`

const HOUR_UPDATE = gql`
  mutation ($hour: UpdateHourInput!) {
    updateHour(input: $hour) {
      ...hourFields
      employee {
        ...employeeFields
      }
    }
  }
  ${fragments.employee.fields}
  ${fragments.hour.fields}
`

const HOUR_DELETE = gql`
  mutation ($id: ID!) {
    deleteHour(id: $id) {
      ...hourFields
      employee {
        ...employeeFields
      }
    }
  }
  ${fragments.employee.fields}
  ${fragments.hour.fields}
`

const INVOICE_CREATE = gql`
  mutation ($invoice: CreateInvoiceInput!) {
    createInvoice(input: $invoice) {
      ...invoiceFields
      client {
        ...clientFields
      }
      job {
        ...jobFields
        client {
          ...clientFields
        }
      }
    }
  }
  ${fragments.client.fields}
  ${fragments.invoice.fields}
  ${fragments.job.fields}
`
const INVOICE_UPDATE = gql`
  mutation ($invoice: UpdateInvoiceInput!) {
    updateInvoice(input: $invoice) {
      ...invoiceFields
      client {
        ...clientFields
      }
      job {
        ...jobFields
        client {
          ...clientFields
        }
      }
    }
  }
  ${fragments.client.fields}
  ${fragments.invoice.fields}
  ${fragments.job.fields}
`
const INVOICE_DELETE = gql`
  mutation ($id: ID!) {
    deleteInvoice(id: $id) {
      ...invoiceFields
      client {
        ...clientFields
      }
      job {
        ...jobFields
      }
    }
  }
  ${fragments.client.fields}
  ${fragments.invoice.fields}
  ${fragments.job.fields}
`

const JOB_CREATE = gql`
  mutation ($job: CreateJobInput!) {
    createJob(input: $job) {
      ...jobFields
      addresses {
        ...addressFields
      }
      hours {
        ...hourFields
        employee {
          ...employeeFields
        }
      }
      invoices {
        ...invoiceFields
      }
      notes {
        ...noteFields
        user {
          ...userFields
        }
      }
      quotes {
        ...quoteFields
        sets {
          ...setFields
          entries {
            ...entryFields
          }
        }
      }
    }
  }
  ${fragments.address.fields}
  ${fragments.employee.fields}
  ${fragments.entry.fields}
  ${fragments.hour.fields}
  ${fragments.invoice.fields}
  ${fragments.job.fields}
  ${fragments.note.fields}
  ${fragments.quote.fields}
  ${fragments.set.fields}
  ${fragments.user.fields}
`
const JOB_UPDATE = gql`
  mutation ($job: UpdateJobInput!) {
    updateJob(input: $job) {
      ...jobFields
      addresses {
        ...addressFields
      }
      hours {
        ...hourFields
        employee {
          ...employeeFields
        }
      }
      invoices {
        ...invoiceFields
      }
      notes {
        ...noteFields
        user {
          ...userFields
        }
      }
      quotes {
        ...quoteFields
        sets {
          ...setFields
          entries {
            ...entryFields
          }
        }
      }
    }
  }
  ${fragments.address.fields}
  ${fragments.employee.fields}
  ${fragments.entry.fields}
  ${fragments.hour.fields}
  ${fragments.invoice.fields}
  ${fragments.job.fields}
  ${fragments.note.fields}
  ${fragments.quote.fields}
  ${fragments.set.fields}
  ${fragments.user.fields}
`
const JOB_DELETE = gql`
  mutation ($id: ID!) {
    deleteJob(id: $id) {
      ...jobFields
      addresses {
        ...addressFields
      }
      hours {
        ...hourFields
        employee {
          ...employeeFields
        }
      }
      invoices {
        ...invoiceFields
      }
      notes {
        ...noteFields
        user {
          ...userFields
        }
      }
      quotes {
        ...quoteFields
        sets {
          ...setFields
          entries {
            ...entryFields
          }
        }
      }
    }
  }
  ${fragments.address.fields}
  ${fragments.employee.fields}
  ${fragments.entry.fields}
  ${fragments.hour.fields}
  ${fragments.invoice.fields}
  ${fragments.job.fields}
  ${fragments.note.fields}
  ${fragments.quote.fields}
  ${fragments.set.fields}
  ${fragments.user.fields}
`

const NOTE_CREATE = gql`
  mutation ($note: CreateNoteInput!) {
    createNote(input: $note) {
      ...noteFields
      user {
        ...userFields
      }
    }
  }
  ${fragments.note.fields}
  ${fragments.user.fields}
`
const NOTE_UPDATE = gql`
  mutation ($note: UpdateNoteInput!) {
    updateNote(input: $note) {
      ...noteFields
      user {
        ...userFields
      }
    }
  }
  ${fragments.note.fields}
  ${fragments.user.fields}
`
const NOTE_DELETE = gql`
  mutation ($id: ID!) {
    deleteNote(id: $id) {
      ...noteFields
      user {
        ...userFields
      }
    }
  }
  ${fragments.note.fields}
  ${fragments.user.fields}
`

const QUOTE_CREATE = gql`
  mutation ($quote: CreateQuoteInput!) {
    createQuote(input: $quote) {
      ...quoteFields
      sets {
        ...setFields
        entries {
          ...entryFields
        }
      }
    }
  }
  ${fragments.quote.fields}
  ${fragments.entry.fields}
  ${fragments.set.fields}
`
const QUOTE_UPDATE = gql`
  mutation ($quote: UpdateQuoteInput!) {
    updateQuote(input: $quote) {
      ...quoteFields
      sets {
        ...setFields
        entries {
          ...entryFields
        }
      }
    }
  }
  ${fragments.quote.fields}
  ${fragments.entry.fields}
  ${fragments.set.fields}
`
const QUOTE_DELETE = gql`
  mutation ($id: ID!) {
    deleteQuote(id: $id) {
      ...quoteFields
      sets {
        ...setFields
        entries {
          ...entryFields
        }
      }
    }
  }
  ${fragments.quote.fields}
  ${fragments.entry.fields}
  ${fragments.set.fields}
`

const SET_CREATE = gql`
  mutation ($set: CreateSetInput!) {
    createSet(input: $set) {
      ...setFields
      entries {
        ...entryFields
      }
    }
  }
  ${fragments.set.fields}
  ${fragments.entry.fields}
`
const SET_UPDATE = gql`
  mutation ($set: UpdateSetInput!) {
    updateSet(input: $set) {
      ...setFields
      entries {
        ...entryFields
      }
    }
  }
  ${fragments.set.fields}
  ${fragments.entry.fields}
`
const SET_DELETE = gql`
  mutation ($id: ID!) {
    deleteSet(id: $id) {
      ...setFields
      entries {
        ...entryFields
      }
    }
  }
  ${fragments.set.fields}
  ${fragments.entry.fields}
`

const USER_CREATE = gql`
  mutation ($user: CreateUserInput!) {
    createUser(input: $user) {
      ...userFields
    }
  }
  ${fragments.user.fields}
`
const USER_UPDATE = gql`
  mutation ($user: UpdateUserInput!) {
    updateUser(input: $user) {
      ...userFields
    }
  }
  ${fragments.user.fields}
`
const USER_DELETE = gql`
  mutation ($id: ID!) {
    deleteUser(id: $id) {
      ...userFields
    }
  }
  ${fragments.user.fields}
`

export {
  ADDRESS_CREATE, ADDRESS_UPDATE, ADDRESS_DELETE,
  BILLABLE_CREATE, BILLABLE_UPDATE, BILLABLE_DELETE,
  CATEGORY_CREATE, CATEGORY_UPDATE, CATEGORY_DELETE,
  CLIENT_CREATE, CLIENT_UPDATE, CLIENT_DELETE,
  COMPANY_UPDATE,
  CONTACT_CREATE, CONTACT_UPDATE, CONTACT_DELETE,
  EMPLOYEE_CREATE, EMPLOYEE_UPDATE, EMPLOYEE_DELETE,
  ENTRY_CREATE, ENTRY_UPDATE, ENTRY_DELETE,
  HOUR_CREATE, HOUR_UPDATE, HOUR_DELETE,
  INVOICE_CREATE, INVOICE_UPDATE, INVOICE_DELETE,
  JOB_CREATE, JOB_UPDATE, JOB_DELETE,
  NOTE_CREATE, NOTE_UPDATE, NOTE_DELETE,
  QUOTE_CREATE, QUOTE_UPDATE, QUOTE_DELETE,
  SET_CREATE, SET_UPDATE, SET_DELETE,
  USER_CREATE, USER_UPDATE, USER_DELETE
}
