<template>
  <section class="manage-billables">
    <v-toolbar dark dense flat color="teal" style="z-index: 2;">
      <v-icon>receipt</v-icon>
      <v-toolbar-title>Billables</v-toolbar-title>

      <v-spacer></v-spacer>
      <v-btn icon @click="expanded = !expanded">
        <v-badge left color="teal lighten-1 teal--text text--lighten-4" class="v-badge--inline">
          <template #badge><span>{{ lines ? lines.length : 0 }}</span></template>
          <v-icon>{{ expanded ? 'expand_less' : 'expand_more' }}</v-icon>
        </v-badge>
      </v-btn>
    </v-toolbar>
    <v-slide-y-transition>
      <v-card v-show="expanded">
        <section class="">
          <v-subheader v-if="billedLinesByInvoice.length" class="title font-weight-bold justify-center">Invoiced</v-subheader>
          <v-expansion-panel expand flat>
            <v-expansion-panel-content lazy v-for="invoice of billedLinesByInvoice" :key="invoice.id">
              <template v-slot:header>
                <span class="subheading">{{ invoice.name }}</span>
                <span class="text-xs-right pr-4">
                  <span class="grey--text text--lighten-1">{{ invoice.created_at.split(' ')[0] }}</span>
                </span>
              </template>
              <div class="px-4 pb-2"><router-link :to="`/invoice/${invoice.id}`">view invoice</router-link></div>
              <v-list subheader dense class="striped">
                <v-list-tile v-for="(line, index) of invoice.lines" :key="`line-${index}`" class="invoiced" :class="{ 'temp-billable': !line.data.id, 'invalid-billable': !(line.value_1 > 0) }">
                  <v-list-tile-content>
                    <v-list-tile-title>
                      <v-layout row ma-0>

                        <v-flex xs11 v-if="line.data.id" pa-0>
                          <v-tooltip bottom color="grey lighten-5">
                            <template #activator="data">
                              <span v-on="data.on">
                                <!--  -->
                                <span class="font-weight-bold">{{ line.data.name }}</span>
                                <span v-if="line.data.size" class="grey--text">
                                  | <span class="font-mono grey--text text--lighten-2">{{ line.data.size }}</span>
                                </span>
                                <template v-if="line.data.supplier"><span class="grey--text"> | </span><span class="font-mono grey--text text--lighten-2">{{ line.data.supplier }}</span></template>
                                <template v-if="line.data.unit"><span class="grey--text"> / </span><span class="caption grey--text text--lighten-2">{{ line.data.unit }}</span></template>
                              </span>
                            </template>
                            <span>
                              <span class="category grey--text text--darken-2 font-weight-medium">{{ line.data.category ? (line.data.category.name ? line.data.category.name : line.data.category) : '' }} | </span>
                              <span style="vertical-align: middle;">
                                <v-chip v-for="(tag, tagIndex) of line.data.tags" :key="tagIndex" small label outline :color="getColor(tag)" class="darken-3 text--darken-3">{{ tag }}</v-chip>
                              </span>
                            </span>
                          </v-tooltip>
                        </v-flex>

                        <!-- Temp Billable -->
                        <template v-else>
                          <v-flex xs8 pa-0>
                            <v-text-field
                              v-model="line.data.name" readonly
                              placeholder="item description..." class="pt-0" ref="tempBillableNameInputs"
                              single-line hide-details
                            ></v-text-field>
                          </v-flex>

                          <v-flex xs3 pa-0 style="flex-shrink: 1;">
                            <v-text-field
                              v-model="line.data.supplier" readonly
                              placeholder="supplier" class="pt-0"
                              single-line hide-details
                            ></v-text-field>
                          </v-flex>
                        </template>

                        <v-flex xs1 style="flex-shrink: 2;" pa-0>
                          <v-text-field
                            v-model="line.value_1" readonly :error="!(line.value_1 > 0)"
                            class="pt-0 quantity-input" ref="lineQuantityInputs" color="teal"
                            prefix="x" placeholder="0"
                            single-line hide-details
                          ></v-text-field>
                        </v-flex>

                      </v-layout>
                    </v-list-tile-title>
                  </v-list-tile-content>
                </v-list-tile>
              </v-list>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </section>

        <v-list subheader dense class="striped mt-3 manage-billables__unbilled-list">
          <v-divider v-if="billedLines.length"></v-divider>
          <v-subheader class="title font-weight-bold justify-center">Uninvoiced</v-subheader>

          <v-list-tile
            v-for="(line, index) in unbilledLines" :key="index"
            :class="{ 'temp-billable': !line.data.id, 'invalid-billable': !(parseNumber(line.value_1) > 0) }">

            <v-list-tile-action>
              <confirm-action @confirm="removeEntry(line)">
                <template v-slot:act="{ on }">
                  <v-btn :loading="saving.includes('Entry:' + line.id)" icon small ripple @click.stop="on.click" class="ma-0">
                    <v-icon color="grey lighten-1">highlight_off</v-icon>
                  </v-btn>
                </template>
              </confirm-action>

            </v-list-tile-action>

            <v-list-tile-content>
              <v-list-tile-title>
                <v-layout row ma-0>

                  <v-flex xs11 v-if="line.data.id" pa-0>
                    <v-tooltip bottom color="grey lighten-5">
                      <template #activator="data">
                        <span v-on="data.on">
                          <!--  -->
                          <span class="font-weight-bold">{{ line.data.name }}</span>
                          <span v-if="line.data.size" class="grey--text">
                            | <span class="font-mono grey--text text--lighten-2">{{ line.data.size }}</span>
                          </span>
                          <template v-if="line.data.supplier"><span class="grey--text"> | </span><span class="font-mono grey--text text--lighten-2">{{ line.data.supplier }}</span></template>
                          <template v-if="line.data.unit"><span class="grey--text"> / </span><span class="caption grey--text text--lighten-2">{{ line.data.unit }}</span></template>
                        </span>
                      </template>
                      <span>
                        <span class="category grey--text text--darken-2 font-weight-medium">{{ line.data.category ? (line.data.category.name ? line.data.category.name : line.data.category) : '' }} | </span>
                        <span style="vertical-align: middle;">
                          <v-chip v-for="(tag, tagIndex) of line.data.tags" :key="tagIndex" small label outline :color="getColor(tag)" class="darken-3 text--darken-3">{{ tag }}</v-chip>
                        </span>
                      </span>
                    </v-tooltip>
                  </v-flex>

                  <!-- Temp Billable -->
                  <template v-else>
                    <v-flex xs8 pa-0>
                      <v-text-field
                        :value="line.data.name" @input="handleNameInput(line, $event)" :tabindex="index.toString() + 1"
                        placeholder="item description..." class="pt-0" ref="tempBillableNameInputs"
                        single-line hide-details
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs3 pa-0 style="flex-shrink: 1;">
                      <v-text-field
                        :value="line.data.supplier" @input="handleSupplierInput(line, $event)" :tabindex="index.toString() + 1"
                        placeholder="supplier" class="pt-0"
                        single-line hide-details
                      ></v-text-field>
                    </v-flex>
                  </template>

                  <v-flex xs1 style="flex-shrink: 2;" pa-0>
                    <v-text-field
                      :value="line.value_1" @input="handleQuantityInput(line, $event)" :tabindex="index.toString() + 1" :error="!(parseNumber(line.value_1) > 0)"
                      class="pt-0 quantity-input" ref="lineQuantityInputs" color="teal"
                      @submit.prevent.stop="focusBillables" @keydown.enter.prevent.stop="focusBillables"
                      prefix="x" placeholder="0" height="28"
                      single-line hide-details
                    ></v-text-field>
                  </v-flex>

                </v-layout>
              </v-list-tile-title>
            </v-list-tile-content>

          </v-list-tile>

        </v-list>

        <billables-search
          :label="'Search for an' + (lines.length ? 'other' : '') + ' item to add'" busy-label="Adding item"
          :tabindex="lines.length.toString() + '0'"
          ref="billablesSearch" theme="dark"
          @input="searchText = $event"
          @change="handleAddBillable"
          :busy="saving.includes('Entry:-1')"
        ></billables-search>

        <v-card-actions class="justify-space-between" style="padding-bottom: 10px;">
          <v-btn
            v-if="admin" @click.stop="createInvoice"
            :disabled="unbilledLines.length === 0 || creatingInvoice || saving.length > 0"
            :loading="creatingInvoice"
            color="green" small flat
          >
            <v-icon>vertical_split</v-icon> To Invoice
          </v-btn>

          <v-btn small flat color="teal" @click.stop="addEntry()">
            <v-icon>hourglass_empty</v-icon> Add Unique
          </v-btn>
        </v-card-actions>

      </v-card>
    </v-slide-y-transition>
    <v-snackbar v-model="showSnackbar" :color="snackbarColor" top>
      {{ snackbarText }}
      <v-btn dark flat @click="showSnackbar = false">
        Close
      </v-btn>
    </v-snackbar>
  </section>
</template>

<script>
/* eslint-disable camelcase */
/* eslint-disable handle-callback-err */

import { debounce } from 'lodash'
import { COLORS } from '@/constants'
import { BILLABLE, ENTRY } from '@/graphql/models'
import ConfirmAction from '@/components/ConfirmAction.vue'
import BillablesSearch from '@/components/BillablesSearch.vue'
import { cloneExcept, cloneOnly, dataToString as _dataToString, itemFromChar, parseNumber } from '@/utils'

export default {
  name: 'manage-billables',
  components: { BillablesSearch, ConfirmAction },

  props: {
    job: {},
    set: {}
  },

  data () {
    return {
      addedBillable: null,
      countdownInterval: null,
      creatingInvoice: false,
      expanded: false,
      saving: [],
      searching: false,
      searchText: '',
      showSnackbar: false,
      snackbarText: '',
      snackbarColor: ''
    }
  },

  computed: {
    admin () { return !!this.me?.is_admin },
    categories () { return this.$oxide.store.apollo.categories },
    me () { return this.$oxide.store.apollo.user },

    invoiceIds () { return this.job?.invoices?.map(invoice => invoice.id) },
    lines () {
      return this.set?.entries ? this.set.entries : []
    },

    billedLines () {
      const lines = this.lines.filter(line => !!line.value_3)
      return lines.sort((a, b) => b.value_3 > a.value_3)
    },

    billedLinesByInvoice () {
      const invoices = []
      for (const line of this.billedLines) {
        const invoice = invoices.find(inv => inv.id === line.value_3)
        if (invoice) {
          invoice.lines.push(line)
        } else {
          const inv = this.getInvoice(line.value_3)
          if (inv) {
            invoices.push({ id: line.value_3, name: inv.name, created_at: inv.created_at, lines: [line] })
          } else {
            console.log('invoice not found', line)
            if (!this.$parent.addingToInvoice) {
              line.value_3 = null
              this.updateEntry(line)
            }
          }
        }
      }

      return invoices
    },

    unbilledLines () { return this.lines.filter(line => !line.value_3) }
  },

  methods: {
    handleSearchInput (query) {
      if (query !== null) {
        this.searching = query && query.length && query.length > 0
        this.updateQuery(query)
      }
    },
    updateQuery: debounce(function (query) {
      this.$nextTick(() => {
        this.searching = false
      })
      if (query !== this.searchText) {
        this.searchText = query
      }
    }, 5),

    handleQuantityInput: debounce(function (entry, quantity) {
      entry.value_1 = quantity === null ? '0' : quantity
      this.updateEntry(entry)
    }, 500),
    handleNameInput: debounce(function (entry, name) {
      entry.data.name = name === null ? '' : name
      this.updateEntry(entry)
    }, 500),
    handleSupplierInput: debounce(function (entry, supplier) {
      entry.data.supplier = supplier === null ? '' : supplier
      this.updateEntry(entry)
    }, 500),

    handleNothing (e) { return false },

    handleAddBillable (input) {
      if (input) {
        const billable = cloneExcept(input, ['__typename', 'created_at', 'updated_at', 'category', '_relevance'])
        billable.category = cloneExcept(input.category, ['__typename', 'created_at', 'updated_at'])
        this.searchText = ''
        this.addEntry(billable)
      }
    },

    getColor (str) { return itemFromChar(COLORS, str) },

    parseNumber (value) { return parseNumber(value) },

    addToSaving (model) {
      const key = `${model.__typename}:${model.id}`
      if (!this.saving.includes(key)) {
        this.saving.push(key)
      }
    },
    removeFromSaving (model) {
      const index = this.saving.indexOf(`${model.__typename}:${model.id}`)
      if (index !== -1) {
        this.saving.splice(index, 1)
      }
    },
    isSaving (model) { return this.saving.includes(`${model.__typename}:${model.id}`) },

    removeEntry (entry) {
      this.addToSaving(entry)
      const index = this.set.entries.indexOf(entry)
      const totalEntries = this.set.entries.length
      if (index !== -1) {
        this.$apollo.mutate({
          mutation: ENTRY.mutations.delete.mutation,
          variables: { id: entry.id }
        }).then(({ data: { deleteEntry } }) => {
          if (totalEntries === this.set.entries.length) {
            this.set.entries.splice(index, 1)
          }
          this.removeFromSaving(deleteEntry)
        })
      }
    },

    addEntry (data = null) {
      const entry = cloneExcept(ENTRY.blank, ['id'])

      if (data === null) {
        entry.data = cloneOnly(BILLABLE.blank, ['name', 'supplier'])
        entry.data.name = this.searchText.trim()
        this.searchText = ''
        this.$refs.billablesSearch.updateQuery('')
      } else {
        entry.listable_type = 'App\\Billable'
        entry.listable_id = data.id
        entry.data = cloneExcept(data, ['created_at', 'supplier_name', 'supplier_desc'])
      }
      entry.value_1 = ''
      entry.value_2 = ''

      const lastBillable = this.set.entries[this.set.entries.length - 1]

      entry.order = lastBillable ? lastBillable.order + 1 : 1
      entry.set = { connect: this.set.id }

      if (typeof entry.data === 'object') {
        if (entry.data === null) {
          entry.data = ''
        } else {
          entry.data = JSON.stringify(entry.data)
        }
      }
      this.addToSaving({ __typename: 'Entry', id: '-1' })
      this.$apollo.mutate({
        mutation: ENTRY.mutations.create.mutation,
        variables: { entry }
      }).then(({ data: { createEntry } }) => {
        createEntry.data = this.stringToData(createEntry.data)
        this.set.entries.push(createEntry)
        setTimeout(() => {
          const inputs = this.$refs[entry.listable_id ? 'lineQuantityInputs' : 'tempBillableNameInputs']
          try {
            const input = inputs[inputs.length - 1]
            input.focus()
            input.$refs.input.setSelectionRange(0, 1)
          } catch (e) {
            console.log('inputs not found')
          }
        }, 500)
        this.removeFromSaving({ __typename: 'Entry', id: '-1' })
      })
    },

    dataToString (data) {
      return _dataToString(data)
    },

    stringToData (str) {
      if (typeof str === 'string') {
        try {
          return JSON.parse(str)
        } catch (e) {
          return str
        }
      } else {
        return str
      }
    },

    updateEntry (entry) {
      this.addToSaving(entry)
      const updateEntry = cloneExcept(entry, ['__typename', 'created_at', 'updated_at'])
      updateEntry.data = this.dataToString(entry.data)
      this.$apollo.mutate({
        mutation: ENTRY.mutations.update.mutation,
        variables: { entry: updateEntry }
      }).then(() => {
        this.removeFromSaving(entry)
      })
    },

    createInvoice () { this.$emit('create:invoice') },

    getInvoice (id) { return this.job?.invoices?.find(invoice => invoice.id === id) },
    getInvoiceName (id) {
      const invoice = this.job?.invoices?.find(invoice => invoice.id === id)
      return (invoice ? invoice?.name : '')
    },

    focusBillables (e) { this.$refs.billablesSearch.focus() }
  }
}
</script>

<style lang="scss">
.manage-billables {
  [readonly=readonly],
  .v-input--is-readonly {
    cursor: not-allowed;
  }
  .v-list--dense {
    &.v-list--two-line {
      & > [role=listitem] > .v-list__tile:not(.v-list__tile--avatar) {
        height: 60px;
      }
    }
  }
  .v-list {
    & > [role=listitem] {
      & > .v-list__tile {
        .v-list__tile__title {
          height: 30px;
          line-height: 30px;
          vertical-align: middle;
          .category {
            // font-size: 85%;
            font-variant: all-small-caps;
          }
        }
        .v-text-field {
          margin-top: 0;
        }
      }
      &.invalid-billable {
        background-color: rgba(255,255,255,0.2);
      }
      &.invoiced {
        .flex {
          opacity: 0.66;
        }
        &.invalid-billable {
          .flex:last-child {
            opacity: 1;
          }
        }
      }
    }
  }

  .quantity-input {
    input {
      text-align: right;
    }
    .input-group--text-field__suffix {
      margin-left: 15px;
    }
    .v-text-field__prefix {
      color: #999999;
    }
  }

  .item-list {
    .v-list > div[role="listitem"]:nth-child(even):not(:last-child) {
      background-color: rgba(0,0,0,0.033);
    }
  }

  .v-list__tile__action {
    min-width: 40px;
  }

  .manage-billables__unbilled-list {
    .v-list__tile {
      margin-left: -6px;
      .v-list__tile__action {
        min-width: 36px;
      }
    }
  }
  .billables-search.focused {
    background-color: #193d51;
    box-shadow: 0 0 0 100vmax rgba(0,0,0,0.5);
  }
}

@media (max-width: 600px) {
  .manage-billables {
    padding-bottom: 0;

    .billables-search {
      &.focused {
        position: fixed;
        bottom: 0;
        display: flex;
        align-items: flex-end;
        .billables-search--results {
          max-height: unset;
          height: calc(100vh - 83px);
          & > .v-list {
            padding-top: 60vh;
            width: 100%;
            min-height: 100%;
            display: flex;
            // align-items: flex-end;
            align-content: flex-end;
            flex-wrap: wrap;
            & > [role=listitem] {
              width: 100%;
            }
          }
        }
      }
    }

    .quantity-input .v-text-field__prefix {
      padding-right: 0;
    }
  }
}
</style>
