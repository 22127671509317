/* eslint-disable no-prototype-builtins */
const TIMERS = Symbol('timers')

class Timers {
  constructor () {
    this[TIMERS] = new Map()
    this.clear = function () {
      for (const timeoutID of this[TIMERS].values()) {
        self.clearTimeout(timeoutID)
      }
      this[TIMERS].clear()
      return this
    }

    const proxy = new Proxy(this, {
      get (t, p) {
        if (t.hasOwnProperty(p)) {
          return t[p]
        } else if (t[TIMERS].has(p)) {
          return t[TIMERS].get(p)
        } else {
          return undefined
        }
      },
      set (t, p, v) {
        if (t.hasOwnProperty(p)) {
          t[p] = v
        } else {
          if (t[TIMERS].has(p)) {
            self.clearTimeout(t[TIMERS].get(p))
          }
          t[TIMERS].set(p, v)
        }
        return true
      }
    })
    return proxy
  }
}

export default Timers
