import { defineModel, recordQueryMeta, store } from '@/utils'
import { CLIENT_QUERY, CLIENTS_QUERY } from '../queries'
import { CLIENT_CREATE, CLIENT_UPDATE, CLIENT_DELETE } from '../mutations'
import { NetworkStatus } from 'apollo-client'

const CLIENT = defineModel({
  name: 'Client',
  fields: { id: 0, name: '', is_business: false },
  queries: {
    index: {
      query: CLIENTS_QUERY,
      update ({ clients }) {
        store(clients, 'clients', 'apollo')
        return clients
      },
      result ({ data, loading, networkStatus }) {
        if (!loading && networkStatus === NetworkStatus.ready) {
          recordQueryMeta('clients', data.clients)
        }
      }
    },
    show: {
      query: CLIENT_QUERY,
      update ({ client }) {
        for (const job of client.jobs) {
          if (job.status === null) {
            job.status = '[]'
          }
          const billablesSet = job.sets?.find(set => set.type === 'Job/Billable')
          if (billablesSet) {
            for (const entry of billablesSet.entries) {
              if (typeof entry.data === 'string') {
                try {
                  entry.data = JSON.parse(entry.data)
                } catch (e) {
                  console.log(e)
                }
              }
            }
          }
          const lists = job.sets?.filter(set => set.type.startsWith('Job/List/'))
          if (Array.isArray(lists)) {
            for (const list of lists) {
              for (const entry of list.entries) {
                if (typeof entry.data === 'string') {
                  try {
                    entry.data = JSON.parse(entry.data)
                  } catch (e) {
                    console.log(e)
                  }
                }
              }
            }
          }
        }

        store(client, 'client', 'apollo')
        return client
      },
      result ({ data, loading, networkStatus }) {
        if (!loading && networkStatus === NetworkStatus.ready && data?.client?.id) {
          recordQueryMeta(`client:${data.client.id}`, data.client)
        }
      }
    }
  },
  mutations: {
    create: {
      mutation: CLIENT_CREATE
    },
    update: {
      mutation: CLIENT_UPDATE
    },
    delete: {
      mutation: CLIENT_DELETE
    }
  }
})

export default CLIENT
