import { defineModel, store, recordQueryMeta } from '@/utils'
import { EMPLOYEE_QUERY, EMPLOYEES_QUERY } from '../queries'
import { EMPLOYEE_CREATE, EMPLOYEE_UPDATE, EMPLOYEE_DELETE } from '../mutations'
import { NetworkStatus } from 'apollo-client'

const EMPLOYEE = defineModel({
  name: 'Employee',
  fields: { id: 0, user_id: 0, name: '', rate: 0, title: '', wage: 0 },
  queries: {
    index: {
      query: EMPLOYEES_QUERY,
      update ({ employees }) {
        store(employees, 'employees', 'apollo')
        return employees
      },
      result ({ data, loading, networkStatus }) {
        if (!loading && networkStatus === NetworkStatus.ready) {
          recordQueryMeta('employees', data.employees)
        }
      }
    },
    show: {
      query: EMPLOYEE_QUERY
    }
  },
  mutations: {
    create: {
      mutation: EMPLOYEE_CREATE
    },
    update: {
      mutation: EMPLOYEE_UPDATE
    },
    delete: {
      mutation: EMPLOYEE_DELETE
    }
  }
})

export default EMPLOYEE
