/* eslint-disable no-useless-escape */

import Version from '@/version'

const packageFile = require('../package.json')
const VERSION = new Version(packageFile.version)

const IS_PROD = process.env.NODE_ENV === 'production'
const IS_DEV = !IS_PROD

const BASE_URL = IS_PROD ? '/' : '//localhost:8000/'
const GRAPH_URL = BASE_URL + 'graphql'

// const PASSPORT = {
//   id: IS_PROD ? 2 : 2,
//   secret: IS_PROD ? 'F0p2LkyAy6HTMbonu4w36kVE2joPbpfYxbTuznxF' : 'dpke60jdsxv6BWuTFmHWmIyScfzi7B92dahWVS5W'
// }

const PASSPORT = { id: 2, secret: 'F0p2LkyAy6HTMbonu4w36kVE2joPbpfYxbTuznxF' }

const MAPS_API_KEY = 'AIzaSyDLu1Lp7Mz2ICE8SnCU9ExzMvHdWBBJW34'

const COLORS = [
  'red', 'pink', 'purple', 'indigo', 'blue', 'light-blue', 'cyan', 'teal',
  'green', 'light-green', 'amber', 'orange', 'deep-orange', 'blue-grey'
]

const LIGHT_COLORS = [
  'pink lighten-1',
  'deep-orange',
  'orange',
  'yellow',
  'light-green',
  'green',
  'teal',
  'light-blue',
  'cyan'
]

const DEFAULT_CONFIG = {
  logo: '',
  templates: {
    lists: []
  },
  accounting: {
    taxes: [],
    markup: 0
  },
  nomenclature: {
    tags: 'Tags',
    items: 'Items',
    invoice: 'Invoice',
    job_identifier: 'Job Number'
  },
  job_billing_types: {
    1: {
      name: 'time/materials',
      fixed_cost: false
    },
    2: {
      name: 'contract',
      fixed_cost: true
    }
  }
}

const JOB_STATUSES = [
  { value: 'quote-required', text: 'quote required', avatar: '' },
  { value: 'quoted', text: 'quoted', avatar: '' },
  { value: 'cancelled', text: 'cancelled', avatar: '' }, // hide
  // { value: 'not-started', text: 'not started', avatar: '' },
  // { value: 'scheduled', text: 'scheduled', avatar: '' },
  { value: 'misc-small-job', text: 'misc / small job' },
  { value: 'ready-to-proceed', text: 'ready to proceed', avatar: '' },
  { value: 'in-progress', text: 'in progress', avatar: '' },
  { value: 'roughed-in', text: 'roughed in', avatar: '' },
  { value: 'delayed', text: 'delayed', avatar: '' },
  { value: 'warranty', text: 'warranty' },
  { value: 'ready-to-bill', text: 'ready to bill', avatar: '' },
  { value: 'billed', text: 'billed', avatar: '' },
  { value: 'billed-to-date', text: 'billed to date', avatar: '' },
  { value: 'complete', text: 'complete', avatar: '' },
  { value: 'internal', text: 'internal', avatar: '' }
]

const GRADIENTS = [
  'linear-gradient(to right, #314755, #26a0da)',
  'linear-gradient(to right, #3A6073, #16222A)',
  'linear-gradient(to right, #2b5876, #4e4376)',
  'linear-gradient(to right, #00467f, #a5cc82)',
  'linear-gradient(to right, #536976, #292e49, #bbd2c5)',
  'linear-gradient(to right, #536976, #bbd2c5)',
  'linear-gradient(to right, #536976, #292e49)',
  'linear-gradient(to right, #334d50, #cbcaa5)',
  'linear-gradient(to right, #0f2027, #203a43, #2c5364)',
  'linear-gradient(to right, #aa4b6b, #6b6b83, #3b8d99)',
  'linear-gradient(to right, #1f4037, #99f2c8)',
  'linear-gradient(to right, #f12711, #f5af19)',
  'linear-gradient(to right, #659999, #f4791f)',
  'linear-gradient(to right, #8360c3, #2ebf91)',
  'linear-gradient(to right, #00b4db, #0083b0)',
  'linear-gradient(to right, #da4453, #89216b)',
  'linear-gradient(to right, #636363, #a2ab58)',
  'linear-gradient(to right, #ad5389, #3c1053)',
  'linear-gradient(to right, #11998e, #38ef7d)',
  'linear-gradient(to right, #c94b4b, #4b134f)',
  'linear-gradient(to right, #0f0c29, #302b63, #24243e)',
  'linear-gradient(to right, #00b09b, #96c93d)',
  'linear-gradient(to right, #667db6, #0082c8, #0082c8, #667db6)',
  'linear-gradient(to right, #20002c, #cbb4d4)'
]

const GRADIENT_COLORS = [
  ['#314755', '#26a0da'],
  ['#3A6073', '#16222A'],
  ['#2b5876', '#4e4376'],
  ['#00467f', '#a5cc82'],
  ['#536976', '#292e49', '#bbd2c5'],
  ['#536976', '#bbd2c5'],
  ['#536976', '#292e49'],
  ['#334d50', '#cbcaa5'],
  ['#0f2027', '#203a43', '#2c5364'],
  ['#aa4b6b', '#6b6b83', '#3b8d99'],
  ['#1f4037', '#99f2c8'],
  ['#f12711', '#f5af19'],
  ['#659999', '#f4791f'],
  ['#8360c3', '#2ebf91'],
  ['#00b4db', '#0083b0'],
  ['#da4453', '#89216b'],
  ['#636363', '#a2ab58'],
  ['#ad5389', '#3c1053'],
  ['#11998e', '#38ef7d'],
  ['#c94b4b', '#4b134f'],
  ['#0f0c29', '#302b63', '#24243e'],
  ['#00b09b', '#96c93d'],
  ['#667db6', '#0082c8', '#0082c8', '#667db6'],
  ['#20002c', '#cbb4d4']
]
/* no lookbehind support yet
const REGULAR_EXPRESSIONS = {
  punctuation: /(?:\(|\)|\[|\]|\{|\}|\,|\.(?!\d)|(?<!\d)[\"\'])/g,

  // measurements
  in: /(?<=\d)(?:\"|inch|inches)\b/g,
  ft: /(?<=\d)(?:\'|foot|feet)\b/g,
  amp: /(?<=\d)(?:a|ampere|amperes)\b/g,
  volt: /(?<=\d)(?:v|volts)\b/g,
  watt: /(?<=\d)(?:w|watts)\b/g,

  // colors
  black: /\b(?:bl|blk)\b/g,
  white: /\b(?:wh|wht)\b/g
}

const SEARCH_STRING_REPLACEMENTS = [
  { exp: REGULAR_EXPRESSIONS.in, val: 'in' },
  { exp: REGULAR_EXPRESSIONS.ft, val: 'ft' },
  { exp: REGULAR_EXPRESSIONS.amp, val: 'amp' },
  { exp: REGULAR_EXPRESSIONS.volt, val: 'volt' },
  { exp: REGULAR_EXPRESSIONS.watt, val: 'watt' },
  { exp: REGULAR_EXPRESSIONS.black, val: 'black' },
  { exp: REGULAR_EXPRESSIONS.white, val: 'white'}
]
*/

const REGIONS_BY_ALPHA_CODE = {
  ab: ['alberta', 'alta', 'alb'],
  bc: ['british columbia', 'bc', 'c-b'],
  mb: ['manitoba', 'man'],
  nb: ['new brunswick', 'nb', 'n-b'],
  nl: ['newfoundland and labrador', 'newfoundland labrador', 'newfoundland', 'nl', 't-n-l'],
  ns: ['nova scotia', 'ns', 'n-é', 'n-e'],
  nt: ['northwest territories', 'nwt', 'tn-o'],
  nu: ['nunavut', 'nvt', 'nt'],
  on: ['ontario', 'ont'],
  pe: ['prince edward island', 'pei', 'î-p-é', 'i-p-e'],
  qc: ['quebec', 'que', 'qc'],
  yt: ['yukon', 'yt', 'yn']
}

const REGULAR_EXPRESSIONS = {
  // don't remove inch/foot symbols
  punctuation: /(?:(\.|\,)(?!\d)|(?:[^\d](\"|\'))|(?:[^\d](\+|\-)[^\d])|([\(\)\[\]\{\}\?\:\;]))/g,

  // measurements
  in: /\d(\"|\ ?inch(?:es)?\b)/g,
  ft: /\d(\'|\ ?f(?:ee|oo)t\b)/g,
  amp: /\d(a|\ ?ampere(?:s)?\b)/g,
  volt: /\d(v|\ ?volts\b)/g,
  watt: /\d(w|\ ?watts\b)/g,

  // colors
  black: /\b(?:bl|blk)\b/g,
  white: /\b(?:wh|wht)\b/g
}

const MILISECOND = 1
const MILISECONDS = MILISECOND

const SECOND = 1000 * MILISECONDS
const SECONDS = SECOND

const MINUTE = 60 * SECONDS
const MINUTES = MINUTE

const HOUR = 60 * MINUTES
const HOURS = HOUR

export {
  BASE_URL,
  COLORS,
  DEFAULT_CONFIG,
  IS_DEV,
  IS_PROD,
  JOB_STATUSES,
  GRADIENTS,
  GRADIENT_COLORS,
  GRAPH_URL,
  LIGHT_COLORS,
  MAPS_API_KEY,
  PASSPORT,
  REGIONS_BY_ALPHA_CODE,
  REGULAR_EXPRESSIONS,
  MILISECOND,
  MILISECONDS,
  SECOND,
  SECONDS,
  MINUTE,
  MINUTES,
  HOUR,
  HOURS,
  VERSION
}
