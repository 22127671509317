import { clone, defineModel, recordQueryMeta, store } from '@/utils'
import { INVOICES_QUERY } from '../queries'
import { INVOICE_CREATE, INVOICE_UPDATE, INVOICE_DELETE } from '../mutations'
import { NetworkStatus } from 'apollo-client'

const INVOICE = defineModel({
  name: 'Invoice',
  fields: {
    client_id: 0,
    job_id: 0,
    name: '',
    number: '',
    lines: [],
    due_at: null,
    paid_at: null,
    total: 0,
    paid: 0
  },
  queries: {
    index: {
      query: INVOICES_QUERY,
      update ({ invoices }) {
        if (Array.isArray(invoices)) {
          const invs = invoices.map((invoice) => {
            if (typeof invoice.lines === 'string') {
              let lines = null
              try {
                lines = invoice.lines.includes('"part":') ? invoice.lines.replace(/"part":/g, '"billable":') : invoice.lines
                lines = JSON.parse(lines)
              } catch (e) {
                console.error(e, 'invoice.lines', invoice.lines, 'lines', lines)
                lines = []
              }

              invoice.lines = lines.map((line) => {
                if (typeof line.billable !== 'undefined' && typeof line.billable.tags === 'string') {
                  let tags = []
                  try {
                    tags = JSON.parse(line.billable.tags)
                  } catch (e) {
                    console.error(e, 'line.billable.tags', line.billable.tags)
                  } finally {
                    line.billable.tags = tags
                  }
                }
                return line
              })
            } else if (!Array.isArray(invoice.lines)) {
              invoice.lines = []
            }

            return invoice
          })

          store(invs, 'invoices', 'apollo')

          return invs
        } else {
          return invoices
        }
      },
      result ({ data, loading, networkStatus }) {
        if (!loading && networkStatus === NetworkStatus.ready) {
          recordQueryMeta('invoices', data.invoices)
        }
      }
    }
  },
  mutations: {
    create: {
      mutation: INVOICE_CREATE,
      update: (store, { data: { createInvoice } }) => {
        const data = store.readQuery({ query: INVOICES_QUERY })
        const tempInvoice = clone(createInvoice)
        if (!tempInvoice.client) {
          tempInvoice.client = null
        }
        if (!tempInvoice.job) {
          tempInvoice.job = null
        }
        data.invoices.push(tempInvoice)
        store.writeQuery({ query: INVOICES_QUERY, data })
      }
    },
    update: {
      mutation: INVOICE_UPDATE,
      update: (store, { data: { updateInvoice } }) => {
        const data = store.readQuery({ query: INVOICES_QUERY })
        const tempInvoice = clone(updateInvoice)
        if (!tempInvoice.client) {
          tempInvoice.client = null
        }
        if (!tempInvoice.job) {
          tempInvoice.job = null
        }
        const id = parseInt(tempInvoice.id, 10)
        const index = data.invoices.findIndex(invoice => parseInt(invoice.id, 10) === id)
        data.invoices[index] = tempInvoice
        store.writeQuery({ query: INVOICES_QUERY, data })
      }
    },
    delete: {
      mutation: INVOICE_DELETE
    }
  }
})

export default INVOICE
