<template>
  <v-layout id="clients">
    <v-window v-model="active" mandatory touchless :items="length" :active="active">
      <v-window-item v-for="(id, component, index) of windows" :key="`${component}-${id}`" :class="{ 'v-item--active': active === index }" :transition="false" @click.self="changeActiveTo(index)">
        <component v-bind:is="component" :model-id="id" :company="company" :active-client-id="$oxide.store.activeClientId"></component>
      </v-window-item>
    </v-window>
  </v-layout>
</template>

<script>
/* eslint-disable handle-callback-err */
import ManageCompany from '@/components/ManageCompany.vue'
import ManageClient from '@/components/ManageClient.vue'
import ManageJob from '@/components/ManageJob.vue'
import { CLIENT } from '@/graphql/models'
import { equivalent } from '@/utils'

export default {
  name: 'clients',
  components: { ManageCompany, ManageClient, ManageJob },
  props: { company: {} },
  data () {
    return {
      active: this.length,
      models: {},
      scroll: {
        deltaX: 0,
        changing: false,
        timeout: false,
        waiting: false
      }
    }
  },
  computed: {
    windows () {
      const items = { 'manage-company': 0 }
      let i = 0
      while (typeof this.$route.params[`rel${++i}_type`] !== 'undefined') {
        items['manage-' + this.$route.params[`rel${i}_type`]] = typeof this.$route.params[`rel${i}_id`] !== 'undefined' ? parseInt(this.$route.params[`rel${i}_id`], 10) : null
      }
      return items
    },
    length () {
      return Object.keys(this.windows).length
    }
  },
  methods: {
    changeActiveTo (index) {
      this.$router.replace({ path: this.$route.path, query: { a: index } }).catch(err => {})
    },
    changeActiveBy (delta) {
      const active = this.active + delta
      if (active > -1 && active < this.length) {
        this.changeActiveTo(active)
      }
    },
    handleWheel ({ deltaX, deltaY }) {
      if (!this.scroll.waiting) {
        window.requestAnimationFrame(() => {
          if (!deltaY && deltaX) {
            this.scroll.deltaX += deltaX

            if (!this.scroll.changing && Math.abs(this.scroll.deltaX) > 222) {
              this.changeActiveBy(Math.sign(this.scroll.deltaX))
              this.scroll.deltaX = 0
              this.scroll.changing = true
              window.setTimeout(() => {
                this.scroll.changing = false
                this.scroll.timeout = false
                this.scroll.deltaX = 0
              }, 1000)
            } else if (!this.scroll.timeout) {
              this.scroll.timeout = true
              window.setTimeout(() => {
                this.scroll.deltaX = 0
                this.scroll.timeout = false
              }, 1111)
            }
          }
          this.scroll.waiting = false
        })

        this.scroll.waiting = true
      }
    },
    updateActive (route = null) {
      if (route === null) {
        route = this.$route
      }
      const active = typeof route.query.a !== 'undefined' ? parseInt(route.query.a, 10) : this.length ? this.length - 1 : this.length
      if (this.active !== active) {
        this.active = active
      }
    },
    updateModels (route = null) {
      if (route === null) {
        route = this.$route
      }
      const models = {}
      let i = 0
      while (typeof route.params[`rel${++i}_type`] !== 'undefined') {
        models[route.params[`rel${i}_type`]] = typeof route.params[`rel${i}_id`] !== 'undefined' ? parseInt(route.params[`rel${i}_id`], 10) : null
      }

      if (!equivalent(models, this.models)) {
        if (typeof models.client !== 'undefined') {
          this.$oxide.store.activeClientId = models.client
          if (!this.$oxide.store.clientQuery) {
            this.$set(this.$oxide.store, 'clientQuery', { query: CLIENT.queries.show.query, variables: { id: models.client } })
          } else {
            this.$oxide.store.clientQuery.variables.id = models.client
          }
        } else {
          this.$oxide.store.activeClientId = 0
        }
        this.models = models
        this.$set(this.$oxide.store, 'crmModels', models)
        this.$cache.set('crmModels', models)
      }
    }
  },
  watch: {
    $route: {
      handler (changed, old) {
        this.$nextTick(() => {
          this.updateActive(changed)
          this.updateModels(changed)
        })
      },
      deep: true
    },
    length (changed, old) {
      this.$router.replace({ path: this.$route.path, query: { a: changed - 1 } }).catch(err => {})
    }
  },
  created () {
    this.updateActive()
    this.updateModels()
  },
  mounted () {
    this.updateActive()
    this.updateModels()
    // window.addEventListener('wheel', this.handleWheel, { capture: false, passive: true })
  },
  beforeDestroy () {
    // window.removeEventListener('wheel', this.handleWheel, { capture: false, passive: true })
  }
}
</script>

<style lang="scss">
#clients {
  margin: 0;
  & > .v-window {
    width: 100%;
  }
  .v-window-item.v-item--active {
    display: block !important;
  }
}
</style>
