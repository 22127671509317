<template>
  <v-layout id="report_viewer">
    <component v-if="component" :is="component" :id="id" :config="config" :me="me"></component>
  </v-layout>
</template>

<script>
import { JobsByDay, TimeCard } from '@/components/reports'

export default {
  name: 'report-viewer',
  components: { JobsByDay, TimeCard },
  props: { company: {} },
  computed: {
    me () { return this.$oxide.store.apollo.user },
    config () { return this.company?.config },
    subject () { return this.$route.params.subject },
    id () { return this.$route.params.id },
    report () { return this.$route.params.report },
    component () {
      switch (this.subject) {
        case 'employee':
        case 'employees':// reports about employees
          if (this.id) { // reports about individual employees
            if (this.report === 'time-card') {
              return 'time-card'
            }
          }
          break
        case 'job':
        case 'jobs':// reports about jobs
          if (this.id === 'all') { // reports about all jobs
            if (this.report === 'by-day') {
              return 'jobs-by-day'
            }
          }
          break
      }
      return null
    }
  },
  methods: {

  }
}
</script>

<style lang="scss">
#report_viewer {
  padding-bottom: 20px;
  justify-content: space-around;
}
@media (min-width: 601px) {
  #report_viewer {
    padding-top: 20px;
  }
}
</style>
