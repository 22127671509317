<template>
  <v-card>
    <v-card-title>Activity</v-card-title>
  </v-card>
</template>

<script>
export default {
  name: 'home',
  mounted () {
    if (this.$cache.lastRouteName && this.$cache.lastRouteName !== 'home') {
      this.$router.replace(this.$cache.lastRouteFullPath)
    }
  }
}
</script>
