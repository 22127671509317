<template>
  <v-card flat dark class="client-list">
    <list-header
      color="blue-grey darken-2" :title="title" no-search
      :saving="savingEmployee" :show-form="showAddEmployeeForm" :form-valid="addEmployeeNameValid && addEmployeeEmailValid"
      textbox-label="New employee's name"
      @create="createEmployee" @showFormChanged="handleShowFormChange"
    >
      <template #form="colors">
        <v-layout row pl-3 pr-4 my-0 ml-0 mr-5>
          <v-flex xs12 sm6 pa-0>
            <v-text-field
              v-model="addEmployeeName"
              hide-details
              label="Name"
              class="mt-0 pt-1"
              :color="addEmployeeName.trim() !== '' ? colors.valid : colors.invalid"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 sm6 pa-0>
            <v-text-field
              v-model="addEmployeeEmail"
              hide-details
              label="Email"
              class="mt-0 pt-1"
              :color="addEmployeeEmail.trim() !== '' ? colors.valid : colors.invalid"
            ></v-text-field>
          </v-flex>
        </v-layout>
      </template>
    </list-header>

    <v-sheet>
      <v-list two-line>
        <template v-for="(employee, index) of employees">
          <v-divider v-if="index" :key="index"></v-divider>
          <v-list-tile avatar :key="`employee.${employee.id}`" :to="`/employee/${employee.id}`">
            <v-list-tile-avatar :color="getColorFromString(employee.name)">
              <span class="white--text headline">{{ employee.name.substring(0, 1) }}</span>
            </v-list-tile-avatar>
            <v-list-tile-content>
              <v-list-tile-title>{{ employee.name }}</v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
        </template>
      </v-list>
    </v-sheet>

  </v-card>
</template>

<script>
/* eslint-disable camelcase */
/* eslint-disable handle-callback-err */

import { clone } from '@/utils'
import { EMPLOYEE, USER } from '@/graphql/models'
import ListHeader from '@/components/ListHeader.vue'
import { BASE_URL, COLORS } from '@/constants'

const isEmail = /^\S+@\S+$/

export default {

  name: 'client-list',

  components: { ListHeader },

  props: {
    employees: { type: Array, required: true },
    title: { type: String, required: false, default: 'Employees' }
  },

  data () {
    return {
      savingEmployee: false,
      showAddEmployeeForm: false,
      addEmployeeName: '',
      addEmployeeEmail: ''
    }
  },

  computed: {
    admin () { return !!this.me?.is_admin },
    company () { return this.$oxide.store.apollo.company },
    me () { return this.$oxide.store.apollo.user },
    addEmployeeNameValid () { return this.addEmployeeName.trim() !== '' },
    addEmployeeEmailValid () { return isEmail.test(this.addEmployeeEmail) }
  },

  methods: {

    editEmployee ({ id }) {
      if (id !== this.$route.params.employeeId) {
        this.$router.push({ params: { employeeId: id } })
      }
    },

    async createEmployee () {
      this.savingEmployee = true

      fetch(`${BASE_URL}api/password/suggest?count=2`, {
        method: 'GET',
        cache: 'no-cache',
        headers: {
          Accept: 'application/json, text/plain, */*',
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      })
        .then(response => {
          if (!response.ok) throw Error(response.status)
          return response
        })
        .then(response => response.text())
        .then(password => {
          this.$apollo.mutate({
            mutation: USER.mutations.create.mutation,
            variables: {
              user: {
                name: this.addEmployeeName.trim(),
                email: this.addEmployeeEmail,
                password,
                is_admin: false,
                inactive: false,
                company: { connect: this.company.id }
              }
            },
            update: (store, { data: { createUser } }) => {
              const data = store.readQuery({ query: USER.queries.index.query })
              data.users.push(clone(createUser))
              store.writeQuery({ query: USER.queries.index.query, data })
            }
          }).then(({ data: { createUser } }) => {
            this.$apollo.mutate({
              mutation: EMPLOYEE.mutations.create.mutation,
              variables: { employee: { name: createUser.name, company: { connect: this.company.id }, user: { connect: createUser.id } } },
              update: (store, { data: { createEmployee } }) => {
                const data = store.readQuery({ query: EMPLOYEE.queries.index.query })
                data.employees.push(clone(createEmployee))
                store.writeQuery({ query: EMPLOYEE.queries.index.query, data })
              }
            }).then(({ data: { createEmployee } }) => {
              this.showAddEmployeeForm = false
              this.savingEmployee = false
              this.addEmployeeName = ''
              this.addEmployeeEmail = ''
              this.editEmployee(createEmployee)
            })
          })
        })
    },

    getColorFromString (str) {
      let seed = 0
      for (let i = 0; i < str.length; i++) {
        seed += str.charCodeAt(i)
      }
      return COLORS[seed % COLORS.length]
    },

    handleShowFormChange (show) {
      this.showAddEmployeeForm = show
    }
  }
}
</script>

<style lang="scss">
.client-list {
  .item--active {
    background-color: rgba(0, 0, 0, 0.04);
  }
  .v-list {
    .search-result-heading,
    .search-result-heading > .v-list__tile {
      height: 36px;
      .v-list__tile__content {
        justify-content: flex-end;
      }
    }
  }
}
</style>
