<template>
  <section>
    <v-toolbar color="deep-purple lighten-1" dark dense flat>
      <v-icon>ballot</v-icon>
      <v-toolbar-title>Lists</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn v-if="expanded" icon @click.stop="showAddListDialog = true">
        <v-tooltip left nudge-left="10">
          <v-icon slot="activator" color="white">add</v-icon>
          Add New List
        </v-tooltip>
      </v-btn>
      <v-btn icon @click="expanded = !expanded" style="margin-left: 40px;">
        <v-badge left color="deep-purple lighten-2 deep-purple--text text--lighten-4" class="v-badge--inline">
          <template #badge><span>{{ lists ? lists.length : 0 }}</span></template>
          <v-icon>{{ expanded ? 'expand_less' : 'expand_more' }}</v-icon>
        </v-badge>
      </v-btn>
    </v-toolbar>

    <v-slide-y-transition>
      <v-card v-show="expanded">
        <manage-list-set
          v-for="list of lists" :key="list.id"
          :list="list" :starts-open="lists.length < 2"
          :adding-entries-current="setSaving === list.id ? entriesAdded : 0"
          :adding-entries-total="setSaving === list.id ? totalEntriesToAdd : 0"
          @set:delete="deleteList"
        ></manage-list-set>
        <v-card-actions>
          <v-btn flat color="deep-purple lighten-3" @click.stop="showAddListDialog = true">
            <v-icon>add</v-icon>
            Add New List
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-slide-y-transition>

    <v-dialog v-model="showAddListDialog" max-width="290">
      <v-card>
        <v-card-title class="headline">Add List</v-card-title>
        <v-card-text>
          <v-select :items="listTemplates" v-model="selectedTemplate" item-text="name" return-object></v-select>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="amber darken-1" flat="flat" @click="showAddListDialog = false">Cancel</v-btn>
          <v-btn color="green darken-1" flat="flat" @click="addList">Add</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </section>
</template>

<script>
import { clone } from '@/utils'
import ManageListSet from '@/components/ManageListSet.vue'
import { ENTRY, SET } from '@/graphql/models'

const blankList = { name: 'new list', items: [] }

export default {
  name: 'manage-list-sets',
  components: {
    ManageListSet
  },
  props: {
    lists: {},
    job: {}
  },
  data () {
    return {
      expanded: false,
      showAddListDialog: false,
      selectedTemplate: clone(blankList),
      newList: clone(blankList),
      setSaving: '',
      entriesAdded: 0,
      totalEntriesToAdd: 0
    }
  },
  computed: {
    company () { return this.$oxide.store.apollo.company },
    me () { return this.$oxide.store.apollo.user },
    config () { return this.company?.config },
    listTemplates () {
      const lists = this.config?.templates?.lists ? this.config.templates.lists : []
      return [clone(this.newList), ...lists]
    }
  },
  methods: {
    addList () {
      this.showAddListDialog = false

      this.$apollo.mutate({
        mutation: SET.mutations.create.mutation,
        variables: {
          set: {
            title: this.selectedTemplate.name === 'new list' ? '' : this.selectedTemplate.name,
            type: this.selectedTemplate.type === 'MANIFEST' ? 'Job/List/Manifest' : 'Job/List/Check',
            order: (Array.isArray(this.lists) ? this.lists.length : 0) + 1,
            setable_id: this.job.id,
            setable_type: 'App\\Job'
          }
        }
      }).then(({ data: { createSet } }) => {
        this.setSaving = createSet.id
        const index = this.job.sets.findIndex(set => set.id === createSet.id)
        if (index === -1) {
          this.job.sets.push(createSet)
        }
        this.$alert({ color: 'cyan darken-2', text: 'Adding List...' })
        if (!Array.isArray(createSet.entries)) {
          createSet.entries = []
        }

        const mutations = []

        this.totalEntriesToAdd = this.selectedTemplate.items.length
        this.entriesAdded = 0

        for (const item of this.selectedTemplate.items) {
          // if (item.del?.at) {
          if (typeof item === 'undefined') {
            // continue
          } else {
            mutations.push(
              this.$apollo.mutate({
                mutation: ENTRY.mutations.create.mutation,
                variables: {
                  entry: {
                    set: { connect: createSet.id },
                    value_1: item.t,
                    value_2: this.selectedTemplate.type === 'MANIFEST' ? item.c : (item.c ? '1' : '0'),
                    data: JSON.stringify({ d: !!item.d, h: !!item.h, n: item.n ? item.n : '' }),
                    order: mutations.length + 1
                  }
                }
              }).then(({ data: { createEntry } }) => {
                this.entriesAdded++
                createSet.entries.push(createEntry)
              })
            )
          }
        }
        Promise.all(mutations).then(() => {
          this.setSaving = ''
          this.entriesAdded = 0
          this.totalEntriesToAdd = 0
          this.$alert({ color: 'deep-purple lighten-1', text: 'List Added' })
        })
      })
    },

    deleteList (list) {
      this.$apollo.mutate({
        mutation: SET.mutations.delete.mutation,
        variables: { id: list.id }
      }).then(({ data: { deleteSet } }) => {
        const index = this.job.sets.findIndex(set => set.id === deleteSet.id)
        if (index !== -1) {
          this.job.sets.splice(index, 1)
        }
        this.$alert({ color: 'deep-orange darken-2', text: 'List deleted' })
      })
    }
  }
}
</script>

<style lang="scss">

</style>
