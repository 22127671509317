<template>
  <v-container pa-0 ma-0 grid-list-xl fluid class="manage-company">
    <v-layout row wrap justify-center ma-0>
      <v-flex v-if="editing">
        <manage-entity title="Company" icon="work" :entity="company" @save="saveName">
          <template #preContent>
            <v-layout align-content-center class="py-4 px-2">
              <upload-btn :fileChangedCallback="handleLogoChange" accept="image/*" color="secondary" :unique-id="true"></upload-btn>
              <img v-if="uploadedImage" :src="uploadedImage" alt style="max-height: 50px;">
              <img v-else-if="logo" :src="logo" alt="logo" style="max-height: 50px;">
            </v-layout>
          </template>
        </manage-entity>
      </v-flex>

      <v-flex v-if="clients">
        <!-- <v-card v-if="recentJobs.length" class="mb-4">
          <v-toolbar flat dark color="deep-orange darken-2">
            <v-toolbar-title>Your Recently Viewed Jobs</v-toolbar-title>
          </v-toolbar>
          <v-list style="overflow-y: auto;" class="recent-list">
            <template v-for="(job, index) of recentJobs">
              <v-divider v-if="job && index" :key="'Divider: ' + index"></v-divider>
              <v-list-tile two-line
                v-if="job" :key="'Job: ' + job.id"
                :to="'/crm/client/' + job.client.id + '/job/' + job.id + '?a=2'">
                <v-list-tile-content>
                  <v-list-tile-title>{{ job.title }}</v-list-tile-title>
                  <v-list-tile-sub-title>{{ job.client.name }}</v-list-tile-sub-title>
                </v-list-tile-content>
              </v-list-tile>
            </template>
          </v-list>
        </v-card> -->

        <v-card v-if="clients.length">
          <v-toolbar flat dark color="green darken-2">
            <v-toolbar-title>Recent Activity</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-select :items="recentSpans" v-model="recentSpan"></v-select>
          </v-toolbar>
          <v-list dense style="overflow-y: auto;" class="recent-list">
            <v-list-tile
              v-for="client of recentClients" :key="'Client: ' + client.id"
              :to="'/crm/client/' + client.id + '?a=1'">
              <v-list-tile-content>
                <v-list-tile-title>{{ client.name }}</v-list-tile-title>
              </v-list-tile-content>
              <v-list-tile-action>
                <v-list-tile-action-text>
                  {{ getFormattedDate(client.updated_at) }}
                </v-list-tile-action-text>
              </v-list-tile-action>
            </v-list-tile>
          </v-list>
        </v-card>
      </v-flex>

      <v-flex>
        <client-list title="Clients" :parentId="id"></client-list>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
/* eslint-disable camelcase */
/* eslint-disable handle-callback-err */

import { differenceInDays, formatDistanceStrict, isAfter, parseISO, sub } from 'date-fns'
import ClientList from '@/components/ClientList.vue'
import ManageEntity from '@/components/ManageEntity.vue'
import UploadButton from 'vuetify-upload-button'
import { COMPANY } from '@/graphql/models'
import { clone, getUTCTimestamp } from '@/utils'

let updateRecentJobsInterval

export default {
  name: 'manage-company',
  components: {
    ManageEntity,
    'client-list': ClientList,
    'upload-btn': UploadButton
  },
  props: {
    company: {}
  },
  data () {
    return {
      editing: false,
      uploadedImage: false,
      imageFile: false,
      recentJobs: [],
      recentSpan: '2 weeks',
      recentSpans: [
        '1 week',
        '2 weeks',
        '3 weeks',
        '1 month',
        '2 months',
        '3 months'
      ]
    }
  },
  computed: {
    clients () { return this.$oxide.store.apollo.clients ? this.$oxide.store.apollo.clients : [] },
    id () { return this.company?.id ? this.company.id : 0 },
    logo () { return this.company.config?.logo },
    entity () { return { ...this.company, type: COMPANY.name } },
    sortedClients () { return clone(this.clients).sort((a, b) => this.parseUTC(b.updated_at) - this.parseUTC(a.updated_at)) },
    ago () {
      const span = (this.recentSpan + (this.recentSpan.endsWith('s') ? '' : 's')).split(' ')
      const duration = { days: 1 }
      if (span[1] === 'weeks') {
        duration.days += parseInt(span[0], 10) * 7
      } else {
        duration[span[1]] = parseInt(span[0], 10)
      }
      return sub(parseISO(getUTCTimestamp()), duration)
    },
    recentClients () {
      const recentClients = []

      for (let i = 0; i < this.sortedClients.length; i++) {
        if (isAfter(this.parseUTC(this.sortedClients[i].updated_at), this.ago)) {
          recentClients.push(this.sortedClients[i])
        } else {
          break
        }
      }
      return recentClients
    }
  },
  methods: {
    handleLogoChange (file) {
      // const fr = new FileReader()
      // fr.readAsDataURL(file)
      // fr.addEventListener('load', () => {
      //   this.uploadedImage = fr.result
      //   this.imageFile = file
      //   this.$store.dispatch('company/uploadLogo', this.imageFile)
      // })
    },
    saveName ({ name }) {
      // this.$store.dispatch('company/update', { id: this.id, name })
    },

    parseUTC (str) {
      const parts = str.split(' ')
      if (parts.length === 2) {
        return Date.parse(parts[0] + 'T' + parts[1] + '+00:00')
      } else {
        return Date.parse(str)
      }
    },

    getFormattedDate (date) {
      const now = new Date()
      const then = this.parseUTC(date)
      const diff = Math.abs(differenceInDays(now, then))
      if (diff > 13) {
        return Math.floor(diff / 7) + ' weeks ago'
      } else if (diff > 6) {
        return '1 week ago'
      } else {
        try {
          return formatDistanceStrict(then, now) + ' ago'
        } catch (e) {
          console.log(date, then, now, e)
        }
      }
    },

    updateRecentJobs () {
      this.recentJobs = this.$cache.get('previouslyViewedJobs', { defaultValue: [] })
        .map(id => this.$oxide.store.apollo.company?.jobs?.find(job => job?.id === id))
    }
  },
  watch: {
    '$oxide.bus.now': {
      handler (val) {
        this.$forceUpdate()
      }
    }
  },
  mounted () {
    this.updateRecentJobs()
    updateRecentJobsInterval = setInterval(this.updateRecentJobs.bind(this), 10000)
  },
  beforeDestroy () {
    clearInterval(updateRecentJobsInterval)
  }
}
</script>

<style lang="scss">
.manage-company {
  & > .layout > .flex {
    width: 100%;
    max-width: 580px;
  }
}
@media (max-width: 600px) {
  .manage-company {
    & > .layout > .flex {
      padding: 0 !important;
    }
  }
}
@media (min-width: 601px) {
  .manage-company {
    & > .layout > .flex {
      padding-right: 16px !important;
      padding-left: 16px !important;
    }
  }
}
@media (max-width: 1159px) {
  .manage-company {
    .recent-list {
      max-height: 212px;
    }
  }
}
</style>
