<template>
  <v-flex class="jobs-by-day px-3">
    <v-card class="pr-4 pb-4">
      <v-card-title>
        <v-spacer></v-spacer>
        <div class="display-1">Active Jobs</div>
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn icon @click="$refs.calendar.prev()">
          <v-icon dark>keyboard_arrow_left</v-icon>
        </v-btn>
        <div class="headline px-4">{{ month }}</div>
        <v-btn icon @click="$refs.calendar.next()">
          <v-icon dark>keyboard_arrow_right</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
      <v-calendar
        ref="calendar"
        type="week"
        v-model="date"
        :interval-count="0"
        :weekdays="[1, 2, 3, 4, 5]"
      >
        <div class="headline px-4">{{ month }}</div>
        <template #dayHeader="{ date }">
          <div v-if="!(jobsByDay[date] && jobsByDay[date].length > 0)" class="text-xs-center py-3">no jobs</div>
          <template v-for="job of jobsByDay[date]">
            <v-divider :key="date + ':' + job.id + ':divider'" class="mx-2"></v-divider>
            <div :key="date + ':' + job.id" @click="openJob(job)" class="job">
              <div class="job--title">{{ job.title }}</div>
              <div class="caption">{{ job.client.name }}</div>
              <div class="caption">Hours: <span v-text="job.hoursByDate.find(hoursByDate => hoursByDate.date === date).hours"></span></div>
              <div class="job--status" v-if="job.status && Array.isArray(job.status)">
                <v-chip outline :color="getStatusColor(status)" class="v-chip--tiny" v-for="(status, statusIndex) of job.status" :key="statusIndex">{{ status }}</v-chip>
              </div>
            </div>
          </template>
        </template>
      </v-calendar>
    </v-card>
  </v-flex>
</template>

<script>
import { COLORS } from '@/constants'
import { getDate, getUTCTimestamp, itemFromString } from '@/utils'
import { addDays, eachDayOfInterval, endOfWeek, format, parseISO, startOfWeek, subDays, startOfTomorrow } from 'date-fns'
let tomorrowTimeout = null
export default {
  name: 'jobs-by-day',
  props: { config: {}, id: {}, me: {} },
  data () {
    return {
      date: !this.$oxide.store.jobsByDayLastDate ? getDate() : this.$oxide.store.jobsByDayLastDate
    }
  },
  computed: {
    selected () { return parseISO(this.date) },
    month () { return format(this.selected, 'MMMM yyyy') },
    start () { return addDays(startOfWeek(this.selected), 1) },
    end () { return subDays(endOfWeek(this.selected), 1) },
    weekdays () { return eachDayOfInterval({ start: this.start, end: this.end }) },
    jobs () { return this.$oxide.store.apollo.company?.jobs },
    activeJobs () { return this.jobs?.filter(job => job.updated_at >= getUTCTimestamp(this.start)) },
    jobsByDay () {
      if (!this.activeJobs) {
        return {}
      }
      const days = Object.create(null)
      for (const weekday of this.weekdays) {
        const date = format(weekday, 'yyyy-MM-dd')
        days[date] = this.activeJobs.filter(job => job.hoursByDate.some(hoursByDate => hoursByDate.date === date))
      }
      return days
    }
  },
  watch: {
    date (newDate) {
      this.$oxide.store.jobsByDayLastDate = newDate
    }
  },
  methods: {
    getStatusColor (str) {
      return itemFromString(COLORS, str) + ' lighten-2'
    },
    openJob (job) {
      this.$router.push('/crm/client/' + job.client.id + '/job/' + job.id + '?a=2')
    },
    updateCalendarTomorrow () {
      tomorrowTimeout = window.setTimeout(() => {
        this.$refs.calendar.updateTimes()
        this.updateCalendarTomorrow()
      }, startOfTomorrow() - Date.now())
    }
  },
  created () {
    this.updateCalendarTomorrow()
  },
  beforeDestroy () {
    window.clearTimeout(tomorrowTimeout)
  }
}
</script>
<style lang="scss">
.jobs-by-day {
  .theme--dark.v-calendar-daily {
    .v-calendar-daily_head-day {
      &.v-past {
        .v-calendar-daily_head-weekday,
        .v-calendar-daily_head-day-label {
          color: #fff;
        }
      }
      &.v-present {
        .v-calendar-daily_head-weekday,
        .v-calendar-daily_head-day-label {
          color: #80DEEA;
        }
      }
      &.v-future {
        .v-calendar-daily_head-weekday,
        .v-calendar-daily_head-day-label {
          color: rgba(255,255,255,0.5);
        }
      }
    }
  }
  .v-calendar-daily .v-calendar-daily_head-day-label {
    margin-bottom: 10px;
  }

  .job {
    margin: 8px 8px;
    padding: 0 0 4px;
    text-align: center;
    cursor: pointer;
    border-radius: 10px;
    transition: background-color ease 350ms;
    background-color: rgba(255, 255, 255, 0.05);
    &:hover {
      background-color: rgba(255, 255, 255, 0.1);
    }
    .job--title {
      text-decoration: underline;
    }
  }
}
</style>
