<template>
  <v-text-field
    :full-width="fullWidth || fullWidth === ''"
    :hide-details="hideDetails || hideDetails === ''"
    :single-line="singleLine || singleLine === ''"
    @input="handleInput"
    :label="label"
    :placeholder="placeholder"
    :prepend-inner-icon="prependInnerIcon"
    :value="local"
    class="font-mono" inputmode="numeric" ref="monetaryInput" step="0.01" type="numeric"
  ></v-text-field>
</template>

<script>
import Cent$ from '@/cents.js'

export default {
  name: 'monetary-input',
  props: {
    fullWidth: { type: [Boolean, String], required: false, default: false },
    hideDetails: { type: [Boolean, String], required: false, default: false },
    label: { type: String, required: false, default: '' },
    placeholder: { type: String, required: false, default: '' },
    prependInnerIcon: { type: String, required: false, default: 'attach_money' },
    singleLine: { type: [Boolean, String], required: false, default: false },
    value: { type: [Number, String], required: true }
  },
  data () {
    return {
      local: '0'
    }
  },
  computed: {

  },
  methods: {
    handleInput (val) {
      const input = this.$refs.monetaryInput.$refs.input
      const length = val?.length
      if (!length) {
        return val
      }
      let output = ''

      let selectionStart = input.selectionStart
      let selectionEnd = input.selectionEnd

      // eslint-disable-next-line no-useless-escape
      val = val.replace(/[^\d\.]/g, '')

      selectionStart -= length - val.length
      selectionEnd -= length - val.length

      const decimalIndex = val.indexOf('.')
      if (decimalIndex === -1) {
        if (selectionStart === selectionEnd && selectionEnd === length - 2) {
          output = val.substring(0, selectionEnd) + '.' + val.substring(selectionEnd)
        } else {
          output = val + '.00'
        }
      } else {
        output = val.substring(0, decimalIndex + 1)
        let cents = ''
        const isDigit = /\d/
        for (let i = decimalIndex + 1; i < val.length; i++) {
          const c = val.charAt(i)
          if (isDigit.test(c)) {
            cents += c
            if (cents.length > 1) {
              break
            }
          } else {
            if (i < selectionStart) {
              selectionStart--
              selectionEnd--
            } else if (i < selectionEnd) {
              selectionEnd--
            }
          }
        }
        while (cents.length < 2) {
          cents += '0'
        }
        if (!output.startsWith('0.')) {
          while (output.startsWith('0')) {
            output = output.substring(1)
          }
        }
        output += cents
      }
      // this.local = output
      this.$refs.monetaryInput.lazyValue = output
      this.$emit('input', parseInt(output.replace('.', ''), 10))
      this.$nextTick(() => {
        input.selectionStart = selectionStart
        input.selectionEnd = selectionEnd
      })
      return output
    },

    updateLocal (input = null) {
      if (input === null) {
        input = this.value
      }
      if (!input) {
        input = '0'
      }
      const local = Cent$.fromCents(input).asDollars
      if (local !== this.local) {
        this.local = local
      }
    }
  },
  watch: {
    value (val) {
      this.updateLocal(val)
    }
  },
  created () {
    this.updateLocal()
  },
  updated () {
    this.updateLocal()
  }
}
</script>

<style lang="scss">
</style>
