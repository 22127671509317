import { defineModel } from '@/utils'
// import { HOURS_QUERY } from '../queries'
import { HOUR_CREATE, HOUR_UPDATE, HOUR_DELETE } from '../mutations'

const HOUR = defineModel({
  name: 'Hour',
  fields: { id: 0, employee_id: 0, job_id: 0, date: '', time: '', role: '', rate: null, billable: true, description: null, start_time: null, start_lat: 0, start_lon: 0, stop_time: null, stop_lat: 0, stop_lon: 0 },
  mutations: {
    create: {
      mutation: HOUR_CREATE
    },
    update: {
      mutation: HOUR_UPDATE
    },
    delete: {
      mutation: HOUR_DELETE
    }
  }
})

export default HOUR
