<template>
  <v-layout id="employee_hours" pb-4>
    <v-flex>
      <v-card v-if="employee">
        <v-toolbar dark color="indigo">
          <v-toolbar-title>{{ employee.name }} - Hours</v-toolbar-title>
          <v-spacer></v-spacer>
          <span>showing {{ filteredHours.length }} of {{ hours.length }}</span>
          <template v-slot:extension>
            <v-menu lazy offset-y full-width
              ref="startDateMenu" max-width="290px" min-width="290px" transition="scale-transition"
              v-model="startDateMenuOpen" :close-on-content-click="false">
              <template v-slot:activator="{ on }">
                <v-text-field :value="startDate" v-on="on" label="Start" prepend-icon="event" style="width: 50%;" hide-details></v-text-field>
              </template>
              <v-date-picker v-model="startDate" no-title :max="endDate" @input="startDateMenuOpen = false"></v-date-picker>
            </v-menu>
            <v-menu lazy offset-y full-width
              ref="endDateMenu" max-width="290px" min-width="290px" transition="scale-transition"
              v-model="endDateMenuOpen" :close-on-content-click="false">
              <template v-slot:activator="{ on }">
                <v-text-field :value="endDate" v-on="on" label="End" prepend-icon="event" style="width: 50%;" hide-details></v-text-field>
              </template>
              <v-date-picker v-model="endDate" no-title :min="startDate" @input="endDateMenuOpen = false"></v-date-picker>
            </v-menu>
          </template>

        </v-toolbar>
        <v-data-table must-sort hide-actions
          :headers="headers" :items="filteredHours" :pagination.sync="pagination">
          <template v-slot:items="props">
            <tr>
              <td class="px-3">{{ getFormattedDate(props.item.date) }}<br><span class="caption font-weight-thin">{{ props.item.date }}</span></td>
              <td class="px-1 text-xs-center">{{ props.item.job.title }}<br><span class="caption font-weight-thin">{{ props.item.job.client.name }}</span></td>
              <td class="px-3 text-xs-right" style="font-family: monospace,monospace;">{{ formatHour(props.item.time) }}</td>
            </tr>
          </template>
          <template v-slot:footer>
            <td :colspan="headers.length" class="px-3 text-xs-right subtitle" style="font-family: monospace,monospace;">{{ formatHour(totalFilteredHours) }}</td>
          </template>
        </v-data-table>
      </v-card>
    </v-flex>

  </v-layout>
</template>

<script>
import { EMPLOYEE, USER } from '@/graphql/models'
import { formatDecimal, getDate } from '@/utils'
import format from 'date-fns/format'
import formatRelative from 'date-fns/formatRelative'
import differenceInDays from 'date-fns/differenceInDays'
import parseISO from 'date-fns/parseISO'
import isWithinInterval from 'date-fns/isWithinInterval'
import startOfWeek from 'date-fns/startOfWeek'
import lastDayOfWeek from 'date-fns/lastDayOfWeek'
import toDate from 'date-fns/toDate'

export default {
  name: 'employee-hours',
  components: { },
  data () {
    return {
      employee: {},
      headers: [
        { text: 'Date', align: 'left', value: 'date', class: 'px-3' },
        { text: 'Job', align: 'center', value: 'job.title', class: 'px-1' },
        { text: 'Hours', align: 'right', value: 'time', width: '10', class: 'px-3' }
      ],
      pagination: {
        descending: true,
        rowsPerPage: -1,
        sortBy: 'date'
      },
      startDate: getDate(toDate(startOfWeek(new Date()))),
      endDate: getDate(toDate(lastDayOfWeek(new Date()))),
      startDateMenuOpen: false,
      endDateMenuOpen: false
    }
  },
  apollo: {
    employee: {
      query: EMPLOYEE.queries.show.query,
      variables () { return { id: this.employeeId } }
    },
    me: { query: USER.queries.me.query }
  },
  computed: {
    employeeId () {
      return this.$route.params.employeeId
    },
    hours () {
      return this.employee?.hours
    },
    totalHours () {
      return this.hours.reduce((total, hour) => total + hour.time, 0)
    },
    filteredHours () {
      const start = parseISO(this.startDate)
      const end = parseISO(this.endDate)
      return this.hours.filter(hour => isWithinInterval(parseISO(hour.date), { start, end }))
    },
    totalFilteredHours () {
      return this.filteredHours.reduce((total, hour) => total + hour.time, 0)
    }
  },
  methods: {
    formatHour (h) {
      return formatDecimal(h, 2)
    },

    getFormattedDate (date) {
      const now = new Date()
      const then = parseISO(date)
      const diff = Math.abs(differenceInDays(now, then))

      if (diff > 5) {
        return format(then, 'MMM do y')
      } else {
        return formatRelative(then, now).split(' at ')[0]
      }
    }

  }
}
</script>

<style lang="scss">

</style>
