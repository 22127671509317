<template>
  <section>
    <v-toolbar color="deep-purple lighten-1" dark dense flat>
      <v-icon>ballot</v-icon>
      <v-toolbar-title>Lists</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn v-if="expanded" icon @click.stop="showAddListDialog = true">
        <v-tooltip left nudge-left="10">
          <v-icon slot="activator" color="white">add</v-icon>
          Add New List
        </v-tooltip>
      </v-btn>
      <v-btn icon @click="expanded = !expanded" style="margin-left: 40px;">
        <v-badge left color="deep-purple lighten-2 deep-purple--text text--lighten-4" class="v-badge--inline">
          <template #badge><span>{{ lists ? lists.length : 0 }}</span></template>
          <v-icon>{{ expanded ? 'expand_less' : 'expand_more' }}</v-icon>
        </v-badge>
      </v-btn>
    </v-toolbar>

    <v-slide-y-transition>
      <v-card v-show="expanded">
        <manage-list
          v-for="(list, index) of lists" :key="list.id" :list="list" :lists-index="index" :lists-count="lists.length" :starts-open="lists.length < 2"
          :notableId="notableId" :notableType="notableType" :parentPath="parentPath"
        ></manage-list>
        <v-card-actions>
          <v-btn flat color="deep-purple lighten-3" @click.stop="showAddListDialog = true">
            <v-icon>add</v-icon>
            Add New List
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-slide-y-transition>

    <v-dialog v-model="showAddListDialog" max-width="290">
      <v-card>
        <v-card-title class="headline">Add List</v-card-title>
        <v-card-text>
          <v-select :items="listTemplates" v-model="selectedTemplate" item-text="name" return-object></v-select>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="amber darken-1" flat="flat" @click="showAddListDialog = false">Cancel</v-btn>
          <v-btn color="green darken-1" flat="flat" @click="addList">Add</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
import { clone, getUTCTimestamp } from '@/utils'
import ManageList from '@/components/ManageList.vue'
import { NOTE } from '@/graphql/models'

const blankList = { name: 'new list', items: [] }

export default {
  name: 'manage-lists',
  components: {
    ManageList
  },
  props: {
    notableId: { type: Number, required: true },
    notableType: { type: String, required: true },
    lists: {},
    parentPath: { type: String, required: true }
  },
  data () {
    return {
      expanded: false,
      showAddListDialog: false,
      selectedTemplate: clone(blankList),
      newList: clone(blankList)
    }
  },
  computed: {
    company () { return this.$oxide.store.apollo.company },
    me () { return this.$oxide.store.apollo.user },
    config () { return this.company?.config },
    listTemplates () {
      const lists = this.config?.templates?.lists ? this.config.templates.lists : []
      return [clone(this.newList), ...lists]
    }
  },
  methods: {
    async addList () {
      this.showAddListDialog = false
      const note = {
        notable_id: this.notableId,
        notable_type: this.notableType,
        title: this.selectedTemplate.name === 'new list' ? '' : this.selectedTemplate.name,
        content: JSON.stringify(this.selectedTemplate.items),
        type: this.selectedTemplate.type ? this.selectedTemplate.type : 'LIST',
        visibility: 'FULL',
        order: 0
      }

      const timestamp = getUTCTimestamp()

      this.$apollo.mutate({
        mutation: NOTE.mutations.create.mutation,
        variables: { note: { ...note, user: { connect: this.me.id } } },
        update: (store, { data: { createNote } }) => {
          const data = store.readQuery(this.$oxide.store.clientQuery)
          const tempNote = clone(createNote)
          const jobId = parseInt(this.notableId, 10)
          const job = data.client.jobs.find(job => parseInt(job.id, 10) === jobId)
          job.notes.push(tempNote)
          store.writeQuery({ ...this.$oxide.store.clientQuery, data })
        },
        optimisticResponse: {
          __typename: 'Mutation',
          createNote: {
            __typename: 'Note',
            id: 0,
            ...note,
            user: this.me,
            created_at: timestamp,
            updated_at: timestamp
          }
        }
      })
    }
  }
}
</script>

<style lang="scss">

</style>
