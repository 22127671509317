// 1 11/32 OR 55-1/2 OR 5/8 OR 3.14159265359 OR .5 OR 69 (nice)
const IS_NUMERIC = /(\d+[ -]\d+\/\d+|\d+\/\d+|[\d ]\.\d+|\d+)/g

const getNestedValue = function (key, val, delimiter = '.') {
  if (typeof val === 'object' && val !== null) {
    const index = key.indexOf(delimiter)
    if (index === -1) {
      return val[key]
    } else {
      return getNestedValue(key.substring(index + 1), val[key.substring(0, index)], delimiter)
    }
  } else {
    return undefined
  }
}

let _ = Symbol('_')
const R = Symbol('rel')

class Index {
  constructor (
    documents,
    {
      using = [], ignoring = [], replacing = {}, units = {}, debug = false
    } = {
      using: [], ignoring: [], replacing: {}, units: {}, debug: false
    }
  ) {
    if (debug) { _ = '_' }
    this[_] = Object.create(null)

    this[_].documents = documents
    this[_].fields = using

    this[_].ignorePatterns = ignoring
    this[_].replacePatterns = replacing
    this[_].unitPatterns = units

    this[_].index = new Map()
    this[_].documentText = new Map()
    this[_].matches = new Map()

    this[_].query = Object.create(null)
    this[_].query.raw = ''
    this[_].query.normalized = ''
    this[_].query.tokens = []

    this[_].limit = Object.create(null)
    this[_].limit.remain = -1
    this[_].limit.max = -1

    this._indexDocuments()
  }

  _indexDocuments () {
    this[_].index.clear()
    this[_].documentText.clear()
    this[_].matches.clear()

    for (const doc of this[_].documents) {
      let documentTokens = []
      for (const field of this[_].fields) {
        let value = getNestedValue(field, doc, '.')
        if (Array.isArray(value)) {
          value = value.join(' ')
        }
        if (typeof value === 'string') {
          const tokens = this._tokenize(this._normalizeString(value))
          // remove duplicates and concat
          documentTokens = [...new Set([...documentTokens, ...tokens])]
          for (const token of tokens) {
            let indexDocs = this[_].index.get(token)
            if (typeof indexDocs === 'undefined') {
              indexDocs = new Map()
              indexDocs.set(doc, 1)
              this[_].index.set(token, indexDocs)
            } else {
              const occurences = indexDocs.get(doc)
              indexDocs.set(doc, (typeof occurences === 'number' ? occurences : 0) + 1)
            }
          }
        }
      }
      this[_].documentText.set(doc, documentTokens.sort().join(' '))
    }
  }

  _tokenize (str) {
    str = this._normalizeMeasurements(str)
    str = this._removeIgnored(str)
    str = this._runReplacements(str)

    return str.split(' ')
  }

  _normalizeString (str) { return str.replace(/\s+/gm, ' ').trim().toLowerCase() }

  _normalizeMeasurements (str) {
    const numericMatches = str.matchAll(IS_NUMERIC)
    const substitutions = []
    for (const numberMatch of numericMatches) {
      // check for a unit after numberMatch
      const strAfterNumber = str.substring(numberMatch.index + numberMatch[0].length)
      for (const unit of Object.keys(this[_].unitPatterns)) {
        const unitMatch = this[_].unitPatterns[unit].exec(strAfterNumber)
        if (unitMatch !== null) {
          substitutions.push({ start: numberMatch.index, from: `${numberMatch[0]}${unitMatch[0]}`, to: `${numberMatch[0].replace(' ', '-')}${unit}` })
          break
        }
      }
    }
    let substitutedString = str
    for (const sub of substitutions) {
      substitutedString = substitutedString.replace(sub.from, sub.to)
    }
    return substitutedString
  }

  _removeIgnored (str) {
    for (const pattern of this[_].ignorePatterns) {
      str = str.replace(pattern, '')
    }
    return this._removeExtraSpaces(str)
  }

  _removeExtraSpaces (str) { return str.replace(/ {2,}/g, ' ').trim() }

  _runReplacements (str) {
    for (const [replacement, pattern] of Object.entries(this[_].replacePatterns)) {
      str = str.replace(pattern, replacement)
    }
    return this._removeExtraSpaces(str)
  }

  get query () { return this[_].query.tokens.join(' ') }
  set query (str) {
    this[_].query.raw = str
    const normalizedString = this._normalizeString(str)
    if (normalizedString !== this[_].query.normalized) {
      this[_].query.normalized = normalizedString
      this[_].query.tokens = this._tokenize(this[_].query.normalized)
      this[_].matches.clear()
    }
  }

  findMatchesFor (query, { matchAllIfBlank = false } = { matchAllIfBlank: false }) {
    this.query = query
    const matches = new Map()
    if (this[_].query.tokens.length === 0 && matchAllIfBlank) {
      // query is blank
      for (const doc of this[_].documents) {
        const match = Object.create(null)
        match.total = 0
        match.tokens = new Map()
        matches.set(doc, match)
      }
    } else {
      for (const queryToken of this[_].query.tokens) {
        // loop through query tokens

        const indexDocs = this[_].index.get(queryToken)

        if (typeof indexDocs !== 'undefined') {
          // query token found in index

          for (const [indexDoc, occurences] of indexDocs.entries()) {
            // loop through documents that contain query token
            const value = Math.sqrt(occurences)
            if (matches.has(indexDoc)) {
              // document already in list of matches

              const match = matches.get(indexDoc)
              if (match.tokens.has(queryToken)) {
                const matchOccurences = match.tokens.get(queryToken)
                match.tokens.set(queryToken, value + matchOccurences)
              } else {
                match.tokens.set(queryToken, value)
              }
              match.total += value
            } else {
              // document is not in list of matches

              const match = Object.create(null)
              match.tokens = new Map()
              match.tokens.set(queryToken, value)
              match.total = value

              matches.set(indexDoc, match)
            }
          }
        } else {
          // query token not found in index
          // TODO: look into adding loose matches
          const looseMatches = []
          for (const [doc, text] of this[_].documentText.entries()) {
            if (text.includes(queryToken)) {
              looseMatches.push(doc)
            }
          }
          if (looseMatches.length > 0) {
            for (const doc of looseMatches) {
              const value = (Math.min(queryToken.length, 4) / 5) / looseMatches.length
              if (matches.has(doc)) {
                const match = matches.get(doc)
                if (match.tokens.has(queryToken)) {
                  const matchOccurences = match.tokens.get(queryToken)
                  match.tokens.set(queryToken, value + matchOccurences)
                } else {
                  match.tokens.set(queryToken, value)
                }
                match.total += value
              } else {
                const match = Object.create(null)
                match.tokens = new Map()
                match.tokens.set(queryToken, value)
                match.total = value

                matches.set(doc, match)
              }
            }
          }
        }
      }
    }
    const matchEntries = Array.from(matches.entries())
    matchEntries.sort(this._sortMatches)
    this[_].matches = new Map(matchEntries)
    return this
  }

  _sortMatches (a, b) { return b[1].total - a[1].total }

  getAll ({ withRelevance = false, withRelevanceAs = null } = { withRelevance: false, withRelevanceAs: null }) {
    let relKey = R
    if (withRelevanceAs !== null) {
      relKey = withRelevanceAs
      withRelevance = true
    }

    if (withRelevance) {
      return Array.from(this[_].matches.entries()).map(([doc, match]) => {
        doc[relKey] = match
        return doc
      })
    } else {
      return Array.from(this[_].matches.keys())
    }
  }

  getTheTop (limit, { withRelevance = false, withRelevanceAs = null } = { withRelevance: false, withRelevanceAs: null }) {
    let relKey = R
    if (withRelevanceAs !== null) {
      relKey = withRelevanceAs
      withRelevance = true
    }

    if (withRelevance) {
      return Array.from(this[_].matches.entries()).slice(0, limit).map(([doc, match]) => {
        doc[relKey] = match
        return doc
      })
    } else {
      return Array.from(this[_].matches.keys()).slice(0, limit)
    }
  }
}

const search = function (
  documents,
  {
    using = [], ignoring = [], replacing = {}, units = {}, debug = false
  } = {
    using: [], ignoring: [], replacing: {}, units: {}, debug: false
  }
) {
  return new Index(documents, { using, ignoring, replacing, units, debug })
}

export { search, Index, R }
