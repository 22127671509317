import { defineModel } from '@/utils'
import { QUOTE_CREATE, QUOTE_UPDATE, QUOTE_DELETE } from '../mutations'

const QUOTE = defineModel({
  name: 'Quote',
  fields: { id: 0, client_id: 0, job_id: null, title: '', description: '' },
  mutations: {
    create: { mutation: QUOTE_CREATE },
    update: { mutation: QUOTE_UPDATE },
    delete: { mutation: QUOTE_DELETE }
  }
})

export default QUOTE
