<template>
  <div class="billables-search" :class="{ 'focused': showResults }">
    <slot name="search">
      <v-text-field
        persistent-hint :hint="hintText"
        class="px-2" color="teal"
        :prepend-inner-icon="busy ? 'indeterminate_check_box' : 'add_box'" :loading="busy" :readonly="busy" :label="busy && !!busyLabel ? busyLabel : label"
        :value="query"
        @keyup.enter="handleSearchEnter" @keyup.tab="handleSearchEnter"
        @keyup.up.capture.prevent.stop="handleSearchUp"
        @keyup.down.capture.prevent.stop="handleSearchDown"
        @keydown.up.capture.stop.prevent @keydown.down.capture.stop.prevent
        @input="handleSearchInput"
        @focus="handleSearchFocus" @blur="handleSearchBlur"
        @keyup.escape="query = ''"
        @click:append="query = ''"
        :append-icon="showResults || query !== '' ? 'clear' : ''"
        :tabindex="tabindex" ref="search">
      </v-text-field>
    </slot>
    <aside v-show="showResults" ref="results" class="billables-search--results elevation-2">
      <slot name="results" :billables="results" :change="handleBillableSelection">
        <v-list :light="theme !== 'dark'" :dark="theme === 'dark'" two-line :class="{ 'py-0': query === '' }">
          <v-list-tile
            v-for="(billable, index) of results" :key="billable.id"
            @click="handleBillableSelection(billable)"
            @mouseover.passive="selectionIndex = results.length - 1 - index"
            :class="{ 'selected': index === results.length - 1 - selectionIndex }">
            <v-list-tile-content>
              <v-list-tile-title class="d-flex">
                <v-flex pa-0 grow-1>
                  <span class="font-weight-medium">{{ billable.name }}</span>
                  <template v-if="billable.size !== ''"><span class="grey--text"> | </span> <span class="font-mono grey--text" :class="{ 'text--darken-1': light,'text--lighten-1': dark }">{{ billable.size }}</span></template>
                </v-flex>
                <v-flex pa-0 class="text-xs-right">
                  <template v-if="showPrice"><span style="font-size: 85%;">{{ billable.cost | monetaryPreScaled}} </span></template>
                  <template v-if="billable.unit"><span class="grey--text" :class="{ 'text--lighten-1': dark }"> / </span> <span style="font-size: 85%;">{{ billable.unit }}</span></template>
                </v-flex>
              </v-list-tile-title>
              <v-list-tile-sub-title>
                <span class="category pr-2">{{ billable.category.name }}</span>
                <span
                  v-if="billable.supplier_desc"
                  class="grey--text pr-2"
                  :class="{ 'text--darken-3': light, 'text--lighten-1': dark }"
                  style="font-size: 12px;"
                  v-text="billable.supplier_desc"
                ></span>
                <v-chip
                  v-for="(tag, tagIndex) of billable.tags" :key="tagIndex"
                  :color="getColor(tag)" :class="{ 'darken-3': light,'text--darken-3': light, 'lighten-2': dark, 'text--lighten-2': dark }"
                  dark outline label>
                  {{ tag }}
                </v-chip>
              </v-list-tile-sub-title>
            </v-list-tile-content>
          </v-list-tile>
          <v-list-tile v-if="results.length === 0 && query !== ''">
            <v-list-tile-content>
              <v-list-tile-title class="text-xs-center">No Results</v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
        </v-list>
      </slot>
    </aside>
  </div>
</template>

<script>
import { COLORS } from '@/constants'
import { itemFromString } from '@/utils'
import { debounce } from 'lodash'
import { search } from '@/search'

// const IGNORE_PATTERNS = [
//   /,/g
// ]

const REPLACEMENT_PATTERNS = {
  black: /\b(?:blk|bl)\b/g,
  white: /\b(?:wht|wh)\b/g,
  ' ': /,/g
}

const UNIT_PATTERNS = {
  mm: /^( ?millimeters| ?milimeters| ?millimeter| ?milimeter| ?mm)\b/,
  cm: /^( ?centemeters| ?centemeter| ?cm)\b/,
  m: /^( ?meters| ?meter| ?m)\b/,
  // quotes don't play well with word boundries
  in: /^( ?inches\b| ?inch\b| ?in\b|["“”](?= |$))/, // double quote, left double, right double
  ft: /^( ?feet\b| ?foot\b| ?ft\b|['‘’](?= |$))/, // apostrophe, left single, right single
  //
  amp: /^( ?amperes| ?ampere| ?amps| ?amp|a)\b/,
  volt: /^( ?volts| ?volt|v)\b/,
  watt: /^( ?watts| ?watt|w)\b/,
  kw: /^( ?kilowatts| ?kilowatt| ?kw)\b/
}

export default {
  name: 'billables-search',
  props: {
    delay: { type: Number, required: false, default: 350 },
    injectRelevance: { type: Boolean, required: false, default: false },
    injectRelevanceAs: { type: String, required: false, default: '' },
    keys: { type: Array, required: false, default: () => ['name', 'size', 'tags', 'supplier_name'] },
    label: { type: String, required: false, default: 'Search for a billable' },
    limit: { type: Number, required: false, default: 20 },
    tabindex: {},
    threshold: { type: Number, required: false, default: 0.001 },
    value: { type: String, required: false, default: '' },
    theme: { type: String, required: false, default: 'light' },
    filteredBillables: { required: false, default: null },
    showPrice: { type: Boolean, required: false, default: false },
    busy: { type: Boolean, required: false, default: false },
    busyLabel: { type: String, required: false, default: 'Adding billable' }
  },
  data () {
    return {
      position: { x: 0, y: 0, width: 0, height: 0, top: 0, right: 0, bottom: 0, left: 0 },
      query: '',
      results: [],
      searching: false,
      selectionIndex: 0,
      showResults: false
    }
  },
  computed: {
    dark () { return this.theme === 'dark' },
    light () { return this.theme !== 'dark' },
    billables () { return this.filteredBillables === null ? this.$oxide.store.apollo.billables : this.filteredBillables },
    categories () { return this.$oxide.store.apollo.categories },
    billableCatalog () {
      return search(
        this.billables,
        { using: this.keys, replacing: REPLACEMENT_PATTERNS, units: UNIT_PATTERNS, debug: true }
      )
    },
    colors () {
      const colors = [...COLORS]
      if (this.dark) {
        const index = colors.indexOf('indigo')
        colors[index] = 'yellow'
      }
      return colors
    },
    hintText () {
      if (this.query === '') {
        return 'searching ' + this.billables.length + ' billables'
      } else {
        return 'searching ' + this.billables.length + ' billables for ' + this.billableCatalog._?.query?.tokens.join(' OR ')
      }
    }
  },
  methods: {
    getColor (str) { return itemFromString(this.colors, str) },
    focus () { this.$refs.search.focus() },
    preventDefault (e) {
      e.preventDefault()
      return false
    },
    handleSearchBlur () { this.showResults = !(this.query === null || this.query === '') },
    handleSearchDown () { this.selectionIndex -= (this.selectionIndex > 0) ? 1 : 0 },
    handleSearchFocus () { this.showResults = true },
    handleSearchEnter () { if (this.results?.length) { this.handleBillableSelection(this.results[this.results.length - 1 - this.selectionIndex]) } },
    handleSearchUp () { this.selectionIndex += (this.selectionIndex < this.results.length - 1) ? 1 : 0 },

    handleSearchInput (query) {
      if (typeof query === 'string') {
        this.updateQuery(query)
      } else if (query === null) {
        this.updateQuery('')
      }
    },

    handleBillableSelection (billable) {
      this.$emit('change', billable)
      this.updateQuery('')
    },

    updateQuery: debounce(async function (query) {
      this.query = query
      if (this.value !== query) {
        this.$emit('input', query)
      }
      if (query === null || (typeof query === 'string' && query.trim() === '')) {
        this.results = []
      } else {
        const resultsOptions = { withRelevance: this.injectRelevance, withRelevanceAs: null }
        if (this.injectRelevanceAs !== '') {
          resultsOptions.withRelevanceAs = this.injectRelevanceAs
          resultsOptions.withRelevance = true
        }
        this.results = this.billableCatalog.findMatchesFor(this.query).getTheTop(this.limit, resultsOptions).reverse()
        if (this.$refs.results) {
          this.$refs.results.scrollTo(0, 100000)
        }
        this.$nextTick(() => {
          if (this.$refs.results) {
            this.$refs.results.scrollTo(0, 100000)
            setTimeout(() => {
              if (this.$refs.results) {
                this.$refs.results.scrollTo(0, 100000)
              }
            }, 300)
          } else {
            setTimeout(() => {
              if (this.$refs.results) {
                this.$refs.results.scrollTo(0, 100000)
              }
            }, 500)
          }
        })
      }
    }, 300)
  },
  watch: {
    results (results) {
      this.selectionIndex = 0
      const reversed = [...results]
      reversed.reverse()
      this.$emit('update:results', reversed)
    },
    query (query) {
      if (query === null || query === '') {
        this.showResults = false
        this.results = []
      } else {
        this.showResults = true
      }
    },
    value (value) {
      if (value !== this.query) {
        this.updateQuery(value)
      }
    },
    showResults (show) {
      document.scrollingElement.style.overflowY = show ? 'hidden' : ''
    }
  },

  created () {
    this.updateQuery(this.value)
    this.showResults = false
  },

  mounted () {
    this.showResults = false
  },

  destroyed () {
    document.scrollingElement.style.overflowY = ''
  }
}
</script>

<style lang="scss">
.billables-search {
  position: relative;
  width: 100%;

  aside {
    position: absolute;
    z-index: 3;
    bottom: 83px;
    width: 100%;
    max-height: 50vh;
    overflow-y: scroll;

    .v-list {
      & > [role="listitem"]:nth-child(even) {
        background-color: #fafafa;
        &.selected {
          background-color: #fbe9e7;
        }
      }
      .selected {
        background-color: #fbe9e7;
      }
      .v-list__tile {
        height: 85px;
      }
      .v-list__tile__title {
        height: 30px;
      }
      .category {
        font-variant: all-small-caps;
      }
      .v-chip {
        margin: 1px;
        .v-chip__content {
          font-size: 75%;
        }
      }
    }

    .v-list.theme--dark {
      background-color: #193d51 !important;
      & > [role="listitem"]:nth-child(even) {
        background-color: #1d445a;
        &.selected {
          background-color: #1d455a;
        }
      }
      .selected {
        font-weight: 600;
        background-color: #2f617c !important;
      }
      a {
        text-decoration: none !important;
      }
    }

    ul.list li:nth-child(even) {
      background-color: #fafafa;
    }

    .v-chip--small {
      height: 20px
    }

    .v-chip {
      height: 20px !important;

      .v-chip__content {
        font-size: 11px !important;
        padding: 0 6px;
        padding-right: 6px;
        height: 20px !important;
      }
    }
  }
  &.focused {
    z-index: 5;
    padding-bottom: 5px;
  }
}
</style>
