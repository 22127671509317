<template>
  <v-menu v-model="show" :close-on-content-click="false" lazy top="top" :right="right" :bottom="bottom" :left="left" absolute style="position: absolute;">

    <template v-slot:activator="{ on }">
      <slot name="act" v-bind:on="on"></slot>
    </template>

    <v-card>
      <v-card-text v-if="confirmTitle" class="title">{{ confirmTitle }}</v-card-text>
      <v-list two-line class="py-0">
        <v-list-tile>
          <v-list-tile-action>
            <v-switch v-model="confirmed" :color="actionColor"></v-switch>
          </v-list-tile-action>
          <slot name="text">
            <span class="body-1">{{ confirmText }}</span>
          </slot>
        </v-list-tile>
      </v-list>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn flat :color="cancelColor" @click="cancel" v-text="cancelText" class="font-weight-bold"></v-btn>
        <v-btn :color="actionColor" @click="confirm" v-text="actionText" :disabled="!confirmed" class="font-weight-bold"></v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  name: 'confirm-action',
  props: {
    actionColor: { type: String, required: false, default: 'primary' },
    actionText: { type: String, required: false, default: 'confirm' },
    cancelColor: { type: String, required: false, default: '' },
    cancelText: { type: String, required: false, default: 'cancel' },
    confirmText: { type: String, required: false, default: '' },
    confirmTitle: { type: String, required: false, default: 'Are you sure?' },
    align: { type: String, required: false, default: 'right' }
  },
  data () {
    return {
      show: false,
      confirmed: false
    }
  },
  computed: {
    top () { return this.align.includes('top') },
    right () { return this.align.includes('right') },
    bottom () { return this.align.includes('bottom') },
    left () { return this.align.includes('left') }
  },
  methods: {
    hide () {
      this.confirmed = false
      this.show = false
    },
    cancel () {
      this.hide()
    },
    confirm () {
      if (this.confirmed) {
        this.$emit('confirm')
        this.hide()
      }
    }
  }
}
</script>
