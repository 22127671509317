<template>
  <section class="billable-list">
    <v-toolbar dark dense flat color="teal" style="z-index: 2;">
      <v-icon>receipt</v-icon>
      <v-toolbar-title>Billables</v-toolbar-title>

      <v-spacer></v-spacer>
      <v-btn icon @click="expanded = !expanded">
        <v-badge left color="teal lighten-1 teal--text text--lighten-4" class="v-badge--inline">
          <template #badge><span>{{ activeLines ? activeLines.length : 0 }}</span></template>
          <v-icon>{{ expanded ? 'expand_less' : 'expand_more' }}</v-icon>
        </v-badge>
      </v-btn>
    </v-toolbar>
    <v-slide-y-transition>
      <v-card v-show="expanded">
        <section class="">
          <v-subheader v-if="billedLinesByInvoice.length" class="title font-weight-bold justify-center">Invoiced</v-subheader>
          <v-expansion-panel expand flat>
            <v-expansion-panel-content lazy v-for="invoice of billedLinesByInvoice" :key="invoice.id">
              <template v-slot:header>
                <span class="subheading">{{ invoice.name }}</span>
                <span class="text-xs-right pr-4">
                  <span class="grey--text text--lighten-1">{{ invoice.created_at.split(' ')[0] }}</span>
                </span>
              </template>
              <div class="px-4 pb-2"><router-link :to="`/invoice/${invoice.id}`">view invoice</router-link></div>
              <v-list subheader dense class="striped">
                <v-list-tile v-for="(line, index) of invoice.lines" :key="`line-${index}`" class="invoiced" :class="{ 'temp-billable': !line.billable.id, 'invalid-billable': !(line.quantity > 0) }">
                  <v-list-tile-content>
                    <v-list-tile-title>
                      <v-layout row ma-0>

                        <v-flex xs11 v-if="line.billable.id" pa-0>
                          <v-tooltip bottom color="grey lighten-5">
                            <template #activator="data">
                              <span v-on="data.on">
                                <!--  -->
                                <span class="font-weight-bold">{{ line.billable.name }}</span>
                                <span v-if="line.billable.size" class="grey--text">
                                  | <span class="font-mono grey--text text--lighten-2">{{ line.billable.size }}</span>
                                </span>
                                <template v-if="line.billable.supplier"><span class="grey--text"> | </span><span class="font-mono grey--text text--lighten-2">{{ line.billable.supplier }}</span></template>
                                <template v-if="line.billable.unit"><span class="grey--text"> / </span><span class="caption grey--text text--lighten-2">{{ line.billable.unit }}</span></template>
                              </span>
                            </template>
                            <span>
                              <span class="category grey--text text--darken-2 font-weight-medium">{{ line.billable.category ? (line.billable.category.name ? line.billable.category.name : line.billable.category) : '' }} | </span>
                              <span style="vertical-align: middle;">
                                <v-chip v-for="(tag, tagIndex) of line.billable.tags" :key="tagIndex" small label outline :color="getColor(tag)" class="darken-3 text--darken-3">{{ tag }}</v-chip>
                              </span>
                            </span>
                          </v-tooltip>
                        </v-flex>

                        <!-- Temp Billable -->
                        <template v-else>
                          <v-flex xs8 pa-0>
                            <v-text-field
                              v-model="line.billable.name" readonly
                              placeholder="item description..." class="pt-0" ref="tempBillableDescriptions"
                              single-line hide-details
                            ></v-text-field>
                          </v-flex>

                          <v-flex xs3 pa-0 style="flex-shrink: 1;">
                            <v-text-field
                              v-model="line.billable.supplier" readonly
                              placeholder="supplier" class="pt-0"
                              single-line hide-details
                            ></v-text-field>
                          </v-flex>
                        </template>

                        <v-flex xs1 style="flex-shrink: 2;" pa-0>
                          <v-text-field
                            v-model="line.quantity" readonly :error="!(line.quantity > 0)"
                            class="pt-0 quantity-input" ref="lineQuantityInputs" color="teal"
                            prefix="x" placeholder="0"
                            single-line hide-details
                          ></v-text-field>
                        </v-flex>

                      </v-layout>
                    </v-list-tile-title>
                  </v-list-tile-content>
                </v-list-tile>
              </v-list>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </section>
        <v-list subheader dense class="striped mt-3 billable-list__unbilled-list">
          <v-divider v-if="billedLines.length"></v-divider>
          <v-subheader class="title font-weight-bold justify-center">Uninvoiced</v-subheader>
          <v-list-tile
            v-for="(line, index) in unbilledLines" :key="index" v-show="!line.invoiceId"
            :class="{ 'temp-billable': !line.billable.id, 'invalid-billable': !(line.quantity > 0) }">

            <v-list-tile-action>
              <confirm-action @confirm="removeLine(line, index)">
                <template v-slot:act="{ on }">
                  <v-btn icon small ripple @click.stop="on.click" class="ma-0">
                    <v-icon color="grey lighten-1">highlight_off</v-icon>
                  </v-btn>
                </template>
              </confirm-action>

            </v-list-tile-action>

            <v-list-tile-content>
              <v-list-tile-title>
                <v-layout row ma-0>

                  <v-flex xs11 v-if="line.billable.id" pa-0>
                    <v-tooltip bottom color="grey lighten-5">
                      <template #activator="data">
                        <span v-on="data.on">
                          <!--  -->
                          <span class="font-weight-bold">{{ line.billable.name }}</span>
                          <span v-if="line.billable.size" class="grey--text">
                            | <span class="font-mono grey--text text--lighten-2">{{ line.billable.size }}</span>
                          </span>
                          <template v-if="line.billable.supplier"><span class="grey--text"> | </span><span class="font-mono grey--text text--lighten-2">{{ line.billable.supplier }}</span></template>
                          <template v-if="line.billable.unit"><span class="grey--text"> / </span><span class="caption grey--text text--lighten-2">{{ line.billable.unit }}</span></template>
                        </span>
                      </template>
                      <span>
                        <span class="category grey--text text--darken-2 font-weight-medium">{{ line.billable.category ? (line.billable.category.name ? line.billable.category.name : line.billable.category) : '' }} | </span>
                        <span style="vertical-align: middle;">
                          <v-chip v-for="(tag, tagIndex) of line.billable.tags" :key="tagIndex" small label outline :color="getColor(tag)" class="darken-3 text--darken-3">{{ tag }}</v-chip>
                        </span>
                      </span>
                    </v-tooltip>
                  </v-flex>

                  <!-- Temp Billable -->
                  <template v-else>
                    <v-flex xs8 pa-0>
                      <v-text-field
                        :value="line.billable.name" @input="handleTempBillableNameInput(line, $event)" :tabindex="index.toString() + 1"
                        placeholder="item description..." class="pt-0" ref="tempBillableDescriptions"
                        single-line hide-details
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs3 pa-0 style="flex-shrink: 1;">
                      <v-text-field
                        :value="line.billable.supplier" @input="handleTempBillableSupplierInput(line, $event)" :tabindex="index.toString() + 1"
                        placeholder="supplier" class="pt-0"
                        single-line hide-details
                      ></v-text-field>
                    </v-flex>
                  </template>

                  <v-flex xs1 style="flex-shrink: 2;" pa-0>
                    <v-text-field
                      :value="line.quantity" @input="handleQuantityInput(line, $event)" :tabindex="index.toString() + 1" :error="!(line.quantity > 0)"
                      class="pt-0 quantity-input" ref="lineQuantityInputs" color="teal"
                      @submit.prevent.stop="focusBillables" @keydown.enter.prevent.stop="focusBillables"
                      prefix="x" placeholder="0" height="28"
                      single-line hide-details
                    ></v-text-field>
                  </v-flex>

                </v-layout>
              </v-list-tile-title>
            </v-list-tile-content>

          </v-list-tile>

        </v-list>

        <billables-search
          :label="'Search for an' + (list.length ? 'other' : '') + ' item to add'"
          :tabindex="list.length.toString() + '0'"
          ref="billablesSearch" theme="dark"
          @input="searchText = $event"
          @change="handleInput"
        ></billables-search>

        <v-card-actions class="justify-space-between" style="padding-bottom: 10px;">
          <v-btn
            v-if="admin" @click.stop="createInvoice"
            :disabled="unbilledLines.length === 0 || creatingInvoice || dirty"
            :loading="creatingInvoice"
            color="green" small flat
          >
            <v-icon>vertical_split</v-icon> To Invoice
          </v-btn>

          <v-btn v-if="savingCountdown > 0 || saving" :loading="saving" small flat color="deep-orange" @click.stop="note.id ? update() : create()">
            <v-icon class="pr-1">save_alt</v-icon> Save Now - {{ Math.ceil(savingCountdown / 1000) }}
          </v-btn>

          <v-btn small flat color="teal" @click.stop="createTempBillable">
            <v-icon>hourglass_empty</v-icon> Add Unique
          </v-btn>
        </v-card-actions>

      </v-card>
    </v-slide-y-transition>
    <v-snackbar v-model="showSnackbar" :color="snackbarColor" top>
      {{ snackbarText }}
      <v-btn dark flat @click="showSnackbar = false">
        Close
      </v-btn>
    </v-snackbar>
  </section>
</template>

<script>
/* eslint-disable camelcase */
/* eslint-disable handle-callback-err */

import { v1 as uuidv1 } from 'uuid'
import { debounce } from 'lodash'
import { COLORS } from '@/constants'
import { BILLABLE, NOTE } from '@/graphql/models'
// import { BillablesSearch, ConfirmAction } from '@/components'
import ConfirmAction from '@/components/ConfirmAction.vue'
import BillablesSearch from '@/components/BillablesSearch.vue'
import { clone, cloneExcept, equivalent, getUTCTimestamp, itemFromChar } from '@/utils'

const SAVE_DELAY = 5000

export default {
  name: 'billable-list',
  components: { BillablesSearch, ConfirmAction },

  props: {
    job: {},
    note: {},
    notableId: { type: Number, required: true },
    notableType: { type: String, required: true },
    parentPath: { type: String, required: true }
  },

  data () {
    return {
      addedBillable: null,
      countdownInterval: null,
      creatingInvoice: false,
      dirty: false,
      expanded: false,
      list: [],
      saving: false,
      searching: false,
      searchText: '',
      showSnackbar: false,
      snackbarText: '',
      snackbarColor: '',
      savingCountdown: 0
    }
  },

  computed: {
    admin () { return !!this.me?.is_admin },
    categories () { return this.$oxide.store.apollo.categories },
    me () { return this.$oxide.store.apollo.user },

    invoiceIds () {
      return this.job?.invoices?.map(invoice => invoice.id)
    },

    activeLines () {
      return this.list.filter(line => !(line.deleted?.at || line.deleted?.by))
    },

    billedLines () {
      const lines = this.activeLines.filter(line => !!line.invoiceId)
      return lines.sort((a, b) => b.invoiceId > a.invoiceId)
    },

    billedLinesByInvoice () {
      const invoices = []
      for (const line of this.billedLines) {
        const invoice = invoices.find(inv => inv.id === line.invoiceId)
        if (invoice) {
          invoice.lines.push(line)
        } else {
          const inv = this.getInvoice(line.invoiceId)
          if (inv) {
            invoices.push({ id: line.invoiceId, name: inv.name, created_at: inv.created_at, lines: [line] })
          } else {
            console.log('invoice not found', line)
          }
        }
      }

      return invoices
    },

    unbilledLines () { return this.activeLines.filter(line => !line.invoiceId) },

    countdown () {
      const percentage = ((SAVE_DELAY - this.savingCountdown) / SAVE_DELAY) * 100
      if (percentage === 100) {
        return this.dirty ? percentage : 0
      } else {
        return percentage
      }
    }
  },

  methods: {
    handleSearchInput (query) {
      if (query !== null) {
        this.searching = query && query.length && query.length > 0
        this.updateQuery(query)
      }
    },
    updateQuery: debounce(function (query) {
      this.$nextTick(() => {
        this.searching = false
      })
      if (query !== this.searchText) {
        this.searchText = query
      }
    }, 5),

    handleQuantityInput: debounce(function (line, quantity) {
      line.quantity = quantity === null ? '0' : quantity
    }, 200),

    handleTempBillableNameInput: debounce(function (line, name) {
      line.billable.name = name === null ? '' : name
    }, 500),

    handleTempBillableSupplierInput: debounce(function (line, supplier) {
      line.billable.supplier = supplier === null ? '' : supplier
    }, 500),

    handleNothing (e) {
      return false
    },

    handleInput (input) {
      if (input) {
        const billable = cloneExcept(input, ['__typename', 'created_at', 'updated_at', 'category', '_relevance'])
        billable.category = cloneExcept(input.category, ['__typename', 'created_at', 'updated_at'])
        this.searchText = ''
        this.list.push({
          billable,
          cost: null,
          quantity: '',
          deleted: Object.create(null),
          uuid: uuidv1()
        })

        setTimeout(() => {
          const quantityInput = this.$refs.lineQuantityInputs[this.$refs.lineQuantityInputs.length - 1]
          quantityInput.focus()
          quantityInput.$refs.input.setSelectionRange(0, 0)
        }, 300)
      }
    },

    getColor (str) {
      return itemFromChar(COLORS, str)
    },

    removeLine (line, index) {
      line.deleted = { at: getUTCTimestamp(), by: `User:${this.me.id}` }
    },

    createTempBillable () {
      const billable = cloneExcept(BILLABLE.blank, ['created_at', 'updated_at'])
      if (this.searchText !== null && this.searchText.trim() !== '') {
        billable.name = this.searchText.trim()
        this.searchText = ''
        this.$refs.billablesSearch.updateQuery('')
      }
      this.list.push({ billable, cost: null, quantity: '', deleted: Object.create(null), uuid: uuidv1() })
      setTimeout(() => {
        const descriptionInput = this.$refs.tempBillableDescriptions[this.$refs.tempBillableDescriptions.length - 1]
        descriptionInput.focus()
      }, 300)
    },

    save () {
      if (!this.expanded) {
        console.log('why did you save')
        return false
      }
      if (!(this.countdownInterval === null)) {
        window.clearInterval(this.countdownInterval)
      }
      this.savingCountdown = SAVE_DELAY
      this.countdownInterval = window.setInterval(() => {
        if (this.savingCountdown > 0) {
          this.savingCountdown -= 500
        } else {
          this.savingCountdown = 0
        }
      }, 500)
      this.handleSave()
    },

    handleSave: debounce(function () {
      window.clearInterval(this.countdownInterval)
      this.savingCountdown = 0
      if (!this.expanded || this.$route.name !== 'crm') {
        console.log('why did you save')
        console.trace()
        return false
      }
      if (this.dirty) {
        if (this.searchText !== '') {
          this.save()
        } else if (this.note.id) {
          console.log('update', this.note)
          this.update()
        } else {
          console.log('create', this.note)
          this.create()
        }
      }
    }, 5000),

    async update (note = null) {
      window.clearInterval(this.countdownInterval)
      this.savingCountdown = 0
      this.saving = true
      this.dirty = false
      if (note === null) {
        note = this.note
        note.content = JSON.stringify(this.list)
      } else if (!note.content) {
        note.content = JSON.stringify(this.list)
      } else {
        if (typeof note.content === 'string') {
          this.list = JSON.parse(note.content)
        } else {
          this.list = clone(note.content)
        }
      }

      const clonedNote = cloneExcept(note, ['__typename', 'user', 'created_at', 'updated_at'])

      await this.$apollo.mutate({
        mutation: NOTE.mutations.update.mutation,
        variables: { note: clonedNote },
        update: (store, { data: { updateNote } }) => {
          const data = store.readQuery(this.$oxide.store.clientQuery)
          const jobId = parseInt(this.notableId, 10)
          const job = data.client.jobs.find(job => parseInt(job.id, 10) === jobId)
          const noteId = parseInt(note.id, 10)
          const index = job.notes.findIndex(n => parseInt(n.id, 10) === noteId)
          job.notes[index] = clone(updateNote)
          store.writeQuery({ ...this.$oxide.store.clientQuery, data })
        }
      }).then(({ data: { updateNote } }) => {
        this.saving = false
        this.snackbarText = 'Billables saved'
        this.snackbarColor = 'teal'
        this.showSnackbar = true
      })
    },

    async create () {
      window.clearInterval(this.countdownInterval)
      this.savingCountdown = 0
      this.saving = true
      this.dirty = false
      const note = {
        notable_id: this.notableId,
        notable_type: this.notableType,
        title: 'Billables',
        content: JSON.stringify(this.list),
        type: 'BILLABLES',
        visibility: 'FULL',
        order: 0
      }

      this.$apollo.mutate({
        mutation: NOTE.mutations.create.mutation,
        variables: { note: { ...note, user: { connect: this.me.id } } },
        update: (store, { data: { createNote } }) => {
          const data = store.readQuery(this.$oxide.store.clientQuery)
          const jobId = parseInt(this.notableId, 10)
          const job = data.client.jobs.find(job => parseInt(job.id, 10) === jobId)
          job.notes.push(clone(createNote))
          store.writeQuery({ ...this.$oxide.store.clientQuery, data })
        }
      }).then(({ data: { createNote } }) => {
        this.saving = false
        this.snackbarText = 'Billables saved'
        this.snackbarColor = 'teal'
        this.showSnackbar = true
      })
    },

    async createInvoice () {
      this.$emit('create:invoice')
    },

    getInvoiceName (id) {
      const invoice = this.job?.invoices?.find(invoice => invoice.id === id)
      return (invoice ? invoice?.name : '')
    },

    getInvoice (id) { return this.job?.invoices?.find(invoice => invoice.id === id) },
    focusBillables (e) { this.$refs.billablesSearch.focus() },

    compareLines (a, b) {
      return a.billable.name === b.billable.name && a.quantity === b.quantity && (a.billable.id ? a.billable.id === b.billable.id : true) && (a.invoiceId ? a.invoiceId === b.invoiceId : true)
    },

    updateList (note = null) {
      if (note === null) {
        note = this.note
      }

      if (!(this.dirty || this.searchText) && note && note.content) {
        let lines = JSON.parse(note.content)
        if (!lines) {
          lines = []
        }

        lines = lines.filter(line => line !== null)
        let upgraded = false
        lines = lines.map(line => {
          if (typeof line.uuid === 'undefined') {
            upgraded = true
            line.uuid = uuidv1()
          }
          if (typeof line.deleted === 'undefined') {
            upgraded = true
            line.deleted = Object.create(null)
          }
          return line
        })

        if (!equivalent(lines, this.list)) {
          if (this.list?.length) {
            for (const local of this.list) {
              let i = lines.findIndex(line => line.uuid === local.uuid)
              if (upgraded && i === -1) {
                i = lines.findIndex(line => this.compareLines(line, local))
              }
              if (i === -1) {
                lines.push(local)
              } else {
                if (!equivalent(lines[i], local)) {
                  if (local.quantity && !lines[i].quantity) {
                    lines[i].quantity = local.quantity
                  }
                  if (local.deleted?.at !== lines[i].deleted?.at) {
                    if (local.deleted?.at) {
                      lines[i].deleted = clone(local.deleted)
                    }
                  }
                  if (!local.billable.id) {
                    if (local.billable.name && !lines[i].billable.name) {
                      lines[i].billable.name = local.billable.name
                    }
                    if (local.billable.supplier && !lines[i].billable.supplier) {
                      lines[i].billable.supplier = local.billable.supplier
                    }
                  }
                }
              }
            }
          }

          let length = lines.length
          for (let i = 0; i < length; i++) {
            for (let ii = i + 1; ii < length; ii++) {
              if (this.compareLines(lines[i], lines[ii])) {
                lines.splice(ii, 1)
                length--
                ii--
              }
            }
          }

          // unlink from deleted invoices
          for (const line of lines) {
            if (line.invoiceId && !this.invoiceIds.includes(line.invoiceId)) {
              delete line.invoiceId
            }
          }

          this.$set(this, 'list', lines)
        }
      }
    }
  },

  watch: {

    list: {
      handler (list) {
        this.dirty = !equivalent(list, JSON.parse(this.note.content))
        if (this.dirty) {
          this.save()
        }
      },
      deep: true
    },

    note: {
      handler (note) {
        this.updateList(note)
      },
      deep: true
    },

    '$refs.addBillable': {
      handler (v) {
        console.log(v)
      },
      deep: true
    }

  },
  created () {
    this.updateList()
  },
  mounted () {
    this.updateList()
  }
}
</script>

<style lang="scss">
.billable-list {
  [readonly=readonly],
  .v-input--is-readonly {
    cursor: not-allowed;
  }
  .v-list--dense {
    &.v-list--two-line {
      & > [role=listitem] > .v-list__tile:not(.v-list__tile--avatar) {
        height: 60px;
      }
    }
  }
  .v-list {
    & > [role=listitem] {
      & > .v-list__tile {
        .v-list__tile__title {
          height: 30px;
          line-height: 30px;
          vertical-align: middle;
          .category {
            // font-size: 85%;
            font-variant: all-small-caps;
          }
        }
        .v-text-field {
          margin-top: 0;
        }
      }
      &.invalid-billable {
        background-color: rgba(255,255,255,0.2);
      }
      &.invoiced {
        .flex {
          opacity: 0.66;
        }
        &.invalid-billable {
          .flex:last-child {
            opacity: 1;
          }
        }
      }
    }
  }

  .quantity-input {
    input {
      text-align: right;
    }
    .input-group--text-field__suffix {
      margin-left: 15px;
    }
    .v-text-field__prefix {
      color: #999999;
    }
  }

  .item-list {
    .v-list > div[role="listitem"]:nth-child(even):not(:last-child) {
      background-color: rgba(0,0,0,0.033);
    }
  }

  .v-list__tile__action {
    min-width: 40px;
  }

  .billable-list__unbilled-list {
    .v-list__tile {
      margin-left: -6px;
      .v-list__tile__action {
        min-width: 36px;
      }
    }
  }
  .billables-search.focused {
    background-color: #193d51;
    box-shadow: 0 0 0 100vmax rgba(0,0,0,0.5);
  }
}

@media (max-width: 600px) {
  .billable-list {
    padding-bottom: 0;

    .billables-search {
      &.focused {
        position: fixed;
        bottom: 0;
        display: flex;
        align-items: flex-end;
        .billables-search--results {
          max-height: unset;
          height: calc(100vh - 83px);
          & > .v-list {
            padding-top: 60vh;
            width: 100%;
            min-height: 100%;
            display: flex;
            // align-items: flex-end;
            align-content: flex-end;
            flex-wrap: wrap;
            & > [role=listitem] {
              width: 100%;
            }
          }
        }
      }
    }

    .quantity-input .v-text-field__prefix {
      padding-right: 0;
    }
  }
}
</style>
