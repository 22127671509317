<template>
  <v-card dark class="client-list">
    <list-header
      color="cyan darken-1" textbox-label="new client name" :title="title"
      :search-placeholder="(isFiltered ? 'Found ' + totalFilteredMatches.toLocaleString() + ' of ' : 'Search ') + clients.length.toLocaleString() + ' clients'"
      :saving="savingClient" :show-form="showAddClientForm"
      @create="createClient" @showFormChanged="handleShowFormChange"
      @search="handleSearch" @search:enter="handleSearchEnter"
    ></list-header>

    <v-sheet>
      <v-data-iterator
        :items="filteredClients"
        :rows-per-page-items="rowsPerPageItems" :pagination.sync="pagination"
        :no-data-text="clients.length ? 'No results for the current search' : 'No clients added'"
        content-tag="v-list" content-class="striped" :content-props="{ 'dense': true }"
      >

        <template v-slot:item="{ item, index }">
          <v-list-tile class="search-result-heading"
            v-if="isFiltered && (index === 0 || (item._relevance < 0.1 && filteredClients[index - 1]._relevance >= 0.1))">
            <v-list-tile-content>
              <v-list-tile-title
                class="text-xs-center font-weight-light font-italic"
                v-text="(item._relevance < 0.1 && (index === 0 || filteredClients[index - 1]._relevance >= 0.1) ? 'Loose' : '') + ' Matches'">
              </v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>

          <v-list-tile :to="'/crm/client/' + item.id + '?a=1'" :class="{ 'item--active': parseInt(item.id, 10) === activeClientId }">
            <v-list-tile-content>
              <v-list-tile-title>{{ item.name }}</v-list-tile-title>
              <v-list-tile-sub-title class="text--primary text-truncate" style="max-width: 240px;">{{ item.description }}</v-list-tile-sub-title>
            </v-list-tile-content>

            <v-list-tile-action v-if="Array.isArray(item.addresses) && item.addresses.length && item.addresses[0].street">
              <v-list-tile-action-text>
                {{ item.addresses[0].street }}
              </v-list-tile-action-text>
            </v-list-tile-action>

            <!-- <v-list-tile-action>
              <v-btn icon @click.stop="editClient(item, index)">
                <v-icon color="grey lighten-1">edit</v-icon>
              </v-btn>
            </v-list-tile-action> -->
          </v-list-tile>
          <v-divider v-if="index + 1 < filteredClients.length"></v-divider>
        </template>

      </v-data-iterator>
    </v-sheet>

  </v-card>
</template>

<script>
/* eslint-disable camelcase */
/* eslint-disable handle-callback-err */

import { clone, getUTCTimestamp, navigate } from '@/utils'
import { debounce } from 'lodash'
import { CLIENT } from '@/graphql/models'
import ListHeader from '@/components/ListHeader.vue'

export default {

  name: 'client-list',

  components: { ListHeader },

  props: {
    title: { type: String, required: false, default: 'Clients' },
    parentId: { type: Number, required: true }
  },

  data () {
    return {
      pagination: { rowsPerPage: 100 },
      rowsPerPageItems: [100, 250, 500, { text: '$vuetify.dataIterator.rowsPerPageAll', value: -1 }],
      savingClient: false,
      search: '',
      showAddClientForm: false
    }
  },

  computed: {
    clients () { return this.$oxide.store.apollo.clients ? this.$oxide.store.apollo.clients : [] },
    company () { return this.$oxide.store.apollo.company },
    me () { return this.$oxide.store.apollo.user },
    admin () { return !!this.me?.is_admin },
    activeClientId () { return this.$cache.crmModels?.client },
    isFiltered () { return this.search.length !== 0 },
    clientCatalog () { return this.$catalog(this.clients, { using: ['name', 'address.street'], sort: { fields: ['name'] }, relevance: { inject: true, threshold: 0.02 } }) },
    filteredClients () { return this.clientCatalog.resultsFor(this.search) },
    totalFilteredMatches () {
      if (this.isFiltered) {
        return this.filteredClients.length
      } else {
        return undefined
      }
    }
  },

  methods: {
    handleSearch: debounce(function (query) { this.search = query.trim() }, 350),
    handleSearchEnter () {
      if (this.isFiltered && this.totalFilteredMatches > 0) {
        this.editClient(this.filteredClients[0])
      }
    },

    editClient (client, index = -1) {
      navigate({ name: 'crm', params: { rel1_type: 'client', rel1_id: client.id, rel2_type: undefined, rel2_id: undefined }, query: { a: 1 } }, this.$router)
    },

    createClient (name) {
      this.savingClient = true
      const timestamp = getUTCTimestamp()
      this.$apollo.mutate({
        mutation: CLIENT.mutations.create.mutation,
        variables: { client: { name, is_business: false, company: { connect: this.company.id } } },
        update: (store, { data: { createClient } }) => {
          const data = store.readQuery({ query: CLIENT.queries.index.query })
          const tempClient = clone(createClient)
          data.clients.push(tempClient)
          store.writeQuery({ query: CLIENT.queries.index.query, data })
        },
        optimisticResponse: {
          __typename: 'Mutation',
          createClient: {
            __typename: 'Client',
            id: 0,
            name,
            is_business: false,
            addresses: [],
            contacts: [],
            notes: [],
            created_at: timestamp,
            updated_at: timestamp
          }
        }
      }).then(({ data: { createClient } }) => {
        this.showAddClientForm = false
        this.savingClient = false
        this.editClient(createClient)
      })
    },

    handleShowFormChange (show) {
      this.showAddClientForm = show
    }
  }
}
</script>

<style lang="scss">
.client-list {
  .item--active {
    background-color: rgba(0, 0, 0, 0.04);
  }
  .v-list {
    .search-result-heading,
    .search-result-heading > .v-list__tile {
      height: 36px;
      .v-list__tile__content {
        justify-content: flex-end;
      }
    }
  }
}
</style>
