<template>
  <v-card>
    <v-responsive :style="background">
      <v-layout column style="text-align: center; background-color: rgba(0,0,0,0.2);">
        <v-card-title class="pb-1">
          <h2 class="white--text title font-weight-regular pl-3 pt-3">
            <v-icon dark>timer</v-icon> Timer
          </h2>

          <!-- <v-spacer></v-spacer>
          <v-btn @click="" dark icon>
            <v-icon>chevron_left</v-icon>
          </v-btn> -->
        </v-card-title>
        <v-spacer></v-spacer>
        <v-card-title class="white--text">
          <v-layout column>
            <v-flex class="display-1">{{ elapsed }}</v-flex>
            <!-- <v-flex v-if="timerRunning">started at {{ new Date(start_time).toLocaleTimeString('en-CA') }}</v-flex> -->
          </v-layout>
        </v-card-title>
        <v-spacer></v-spacer>
        <v-card-title class="white--text">
          <v-layout flex row justify-space-around>
            <v-flex>
              <v-btn @click="startTimer" style="mix-blend-mode: screen;">Start</v-btn>
            </v-flex>
            <v-flex>
              <v-btn @click="stopTimer" style="mix-blend-mode: screen;">Stop</v-btn>
            </v-flex>
          </v-layout>
        </v-card-title>
      </v-layout>
    </v-responsive>

    <v-list two-line style="padding-top: 0; padding-bottom: 0;">
      <v-list-tile v-if="timerStarted">
        <v-list-tile-content>
          <v-list-tile-title>Started {{ startedAt }}</v-list-tile-title>
          <v-list-tile-sub-title>Near {{ startAddress }}</v-list-tile-sub-title>
        </v-list-tile-content>
      </v-list-tile>

      <v-list-tile v-if="timerStopped">
        <v-list-tile-content>
          <v-list-tile-title>Stopped {{ stoppedAt }}</v-list-tile-title>
          <v-list-tile-sub-title>Near {{ stopAddress }}</v-list-tile-sub-title>
        </v-list-tile-content>
      </v-list-tile>
    </v-list>
  </v-card>
</template>

<script>
// import { mapGetters } from 'vuex'
import { format, isToday, isYesterday } from 'date-fns'
import { GRADIENTS, MAPS_API_KEY } from '@/constants'

export default {
  name: 'timer',
  props: {
    //
  },
  data () {
    return {
      now: 0,
      startAddress: '',
      stopAddress: ''
    }
  },
  computed: {
    background () {
      if (this.start_lat && this.start_lon) {
        const zoom = '15'
        const size = '600x300'
        const type = 'hybrid'
        let params = `key=${MAPS_API_KEY}&size=${size}&maptype=${type}`

        // params += `&markers=color:${'green'}%7Clabel:${'S'}%7C${this.start_lat},${this.start_lon}`
        params += `&markers=color:${'green'}%7C${this.start_lat},${this.start_lon}`

        if (this.stop_lat && this.stop_lon) {
          params += `&markers=color:${'red'}%7C${this.stop_lat},${this.stop_lon}`
        } else {
          params += `&zoom=${zoom}`
        }

        return { background: `50% 35% no-repeat url(https://maps.googleapis.com/maps/api/staticmap?${params})` }
      } else {
        return { background: GRADIENTS[Math.floor(GRADIENTS.length * Math.random())] }
      }
    },
    elapsedMS () {
      if (this.timerStarted) {
        if (this.timerStopped) {
          return this.stop_time - this.start_time
        } else {
          return this.now - this.start_time
        }
      } else {
        return 0
      }
    },
    elapsedSeconds () {
      return Math.floor(this.elapsedMS / 1000)
    },
    elapsed () {
      let seconds = this.elapsedSeconds

      const hours = Math.floor(seconds / 3600)
      seconds %= 3600

      const minutes = Math.floor(seconds / 60)
      seconds %= 60

      return `${this.padTime(hours)}:${this.padTime(minutes)}:${this.padTime(seconds)}`
    },
    startedAt () {
      const startedAt = new Date(this.start_time)
      const date = isToday(startedAt) ? 'today' : (isYesterday(startedAt) ? 'yesterday' : format(startedAt, 'MMM Do, YYYY'))
      return `${date} at ${startedAt.toLocaleTimeString()}`
    },
    stoppedAt () {
      const stoppedAt = new Date(this.stop_time)
      const date = isToday(stoppedAt) ? 'today' : (isYesterday(stoppedAt) ? 'yesterday' : format(stoppedAt, 'MMM Do, YYYY'))
      return `${date} at ${stoppedAt.toLocaleTimeString()}`
    }
    // ...mapGetters('job', { jobTitle: 'title', jobDescription: 'description', jobAddresses: 'addresses', jobHours: 'hours/list' }),
    // ...mapGetters('hour', [ 'id', 'model', 'date', 'time', 'rate', 'billable', 'start_time', 'start_lat', 'start_lon', 'stop_time', 'stop_lat', 'stop_lon', 'timerStarted', 'timerStopped', 'timerRunning' ])
  },
  methods: {
    padTime (time) {
      return (typeof time === 'string' ? time : time.toString()).padStart(2, '0')
    },
    updateNow () {
      this.now = Date.now()
    },
    startInterval () {
      this.$interval = window.setInterval(this.updateNow, 500)
    },
    stopInterval () {
      window.clearInterval(this.$interval)
    },
    startTimer () {
      this.$getLocation({
        enableHighAccuracy: true,
        timeout: 10000,
        maximumAge: 60000
      })
        .then(coordinates => {
          this.$store.dispatch('hour/startTimer', coordinates)
        })
    },
    stopTimer () {
      this.$getLocation({
        enableHighAccuracy: true,
        timeout: 10000,
        maximumAge: 60000
      })
        .then(coordinates => {
          this.$store.dispatch('hour/stopTimer', coordinates)
        })
    },
    fetchStartAddress () {
      if (this.start_lat && this.start_lon) {
        this.http
          .get(
            `https://maps.googleapis.com/maps/api/geocode/json?latlng=${this.start_lat},${this.start_lon}&result_type=street_address|route&key=${MAPS_API_KEY}`,
            {
              transformRequest: [(data, headers) => {
                delete headers.common.Authorization
                return data
              }]
            }
          )
          .then(response => {
            this.startAddress = response.data.results[0].formatted_address
          })
      }
    },
    fetchStopAddress () {
      if (this.stop_lat && this.stop_lon) {
        this.http
          .get(
            `https://maps.googleapis.com/maps/api/geocode/json?latlng=${this.stop_lat},${this.stop_lon}&result_type=street_address|route&key=${MAPS_API_KEY}`,
            {
              transformRequest: [(data, headers) => {
                delete headers.common.Authorization
                return data
              }]
            }
          )
          .then(response => {
            this.stopAddress = response.data.results[0].formatted_address
          })
      }
    }
  },
  created () {
    this.startInterval()
  },
  watch: {
    start_lat: 'fetchStartAddress',
    stop_lat: 'fetchStopAddress'
  }
}
</script>
