import Vue from 'vue'
import catalog, { expressions } from 'catalogger/src/catalogger.js'
import { REGULAR_EXPRESSIONS } from '@/constants'

Vue.prototype.$catalog = function (documents,
  {
    relevance = {}, results = {}, sort = {}, string = {}, using = []
  } = {
    relevance: {}, results: {}, sort: {}, string: {}, using: []
  }
) {
  return catalog(
    documents,
    {
      relevance: { field: '_relevance', inject: true, threshold: 0.001, ...relevance },
      results,
      sort,
      string,
      using
    }
  )
}

Vue.prototype.$billableCatalog = function (documents,
  {
    relevance = {}, results = {}, sort = {}, string = {}, using = []
  } = {
    relevance: {}, results: {}, sort: {}, string: {}, using: []
  }
) {
  return catalog(
    documents,
    {
      relevance: { field: '_relevance', inject: true, threshold: 0.001, ...relevance },
      results,
      sort,
      string: {
        replacements: [
          { exp: expressions.singleCharacters, val: '' },
          { exp: REGULAR_EXPRESSIONS.punctuation, val: ' ', grouped: true },
          { exp: expressions.articlesAndDeterminers, val: ' ' },
          { exp: expressions.tabsAndVerticalWhitespace, val: ' ' },
          { exp: expressions.multiSpace, val: ' ' },
          { exp: REGULAR_EXPRESSIONS.in, val: 'in', grouped: true },
          { exp: REGULAR_EXPRESSIONS.ft, val: 'ft', grouped: true },
          { exp: REGULAR_EXPRESSIONS.amp, val: 'amp', grouped: true },
          { exp: REGULAR_EXPRESSIONS.volt, val: 'volt', grouped: true },
          { exp: REGULAR_EXPRESSIONS.watt, val: 'watt', grouped: true },
          { exp: REGULAR_EXPRESSIONS.black, val: 'black' },
          { exp: REGULAR_EXPRESSIONS.white, val: 'white' }
        ]
      },
      using
    }
  )
}
