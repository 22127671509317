import gql from 'graphql-tag'

// self fields
const ADDRESS_FIELDS = gql`
fragment addressFields on Address {
  id
  label
  order
  street
  city
  region
  country
  code
}`

const ADDRESS_FIELDS_TIME = gql`
fragment addressFieldsTime on Address {
  id
  created_at
  updated_at
  label
  order
  street
  city
  region
  country
  code
}`

const BILLABLE_FIELDS = gql`
fragment billableFields on Billable {
  id
  created_at
  updated_at
  name
  supplier
  supplier_name
  supplier_desc
  size
  tags
  cost
  unit
}`

const CATEGORY_FIELDS = gql`
fragment categoryFields on Category {
  id
  created_at
  updated_at
  name
}`

const CLIENT_FIELDS = gql`
fragment clientFields on Client {
  id
  created_at
  updated_at
  name
  is_business
}`

const COMPANY_FIELDS = gql`
fragment companyFields on Company {
  id
  created_at
  updated_at
  name
  config
}`

const CONTACT_FIELDS = gql`
fragment contactFields on Contact {
  id
  label
  order
  type
  value
}`

const CONTACT_FIELDS_TIME = gql`
fragment contactFieldsTime on Contact {
  id
  created_at
  updated_at
  label
  order
  type
  value
}`

const EMPLOYEE_FIELDS = gql`
fragment employeeFields on Employee {
  id
  created_at
  updated_at
  name
  rate
  title
  wage
}`

const ENTRY_FIELDS = gql`
fragment entryFields on Entry {
  id
  created_at
  updated_at
  order
  data
  value_1
  value_2
  value_3
}`

const HOUR_FIELDS = gql`
fragment hourFields on Hour {
  id
  created_at
  updated_at
  date
  description
  time
  role
  rate
  wage
  billable
  start_time
  start_lat
  start_lon
  stop_time
  stop_lat
  stop_lon
}`

const INVOICE_FIELDS = gql`
fragment invoiceFields on Invoice {
  id
  created_at
  updated_at
  name
  number
  lines
  due_at
  paid_at
  total
  paid
}`

const JOB_FIELDS = gql`
fragment jobFields on Job {
  id
  created_at
  updated_at
  title
  description
  identifier
  billing_type
  start
  started
  finish
  finished
  status
}`

const NOTE_FIELDS = gql`
fragment noteFields on Note {
  id
  created_at
  updated_at
  order
  type
  visibility
  title
  content
}`

const QUOTE_FIELDS = gql`
fragment quoteFields on Quote {
  id
  created_at
  updated_at
  title
  description
}`

const SET_FIELDS = gql`
fragment setFields on Set {
  id
  created_at
  updated_at
  order
  type
  title
}`

const USER_FIELDS = gql`
fragment userFields on User {
  id
  created_at
  updated_at
  name
  email
  is_admin
  inactive
}`

// parent fields
const BILLABLE_PARENT_FIELDS = gql`
${CATEGORY_FIELDS}
${COMPANY_FIELDS}
fragment billableParentFields on Billable {
  category {
    ...categoryFields
  }
  company {
    ...companyFields
  }
}`

const CATEGORY_PARENT_FIELDS = gql`
${COMPANY_FIELDS}
fragment categoryParentFields on Category {
  company {
    ...companyFields
  }
}`

const CLIENT_PARENT_FIELDS = gql`
${COMPANY_FIELDS}
fragment clientParentFields on Client {
  company {
    ...companyFields
  }
}`

const EMPLOYEE_PARENT_FIELDS = gql`
${COMPANY_FIELDS}
${USER_FIELDS}
fragment employeeParentFields on Employee {
  company {
    ...companyFields
  }
  user {
    ...userFields
  }
}`

const ENTRY_PARENT_FIELDS = gql`
${SET_FIELDS}
fragment entryParentFields on Entry {
  set {
    ...setFields
  }
}`

const HOUR_PARENT_FIELDS = gql`
${EMPLOYEE_FIELDS}
${JOB_FIELDS}
fragment hourParentFields on Hour {
  employee {
    ...employeeFields
  }
  job {
    ...jobFields
  }
}`

const INVOICE_PARENT_FIELDS = gql`
${COMPANY_FIELDS}
${CLIENT_FIELDS}
${JOB_FIELDS}
fragment invoiceParentFields on Invoice {
  company {
    ...companyFields
  }
  client {
    ...clientFields
  }
  job {
    ...jobFields
  }
}`

const JOB_PARENT_FIELDS = gql`
${CLIENT_FIELDS}
fragment jobParentFields on Job {
  client {
    ...clientFields
  }
}`

const QUOTE_PARENT_FIELDS = gql`
${CLIENT_FIELDS}
fragment quoteParentFields on Quote {
  client {
    ...clientFields
  }
}`

const USER_PARENT_FIELDS = gql`
${COMPANY_FIELDS}
fragment userParentFields on User {
  company {
    ...companyFields
  }
}`

// child fields
const CATEGORY_CHILD_FIELDS = gql`
${BILLABLE_FIELDS}
fragment categoryChildFields on Category {
  billables {
    ...billableFields
  }
}`

const CLIENT_CHILD_FIELDS = gql`
${ADDRESS_FIELDS}
${CONTACT_FIELDS}
${JOB_FIELDS}
${NOTE_FIELDS}
fragment clientChildFields on Client {
  addresses {
    ...addressFields
  }
  contacts {
    ...contactFields
  }
  jobs {
    ...jobFields
  }
  notes {
    ...noteFields
  }
}`

const COMPANY_CHILD_FIELDS = gql`
${ADDRESS_FIELDS}
${BILLABLE_FIELDS}
${CATEGORY_FIELDS}
${CONTACT_FIELDS}
${INVOICE_FIELDS}
${JOB_FIELDS}
${NOTE_FIELDS}
fragment companyChildFields on Company {
  addresses {
    ...addressFields
  }
  billables {
    ...billableFields
  }
  categories {
    ...categoryFields
  }
  contacts {
    ...contactFields
  }
  invoices {
    ...invoiceFields
  }
  jobs {
    ...jobFields
  }
  notes {
    ...noteFields
  }
}`

const EMPLOYEE_CHILD_FIELDS = gql`
${ADDRESS_FIELDS}
${CONTACT_FIELDS}
${HOUR_FIELDS}
${NOTE_FIELDS}
fragment employeeChildFields on Employee {
  addresses {
    ...addressFields
  }
  contacts {
    ...contactFields
  }
  hours {
    ...hourFields
  }
  notes {
    ...noteFields
  }
}`

const JOB_CHILD_FIELDS = gql`
${ADDRESS_FIELDS}
${CONTACT_FIELDS}
${HOUR_FIELDS}
${INVOICE_FIELDS}
${NOTE_FIELDS}
fragment jobChildFields on Job {
  addresses {
    ...addressFields
  }
  contacts {
    ...contactFields
  }
  hours {
    ...hourFields
  }
  invoices {
    ...invoiceFields
  }
  notes {
    ...noteFields
  }
}`

const QUOTE_CHILD_FIELDS = gql`
${ADDRESS_FIELDS}
${NOTE_FIELDS}
fragment quoteChildFields on Quote {
  addresses {
    ...addressFields
  }
  notes {
    ...noteFields
  }
}`

const SET_CHILD_FIELDS = gql`
${ENTRY_FIELDS}
fragment setChildFields on Set {
  entries {
    ...entryFields
  }
}`

const USER_CHILD_FIELDS = gql`
${EMPLOYEE_FIELDS}
fragment userChildFields on User {
  employee {
    ...employeeFields
  }
}`

const FRAGMENTS = {
  address: {
    fields: ADDRESS_FIELDS,
    time: ADDRESS_FIELDS_TIME
  },
  billable: {
    fields: BILLABLE_FIELDS,
    parent: BILLABLE_PARENT_FIELDS
  },
  category: {
    fields: CATEGORY_FIELDS,
    parent: CATEGORY_PARENT_FIELDS,
    child: CATEGORY_CHILD_FIELDS
  },
  client: {
    fields: CLIENT_FIELDS,
    parent: CLIENT_PARENT_FIELDS,
    child: CLIENT_CHILD_FIELDS
  },
  company: {
    fields: COMPANY_FIELDS,
    child: COMPANY_CHILD_FIELDS
  },
  contact: {
    fields: CONTACT_FIELDS,
    time: CONTACT_FIELDS_TIME
  },
  employee: {
    fields: EMPLOYEE_FIELDS,
    parent: EMPLOYEE_PARENT_FIELDS,
    child: EMPLOYEE_CHILD_FIELDS
  },
  entry: {
    fields: ENTRY_FIELDS,
    parent: ENTRY_PARENT_FIELDS
  },
  hour: {
    fields: HOUR_FIELDS,
    parent: HOUR_PARENT_FIELDS
  },
  invoice: {
    fields: INVOICE_FIELDS,
    parent: INVOICE_PARENT_FIELDS
  },
  job: {
    fields: JOB_FIELDS,
    parent: JOB_PARENT_FIELDS,
    child: JOB_CHILD_FIELDS
  },
  note: {
    fields: NOTE_FIELDS
  },
  quote: {
    fields: QUOTE_FIELDS,
    parent: QUOTE_PARENT_FIELDS,
    child: QUOTE_CHILD_FIELDS
  },
  set: {
    fields: SET_FIELDS,
    child: SET_CHILD_FIELDS
  },
  user: {
    fields: USER_FIELDS,
    parent: USER_PARENT_FIELDS,
    child: USER_CHILD_FIELDS
  }
}

export default FRAGMENTS
