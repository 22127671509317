<template>
  <v-bottom-sheet lazy v-model="_show">
    <v-card>
      <v-card-title class="justify-space-between">
        <span class="title">{{ model.id ? 'Edit' : 'Add' }} {{ model.type | title }}</span>
        <span>
          <v-btn small icon @click.stop="cancel">
            <v-icon small>close</v-icon>
          </v-btn>
        </span>
      </v-card-title>

      <v-form>
        <v-container grid-md>
          <v-layout row wrap>
            <v-flex xs12 sm6 md4>
              <v-text-field
                :label="isPhoneNumber ? 'Phone Number' : 'Email Address'"
                v-model="model.value" ref="valueInput"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm6 md4>
              <v-text-field label="Label" v-model="model.label"></v-text-field>
            </v-flex>
          </v-layout>
          <v-layout row justify-space-between mx-0>
            <v-btn small color="success" :disabled="!dirty" @click="save" class="ml-0" style="min-width: 80px;">Save</v-btn>
            <v-btn small color="" @click="cancel">Cancel</v-btn>
            <v-spacer class="hidden-xs-only"></v-spacer>
            <confirm-action v-if="model.id && admin" @confirm="remove" action-text="Delete">
              <template v-slot:act="{ on }">
                <v-btn dark small color="primary" v-on="on" class="mr-0" style="min-width: 80px;">delete</v-btn>
              </template>
            </confirm-action>
          </v-layout>
        </v-container>
      </v-form>

    </v-card>

  </v-bottom-sheet>
</template>

<script>
/* eslint-disable camelcase */
/* eslint-disable handle-callback-err */

import { clone } from '@/utils'
import ConfirmAction from './ConfirmAction.vue'
import { CONTACT } from '@/graphql/models'

export default {
  name: 'edit-contact',
  components: { ConfirmAction },
  props: {
    contact: {},
    show: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      model: CONTACT.blank
    }
  },
  computed: {
    me () { return this.$oxide.store.apollo.user },
    admin () { return !!this.me?.is_admin },
    dirty () {
      const original = (this.model.id ? this.contact : CONTACT.blank)
      return this.model.value !== original.value || this.model.label !== original.label
    },
    _show: {
      get () {
        return this.show
      },
      set (value) {
        if (!value) {
          this.cancel()
        }
      }
    },
    isPhoneNumber () {
      return this.model && this.model.type && this.model.type === 'phone'
    },
    isEmailAddress () {
      return this.model && this.model.type && this.model.type === 'email'
    }
  },
  methods: {
    reset () {
      this.model = clone(this.contact)
    },
    cancel () {
      this.reset()
      this.close()
    },
    close () {
      this.$emit('show', false)
    },

    save () {
      if (this.dirty) {
        this.$emit('save', this.model)
      }
      this.close()
    },
    remove () {
      this.$emit('remove', this.model)
      this.close()
    }
  },
  watch: {
    contact: {
      handler (newModel, oldModel) {
        this.model = clone(newModel)
      },
      deep: true
    },
    show (val) {
      if (val) {
        this.$nextTick(() => {
          this.$refs.valueInput.focus()
        })
      }
    }
  }
}
</script>
