/* eslint-disable camelcase, no-useless-escape, handle-callback-err, no-fallthrough */
import Vue from 'vue'
import Cent$ from '@/cents'
import { GRADIENT_COLORS, REGIONS_BY_ALPHA_CODE } from '@/constants'
import formatDistanceToNow from 'date-fns/formatDistanceToNow'
// import * as Sentry from '@sentry/vue'

const clone = function (variable) {
  if (typeof variable === 'object') {
    return JSON.parse(JSON.stringify(variable))
  } else {
    return variable
  }
}

const cloneExcept = function (obj, keys) {
  if (typeof keys === 'string') {
    keys = [keys]
  }
  const cloned = clone(obj)
  for (const key of keys) {
    delete cloned[key]
  }
  return cloned
}

const cloneOnly = function (obj, keys) {
  if (typeof keys === 'string') {
    keys = [keys]
  }
  const cloned = Object.create(null)
  for (const key of keys) {
    cloned[key] = obj[key]
  }
  return clone(cloned)
}

const copyCase = function (target, str) {
  return isUpperCase(str) ? str.toUpperCase() : str.toLowerCase()
}

// TODO: make this global and add to settings
let currencyFormatter = null
try {
  currencyFormatter = new Intl.NumberFormat(undefined, { style: 'currency', currency: 'CAD', currencyDisplay: 'narrowSymbol' })
} catch (e) {
  currencyFormatter = new Intl.NumberFormat(undefined, { style: 'currency', currency: 'CAD', currencyDisplay: 'symbol' })
}

const dataToString = function (data) {
  if (typeof data === 'object') {
    if (data === null) {
      return ''
    } else {
      try {
        return JSON.stringify(data)
      } catch (e) {
        return `${data}`
      }
    }
  } else {
    return `${data}`
  }
}

const defineModel = function ({ name, fields, queries, mutations }) {
  const MODEL = Object.create(null)

  if (typeof name !== 'undefined') {
    const nameDescriptor = Object.create(null)
    nameDescriptor.value = name
    Object.defineProperty(MODEL, 'name', nameDescriptor)
  }

  if (typeof fields !== 'undefined') {
    const blankDescriptor = Object.create(null)

    blankDescriptor.get = function (obj) {
      return clone(obj)
    }.bind(null, fields)

    Object.defineProperty(MODEL, 'blank', blankDescriptor)
  }

  if (typeof queries !== 'undefined') {
    const queriesDescriptor = Object.create(null)
    queriesDescriptor.value = queries
    Object.defineProperty(MODEL, 'queries', queriesDescriptor)
  }

  if (typeof mutations !== 'undefined') {
    const mutationsDescriptor = Object.create(null)
    mutationsDescriptor.value = mutations
    Object.defineProperty(MODEL, 'mutations', mutationsDescriptor)
  }

  return MODEL
}

const doNothing = function () {
  // done
}

const equivalent = function (a, b, { ignore = [] } = { ignore: [] }) {
  // if scalar, compare values; if objects, compare references
  if (a === b) { return true }

  // figure out what a and b are
  const aIsArray = Array.isArray(a)
  const aIsSet = !aIsArray && (a instanceof Set || a instanceof WeakSet)
  const aIsKeyed = !(aIsArray || aIsSet)
  const aIsMap = aIsKeyed && (a instanceof Map || a instanceof WeakMap)

  const bIsArray = Array.isArray(b)
  const bIsSet = !bIsArray && (b instanceof Set || b instanceof WeakSet)
  const bIsKeyed = !(bIsArray || bIsSet)
  const bIsMap = bIsKeyed && (b instanceof Map || b instanceof WeakMap)

  // if scalar but not equal OR if types are different OR if one has keys while the other doesn't
  if (typeof a !== 'object' || typeof a !== typeof b || aIsKeyed !== bIsKeyed) { return false }

  if (aIsKeyed) {
    let keysA = Array.from(typeof a.keys === 'function' ? a.keys() : Object.keys(a))
    let keysB = Array.from(typeof b.keys === 'function' ? b.keys() : Object.keys(b))

    for (let i = 0; i < ignore.length; i++) {
      keysA = keysA.filter(key => key !== ignore[i])
      keysB = keysB.filter(key => key !== ignore[i])
    }

    // if they don't have the same number of keys
    if (keysA.length !== keysB.length) { return false }

    for (const key of keysA) {
      if (equivalent(aIsMap ? a.get(key) : a[key], bIsMap ? b.get(key) : b[key])) {
        keysB.splice(keysB.indexOf(key), 1)
      } else {
        return false
      }
    }

    return keysB.length === 0
  } else {
    const valuesA = Array.from(typeof a.values === 'function' ? a.values() : Object.values(a))
    const valuesB = Array.from(typeof b.values === 'function' ? b.values() : Object.values(b))
    // if they don't have the same number of values
    if (valuesA.length !== valuesB.length) { return false }

    let index = -1
    let found = false

    for (const value of valuesA) {
      found = false
      for (index = 0; index < valuesB.length; index++) {
        // eslint-disable-next-line no-cond-assign
        if (found = equivalent(value, valuesB[index])) { break }
      }
      if (found) {
        valuesB.splice(index, 1)
      } else {
        return false
      }
    }
    return valuesB.length === 0
  }
}

const findByProperty = function (
  obj, property, val,
  { castToInt = false, defaultValue = undefined } = { castToInt: false, defaultValue: undefined }
) {
  const key = findKeyByProperty(obj, property, val, { castToInt, defaultValue })

  if (key === defaultValue) {
    return defaultValue
  } else {
    return obj[key]
  }
}

const findKeyByProperty = function (
  obj, property, val,
  { castToInt = false, defaultValue = undefined } = { castToInt: false, defaultValue: undefined }
) {
  const keys = Object.keys(obj)
  if (castToInt) {
    const intVal = parseInt(val, 10)
    for (const key of keys) {
      try {
        if (parseInt(obj[key][property], 10) === intVal) {
          return key
        }
      } catch (e) {}
    }
  } else {
    for (const key of keys) {
      try {
        if (obj[key][property] === val) {
          return key
        }
      } catch (e) {}
    }
  }
  return defaultValue
}

const formatDecimal = function (dec, precision = 1) {
  if (precision < 1) { return null }

  const str = String(dec)
  const p = str.trim().split('.')
  const l = p.length

  switch (l) {
    case 0:
      p.push('0')
    // eslint-disable-next-line no-fallthrough
    case 1:
      p.push('0')
      break
    case 2: break
    default: return null
  }

  if (p[0].trim() === '') { p[0] = '0' }
  if (p[1].trim() === '') { p[1] = '0' }

  if (p[1].length > precision) {
    p[1] = String(Math.round(parseInt(p[1], 10) / Math.pow(10, p[1].length - precision)))
  } else if (p[1].length < precision) {
    p[1] = padRight(p[1], precision, '0')
  }

  return p.join('.')
}

const formatMonetary = function (value) {
  if (value === '') {
    return ''
  }
  try {
    const cents = Cent$.fromDollars(value)
    return cents.asDollars
  } catch (e) {
    return ''
  }
}

const formatMonetaryPreScaled = function (value) {
  if (value === '') {
    return ''
  }

  try {
    const cents = new Cent$(value)
    return cents.asDollars
  } catch (e) {
    return ''
  }
}

const formatMonetaryFormatted = function (value) {
  if (value === '') {
    return ''
  }

  try {
    return currencyFormatter.format(value)
  } catch (e) {
    return ''
  }
}

const formatPhoneNumber = function (value) {
  if (!value) {
    return ''
  }
  const str = value.toString().trim()
  const match = str.match(regexp.phoneNumber)

  if (match && match.groups) {
    let number = ''

    if (typeof match.groups.code === 'string') {
      number += `+${match.groups.code} `
    }

    if (typeof match.groups.area === 'string') {
      number += `${match.groups.area}-`
    }

    number += `${match.groups.prefix}-${match.groups.line}`

    if (typeof match.groups.extension === 'string') {
      number += ` ${match.groups.extension.trimStart()}`
    }

    return number
  } else {
    return str
  }
}

const formatPostal = function (value) {
  if (!value) { return '' }
  let postal = value.toString().trim().toUpperCase()
  switch (postal.length) {
    case 7:
      if (postal.substring(3, 4) === '-') {
        postal = postal.substring(0, 3) + postal.substring(4)
      } else {
        break
      }
    case 6:
      postal = postal.substring(0, 3) + ' ' + postal.substring(3)
  }
  return postal
}

const formatRegionToAlphaCode = function (value) {
  if (typeof value !== 'string' || value.trim() === '') { return '' }
  const region = value.trim().toLowerCase().replace('.', '')
  for (const [code, variations] of Object.entries(REGIONS_BY_ALPHA_CODE)) {
    if (code === region || variations.includes(code)) {
      return code.toUpperCase()
    }
  }
  return value
}

const formatTimespan = function (value) {
  let t = ''
  if (typeof value === 'string') {
    t = value
  } else if (typeof value === 'object' && typeof value.date === 'string') {
    t = value.date + (typeof value.timezone === 'string' ? ' ' + value.timezone : '')
  } else if (typeof value.toString === 'function') {
    t = value.toString()
  }

  if (value.length === 19) {
    t = t.replace(' ', 'T') + 'Z'
  }

  if (t !== '') {
    try {
      return formatDistanceToNow(Date.parse(t), { addSuffix: true })
    } catch (e) {
      console.error('error parsing date', value, e)
      return ''
    }
  }
  return t
}

const formatTitle = function (value) {
  if (!value) { return '' }
  const words = value.toString().trim().split(' ')
  return words.reduce(reduceTitleCaseWord)
}

const getDate = function (date = new Date()) {
  const yyyy = padLeft(date.getFullYear(), 4, '0')
  const MM = padLeft(date.getMonth() + 1, 2, '0')
  const dd = padLeft(date.getDate(), 2, '0')

  return `${yyyy}-${MM}-${dd}`
}

const getNestedValue = function (
  obj, keys = [], { defaultValue = undefined, idProperty = 'id', intsAreIds = true, keySeparator = '/' } = { defaultValue: undefined, idProperty: 'id', intsAreIds: true, keySeparator: '/' }
) {
  if (typeof keys === 'string') {
    keys = keys.split(keySeparator)
  }

  if (!Array.isArray(keys)) {
    namedLog('Invalid keys parameter', { keys })
    return defaultValue
  }

  if (keys.length) {
    const key = keys.splice(0, 1)[0]

    if (key === '') {
      return getNestedValue(obj, keys, { defaultValue, idProperty, intsAreIds })
    }

    const int = parseInt(key, 10)

    if (!Number.isNaN(int)) {
      if (intsAreIds) {
        const found = findByProperty(obj, idProperty, int, { castToInt: true })

        if (typeof found !== 'undefined') {
          return getNestedValue(found, keys, { defaultValue, idProperty, intsAreIds })
        }
      } else if (typeof obj[int] !== 'undefined') {
        return getNestedValue(obj[int], keys, { defaultValue, idProperty, intsAreIds })
      }
    }

    if (typeof obj[key] !== 'undefined') {
      return getNestedValue(obj[key], keys, { defaultValue, idProperty, intsAreIds })
    }

    namedLog('Nested object not found at given key', { obj, key })
    return defaultValue
  } else {
    return obj
  }
}

const getTime = function (date = new Date()) {
  const HH = padLeft(date.getHours(), 2, '0')
  const mm = padLeft(date.getMinutes(), 2, '0')
  const ss = padLeft(date.getSeconds(), 2, '0')

  return `${HH}:${mm}:${ss}`
}

const getTimestamp = function (date = new Date()) {
  return `${getDate(date)} ${getTime(date)}`
}

const getUTCDate = function (date = new Date()) {
  const yyyy = padLeft(date.getUTCFullYear(), 4, '0')
  const MM = padLeft(date.getUTCMonth() + 1, 2, '0')
  const dd = padLeft(date.getUTCDate(), 2, '0')

  return `${yyyy}-${MM}-${dd}`
}

const getUTCTime = function (date = new Date()) {
  const HH = padLeft(date.getUTCHours(), 2, '0')
  const mm = padLeft(date.getUTCMinutes(), 2, '0')
  const ss = padLeft(date.getUTCSeconds(), 2, '0')

  return `${HH}:${mm}:${ss}`
}

const getUTCTimestamp = function (date = new Date()) {
  return `${getUTCDate(date)} ${getUTCTime(date)}`
}

const gradient = function ({ direction = 'to right', seed = null, type = 'linear' } = { direction: 'to right', seed: null, type: 'linear' }) {
  const colors = GRADIENT_COLORS[(seed === null) ? Math.floor(Math.random() * GRADIENT_COLORS.length) : parseInt(seed, 10) % GRADIENT_COLORS.length]
  return `${type}-gradient(${direction}, ${colors.join(', ')})`
}

const indexFromChar = function (arr, str) {
  return str.charCodeAt(0) % arr.length
}

const isEmpty = function (val) {
  const type = typeof val
  switch (type) {
    case 'undefined': return true
    case 'string': return val === ''
    case 'boolean': return val
    case 'number':
    case 'bigint':
      // eslint-disable-next-line eqeqeq
      return val == 0 // if bigint, 0n !== 0
    case 'function':
    case 'symbol':
      return false
    case 'object':
      if (Array.isArray(val)) {
        return val.length === 0
      } else if (val === null) {
        return true
      } else if (typeof val.size === 'number') {
        return val.size === 0
      }
    // eslint-disable-next-line no-fallthrough
    default: return false // could be lots of things so best to assume it's not empty
  }
}

const isLowerCase = function (str) {
  return str === str.toLowerCase()
}

const isMixedCase = function (str) {
  return (!isLowerCase(str)) && (!isUpperCase(str))
}

const isNothing = function (val) {
  const type = typeof val
  switch (type) {
    case 'function':
    case 'symbol':
      return false
    case 'undefined':
      return true
    case 'string':
      return (val.trim() === '' || /[+-]?0+(\.?0)?0*/.test(val.trim()))
    case 'object':
      if (Array.isArray(val)) {
        return val.length === 0
      } else if (val === null) {
        return true
      } else if (typeof val.size === 'number') {
        return val.size === 0
      }
    // eslint-disable-next-line no-fallthrough
    default: return !val
  }
}

const isNumeric = function (value, acceptEmptyValues = false) {
  switch (typeof value) {
    case 'number':
      return true
    case 'string':
      if (value.trim().length === 0) {
        return acceptEmptyValues
      } else {
        const number = parseNumber(value, Number.NaN)
        return !Number.isNaN(number)
      }
    case 'object':
      if (value !== null) {
        return false
      }
    case 'undefined':
      return acceptEmptyValues
    default:
      return false
  }
}

const isUpperCase = function (str) {
  return str === str.toUpperCase()
}

const itemFromChar = function (arr, str) {
  return arr[indexFromChar(arr, str)]
}

const itemFromString = function (arr, str) {
  let charCodeTotal = 0
  for (const s of str) {
    charCodeTotal += s.charCodeAt(0)
  }
  return arr[charCodeTotal % arr.length]
}

const log = function (...args) {
  for (const arg of args) {
    if (typeof arg === 'object') {
      try {
        console.log(clone(arg))
      } catch (e) {
        console.error(e)
        console.log(arg)
      }
    } else {
      console.log(arg)
    }
  }
  return args
}

const logError = function (...args) {
  for (const arg of args) {
    if (typeof arg === 'object') {
      console.error(clone(arg))
    } else {
      console.error(arg)
    }
  }
  return args
}

const mapItem = function (item) {
  return [item.id, item]
}

const mapItems = function (items) {
  return new Map((Array.isArray(items) ? items : items.values()).map(mapItem))
}

const namedLog = function (name, ...args) {
  console.group(name)
  log(args)
  console.groupEnd()
}

const namedLogError = function (name, ...args) {
  console.group(name)
  logError(args)
  console.groupEnd()
}

const navigate = function (options, router) {
  // console.log(options)
  const current = router.currentRoute
  const route = {}
  for (const key of Object.keys(options)) {
    if (typeof options[key] === 'object' && typeof current[key] === 'object') {
      route[key] = {}
      for (const innerKey of Object.keys(options[key])) {
        if (typeof options[key][innerKey] === 'number') {
          if (current[key][innerKey] !== String(options[key][innerKey])) {
            route[key][innerKey] = String(options[key][innerKey])
          }
        } else {
          if (current[key][innerKey] !== options[key][innerKey]) {
            route[key][innerKey] = options[key][innerKey]
          }
        }
      }
    } else {
      if (typeof options[key] === 'number') {
        if (current[key] !== String(options[key])) {
          route[key] = String(options[key])
        }
      } else {
        if (current[key] !== options[key]) {
          route[key] = options[key]
        }
      }
    }
  }
  // console.log(route)
  router.push(route).catch(err => {})
}

const numberFormatter = new Intl.NumberFormat()

const padLeft = function (str, length, char = ' ') {
  let s = String(str)
  while (s.length < length) {
    s = char + s
  }
  return s
}

const padRight = function (str, length, char = ' ') {
  let s = String(str)
  while (s.length < length) {
    s = s + char
  }
  return s
}

const parseNumber = function (value, fallback = 0) {
  switch (typeof value) {
    case 'number':
      console.log('nnn')
      return value
    case 'string': {
      // if (typeof value.replaceAll !== 'function') {
      //   console.log(value, typeof value)
      //   Sentry.captureMessage(typeof value + ' : ' + value)
      //   return fallback
      // }
      // const cleaned = value.replaceAll(/[^0-9\.\,\+\-\/]/g, '')
      const cleaned = value.replace(/[^0-9\.\,\+\-\/]/g, '')
      if (/^\d+\/\d+$/.test(cleaned)) {
        let [dividend, divisor] = cleaned.split('/')
        dividend = Number.parseInt(dividend, 10)
        divisor = Number.parseInt(divisor, 10)
        if (divisor === 0) {
          return fallback
        } else {
          return dividend / divisor
        }
      } else {
        const number = Number(cleaned)
        return Number.isNaN(number) ? fallback : number
      }
    }
    default:
      return fallback
  }
}

const parseVersionString = function (str) {
  if (typeof str === 'string') {
    const segments = str.toLowerCase()
    if (segments.length === 3) {
      return { major: segments[0].trim(), minor: segments[1].trim(), patch: segments[2].trim() }
    }
  }
  return null
}

const pluckFrom = function (items, index) {
  return items.splice(index, 1)[0]
}

const pluckRandomFrom = function (items) {
  return this.pluckFrom(items, randomLessThan(items.length))
}

const pluralize = function (word, count = 2) {
  if (!word) {
    word = ''
  }
  word = word.trim()
  if (parseInt(count, 10) === 1) {
    return singularize(word)
  } else {
    switch (word.toLowerCase()) {
      case 'each': return word
      case 'feet': return word
      case 'foot':
        return `${word.charAt(0)}${copyCase(word.charAt(1), 'e')}${copyCase(word.charAt(2), 'e')}${word.charAt(3)}`
      case 'meter':
        return word + copyCase(word.charAt(4), 's')
      case 'meters': return word

      default: return word
    }
  }
}

const polymorphicApiUrl = function (child) {
  const parentId = polymorphicParentId(child)
  const parentPath = polymorphicParentPath(child)
  const childPath = polymorphicChildPath(child)
  return `/api/${parentPath}/${parentId}/${childPath}${(child.id ? '/' + child.id : '')}`
}

const polymorphicChildPath = function ({ addressable_type, contactable_type, notable_type, model }) {
  if (typeof model === 'string' && model.trim() !== '') {
    return model.trim().toLowerCase()
  } else {
    return typeof addressable_type !== 'undefined' ? 'address' : (typeof contactable_type !== 'undefined' ? 'contact' : 'note')
  }
}

const polymorphicParentId = function ({ addressable_id, contactable_id, notable_id }) {
  return typeof addressable_id !== 'undefined' ? addressable_id : (typeof contactable_id !== 'undefined' ? contactable_id : notable_id)
}

const polymorphicParentPath = function (child) {
  let type = polymorphicParentType(child)
  if (typeof type === 'undefined') {
    return ''
  }
  const slashIndex = type.lastIndexOf('\\')
  if (slashIndex !== -1) {
    type = type.substring(slashIndex + 1)
  }
  return type.toLowerCase()
}

const polymorphicParentType = function ({ addressable_type, contactable_type, notable_type }) {
  return typeof addressable_type !== 'undefined' ? addressable_type : (typeof contactable_type !== 'undefined' ? contactable_type : notable_type)
}

const randomBetween = function (a, b) {
  const low = Math.min(a, b)
  const high = Math.max(a, b)
  return Math.round(Math.random() * (high - low)) + low
}

const randomFrom = function (items) {
  return items[randomLessThan(items.length)]
}

const randomLessThan = function (limit) {
  return Math.floor(Math.random() * limit)
}

const recordQueryMeta = function (name, data, updatedProp = 'updated_at') {
  const meta = Object.create(null)
  meta.fetched = Date.now()
  meta.updated = ''
  if (Array.isArray(data)) {
    for (const entry of data) {
      if (entry[updatedProp] > meta.updated) {
        meta.updated = entry[updatedProp]
      }
    }
  } else if (typeof data === 'object' && data !== null) {
    meta.updated = data[updatedProp]
  } else if (typeof data === 'string') {
    meta.updated = data
  }

  if (typeof meta.updated === 'string' && meta.updated.length === 19) {
    meta.updated = Date.parse(meta.updated.replace(' ', 'T') + 'Z')
  }

  store(meta, name, 'apollo/_meta')
}

const regexp = {
  phoneNumber: /^\+?(?<code>[0-9]{1,2})*?[\(\)\-\.\ ]{0,2}(?<area>[0-9]{3})?[\(\)\-\.\ ]{0,2}(?<prefix>[0-9]{3})[\(\)\-\.\ ]{0,2}(?<line>[0-9]{4})(?<extension>.+)*?$/
}

const reduceTitleCaseWord = (output, word) => word.charAt(0).toUpperCase() + word.slice(1)

const retrieveQueryMeta = function (name) {
  return window.$oxide.store.apollo._meta[name]
}

const singularize = function (word, count = 1) {
  if (!word) {
    word = ''
  }
  word = word.trim()
  if (parseInt(count, 10) === 1) {
    switch (word.toLowerCase()) {
      case 'each': return word
      case 'feet':
        return `${word.charAt(0)}${copyCase(word.charAt(1), 'o')}${copyCase(word.charAt(2), 'o')}${word.charAt(3)}`
      case 'foot': return word
      case 'meter': return word
      case 'meters': return word.substring(0, 5)

      default: return word
    }
  } else {
    return pluralize(word)
  }
}

const store = function (value, key, target = null) {
  if (target === null) {
    target = window.$oxide.store
  } else {
    const type = typeof target
    if (type === 'string') {
      target = getNestedValue(window.$oxide.store, target)
    } else if (type !== 'object') {
      console.error('store:invalid_target', target)
      return null
    }
  }

  if (typeof window._vm !== 'undefined') {
    window._vm.$set(target, key, value)
  } else {
    Vue.set(target, key, value)
  }
  return true
}

const timestampToDate = function (value) {
  let t = ''
  if (typeof value === 'string') {
    t = value
  } else if (typeof value === 'object' && typeof value.date === 'string') {
    t = value.date + (typeof value.timezone === 'string' ? ' ' + value.timezone : '')
  } else if (typeof value.toString === 'function') {
    t = value.toString()
  }

  if (value.length === 19) {
    t = t.replace(' ', 'T') + 'Z'
  }

  return Date.parse(t)
}

const titleCase = function (str) {
  return str.replace(/\w\S*/g, upperCaseFirst)
}

const toDataURL = function (url) {
  return fetch(url, { mode: 'no-cors' }).then((response) => {
    return response.blob()
  }).then(blob => {
    return URL.createObjectURL(blob)
  })
}

const upperCaseFirst = function (str) {
  return str.charAt(0).toUpperCase() + str.substring(1).toLowerCase()
}

const valueToArray = function (
  value,
  {
    blank = [], removeEmpty = false, separator = undefined, trimStrings = false
  } = {
    blank: [], removeEmpty: false, separator: undefined, trimStrings: false
  }
) {
  let arr = null
  switch (typeof value) {
    case 'object':
      if (value === null) { return blank }
      arr = [...(typeof value[Symbol.iterator] === 'function' ? value : Object.values(value))]
      break
    case 'undefined': return blank
    case 'string':
    default: value = String(value)
      arr = value.split(separator)
  }

  if (trimStrings) {
    for (let i = 0; i < arr.length; i++) {
      if (typeof arr[i] === 'string') {
        arr[i] = arr[i].trim()
      }
    }
  }

  if (removeEmpty) {
    for (let i = 0; i < arr.length; i++) {
      const type = typeof arr[i]
      if (
        (type === 'string' && arr[i].trim() === '') ||
        arr[i] === null || type === 'undefined'
      ) {
        arr.splice(i--, 1)
      }
    }
  }
  return arr
}

const valueToString = function (value, { blank = '', glue = ', ' } = { blank: '', glue: ', ' }) {
  switch (typeof value) {
    case 'string': return value
    case 'object':
      if (value === null) { return blank }
      return [...(typeof value[Symbol.iterator] === 'function' ? value : Object.values(value))].join(glue)
    case 'undefined': return blank
    default: return String(value)
  }
}

export {
  clone,
  cloneExcept,
  cloneOnly,
  copyCase,
  currencyFormatter,
  dataToString,
  defineModel,
  doNothing,
  equivalent,
  findByProperty,
  findKeyByProperty,
  formatDecimal,
  formatMonetary,
  formatMonetaryPreScaled,
  formatMonetaryFormatted,
  formatPhoneNumber,
  formatPostal,
  formatRegionToAlphaCode,
  formatTimespan,
  formatTitle,
  getNestedValue,
  getDate,
  getTime,
  getTimestamp,
  getUTCDate,
  getUTCTime,
  getUTCTimestamp,
  gradient,
  indexFromChar,
  isEmpty,
  isLowerCase,
  isMixedCase,
  isNothing,
  isNumeric,
  isUpperCase,
  itemFromChar,
  itemFromString,
  log,
  logError,
  mapItem,
  mapItems,
  namedLog,
  namedLogError,
  navigate,
  numberFormatter,
  padLeft,
  padRight,
  parseNumber,
  parseVersionString,
  pluralize,
  polymorphicApiUrl,
  polymorphicChildPath,
  polymorphicParentId,
  polymorphicParentPath,
  polymorphicParentType,
  pluckFrom,
  pluckRandomFrom,
  randomBetween,
  randomFrom,
  randomLessThan,
  recordQueryMeta,
  reduceTitleCaseWord,
  regexp,
  retrieveQueryMeta,
  singularize,
  store,
  timestampToDate,
  titleCase,
  toDataURL,
  upperCaseFirst,
  valueToArray,
  valueToString
}
