import Vue from 'vue'
import Vuetify, { VList } from 'vuetify/lib'
// import 'vuetify/dist/vuetify.min.css'
// import 'vuetify/src/stylus/app.styl'
import '@/main.styl'
import colors from 'vuetify/es5/util/colors'

Vue.use(Vuetify, {
  components: {
    VList
  },
  theme: {
    primary: colors.deepOrange,
    secondary: colors.blueGrey,
    accent: colors.cyan,
    error: colors.red.lighten1,
    warning: colors.lime,
    info: colors.deepPurple,
    success: colors.lightBlue
  }
})

// Vue.use(Vuetify, {
//   theme: {
//     primary: '#ff5722', //   colors.deep-orange.base
//     secondary: '#607d8b', // colors.blue-grey.base
//     accent: '#00bcd4', //    colors.cyan.base
//     error: '#795548', //     colors.brown.base
//     warning: '#ffc107', //   colors.amber.base
//     info: '#673ab7', //      colors.deep-purple.base
//     success: '#03a9f4' //    colors.light-blue.base
//   }
// })

// {
//     primary: '#ee44aa',
//     secondary: '#424242',
//     accent: '#82B1FF',
//     error: '#FF5252',
//     info: '#2196F3',
//     success: '#4CAF50',
//     warning: '#FFC107'
//   }
