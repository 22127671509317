/* eslint-disable camelcase */

import Vue from 'vue'
import Router from 'vue-router'

import Billables from '@/views/Billables.vue'
import Clients from '@/views/Clients.vue'
import Config from '@/views/Config.vue'
import EmployeeHours from '@/views/EmployeeHours.vue'
import Employees from '@/views/Employees.vue'
import Home from '@/views/Home.vue'
import Invoice from '@/views/Invoice.vue'
import Jobs from '@/views/Jobs.vue'
import ReportViewer from '@/views/ReportViewer.vue'

// https://stackoverflow.com/questions/57837758/navigationduplicated-navigating-to-current-location-search-is-not-allowed
// const originalPush = Router.prototype.push
// Router.prototype.push = function push (location) {
//   return originalPush.call(this, location).catch(err => err)
// }

Vue.use(Router)

const router = new Router({
  routes: [
    {
      component: Home,
      name: 'home',
      path: '/'
    },

    {
      beforeEnter: (to, from, next) => { next(window.$oxide.store.apollo.user?.is_admin ? undefined : false) },
      component: Billables,
      name: 'billables',
      path: '/billables'
    },

    {
      component: Clients,
      name: 'crm',
      path: '/crm/:rel1_type?/:rel1_id?/:rel2_type?/:rel2_id?/:rel3_type?/:rel3_id?',
      props: (route) => ({ a: typeof route.query.a !== 'undefined' ? parseInt(route.query.a, 10) : 0 })
    },

    {
      beforeEnter: (to, from, next) => { next(window.$oxide.store.apollo.user?.is_admin ? undefined : false) },
      component: Employees,
      name: 'employees',
      path: '/employee/:employeeId?'
    },

    {
      beforeEnter: (to, from, next) => { next(window.$oxide.store.apollo.user?.is_admin ? undefined : false) },
      component: Invoice,
      name: 'invoice',
      path: '/invoice/:invoiceId?'
    },

    {
      component: Jobs,
      name: 'jobs',
      path: '/jobs/:view?'
    },

    {
      beforeEnter: (to, from, next) => { next(window.$oxide.store.apollo.user?.is_admin ? undefined : false) },
      component: EmployeeHours,
      name: 'employee_hours_report',
      path: '/reports/employee/:employeeId/hours'
    },

    {
      beforeEnter: (to, from, next) => {
        const user = window.$oxide.store.apollo.user
        if (user?.is_admin) {
          next()
        } else if (
          to.params.subject.startsWith('employee') &&
          to.params.id &&
          to.params.id === user?.employee?.id
        ) {
          next()
        } else {
          next(false)
        }
      },
      component: ReportViewer,
      name: 'reports',
      path: '/reports/:subject/:id?/:report?'
    },

    {
      beforeEnter: (to, from, next) => { next(window.$oxide.store.apollo.user?.is_admin ? undefined : false) },
      component: Config,
      name: 'config',
      path: '/config'
    },

    {
      component: Home,
      name: 'reset',
      path: '/password/reset/:token'
    }
  ]
})

export default router
