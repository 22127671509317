import { defineModel } from '@/utils'
// import { ADDRESS_QUERY, ADDRESSES_QUERY } from '../queries'
import { ADDRESS_CREATE, ADDRESS_UPDATE, ADDRESS_DELETE } from '../mutations'

const ADDRESS = defineModel({
  name: 'Address',
  fields: {
    id: 0,
    addressable_id: 0,
    addressable_type: '',
    order: 0,
    label: '',
    street: '',
    city: '',
    region: '',
    country: '',
    code: ''
  },
  mutations: {
    create: {
      mutation: ADDRESS_CREATE
    },
    update: {
      mutation: ADDRESS_UPDATE
    },
    delete: {
      mutation: ADDRESS_DELETE
    }
  }
})

export default ADDRESS
