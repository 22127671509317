<template>
  <v-container class="manage-client" grid-list-xl fluid pa-0 ma-0>
    <v-layout row wrap justify-center ma-0>
      <v-flex :class="{'is-loading': loading}">
        <manage-entity
          title="Client" color="cyan darken-1" :icon="is_business ? 'domain' : 'people'"
          :entity="entity" :loading="loading" :path="path" :query="query"
          @save="saveClient" backText="Client List">
          <template #postContent v-if="admin">
            <v-layout row ma-0>
              <v-spacer></v-spacer>
              <confirm-action @confirm="deleteClient">
                <template v-slot:act="{ on }">
                  <v-btn small outline dark color="grey" v-on="on">delete client</v-btn>
                </template>
              </confirm-action>
            </v-layout>
          </template>
          <template #bottom>
            <note-list :notableId="id" notableType="App\Client" :path="path" :notes="messages" :query="query"></note-list>
          </template>
        </manage-entity>
      </v-flex>
      <v-flex>
        <job-list title="Jobs" :parentId="id" :jobs="jobs" :class="{'is-loading': loading}" class="is-loading-overlay"></job-list>
      </v-flex>
      <!-- <v-flex>
      </v-flex> -->
    </v-layout>
    <v-footer style="background: none; opacity: 0.5;">
      <v-spacer></v-spacer>
      <div class="caption"><span style="opacity: 0.75;">updated </span>{{ queryMetaUpdated }}<span style="opacity: 0.6;"> as of </span>{{ queryMetaFetched }}</div>
      <v-spacer></v-spacer>
    </v-footer>
  </v-container>
</template>

<script>
/* eslint-disable camelcase */
/* eslint-disable handle-callback-err */
import { cloneExcept, cloneOnly, findKeyByProperty, getUTCTimestamp, log } from '@/utils'
import { formatDistance } from 'date-fns/esm'

import ManageEntity from '@/components/ManageEntity.vue'
import JobList from '@/components/JobList.vue'
import NoteList from '@/components/NoteList.vue'
import ConfirmAction from './ConfirmAction.vue'

import { CLIENT } from '@/graphql/models'

export default {
  name: 'manage-client',
  components: { ConfirmAction, JobList, ManageEntity, NoteList },
  props: { modelId: {}, company: {} },
  apollo: {
    client: {
      query: CLIENT.queries.show.query,
      pollInterval: 1000 * 30 * 1, // 30 seconds
      variables () { return { id: this.modelId } },
      skip () { return typeof this.modelId === 'undefined' }
    }
  },
  computed: {
    clients () { return this.$oxide.store.apollo.clients },
    me () { return this.$oxide.store.apollo.user },
    admin () { return !!this.me?.is_admin },
    loaded () { return !this.loading && typeof this.client.id !== 'undefined' },
    loading () { return this.$apollo.queries.client.loading },
    id () { return parseInt(this.modelId, 10) },
    messages () {
      return this.client?.notes.filter(note => note.type === 'IMAGE' || note.type === 'LINK' || note.type === 'TEXT').sort((a, b) => b.id - a.id)
    },
    is_business () { return this.client?.is_business },
    jobs () { return this.client?.jobs },
    path () { return 'client' },
    entity () {
      return this.loaded ? {
        id: this.client.id,
        name: this.client.name,
        is_business: this.client.is_business,
        type: CLIENT.name,
        addresses: this.client.addresses,
        contacts: this.client.contacts
      } : {}
    },
    query () { return this.$oxide.store.clientQuery },

    now () { return this.$oxide.bus.now },
    queryMeta () { return this.$oxide.store.apollo._meta[`client:${this.client?.id}`] },
    queryMetaFetched () { return (this.now && this.queryMeta?.fetched) ? formatDistance(this.queryMeta?.fetched, this.now, { includeSeconds: true, addSuffix: true }) : '' },
    queryMetaUpdated () { return (this.now && this.queryMeta?.updated) ? formatDistance(this.queryMeta?.updated, this.now, { includeSeconds: true, addSuffix: true }) : '' }
  },
  methods: {
    async saveClient (client) {
      log(client)
      await this.$apollo.mutate({
        mutation: CLIENT.mutations.update.mutation,
        variables: { client: cloneOnly(client, ['id', 'name', 'is_business']) },
        optimisticResponse: {
          __typename: 'Mutation',
          updateClient: {
            __typename: 'Client',
            ...client,
            created_at: this.client.created_at,
            updated_at: getUTCTimestamp(),
            notes: this.client.notes
          }
        }
      })
    },
    async deleteClient () {
      if (this.id) {
        const client = cloneExcept(this.client, 'jobs')
        await this.$apollo.mutate({
          mutation: CLIENT.mutations.delete.mutation,
          variables: { id: this.id },
          update: (store, { data: { deleteClient } }) => {
            const data = store.readQuery({ query: CLIENT.queries.index.query })
            const index = findKeyByProperty(data.clients, 'id', this.id, { castToInt: true })
            data.clients.splice(index, 1)
            store.writeQuery({ query: CLIENT.queries.index.query, data })
          },
          optimisticResponse: { __typename: 'Mutation', deleteClient: client }
        })
        this.$router.replace({ name: 'crm', query: { a: 0 } }).catch(err => {})
      }
    },
    manageCompany () {
      this.$router.push({ name: 'company' }).catch(err => {})
    }
  }
}
</script>

<style lang="scss">
.manage-client {
  & > .layout > .flex {
    width: 100%;
    max-width: 580px;
  }
}
@media (max-width: 600px) {
  .manage-client {
    & > .layout > .flex {
      padding: 0 !important;
    }
  }
}
@media (min-width: 601px) {
  .manage-client {
    & > .layout > .flex {
      padding-right: 16px !important;
      padding-left: 16px !important;
    }
  }
}
</style>
