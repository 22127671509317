import { defineModel, recordQueryMeta, store } from '@/utils'
import { AUTHENTICATED_USER_QUERY, USERS_QUERY } from '../queries'
import ca$h from '@/johnny-cache.js'
import { USER_CREATE, USER_UPDATE, USER_DELETE } from '../mutations'
import { NetworkStatus } from 'apollo-client'

const USER = defineModel({
  name: 'name',
  fields: { id: 0, name: '', email: '', employee: null, is_admin: false, inactive: false },
  queries: {
    index: {
      query: USERS_QUERY,
      update ({ users }) {
        store(users, 'users', 'apollo')
        return users
      },
      result ({ data, loading, networkStatus }) {
        if (!loading && networkStatus === NetworkStatus.ready) {
          recordQueryMeta('users', data.users)
        }
      }
    },
    me: {
      query: AUTHENTICATED_USER_QUERY,
      update ({ me }) {
        store(me, 'user', 'apollo')
        ca$h.set('me', me)
        return me
      },
      result ({ data, loading, networkStatus }) {
        if (!loading && networkStatus === NetworkStatus.ready) {
          recordQueryMeta('user', data.me)
        }
      }
    }
  },
  mutations: {
    create: { mutation: USER_CREATE },
    update: { mutation: USER_UPDATE },
    delete: { mutation: USER_DELETE }
  }
})

export default USER
