/* eslint-disable no-unused-vars, no-useless-escape */
const monetarySymbols = /[\$\,\.]/g
const isDollarsAndCents = /\d+\.\d*$/
const isDollars = /^\$\d[\d\,]*$/
/* eslint-enable no-unused-vars, no-useless-escape */

class Cent$ {
  constructor (value) {
    if (Number.isNaN(value)) {
      return 0
    }
    let str = value.toString().trim().replace(',', '')
    let isDollars = false
    this._isNegative = false

    if (str.startsWith('-')) {
      this._isNegative = true
      str = str.substr(1)
    }

    if (str.startsWith('$')) {
      isDollars = true
      str = str.substr(1)
    }

    if (str.trim() === '') {
      str = '0'
    }

    if (/^\d*\.\d*$/.test(str)) {
      const precision = str.lastIndexOf('.') + 2
      str = str.replace('.', '')
      while (str.length < precision) {
        str = str + '0'
      }

      const significant = str.substr(0, precision)
      const insignificant = str.substr(precision)
      this._cents = parseInt(significant, 10) + (Cent$.shouldRoundUp(insignificant) ? 1 : 0)
    } else if (/^\d+$/.test(str)) {
      this._cents = parseInt(str + (isDollars ? '00' : ''), 10)
    } else {
      this._cents = null
      console.warn('invalid number supplied', value)
      return null
    }
  }

  static fromCents (value) {
    let str = value.toString()
    if (str.startsWith('$')) {
      str = str.substr(1)
    }
    return new this(str)
  }

  static fromDollars (value) {
    let str = value.toString()
    if (!str.startsWith('$')) {
      str = '$' + str
    }
    return new this(str)
  }

  get asCents () {
    return this.value()
  }

  get asDollars () {
    let str = this._cents.toString()
    while (str.length < 3) {
      str = '0' + str
    }
    return (this._isNegative ? '-' : '') + str.substr(0, str.length - 2) + '.' + str.substr(-2)
  }

  toString () {
    return this.asDollars
  }

  toNumber () {
    return this.value()
  }

  value () {
    return this._isNegative ? this._cents * -1 : this._cents
  }

  static shouldRoundUp (insignificantDigits) {
    const digits = insignificantDigits.toString().split('')
    for (let i = 0; i < digits.length; i++) {
      const digit = parseInt(digits[i], 10)
      if (digit > 4) {
        return true
      } else if (digit < 4) {
        return false
      }
    }
    return false
  }
}

export default Cent$
