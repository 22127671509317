<template>
  <v-app dark>
    <v-toolbar app light color="rgba(255,255,255,0.95)" clipped-left absolute v-if="admin">
      <v-toolbar-side-icon @click.stop="handleHamburgerClick" :style="{opacity: $route.name === 'invoice' ? '1' : '0', pointerEvents: $route.name === 'invoice' ? 'auto' : 'none'}"></v-toolbar-side-icon>
      <v-toolbar-title class="hidden-xs-only"  @mousedown.stop="handleLongPressStart" @mouseleave.stop="handleLongPressStop" @mouseup.stop="handleLongPressStop" @touchstart.stop="handleLongPressStart" @touchend.stop="handleLongPressStop" @touchcancel.stop="handleLongPressStop">
        <span class="deep-orange--text" style="user-select: none;">Oxide</span>
      </v-toolbar-title>
      <v-toolbar-title style="cursor: pointer;" class="hidden-sm-and-up" @mousedown.stop="handleLongPressStart" @mouseleave.stop="handleLongPressStop" @mouseup.stop="handleLongPressStop" @touchstart.stop="handleLongPressStart" @touchend.stop="handleLongPressStop" @touchcancel.stop="handleLongPressStop">
        <span class="deep-orange--text">O</span>x
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <v-toolbar-items class="hidden-sm-and-down">
        <v-btn to="/invoice" flat>
          <v-icon left>vertical_split</v-icon>Invoices
        </v-btn>
        <v-btn to="/billables" flat>
          <v-icon left>table_chart</v-icon>Billables
        </v-btn>

        <v-btn to="/crm" flat>
          <v-icon left>assignment_ind</v-icon>Clients
        </v-btn>

        <v-btn to="/jobs?without=internal" flat>
          <v-icon left>assignment</v-icon>Jobs
        </v-btn>

        <v-btn to="/reports/jobs/all/by-day" flat>
          <v-icon left>event_note</v-icon>Jobs By Day
        </v-btn>

        <v-btn to="/employee" flat>
          <v-icon left>people</v-icon>Employees
        </v-btn>

        <v-btn v-if="me && me.employee" :to="'/reports/employees/' + me.employee.id + '/time-card'" flat>
          <v-icon left>access_time</v-icon>Time Cards
        </v-btn>

        <v-btn to="/config" flat>
          <v-icon left>settings</v-icon>Config
        </v-btn>

        <v-btn icon v-if="$route.name === 'invoice'" @click="printPage">
          <v-icon>print</v-icon>
        </v-btn>
        <v-btn icon v-else style="visibility: hidden; pointer-events: none;"></v-btn>

      </v-toolbar-items>

      <v-toolbar-items class="hidden-md-and-up">
        <v-btn v-if="admin" @click="showExtension = !showExtension" icon>
          <v-icon>verified_user</v-icon>
        </v-btn>
        <v-btn to="/crm" icon>
          <v-icon>assignment_ind</v-icon>
        </v-btn>
        <v-btn v-if="me && me.employee" :to="'/reports/employees/' + me.employee.id + '/time-card'" icon>
          <v-icon>access_time</v-icon>
        </v-btn>
      </v-toolbar-items>

      <template #extension v-if="admin && showExtension">
        <v-spacer></v-spacer>

        <v-toolbar-items class="hidden-md-and-up">
          <v-btn to="/invoice" icon>
            <v-icon>vertical_split</v-icon>
          </v-btn>
          <v-btn to="/billables" icon>
            <v-icon>table_chart</v-icon>
          </v-btn>
          <v-btn to="/jobs?without=internal" icon>
            <v-icon>assignment</v-icon>
          </v-btn>
          <v-btn to="/reports/jobs/all/by-day" icon>
            <v-icon>event_note</v-icon>
          </v-btn>
          <v-btn to="/employee" icon>
            <v-icon>people</v-icon>
          </v-btn>
          <v-btn to="/config" icon>
            <v-icon>settings</v-icon>
          </v-btn>
        </v-toolbar-items>
      </template>

    </v-toolbar>

    <v-content :class="{ 'scrolling-disabled': disableScrolling }">
      <v-container :fluid="fluid" v-if="authenticated">
        <!-- <keep-alive> -->
          <router-view :company="company"></router-view>
        <!-- </keep-alive> -->
      </v-container>
    </v-content>

    <login-form v-if="unauthenticated" @authenticated="handleAuthentication"></login-form>

    <omni-search v-model="showOmniSearch"></omni-search>

    <v-dialog :value="reloadRequired" hide-overlay persistent>
      <v-card :color="reloadRequiredColor" dark>
        <v-card-title class="headline" v-html="reloadRequiredTitle"></v-card-title>
        <v-card-text v-html="reloadRequiredText"></v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="deep-orange darken-1" flat @click="reloadApp">Reload</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog :value="showOptionsMenu" fullscreen lazy hide-overlay transition="dialog-bottom-transition">
      <v-sheet style="height: 100%;">
        <v-toolbar dark color="primary">
          <v-toolbar-title>Oxide</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="showOptionsMenu = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-container grid-list-xl>
          <v-layout row wrap justify-space-between>
            <v-flex xs12 sm6>
              <v-card>
                <v-card-title primary-title>
                  <div class="headline">Account</div>
                </v-card-title>
                <v-list>
                  <v-list-tile>
                    <v-list-tile-content>
                      <span>Name</span><span>{{ me.name }}</span>
                    </v-list-tile-content>
                  </v-list-tile>
                </v-list>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn flat @click="logOut">Logout</v-btn>
                </v-card-actions>
              </v-card>
            </v-flex>

            <v-flex xs12 sm6>
              <v-card>
                <v-card-title primary-title>
                  <div class="headline">Troubleshooting</div>
                </v-card-title>
                <v-card-text>
                  <span class="subheading">Version: </span><span class="right">{{ version }}</span>
                </v-card-text>
                <v-card-actions>
                  <v-btn flat @click="startDataPolling" color="cyan" :loading="authenticated && loading">Manually reload data</v-btn>
                  <v-spacer></v-spacer>
                  <v-btn flat @click="reloadApp" color="amber">Reload Oxide</v-btn>
                </v-card-actions>
              </v-card>
            </v-flex>
          </v-layout>
        </v-container>
      </v-sheet>
    </v-dialog>

    <v-snackbar
      v-model="snackbar.show" :color="snackbar.color" :timeout="snackbar.timeout" top>
      <span v-html="snackbar.text"></span>
      <v-btn dark flat @click="snackbar.show = false">
        Close
      </v-btn>
    </v-snackbar>

    <v-snackbar v-model="showInstallPrompt" :timeout="0" vertical bottom>
      <div class="text-xs-center title">Install Oxide to this device?</div>
      <div class="d-flex">
        <v-btn dark flat color="accent" @click="handleInstallResponse(true)" style="justify-content: center;">
          Yes
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn dark flat color="warning" @click="handleInstallResponse(false)" style="justify-content: center;">
          No
        </v-btn>
      </div>
    </v-snackbar>

    <v-bottom-nav
      app dark :color="offline ? 'red darken-2' : 'rgb(5,42,70)'" height="42" style="box-shadow: 0 3px 14px 2px rgba(255,255,255,0.12); border-top-width: 1px; border-top-color: rgba(255,255,255,0.5);"
      :active.sync="bottomNav" :value="true">

      <!-- <v-btn dark style="pointer-events: none;" :loading="authenticated && loading" active-class="">
        <span
          :class="{'deep-orange--text': !offline}"
          class="subheading" style="pointer-events: auto; cursor: pointer; user-select: none;"
          @mousedown.stop="handleLongPressStart" @mouseleave.stop="handleLongPressStop" @mouseup.stop="handleLongPressStop"
          @touchstart.stop="handleLongPressStart" @touchend.stop="handleLongPressStop" @touchcancel.stop="handleLongPressStop"
        >{{ offline ? 'OFFLINE' : 'Ox' }}</span>
      </v-btn> -->

      <v-btn dark :loading="authenticated && loading" active-class="" @click="showOptionsMenu = !showOptionsMenu">
        <span
          :class="{'deep-orange--text': !offline}"
          class="subheading" style="pointer-events: auto; cursor: pointer; user-select: none;"
        >{{ offline ? 'OFFLINE' : 'Ox' }}</span>
      </v-btn>

      <v-btn dark @click="$router.go(-1)">
        <v-icon class="mb-0">chevron_left</v-icon>
      </v-btn>

      <v-btn dark to="/crm">
        <v-icon class="mb-0">assignment_ind</v-icon>
      </v-btn>

      <v-btn v-if="me && me.employee" dark :to="'/reports/employees/' + me.employee.id + '/time-card'">
        <v-icon class="mb-0">access_time</v-icon>
      </v-btn>

      <v-btn dark @click="showOmniSearch = !showOmniSearch" :style="{ zIndex: (showOmniSearch ? 5000 : 'initial') }">
        <v-icon class="mb-0">{{ showOmniSearch ? 'close' : 'search' }}</v-icon>
      </v-btn>

    </v-bottom-nav>
  </v-app>
</template>

<script>
/* eslint-disable camelcase */
/* eslint-disable handle-callback-err */

import LoginForm from '@/components/LoginForm.vue'
import OmniSearch from '@/components/OmniSearch.vue'

import { sortBy } from 'lodash'
import { SECONDS, MINUTE, MINUTES } from '@/constants'
import { BILLABLE, CATEGORY, CLIENT, COMPANY, EMPLOYEE, USER } from '@/graphql/models'
import Timers from '@/timers'

let loadingTimeout = null
let reloadingTimeout = null
let offlineCheckTimeout = null
const timers = new Timers()

export default {
  name: 'app',
  components: { LoginForm, OmniSearch },
  data () {
    return {
      bottomNav: -1,
      longPressStart: 0,
      me: {},
      showDrawer: false,
      showLoginForm: false,
      showInstallPrompt: false,
      showExtension: false,
      reloadRequired: false,
      reloadRequiredColor: 'grey darken-3',
      reloadRequiredText: '',
      reloadRequiredTitle: '',
      showOmniSearch: false,
      showOptionsMenu: false
    }
  },
  apollo: {
    // $skipAll () {
    //   return false
    //   const skip = this.$oxide.bus.offline || this.$oxide.bus.hidden || !this.authenticated
    //   // console.log('skip queries: ' + (skip ? 'true' : 'false'))
    //   return skip
    // },
    $error (error, vm, key, type, options) {
      // console.log('errall', error, vm, key, type, options)
    },
    billables: {
      query: BILLABLE.queries.index.query,
      pollInterval: 0,
      update: BILLABLE.queries.index.update,
      result: BILLABLE.queries.index.result,
      fetchPolicy: 'cache-and-network',
      error (error, vm, key, type, options) {
        console.log('errbil', error, vm, key, type, options)
      }
    },
    categories: {
      query: CATEGORY.queries.index.query,
      pollInterval: 0,
      update: CATEGORY.queries.index.update,
      result: CATEGORY.queries.index.result,
      fetchPolicy: 'cache-and-network'
    },
    client: {
      query: CLIENT.queries.show.query,
      variables () { return { id: this.$oxide.store.activeClientId ? this.$oxide.store.activeClientId : 0 } },
      pollInterval: 0,
      skip () { return !(this.$oxide.store.activeClientId) || this.$oxide.store.activeClientId === null },
      update: CLIENT.queries.show.update,
      result: CLIENT.queries.show.result,
      fetchPolicy: 'cache-and-network'
    },
    clients: {
      query: CLIENT.queries.index.query,
      pollInterval: 0,
      update: CLIENT.queries.index.update,
      result: CLIENT.queries.index.result,
      fetchPolicy: 'cache-and-network'
    },
    company: {
      query: COMPANY.queries.show.query,
      pollInterval: 0,
      update: COMPANY.queries.show.update,
      result: COMPANY.queries.show.result,
      fetchPolicy: 'cache-and-network'
    },
    employees: {
      query: EMPLOYEE.queries.index.query,
      pollInterval: 0,
      update: EMPLOYEE.queries.index.update,
      result: EMPLOYEE.queries.index.result,
      fetchPolicy: 'cache-and-network'
    },
    me: {
      query: USER.queries.me.query,
      pollInterval: 0,
      update: USER.queries.me.update,
      result: USER.queries.me.result,
      fetchPolicy: 'cache-and-network'
    },
    users: {
      query: USER.queries.index.query,
      pollInterval: 0,
      update: USER.queries.index.update,
      result: USER.queries.index.result,
      fetchPolicy: 'cache-and-network'
    }
  },
  computed: {
    admin () { return this.me?.is_admin },
    authenticated: {
      get () { return this.$oxide.bus.authenticated },
      set (value) { this.$oxide.bus.authenticated = value }
    },
    hidden () { return this.$oxide.bus.hidden },
    unauthenticated () { return !this.authenticated },
    loading () { return this.$apollo.loading },
    config () { return this.$apollo.queries.company.loading ? [] : this.company.config },
    disableScrolling () { return this.$oxide.store.disableScrolling },
    offline () { return this.$oxide.bus.offline },
    readyToQuery () { return !this.hidden && !this.offline && this.authenticated },
    fluid () { return this.$route.name === 'crm' || this.$route.name === 'reports' },
    snackbar () { return this.$oxide.store.snackbar },
    clientInvoices () {
      if (this.authenticated && this.$apollo.queries.clients.loading) {
        return []
      }
      const clients = sortBy(this.clients, ['name'])
      const invoicesByClient = []
      for (const client of clients) {
        const invoices = [...this.invoices?.filter(invoice => client.id === invoice.client_id), ...this.invoices?.filter(invoice => client.id === invoice?.job?.client?.id)]

        if (invoices?.length) {
          invoicesByClient.push({
            id: client.id,
            name: client.name,
            invoices
          })
        }
      }
      const orphanInvoices = this.invoices?.filter(invoice => !invoice.client && !invoice.job)
      if (orphanInvoices?.length) {
        invoicesByClient.push({
          id: 0,
          name: 'No Client',
          invoices: orphanInvoices
        })
      }
      return invoicesByClient
    },
    bottomNavColor () {
      switch (this.bottomNav) {
        case 1: return 'cyan darken-2'
        case 2: return 'blue-grey darken-2'
        default: return 'secondary darken-2'
      }
    },
    version () {
      return this.$version
    }
  },
  methods: {
    printPage () {
      window.print()
    },
    handleAuthentication () {
      this.authenticated = !!this.$cache.auth_access
    },
    nomenclature (str, fallback = '') {
      if (this.$apollo.queries.company.loading) {
        return ''
      } else if (
        typeof this.company.config !== 'undefined' &&
        typeof this.company.config.nomenclature !== 'undefined' &&
        typeof this.company.config.nomenclature[str] !== 'undefined'
      ) {
        return this.company.config.nomenclature[str]
      } else {
        return fallback
      }
    },
    openInvoice (invoice) {
      const route = { params: { invoiceId: invoice.id } }
      if (this.$route.name !== 'invoice') {
        route.name = 'invoice'
      }
      this.$router.push(route).catch(err => {})
    },
    async deleteInvoice (invoice) {
      const id = invoice.id
      if (this.$router.name === 'invoice' && parseInt(this.$router.params.invoiceId, 10) === parseInt(id, 10)) {
        this.$router.push({ params: { invoiceId: null } }).catch(err => {})
      }
    },

    resetNewInvoice () {
      console.log('reset')
    },

    handleLongPressStart () {
      if (!this.longPressStart) {
        this.longPressStart = Date.now()
        reloadingTimeout = window.setTimeout(() => {
          this.snackbarColor = 'pink darken-2'
          this.snackbarText = 'Release to reload'
          this.snackbarTimeout = 0
          this.showSnackbar = true
        }, 2000)
      }
    },
    handleLongPressStop () {
      if (this.longPressStart) {
        if (reloadingTimeout) {
          window.clearTimeout(reloadingTimeout)
        }
        const longPressLength = Date.now() - this.longPressStart
        if (longPressLength > 2000) {
          this.snackbarColor = 'amber darken-2'
          this.snackbarText = 'Reloading app'
          this.snackbarTimeout = 0
          this.showSnackbar = true
          window.setTimeout(() => {
            location.reload(true)
          }, 3000)
        } else {
          this.startDataPolling()
          // this.showOptionsMenu = !this.showOptionsMenu
        }
        this.longPressStart = 0
      }
    },
    handleInstallResponse (yes) {
      this.showInstallPrompt = false
      if (yes) {
        this.$oxide.bus.beforeinstallprompt.prompt()
        this.$oxide.bus.beforeinstallprompt.userChoice
          .then((choiceResult) => {
            if (choiceResult.outcome === 'accepted') {
              console.log('User accepted the A2HS prompt')
            } else {
              window.localStorage.setItem('oxide_install_denied', true)
              console.log('User dismissed the A2HS prompt')
            }
            this.$oxide.bus.beforeinstallprompt = null
          })
      } else {
        window.localStorage.setItem('oxide_install_denied', true)
      }
    },
    loadUser () {
      if (this.me === null || !this.me?.id) {
        if (this.authenticated && !this.$apollo.queries.me.loading) {
          this.$apollo.queries.me.refetch()
        }
        window.setTimeout(this.loadUser, 5000)
      }
    },
    verifyOffline () {
      if (!navigator.onLine !== this.$oxide.bus.offline) {
        this.$oxide.bus.offline = !navigator.onLine
      }
      if (navigator.onLine) {
        window.clearTimeout(offlineCheckTimeout)
      } else {
        offlineCheckTimeout = window.setTimeout(this.verifyOffline, 5000)
      }
    },
    reloadApp () {
      location.reload(true)
    },
    startDataPolling () {
      if (this.authenticated) {
        timers.clear()
        this.$apollo.queries.company.refetch()
        timers.loadCompany = self.setInterval(() => {
          if (this.readyToQuery) {
            this.$apollo.queries.company.refetch()
          }
        }, 2 * MINUTES)

        this.$apollo.queries.clients.refetch()
        timers.loadClients = self.setInterval(() => {
          if (this.readyToQuery) {
            this.$apollo.queries.clients.refetch()
          }
        }, 1 * MINUTE)

        if (this.$oxide.store.activeClientId) {
          this.$apollo.queries.client.refetch()
        }
        timers.loadClient = self.setInterval(() => {
          if (this.$oxide.store.activeClientId) {
            this.$apollo.queries.client.refetch()
          }
        }, 30 * SECONDS)

        this.$apollo.queries.users.refetch()
        timers.loadUsers = self.setInterval(() => {
          if (this.readyToQuery) {
            this.$apollo.queries.users.refetch()
          }
        }, 5 * MINUTES)

        this.$apollo.queries.employees.refetch()
        timers.loadEmployees = self.setInterval(() => {
          if (this.readyToQuery) {
            this.$apollo.queries.employees.refetch()
          }
        }, 5 * MINUTES)

        this.$apollo.queries.employees.refetch()
        timers.loadEmployees = self.setInterval(() => {
          if (this.readyToQuery) {
            this.$apollo.queries.employees.refetch()
          }
        }, 5 * MINUTES)

        this.$apollo.queries.categories.refetch()
        timers.loadCategories = self.setInterval(() => {
          if (this.readyToQuery) {
            this.$apollo.queries.categories.refetch()
          }
        }, 5 * MINUTES)

        this.$apollo.queries.billables.refetch()
        timers.loadBillables = self.setInterval(() => {
          if (this.readyToQuery) {
            this.$apollo.queries.billables.refetch()
          }
        }, 5 * MINUTES)
      }
    },
    handleHamburgerClick () {
      this.$oxide.bus.showSidebar = !this.$oxide.bus.showSidebar
    },
    updateNow () { this.$oxide.bus.now = new Date() },
    logOut () {
      this.$router.push('/')
      this.$apollo.getClient().clearStore()
      this.$cache.set('auth_email', '')
      this.$cache.set('auth_refresh', '')
      this.$cache.set('auth_expires', '')
      this.$cache.set('auth_access', '')
      this.authenticated = false
      window.location = '/#/'
    }
  },
  // Lifecycle Events
  created () {
    this.authenticated = !!this.$cache.auth_access
    if (this.$nowInterval) {
      self.clearInterval(this.$nowInterval)
    }
    this.$nowInterval = self.setInterval(this.updateNow.bind(this), 1000)
    // this.startDataPolling()
  },
  beforeDestroy () {
    if (this.$nowInterval) {
      self.clearInterval(this.$nowInterval)
    }
    timers.clear()
  },
  mounted () {
    if (window.localStorage.getItem('oxide_updated')) {
      this.snackbarColor = 'deep-orange darken-2'
      this.snackbarText = 'Oxide has been updated'
      this.showSnackbar = true
      this.snackbarTimeout = 5000
      window.localStorage.removeItem('oxide_updated')
    }
    if (this.$route.name === 'crm') {
      this.bottomNav = 1
    } else if (this.$route.name === 'reports') {
      this.bottomNav = 2
    }
    this.loadUser()

    this.verifyOffline()
  },
  // Manual Watchers
  watch: {
    '$oxide.bus.beforeinstallprompt' (e) {
      if (e) {
        this.showInstallPrompt = true
      }
    },
    '$oxide.bus.hidden' (hidden) {
      if (this.$nowInterval) {
        self.clearInterval(this.$nowInterval)
      }
      if (!hidden) {
        this.$nowInterval = self.setInterval(this.updateNow.bind(this), 1000)
      }
    },
    '$oxide.bus.offline' (offline) {
      if (offline) {
        this.snackbarColor = 'red darken-2'
        this.snackbarText = 'No internet detected'
        this.verifyOffline()
      } else {
        this.snackbarColor = 'green darken-2'
        this.snackbarText = 'Connected to Oxide Server'
      }
      this.snackbarTimeout = 2000
      this.showSnackbar = true
    },
    '$oxide.bus.errors': {
      deep: true,
      handler (errors) {
        const error = errors[errors.length - 1]
        if (typeof error === 'string') {
          if (error.includes('QuotaExceededError')) {
            this.reloadRequiredTitle = 'Local Data Deleted'
            this.reloadRequiredText = 'Your device has deleted Oxide\'s local data because it is low on storage.<br>Please free up some storage and reload Oxide to prevent data loss.'
            this.reloadRequiredColor = 'blue-grey darken-2'
            this.reloadRequired = true
          } else if (error.includes('Refresh')) {
            this.reloadRequiredTitle = 'An error has occurred and been recorded'
            this.reloadRequiredText = 'Please reload Oxide to continue using it while we look into the issue.'
            this.reloadRequiredColor = 'teal darken-4'
            this.reloadRequired = true
          }
        }
      }
    },
    '$apollo.loading' (loading) {
      if (loading) {
        loadingTimeout = window.setTimeout(() => {
          if (this.$apollo.loading) {
            this.$oxide.bus.offline = true
          } else if (this.$oxide.bus.offline) {
            this.$oxide.bus.offline = false
          }
        }, 15000) // 15000ms === 15s
      } else if (loadingTimeout) {
        window.clearTimeout(loadingTimeout)
      }
    },
    me: {
      handler (me) {
        if (me && this.$sentry) {
          this.$sentry.configureScope(function (scope) {
            scope.setUser(me)
          })
        }
      },
      deep: true
    },
    '$route.name' (name) {
      this.showExtension = false
      this.$cache.set('lastRouteName', name)
    },
    '$route.fullPath' (fullPath) {
      this.$cache.set('lastRouteFullPath', fullPath)
    },
    async readyToQuery (val) {
      timers.clear()
      if (val) {
        self.setTimeout(this.startDataPolling.bind(this), 5000)
      }
    }
  }
}
</script>

<style lang="scss">

#app.theme--dark {
  background: rgb(19,73,96);
  background: radial-gradient(circle, rgb(19,73,96) 0%, rgb(5,42,70) 100%);

  .v-overlay {
    &::before {
      background-color: rgb(5,15,20);
    }
    &.v-overlay--active::before {
      opacity: 0.95;
    }
  }
  .v-dialog--fullscreen.v-dialog--active {
    background-color: rgb(10,53,80);
  }

  .v-menu__content .v-card.theme--dark {
    background-color: rgba(10,53,80,0.95);
  }

  .theme--dark {
    &.v-expansion-panel > .v-expansion-panel__container,
    &.v-list,
    &.v-sheet,
    &.v-table {
      background-color: rgba(255,255,255,0.05);
    }

    &.v-calendar-daily {
      background: none;
    }

    &.v-datatable {
      tbody {
        tr:hover:not(.v-datatable__expand-row),
        tr[active] {
          background: rgba(255,255,255,0.1);
        }
      }
      & > .v-datatable__actions {
        background: none;
      }
    }

    &.v-card,
    &.v-sheet {
      border-color: rgba(255,255,255,0.25);
    }

    &.v-toolbar {
      box-shadow: 0px 2px 4px -1px rgba(255,255,255,0.2), 0px 4px 5px 0px rgba(255,255,255,0.14), 0px 1px 10px 0px rgba(255,255,255,0.12);
    }

    // &.v-card {
      // box-shadow: 0px 3px 1px -2px rgba(255,255,255,0.2), 0px 2px 2px 0px rgba(255,255,255,0.14), 0px 1px 5px 0px rgba(255,255,255,0.12);
    // }
    &.v-expansion-panel {
      // box-shadow: 0px 2px 1px -1px rgba(255,255,255,0.2), 0px 1px 1px 0px rgba(255,255,255,0.14), 0px 1px 3px 0px rgba(255,255,255,0.12);
      box-shadow: none;
      border-top-color: rgba(255,255,255,0.25);
      border-bottom-color: rgba(255,255,255,0.25);
    }

    &.striped > [role=listitem]:nth-of-type(even),
    &.striped > .v-list__group > .v-list__group__items > [role=listitem]:nth-of-type(even),
    &.striped > tr:nth-child(odd),
    .striped > .v-table__overflow > .v-table.theme--dark > tbody > tr:nth-child(odd),
    &.v-menu__content > .v-select-list > .v-list > [role=listitem]:nth-of-type(even) {
      background-color: rgba(255,255,255,0.02);
    }

    a:not(.v-tabs__item):not(.v-btn):not(.theme--light) {
      color: #ffffff;
      text-decoration: underline #80DEEA; // cyan lighten-3
    }
    a {
      font-weight: bold;
    }

    // a.primary--text {
    //   color: #ffffff !important;
    // }
    .v-list__tile--link.v-list__tile--active.theme--dark {
      color: #80DEEA !important;
    }

    .v-list__tile__action-text {
      color: #dddddd;
    }

    .v-btn.v-btn--flat {
      font-weight: bold;
    }

    .theme--dark.v-list .v-list__tile__sub-title {
      color: rgba(255,255,255,0.85);
    }
  }
}

.v-navigation-drawer .v-list--dense {
  .v-subheader,
  .v-list__group {
    .v-subheader {
      height: auto;
    }

    .v-list__group__items {
      // max-height: 50vh;
      // overflow-y: scroll;

      .hover-action {
        .v-list__tile__action {
          min-width: 24px;
          display: none;
        }
        &:not(:hover) .v-list__tile__action {
          display: none;
        }
      }
    }
  }
}

input,
textarea,
select {
  &:-webkit-autofill,
  &:-internal-autofill-selected {
    &,
    &:hover,
    &:focus {
      -webkit-text-fill-color: #ffffff;

      // a man can dream...
      background-color: transparent !important;

      // wait 24hrs after autofill to change the background
      // color to the stupid unmodifiable useragent default
      // (24 * 60 * 60 === 86400)
      transition: background-color 0s ease-in-out 86400s;
    }
  }
}

.v-bottom-sheet.v-dialog {
  overflow-y: scroll;
}

input:not(:focus):not(:hover)::-webkit-inner-spin-button {
  display: none;
}

// .v-input.v-text-field--placeholder.error--text {
// }
.v-input.error--text input::placeholder {
  opacity: 1 !important;
}

.v-content {
  .v-content__wrap > .container {
    &.fluid {
      padding: 0;
    }
    &:not(.fluid) {
      max-width: 1185px;
    }
  }
}

.v-toolbar + .v-card > :first-child:not(.v-btn):not(.v-chip),
.v-toolbar + .v-sheet {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.v-chip {
  &.v-chip--small {
    & > .v-chip__content {
      height: 24px!important;
      padding: 0 8px;
    }
    &.v-chip--removable {
      & > .v-chip__content {
        padding: 0 0 4px 8px;
        .v-chip__close {
          transform: translateY(2px);
        }
      }
    }
  }
  &.v-chip--tiny {
    height: 18px!important;
    padding-bottom: 4px;
    margin: 1px 3px;
    font-size: 80%;
    &>.v-chip__content {
      height: 18px!important;
      padding: 0 4px;
    }
  }
}

.v-btn {
  &.v-btn--tiny {
    font-size: 12px;
    height: 20px!important;
    &.v-btn--icon {
      width: 20px;
      &>.v-btn__content>.v-icon {
        font-size: 13px!important;
      }
    }
    &>.v-btn__content {
      height: 20px!important;
      // padding: 0 4px;

    }
    &>a.v-btn__content {
      text-decoration: none;
    }
  }

}

.v-textarea.text-xs-right textarea {
  text-align: right;
}

.v-badge.v-badge--inline {
  .v-badge__badge {
    top: 50%;
    transform: translateY(-50%);
    right: -33px;
  }
  &.v-badge--left .v-badge__badge {
    left: -33px;
    line-height: 1.4;
    align-items: flex-start;
  }
}

.is-loading {
  opacity: 0.5;
  cursor: wait;
  & > * {
    pointer-events: none;
  }
  &.is-loading-overlay::after {
    top:0; right:0; bottom:0; left:0;
    content: " ";
    position: absolute;
    background-color: rgba(0, 0, 0, 0.25);
    z-index: 10;
  }
}
.v-input {
  &.error--text {
    input,
    input::placeholder {
      color: inherit !important;
    }
    input::placeholder {
      opacity: 0.5;
    }
  }
}
.font-mono {
  font-family: monospace, monospace !important;
}
.font-all-small-caps {
  font-variant: all-small-caps;
}

.opaque-on-hover {
  opacity: 0.66;
  &:hover {
    opacity: 1;
  }
}

$gridSize: 100% / 12;

@for $size from 0 through 3 {
  @for $dividend from 1 through 3 {
    $fraction: $dividend / 4;
    $percentage: $fraction * 100;
    .xs#{$size}_#{$percentage} {
      flex-grow: 0;
      flex-basis: $gridSize * ($size + $fraction);
      max-width: $gridSize * ($size + $fraction);
    }
  }
}

.spinning-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 600px) {
  .v-item-group.v-bottom-nav .v-btn {
    min-width: auto;
  }
}

@media not print {
  .print-only {
    display: none !important;
  }
}

@media print {
  #app {
    background: #ffffff !important;
  }
  .v-bottom-nav {
    display: none !important;
  }
  .v-toolbar,
  .v-navigation-drawer,
  .v-bottom-nav {
    display: none;
  }

  .print-hide {
    display: none !important;
  }

  .v-content {
    padding: 0 !important;
  }

  input::-webkit-inner-spin-button {
    display: none;
  }
}
</style>
