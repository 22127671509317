<template>
  <v-card v-if="entity" tile style="width: 100%;" class="manage-entity elevation-2">
    <v-responsive :style="backgroundGradient" :class="[colorClasses]">
      <v-container pa-2>
        <v-layout column ma-0>
          <v-card-title class="pa-0">
            <v-btn @click="goBack" dark flat round class="back-button">
              <v-icon>chevron_left</v-icon>
              <span v-if="backText" style="padding-right: 8px;">{{ backText }}</span>
            </v-btn>
            <v-spacer></v-spacer>
            <slot name="title"></slot>
            <h2 class="white--text title font-weight-regular pr-3">
              <v-icon dark>{{ icon }}</v-icon> {{ title }}
            </h2>
          </v-card-title>
          <v-card-title dark class="pa-1">
            <slot name="form">
              <v-container pa-0>
                <v-text-field v-if="hasName" label="Name" v-model="local.name" @input="dirty = true" outline dark hide-details color="white"></v-text-field>
                <v-text-field v-else-if="hasTitle" label="Title" v-model="local.title" @input="dirty = true" outline dark hide-details color="white"></v-text-field>
                <v-layout row pa-3 v-if="isNameChanged">
                  <v-btn small outline dark class="ma-0" @click="reset">cancel</v-btn>
                  <v-spacer></v-spacer>
                  <v-btn small depressed class="ma-0" @click="save" style="mix-blend-mode: screen;">save</v-btn>
                </v-layout>
              </v-container>
            </slot>
          </v-card-title>
        </v-layout>
      </v-container>
    </v-responsive>

    <aside class="loading-overlay" v-if="loading">
      <v-progress-circular indeterminate color="white" :size="48" :width="8"></v-progress-circular>
      <!-- <h2 class="white--text display-1 font-weight-regular">l<v-progress-circular indeterminate color="white" :size="20" :width="3"></v-progress-circular>ading</h2> -->
    </aside>

    <!-- <v-toolbar dense flat :color="detailsColor"> -->
    <v-toolbar dense flat light color="rgba(255,255,255,0.9)" :class="{ 'elevation-1': showDetails}">
      <v-icon :color="altColors[0]">list_alt</v-icon><!-- <v-icon>toc</v-icon> -->
      <v-toolbar-title class="font-weight-regular" :class="detailsTextColorClasses">Details</v-toolbar-title>
      <slot name="details-toolbar" :expanded="showDetails">
        <v-spacer></v-spacer>
      </slot>
      <v-btn icon @click="showDetails = !showDetails" style="margin-left: 40px;">
        <v-badge left :color="altColors[0]" class="v-badge--inline" style="opacity: 0.5;">
          <template #badge><span>{{ detailsTotal }}</span></template>
          <v-icon>{{ showDetails ? 'expand_less' : 'expand_more' }}</v-icon>
        </v-badge>
      </v-btn>
    </v-toolbar>

    <v-slide-y-transition>
      <section v-show="showDetails">
        <slot name="preContent"></slot>

        <v-list two-line expand class="contacts py-0 striped">
          <template v-if="!noContacts">
            <template v-if="!noPhone">
              <v-list-group v-if="phoneNumbers.length" v-model="phoneExpanded" prepend-icon="contact_phone" :append-icon="phoneExpanded || phoneNumbers.length === 1 ? $vuetify.icons.expand : ('looks_' + (phoneNumbers.length === 2 ? 'two' : phoneNumbers.length ))" :class="{ 'elevation-2':phoneExpanded }" lazy>

                <v-list-tile v-if="primaryPhoneNumber" slot="activator" :color="phoneExpanded ? 'red' : ''">
                  <v-list-tile-content v-if="phoneExpanded">
                    <div>Phone Numbers</div>
                  </v-list-tile-content>

                  <v-list-tile-content v-else>
                    <v-list-tile-sub-title v-if="primaryPhoneNumber.label">
                      <span class="caption grey--text text--lighten-2">{{ primaryPhoneNumber.label }}</span>
                    </v-list-tile-sub-title>
                    <v-list-tile-title class="d-block">
                      {{ primaryPhoneNumber.value | phone }}

                    </v-list-tile-title>
                    <span class="v-btn v-btn--icon v-btn--tiny theme--light primary ma-0" style="position: absolute; right: 0;">
                      <a :href="'tel:' + primaryPhoneNumber.value" class="v-btn__content white--text" @click.passive.stop>
                        <v-icon small>phone</v-icon>
                      </a>
                    </span>
                  </v-list-tile-content>
                </v-list-tile>

                <template v-for="(phoneNumber, index) of phoneNumbers">
                  <v-list-tile :key="'view:' + phoneNumber.id" :class="{ 'editing': editingPhone && editingId === phoneNumber.id }">
                    <v-list-tile-action v-if="index === 0">
                      <v-icon color="accent">stars</v-icon>
                    </v-list-tile-action>

                    <v-list-tile-action v-else>
                      <v-hover>
                        <v-btn dark icon slot-scope="{ hover }" @click.stop="makeContactPrimary(phoneNumber)">
                          <v-icon :color="hover ? 'accent' : 'white'">stars</v-icon>
                        </v-btn>
                      </v-hover>
                    </v-list-tile-action>

                    <v-list-tile-content>
                      <v-list-tile-sub-title v-if="phoneNumber.label">
                        <span class="caption grey--text text--lighten-2">{{ phoneNumber.label }}</span>
                      </v-list-tile-sub-title>
                      <v-list-tile-title class="d-block">
                        <a :href="'tel:' + phoneNumber.value" class="font-weight-regular" @click.passive.stop>{{ phoneNumber.value | phone }}</a>
                      </v-list-tile-title>
                    </v-list-tile-content>
                    <v-list-tile-action>
                      <v-hover>
                        <v-btn dark small icon slot-scope="{ hover }" @click.stop="editContact(phoneNumber, index)">
                          <v-icon small :color="hover ? 'accent' : 'white'">{{ (editingPhone && editingId === phoneNumber.id) ? 'edit_off' : 'edit' }}</v-icon>
                        </v-btn>
                      </v-hover>
                    </v-list-tile-action>
                  </v-list-tile>

                  <v-slide-y-transition :key="'edit:' + phoneNumber.id">
                    <v-list-tile  v-show="editingPhone && editingId === phoneNumber.id" class="editing popout">
                      <v-list-tile-content>
                        <v-layout row ma-0 pa-0 style="width: 100%;">
                          <v-flex xs6>
                            <v-text-field label="Number" v-model="phoneNumber.value" @input="handleContactValueInput(phoneNumber, $event)" hide-details ref="phoneValueTextFields"></v-text-field>
                          </v-flex>
                          <v-flex xs6>
                            <v-text-field label="Label" v-model="phoneNumber.label" @input="handleContactLabelInput(phoneNumber, $event)" hide-details></v-text-field>
                          </v-flex>
                        </v-layout>
                      </v-list-tile-content>
                      <v-list-tile-action>
                        <v-btn icon small class="right" @click.stop="deleteContact(phoneNumber)">
                          <v-icon small>delete</v-icon>
                        </v-btn>
                      </v-list-tile-action>
                    </v-list-tile>
                  </v-slide-y-transition>
                </template>

                <v-list-tile v-if="editingType === ''" @click="addContact('phone')">
                  <v-list-tile-action><v-icon color="green">add</v-icon></v-list-tile-action>
                  <v-list-tile-content>
                    <v-list-tile-sub-title>add new phone number</v-list-tile-sub-title>
                  </v-list-tile-content>
                </v-list-tile>
              </v-list-group>
              <v-list-tile v-else-if="editingType === ''" @click="addContact('phone')">
                <v-list-tile-action><v-icon color="green">add</v-icon></v-list-tile-action>
                <v-list-tile-content>
                  <v-list-tile-sub-title>add phone number</v-list-tile-sub-title>
                </v-list-tile-content>
              </v-list-tile>

              <v-slide-y-transition>
                <v-list-tile v-show="editingId === 0 && editingType === 'phone'" class="editing">
                  <v-list-tile-action>
                    <v-icon>phone</v-icon>
                  </v-list-tile-action>

                  <v-list-tile-content>
                    <v-list-tile-sub-title v-if="newContact.label">
                      <span class="caption grey--text text--lighten-2">{{ newContact.label }}</span>
                    </v-list-tile-sub-title>
                    <v-list-tile-title class="d-block" v-if="newContact.value">
                      {{ newContact.value | phone }}
                    </v-list-tile-title>
                    <v-list-tile-title class="d-block" v-else>
                      New Phone Number
                    </v-list-tile-title>
                  </v-list-tile-content>
                  <v-list-tile-action>
                    <v-hover>
                      <v-btn dark small icon slot-scope="{ hover }" @click.stop="editingType = ''">
                        <v-icon small :color="hover ? 'accent' : 'white'">edit_off</v-icon>
                      </v-btn>
                    </v-hover>
                  </v-list-tile-action>
                </v-list-tile>
              </v-slide-y-transition>

              <v-slide-y-transition>
                <v-list-tile  v-show="editingId === 0 && editingType === 'phone'" class="editing popout">
                  <v-list-tile-content>
                    <v-layout row ma-0 pa-0 style="width: 100%;">
                       <v-flex xs6>
                        <v-text-field label="New Phone Number" v-model="newContact.value" @keyup.enter="createContact({ id: 0, type: 'phone', label: newContact.label, value: newContact.value })" hide-details ref="newPhoneValueTextField"></v-text-field>
                      </v-flex>
                      <v-flex xs6>
                        <v-text-field label="New Phone Label" v-model="newContact.label" hide-details></v-text-field>
                      </v-flex>
                    </v-layout>
                  </v-list-tile-content>
                  <v-list-tile-action>
                    <v-btn icon small class="right" @click.stop="createContact({ id: 0, type: 'phone', label: newContact.label, value: newContact.value })">
                      <v-icon small>save</v-icon>
                    </v-btn>
                  </v-list-tile-action>
                </v-list-tile>
              </v-slide-y-transition>
            </template>

            <template v-if="!noEmail">
              <v-list-group v-if="emailAddresses.length" v-model="emailExpanded" prepend-icon="contact_mail" :append-icon="emailExpanded || emailAddresses.length === 1 ? $vuetify.icons.expand : ('looks_' + (emailAddresses.length === 2 ? 'two' : emailAddresses.length ))" :class="{ 'elevation-2':emailExpanded }" lazy>
                <v-list-tile v-if="primaryEmailAddress" slot="activator" :color="emailExpanded ? 'red' : ''">
                  <v-list-tile-content v-if="emailExpanded">
                    <div>Email Addresses</div>
                  </v-list-tile-content>
                  <v-list-tile-content v-else>
                    <v-list-tile-sub-title v-if="primaryEmailAddress.label">
                      <span class="caption grey--text text--lighten-2">{{ primaryEmailAddress.label }}</span>
                    </v-list-tile-sub-title>
                    <v-list-tile-title class="d-block">
                      {{ primaryEmailAddress.value }}

                    </v-list-tile-title>
                    <span class="v-btn v-btn--icon v-btn--tiny theme--light primary ma-0" style="position: absolute; right: 0;">
                      <a :href="'mailto:' + primaryEmailAddress.value" class="v-btn__content white--text" @click.passive.stop>
                        <v-icon small>mail</v-icon>
                      </a>
                    </span>
                  </v-list-tile-content>

                </v-list-tile>

                <template v-for="(emailAddress, index) of emailAddresses">
                  <v-list-tile :key="'view:' + emailAddress.id" :class="{ 'editing': editingEmail && editingId === emailAddress.id }">
                    <v-list-tile-action v-if="index === 0">
                      <v-icon color="accent">stars</v-icon>
                    </v-list-tile-action>

                    <v-list-tile-action v-else>
                      <v-hover>
                        <v-btn dark icon slot-scope="{ hover }" @click.stop="makeContactPrimary(emailAddress)">
                          <v-icon :color="hover ? 'accent' : 'white'">stars</v-icon>
                        </v-btn>
                      </v-hover>
                    </v-list-tile-action>

                    <v-list-tile-content>
                      <v-list-tile-sub-title v-if="emailAddress.label">
                        <span class="caption grey--text text--lighten-2">{{ emailAddress.label }}</span>
                      </v-list-tile-sub-title>
                      <v-list-tile-title class="d-block">
                        <a :href="'mailto:' + emailAddress.value" class="font-weight-regular" @click.passive.stop>{{ emailAddress.value }}</a>
                      </v-list-tile-title>
                    </v-list-tile-content>
                    <v-list-tile-action>
                      <v-hover>
                        <v-btn dark small icon slot-scope="{ hover }" @click.stop="editContact(emailAddress, index)">
                          <v-icon small :color="hover ? 'accent' : 'white'">{{ (editingEmail && editingId === emailAddress.id) ? 'edit_off' : 'edit' }}</v-icon>
                        </v-btn>
                      </v-hover>
                    </v-list-tile-action>
                  </v-list-tile>

                  <v-slide-y-transition :key="'edit:' + emailAddress.id">
                    <v-list-tile  v-show="editingEmail && editingId === emailAddress.id" class="editing popout">
                      <v-list-tile-content>
                        <v-layout row ma-0 pa-0 style="width: 100%;">
                          <v-flex xs6>
                            <v-text-field label="Number" v-model="emailAddress.value" @input="handleContactValueInput(emailAddress, $event)" hide-details ref="emailValueTextFields"></v-text-field>
                          </v-flex>
                          <v-flex xs6>
                            <v-text-field label="Label" v-model="emailAddress.label" @input="handleContactLabelInput(emailAddress, $event)" hide-details></v-text-field>
                          </v-flex>
                        </v-layout>
                      </v-list-tile-content>
                      <v-list-tile-action>
                        <v-btn icon small class="right" @click.stop="deleteContact(emailAddress)">
                          <v-icon small>delete</v-icon>
                        </v-btn>
                      </v-list-tile-action>
                    </v-list-tile>
                  </v-slide-y-transition>
                </template>
                <v-list-tile v-if="editingType === ''" @click="addContact('email')">
                  <v-list-tile-action><v-icon color="green">add</v-icon></v-list-tile-action>
                  <v-list-tile-content>
                    <v-list-tile-sub-title>add new email address</v-list-tile-sub-title>
                  </v-list-tile-content>
                </v-list-tile>
              </v-list-group>

              <v-list-tile v-else-if="editingType === ''" @click="addContact('email')">
                <v-list-tile-action><v-icon color="green">add</v-icon></v-list-tile-action>
                <v-list-tile-content>
                  <v-list-tile-sub-title>add email address</v-list-tile-sub-title>
                </v-list-tile-content>
              </v-list-tile>
            </template>

            <v-slide-y-transition>
              <v-list-tile v-show="editingId === 0 && editingType === 'email'" class="editing">
                <v-list-tile-action>
                  <v-icon>mail</v-icon>
                </v-list-tile-action>

                <v-list-tile-content>
                  <v-list-tile-sub-title v-if="newContact.label">
                    <span class="caption grey--text text--lighten-2">{{ newContact.label }}</span>
                  </v-list-tile-sub-title>
                  <v-list-tile-title class="d-block" v-if="newContact.value">
                    {{ newContact.value }}
                  </v-list-tile-title>
                  <v-list-tile-title class="d-block" v-else>
                    New Email Address
                  </v-list-tile-title>
                </v-list-tile-content>
                <v-list-tile-action>
                  <v-hover>
                    <v-btn dark small icon slot-scope="{ hover }" @click.stop="editingType = ''">
                      <v-icon small :color="hover ? 'accent' : 'white'">edit_off</v-icon>
                    </v-btn>
                  </v-hover>
                </v-list-tile-action>
              </v-list-tile>
            </v-slide-y-transition>

            <v-slide-y-transition>
                <v-list-tile  v-show="editingId === 0 && editingType === 'email'" class="editing popout">
                  <v-list-tile-content>
                    <v-layout row ma-0 pa-0 style="width: 100%;">
                      <v-flex xs6>
                        <v-text-field label="New Email Address" v-model="newContact.value" @keyup.enter="createContact({ id: 0, type: 'email', label: newContact.label, value: newContact.value })" hide-details ref="newEmailValueTextField"></v-text-field>
                      </v-flex>
                      <v-flex xs6>
                        <v-text-field label="New Email Label" v-model="newContact.label" hide-details></v-text-field>
                      </v-flex>
                    </v-layout>
                  </v-list-tile-content>
                  <v-list-tile-action>
                    <v-btn icon small class="right" @click.stop="createContact({ id: 0, type: 'email', label: newContact.label, value: newContact.value })">
                      <v-icon small>save</v-icon>
                    </v-btn>
                  </v-list-tile-action>
                </v-list-tile>
              </v-slide-y-transition>
          </template>

        </v-list>

        <v-list v-if="!noAddresses" :three-line="addresses.length > 0" class="addresses py-0 striped">
          <v-list-group v-if="addresses.length" v-model="addressExpanded" prepend-icon="home_work" :append-icon="addressExpanded || addresses.length === 1 ? $vuetify.icons.expand : ('looks_' + (addresses.length === 2 ? 'two' : addresses.length ))" :class="{ 'elevation-2':addressExpanded }" lazy>

            <v-list-tile v-if="primaryAddress" slot="activator" :color="addressExpanded ? 'red' : ''">

              <v-list-tile-content v-if="addressExpanded">
                <div>Addresses</div>
              </v-list-tile-content>

              <v-list-tile-content v-else>
                <v-list-tile-title>{{ primaryAddress.street }}</v-list-tile-title>
                <v-list-tile-sub-title class="text--primary d-block">
                  {{ primaryAddress.city }}, {{ primaryAddress.region | regionToAlphaCode }}
                  <span v-if="isNotEmptyAddress(primaryAddress)" class="v-btn v-btn--icon v-btn--tiny theme--light primary right ma-0">
                    <a :href="buildMapsURL(primaryAddress)" class="v-btn__content white--text" @click.passive.stop target="_blank" rel="noreferrer">
                      <v-icon small>place</v-icon>
                    </a>
                  </span><br>
                  {{ primaryAddress.code | postal }}
                </v-list-tile-sub-title>
              </v-list-tile-content>

            </v-list-tile>

            <v-list-tile v-for="(address, index) of addresses" :key="index" @click="editAddress(address)">

              <v-list-tile-action v-if="index === 0">
                <v-icon color="accent">stars</v-icon>
              </v-list-tile-action>

              <v-list-tile-action v-else>
                <v-hover>
                  <v-btn dark icon slot-scope="{ hover }" @click.stop="makeAddressPrimary(address)">
                    <v-icon :color="hover ? 'accent' : 'white'">stars</v-icon>
                  </v-btn>
                </v-hover>
              </v-list-tile-action>

              <v-list-tile-content>
                <v-list-tile-title>{{ address.street }}</v-list-tile-title>
                <v-list-tile-sub-title class="text--primary d-block">
                  {{ address.city }}, {{ address.region | regionToAlphaCode }}
                  <span class="right caption grey--text text--lighten-2">{{ address.label }}</span><br>
                  {{ address.code }}
                  <a
                    v-if="isNotEmptyAddress(address)" :href="buildMapsURL(address)"
                    class="right caption" @click.passive.stop target="_blank" rel="noreferrer"
                  >map</a>
                </v-list-tile-sub-title>
              </v-list-tile-content>
            </v-list-tile>

            <v-list-tile @click="editAddress()">
              <v-list-tile-action><v-icon color="green">add</v-icon></v-list-tile-action>
              <v-list-tile-content>
                <v-list-tile-sub-title>add new address</v-list-tile-sub-title>
              </v-list-tile-content>
            </v-list-tile>
          </v-list-group>

          <v-list-tile v-else @click="editAddress()">
            <v-list-tile-action><v-icon color="green">add</v-icon></v-list-tile-action>
            <v-list-tile-content>
              <v-list-tile-sub-title>add address</v-list-tile-sub-title>
            </v-list-tile-content>
          </v-list-tile>

        </v-list>

        <slot name="postContent"></slot>

      </section>
    </v-slide-y-transition>

    <slot name="bottom"></slot>

    <edit-address
      :show="editingType === 'address'" :address="address"
      @show="handleEditShow" @save="handleAddressSave" @remove="handleAddressDelete">
    </edit-address>

    <edit-contact
      v-if="false"
      :show="editingType === 'email' || editingType === 'phone'" :contact="contact"
      @show="handleEditShow" @save="handleContactSave" @remove="handleContactDelete">
    </edit-contact>
  </v-card>
</template>

<script>
/* eslint-disable camelcase */
/* eslint-disable no-fallthrough */
/* eslint-disable handle-callback-err */

import { debounce } from 'lodash'
import { clone, cloneExcept, getNestedValue, getUTCTimestamp } from '@/utils'
import { GRADIENTS } from '@/constants'
import { ADDRESS, CONTACT } from '@/graphql/models'

import EditAddress from '@/components/EditAddress.vue'
import EditContact from '@/components/EditContact.vue'

export default {
  name: 'manage-entity',
  components: { EditAddress, EditContact },
  data () {
    return {
      editingId: 0,
      editingType: '',
      emailExpanded: false,
      addressExpanded: false,
      phoneExpanded: false,
      local: {},
      dirty: false,
      showDetails: false,
      newAddress: { id: 0, value: '', label: '' },
      newContact: { id: 0, type: '', value: '', label: '' }
    }
  },
  props: {
    entity: {},
    loading: { type: Boolean, required: false, default: false },
    title: { type: String, required: false, default: '' },
    icon: { type: String, required: false, default: 'dashboard' },
    back: { required: false, default: '' },
    backText: { required: false, default: '' },
    noAddresses: { type: Boolean, required: false, default: false },
    noContacts: { type: Boolean, required: false, default: false },
    noEmail: { type: Boolean, required: false, default: false },
    noPhone: { type: Boolean, required: false, default: false },
    path: { type: String, required: false, default: '' },
    query: {},
    color: { type: String, required: false, default: 'gradient' },
    altColor: { type: String, required: false, default: '' },
    details: { type: Boolean, required: false, default: false },
    detailsCount: { type: Number, required: false, default: -1 }
  },
  computed: {
    backgroundGradient () {
      if (this.color === 'gradient') {
        const id = (this.entity && this.entity.id) ? this.entity.id : 1
        return { background: GRADIENTS[id % GRADIENTS.length] }
      } else {
        return {}
      }
    },
    colors () { return (this.color === 'gradient' || this.color.trim() === '') ? [] : this.color.trim().split(' ') },
    altColors () { return this.altColor.trim() === '' ? this.colors : this.altColor.trim().split(' ') },
    colorClasses () {
      const classes = {}
      for (const color of this.colors) {
        classes[color] = true
      }
      return classes
    },
    detailsColor () {
      if (this.altColors.length) {
        return `${this.altColors[0]} lighten-4`
      } else if (this.colors.length) {
        return `${this.colors[0]} lighten-4`
      } else {
        return ''
      }
    },
    detailsTextColor () {
      const colors = this.altColors.length ? this.altColors : this.colors
      let color = ''
      switch (colors.length) {
        case 2: color = `text--${colors[1]}`
        case 1: color = `${colors[0]}--text ${color}`.trim()
      }
      return color
    },
    detailsTextColorClasses () {
      const classes = {}
      for (const color of this.detailsTextColor.split(' ')) {
        classes[color] = true
      }
      return classes
    },
    addresses () { return typeof this.entity.addresses !== 'undefined' ? this.entity.addresses : [] },
    contacts () { return typeof this.entity.contacts !== 'undefined' ? this.entity.contacts : [] },
    addressCount () { return this.addresses?.length ? this.addresses.length : 0 },
    contactCount () { return this.contacts?.length ? this.contacts.length : 0 },
    detailsTotal () { return this.detailsCount === -1 ? this.addressCount + this.contactCount : this.detailsCount },
    phoneNumbers () { return this.contacts.filter(this.isPhoneNumber) },
    emailAddresses () { return this.contacts.filter(this.isEmailAddress) },
    primaryAddress () { return this.addresses.length ? this.addresses[0] : null },
    primaryPhoneNumber () { return this.phoneNumbers.length ? this.phoneNumbers[0] : null },
    primaryEmailAddress () { return this.emailAddresses.length ? this.emailAddresses[0] : null },
    alternateAddresses () { return this.addresses.length > 1 ? this.addresses.slice(1) : [] },
    alternatePhoneNumbers () { return this.phoneNumbers.length > 1 ? this.phoneNumbers.slice(1) : [] },
    alternateEmailAddresses () { return this.emailAddresses.length > 1 ? this.emailAddresses.slice(1) : [] },
    editingAddress () { return this.editingId !== '0' && this.editingType === 'address' },
    editingEmail () { return this.editingId !== '0' && this.editingType === 'email' },
    editingPhone () { return this.editingId !== '0' && this.editingType === 'phone' },
    address () {
      let address
      if (this.editingId !== '0' && this.editingType === 'address') {
        address = this.addresses?.find(address => address.id === this.editingId)
      }

      if (typeof address === 'undefined') {
        address = this.newAddress
      }
      return address
    },
    contact () {
      let contact
      if (this.editingId !== '0' && (this.editingType === 'email' || this.editingType === 'phone')) {
        contact = this.contacts?.find(contact => contact.id === this.editingId)
      }
      if (typeof contact === 'undefined') {
        contact = CONTACT.blank
        contact.type = this.editingType
      }
      return contact
    },
    hasName () { return typeof this.local.name === 'string' },
    hasTitle () { return typeof this.local.title === 'string' },
    name () { return typeof this.local.name === 'string' ? this.local.name : (typeof this.local.title === 'string' ? this.local.title : '') },
    originalName () { return typeof this.entity.name === 'string' ? this.entity.name : (typeof this.entity.title === 'string' ? this.entity.title : '') },
    isNameChanged () { return this.name !== this.originalName }
  },
  methods: {
    goBack () {
      if (this.$route.name === 'crm') {
        const active = typeof this.$route.query.a !== 'undefined' ? parseInt(this.$route.query.a, 10) : 0
        if (active) {
          this.$router.replace({ path: this.$route.path, query: { a: active - 1 } }).catch(err => {})
        }
      } else {
        const location = (typeof this.back === 'string') ? { path: this.back } : this.back
        if (location.path === '') {
          location.path = '/'
        }
        this.$router.push(location).catch(err => {})
      }
    },

    isPhoneNumber (contact) {
      return contact && typeof contact.type === 'string' && contact.type === 'phone'
    },

    isEmailAddress (contact) {
      return contact && typeof contact.type === 'string' && contact.type === 'email'
    },

    reset () { this.local = clone(this.entity) },
    save () { this.$emit('save', this.local) },

    editAddress (address = null, index = 0) {
      if (!address) {
        address = { ...ADDRESS.blank, ...address }
        this.newAddress = address
      }
      this.editingId = address.id
      this.editingType = 'address'
      if (index) {
        this.$nextTick(() => {
          if (this.$refs.addressValueTextFields && this.$refs.addressValueTextFields[index]) {
            this.$refs.addressValueTextFields[index].focus()
          }
        })
      }
    },

    addContact (type) {
      this.newContact.label = ''
      this.newContact.value = ''
      this.editingId = 0
      this.editingType = type
      const refsProperty = (type === 'email' ? 'newEmailValue' : 'newPhoneValue') + 'TextField'
      this.$nextTick(() => {
        if (this.$refs[refsProperty]) {
          this.$refs[refsProperty].focus()
        }
      })
    },

    editContact (contact = null, index = 0) {
      if (typeof contact === 'object' && contact !== null) {
        if (!contact.id) {
          contact = { ...CONTACT.blank, ...contact }
        }
      } else {
        contact = CONTACT.blank
      }

      if (contact.type !== 'email' && contact.type !== 'phone') {
        contact.type = 'phone'
      }

      if (this.editingId === contact.id && this.editingType === contact.type) {
        this.editingId = '0'
        this.editingType = ''
      } else {
        this.editingId = contact.id
        this.editingType = contact.type
        if (index) {
          const refsProperty = (contact.type === 'email' ? 'emailValue' : 'phoneValue') + 'TextFields'
          this.$nextTick(() => {
            if (this.$refs[refsProperty] && this.$refs[refsProperty][index]) {
              this.$refs[refsProperty][index].focus()
            }
          })
        }
      }
    },

    handleContactValueInput: debounce(function (contact, e) { this.handleContactSave(contact) }, 1111),
    handleContactLabelInput: debounce(function (contact, e) { this.handleContactSave(contact) }, 1111),

    handleEditShow (value) {
      if (!value) {
        this.editingType = ''
        this.editingId = '0'
      }
    },

    async handleAddressSave (address) {
      if (address.id) {
        await this.updateAddress(address)
      } else {
        await this.createAddress(address)
        this.newAddress = ADDRESS.blank
      }
    },

    async handleAddressDelete (address) {
      await this.deleteAddress(address)
    },

    async handleContactSave (contact) {
      if (contact.id) {
        await this.updateContact(contact)
      } else {
        await this.createContact(contact)
      }
    },
    async handleContactDelete (contact) {
      await this.deleteContact(contact)
    },

    async createAddress (address) {
      const timestamp = getUTCTimestamp()
      const partialAddress = cloneExcept(address, ['id', '__typename', 'created_at', 'updated_at'])

      partialAddress.order = this.addresses.length
      partialAddress.addressable_id = this.entity.id
      partialAddress.addressable_type = `App\\${this.entity.type}`

      await this.$apollo.mutate({
        mutation: ADDRESS.mutations.create.mutation,
        variables: { address: partialAddress },
        update: (store, { data: { createAddress } }) => {
          const data = store.readQuery(this.query)
          const entity = getNestedValue(data, this.path)
          entity.addresses.push(clone(createAddress))
          store.writeQuery({ ...this.query, data })
        },
        optimisticResponse: {
          __typename: 'Mutation',
          createAddress: {
            __typename: 'Address', id: -1, ...partialAddress, created_at: timestamp, updated_at: timestamp
          }
        }
      })
    },

    async updateAddress (address) {
      const partialAddress = cloneExcept(address, ['__typename', 'created_at', 'updated_at'])
      await this.$apollo.mutate({
        mutation: ADDRESS.mutations.update.mutation,
        variables: { address: partialAddress }
      }).then(() => {
        this.$alert({ color: 'deep-orange lighten-1', text: 'Address saved' })
      })
    },

    async deleteAddress (address) {
      await this.$apollo.mutate({
        mutation: ADDRESS.mutations.delete.mutation,
        variables: { id: address.id },
        update: (store, { data: { deleteAddress } }) => {
          const data = store.readQuery(this.query)
          const id = parseInt(deleteAddress.id, 10)
          const entity = getNestedValue(data, this.path)
          const index = entity.addresses.findIndex(addr => parseInt(addr.id, 10) === id)
          entity.addresses.splice(index, 1)
          store.writeQuery({ ...this.query, data })
        },
        optimisticResponse: {
          __typename: 'Mutation',
          deleteAddress: { __typename: 'Address', ...address }
        }
      })
    },

    async makeAddressPrimary (address) {
      let order = 1
      const data = this.$apollo.getClient().cache.readQuery(this.query)
      const entity = getNestedValue(data, this.path)
      for (const addr of entity.addresses) {
        addr.order = addr.id === address.id ? 0 : order++
        await this.updateAddress(addr)
      }
      entity.addresses.sort((a, b) => a.order - b.order)
      this.$apollo.getClient().cache.writeQuery({ ...this.query, data })
    },

    async createContact (contact) {
      // const timestamp = getUTCTimestamp()
      const partialContact = cloneExcept(contact, ['id', '__typename', 'created_at', 'updated_at'])

      partialContact.order = this.contacts.length
      partialContact.contactable_id = this.entity.id
      partialContact.contactable_type = `App\\${this.entity.type}`

      await this.$apollo.mutate({
        mutation: CONTACT.mutations.create.mutation,
        variables: { contact: partialContact },
        update: (store, { data: { createContact } }) => {
          const data = store.readQuery(this.query)
          const entity = getNestedValue(data, this.path)
          entity.contacts.push(clone(createContact))
          store.writeQuery({ ...this.query, data })
        }
      }).then(() => {
        this.newContact.label = ''
        this.newContact.value = ''
        this.editingId = 0
        this.editingType = ''
      })
    },

    async updateContact (contact) {
      const partialContact = cloneExcept(contact, ['__typename', 'created_at', 'updated_at'])
      await this.$apollo.mutate({
        mutation: CONTACT.mutations.update.mutation,
        variables: { contact: partialContact }
      }).then(() => {
        this.$alert({ color: 'deep-orange lighten-1', text: 'Contact saved' })
      })
    },

    async deleteContact (contact) {
      await this.$apollo.mutate({
        mutation: CONTACT.mutations.delete.mutation,
        variables: { id: contact.id },
        update: (store, { data: { deleteContact } }) => {
          const data = store.readQuery(this.query)
          const id = parseInt(deleteContact.id, 10)
          const entity = getNestedValue(data, this.path)
          const index = entity.contacts.findIndex(cont => parseInt(cont.id, 10) === id)
          entity.contacts.splice(index, 1)
          store.writeQuery({ ...this.query, data })
        },
        optimisticResponse: {
          __typename: 'Mutation', deleteContact: { __typename: 'Contact', ...contact }
        }
      })
    },
    async makeContactPrimary (contact) {
      let order = 1
      const data = this.$apollo.getClient().cache.readQuery(this.query)
      const entity = getNestedValue(data, this.path)
      for (const cont of entity.contacts) {
        cont.order = cont.id === contact.id ? 0 : order++
        await this.updateContact(cont)
      }
      entity.contacts.sort((a, b) => a.order - b.order)
      this.$apollo.getClient().cache.writeQuery({ ...this.query, data })
    },
    isNotEmptyAddress (address) {
      return `${address.street + address.city + address.region + address.code}`.trim() !== ''
    },
    buildMapsURL (address) {
      if (typeof address === 'object' && address !== null) {
        let url = 'https://www.google.com/maps/dir/?api=1&travelmode=driving&destination='
        for (const key of ['street', 'city', 'region', 'code']) {
          if (typeof address[key] === 'string' && address[key].trim() !== '') {
            url += address[key].trim().replace(/ /g, '+') + ',+'
          }
        }
        if (url.endsWith(',+')) {
          url = url.substring(0, url.length - 2)
        }
        return url
      } else {
        return ''
      }
    }
  },
  watch: {
    details (details) {
      if (details !== this.showDetails) {
        this.showDetails = details
      }
    },
    entity (changed, old) {
      this.local = clone(changed)
    },
    showDetails (showDetails) {
      if (showDetails !== this.details) {
        this.$emit('update:details', showDetails)
      }
    }
  },
  mounted () {
    this.local = clone(this.entity)
    this.showDetails = this.details
    return true
  }
}
</script>

<style lang="scss">
.manage-entity {
  .loading-overlay {
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0,0,0,0.25);
    z-index: 10;
    cursor: wait;

    .v-progress-circular--indeterminate .v-progress-circular__overlay {
      stroke-linecap: butt;
    }

    h2 > .v-progress-circular {
      transform: scaleX(0.8);
    }
  }

  .editing > * {
    // transform: scale(1.02);
    background-color: #134960;
    // transform-origin: bottom;
    border: 1px solid rgba(255,255,255, 0.3);
    border-bottom: none;
  }

  .editing.popout > div {
    // transform-origin: top;
    border-top: none;
    border-bottom: 1px solid rgba(255,255,255, 0.3);
  }

  .editing > a:hover {
    background-color: #164c63 !important;
  }

  .addresses,
  .contacts {
    div[role="listitem"] > a,
    a.v-btn__content {
      text-decoration: none !important;
    }
    .v-list__group__header {
      .v-list__tile {
        padding: 0 8px;
      }
      &.v-list__group__header--active {
        background-color: rgba(0,0,0,.04);
        .v-list__tile {
          // color: #ff5722;
          font-weight: 500;
          // height: 72px;
        }
      }
    }
    .v-list__group__items {
      .v-list__tile {
        .v-list__tile__action {
          min-width: 48px;
        }
      }
    }
  }
  .addresses {
    // .v-list__group__header {
    //   .v-list__tile {

    //   }
    // }
    .v-list__group__items {
      [role="listitem"]:last-child > .v-list__tile {
        height: 56px;
      }
    }
  }
  .back-button {
    min-width: 0;
    padding: 0 6px;
    text-transform: none;
    .v-btn__content {
      margin: 0;
    }
  }
}
</style>
