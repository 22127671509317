<template>
  <v-bottom-sheet lazy v-model="_show">
    <v-card>
      <v-card-title class="justify-space-between">
        <span class="title">Edit Address</span>
        <span>
          <v-btn small icon @click.stop="cancel">
            <v-icon small>close</v-icon>
          </v-btn>
        </span>
      </v-card-title>

      <v-form v-if="model">
        <v-container grid-md pt-0>
          <v-layout row wrap>
            <v-flex xs12 sm6 md4>
              <v-textarea label="Street" v-model="model.street" auto-grow rows="1" ref="streetInput"></v-textarea>
            </v-flex>
            <v-flex xs7 md4>
              <v-text-field label="City" v-model="model.city"></v-text-field>
            </v-flex>
            <v-flex xs5 md4>
              <v-text-field label="Province" v-model="model.region"></v-text-field>
            </v-flex>
            <v-flex xs7 md4>
              <v-text-field label="Country" v-model="model.country"></v-text-field>
            </v-flex>
            <v-flex xs5 md4>
              <v-text-field label="Postal" v-model="model.code" mask="A#A #A#" return-masked-value></v-text-field>
            </v-flex>
            <v-flex xs12 sm6 md4>
              <v-text-field label="Label" v-model="model.label"></v-text-field>
            </v-flex>
          </v-layout>
          <v-layout row justify-space-between mx-0>
            <v-btn small color="success" @click.stop="save" class="ml-0" style="min-width: 80px;">Save</v-btn>
            <v-btn small color="" @click.stop="cancel" style="min-width: 80px;">Cancel</v-btn>
            <v-spacer class="hidden-xs-only"></v-spacer>
            <confirm-action v-if="model.id && admin" @confirm="remove" action-text="Delete" confirm-text="Are you sure you want to delete this address?">
              <template v-slot:act="{ on }">
                <v-btn small dark color="primary" v-on="on" class="mr-0" style="min-width: 80px;">delete</v-btn>
              </template>
            </confirm-action>
          </v-layout>
        </v-container>
      </v-form>

    </v-card>

  </v-bottom-sheet>
</template>

<script>
/* eslint-disable camelcase */
/* eslint-disable handle-callback-err */

import { clone } from '@/utils'
import ConfirmAction from './ConfirmAction.vue'
import { ADDRESS } from '@/graphql/models'

export default {
  name: 'edit-address',
  components: { ConfirmAction },
  props: {
    address: {},
    show: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      model: ADDRESS.blank
    }
  },
  computed: {
    me () { return this.$oxide.store.apollo.user },
    admin () { return !!this.me?.is_admin },
    _show: {
      get () {
        return this.show
      },
      set (value) {
        if (!value) {
          this.cancel()
        }
      }
    }
  },
  methods: {
    reset () {
      this.model = clone(this.address)
    },
    cancel () {
      this.reset()
      this.close()
    },
    close () {
      this.$emit('show', false)
    },
    save () {
      this.$emit('save', this.model)
      this.close()
    },
    remove () {
      this.$emit('remove', this.model)
      this.close()
    }
  },
  watch: {
    address: {
      handler (newModel, oldModel) {
        this.model = clone(newModel)
      },
      deep: true
    },
    show (val) {
      if (val) {
        this.$nextTick(() => {
          this.$refs.streetInput.focus()
        })
      }
    }
  }
}
</script>
