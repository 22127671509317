import { clone, defineModel, store, recordQueryMeta } from '@/utils'
import { BILLABLES_QUERY } from '../queries'
import { BILLABLE_CREATE, BILLABLE_UPDATE, BILLABLE_DELETE } from '../mutations'
import { NetworkStatus } from 'apollo-client'

const BILLABLE = defineModel({
  name: 'Billable',
  fields: { created_at: '', updated_at: '', name: '', size: '', tags: [], year: '', cost: 0, unit: '', supplier: '', supplier_name: '', supplier_desc: '' },
  queries: {
    index: {
      query: BILLABLES_QUERY,
      update ({ billables }) {
        if (Array.isArray(billables)) {
          const b = billables.map(billable => {
            if (typeof billable.tags === 'string') {
              try {
                billable.tags = JSON.parse(billable.tags)
              } catch (e) {
                console.error(e, 'billable.tags', billable.tags)
                billable.tags = [billable.tags]
              }
            } else if (!Array.isArray(billable.tags)) {
              billable.tags = []
            }
            return billable
          })
          store(b, 'billables', 'apollo')
          return b
        } else {
          return billables
        }
      },
      result ({ data, loading, networkStatus }) {
        if (!loading && networkStatus === NetworkStatus.ready) {
          recordQueryMeta('billables', data.billables)
        }
      }
    }
  },
  mutations: {
    create: {
      mutation: BILLABLE_CREATE,
      update: (store, { data: { createBillable } }) => {
        const data = store.readQuery({ query: BILLABLES_QUERY })
        const tempBillable = clone(createBillable)
        data.billables.push(tempBillable)
        store.writeQuery({ query: BILLABLES_QUERY, data })
      }
    },
    update: {
      mutation: BILLABLE_UPDATE,
      update: (store, { data: { updateBillable } }) => {
        const data = store.readQuery({ query: BILLABLES_QUERY })
        const tempBillable = clone(updateBillable)
        const id = parseInt(tempBillable.id, 10)
        const index = data.billables.findIndex(billable => parseInt(billable.id, 10) === id)
        data.billables[index] = tempBillable
        store.writeQuery({ query: BILLABLES_QUERY, data })
      }
    },
    delete: {
      mutation: BILLABLE_DELETE,
      update: (store, { data: { deleteBillable } }) => {
        const data = store.readQuery({ query: BILLABLES_QUERY })
        const id = parseInt(deleteBillable.id, 10)
        const index = data.billables.findIndex(billable => parseInt(billable.id, 10) === id)
        data.billables.splice(index, 1)
        store.writeQuery({ query: BILLABLES_QUERY, data })
      }
    }
  }
})

export default BILLABLE
