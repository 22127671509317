<template>
  <v-responsive class="list-header" :style="backgroundGradient" :class="[colorClasses, { 'pb-4': !(noTitle && noSearch) }]">
    <v-card-title
      class="pb-0"
      :class="{ 'pt-0': (noTitle && noSearch), 'pt-4': !(noTitle && noSearch), 'mb-2': !(noTitle && noSearch) }"
    >
      <span v-if="!noTitle" class="title">{{ title }}</span>
      <v-spacer v-if="!(noTitle && noSearch)"></v-spacer>
      <v-text-field
        v-if="!noSearch"
        :readonly="saving" v-model="searchQuery"
        append-icon="search" :label="searchPlaceholder" class="pt-0 mt-0" color="white"
        hide-details expand
        @input="handleSearchInput"
        @keyup.enter="handleSearchEnter"
      ></v-text-field>

      <slot name="toggle"></slot>

      <v-btn fab absolute bottom right small @click="handleAdd" :color="addColor" :disabled="saving" class="add-button" :style="{ bottom: (noTitle && noSearch) ? '-30px' : '-50px' }">
        <v-icon>{{ addButtonIcon }}</v-icon>
        <v-progress-circular v-if="saving" indeterminate size="40" width="3" color="white" :style="{ top: (noTitle && noSearch) ? '-20px' : '0' }"></v-progress-circular>
      </v-btn>
    </v-card-title>

    <v-expand-transition>
      <div v-show="showFormLocal">
        <slot name="form" :colors="defaultColors">
          <v-text-field
            v-model="addText" :label="textboxLabel" :color="defaultColors.invalid"
            ref="addTextbox" class="mt-0 pt-1 pl-3 pr-4 mr-5" hide-details clearable
            @keyup.enter="handleAdd"
          ></v-text-field>
        </slot>
      </div>
    </v-expand-transition>
  </v-responsive>
</template>

<script>
/* eslint-disable no-fallthrough */
import { GRADIENT_COLORS } from '@/constants'
import { gradient } from '@/utils'

export default {
  name: 'list-header',

  props: {
    color: { type: String, required: false, default: 'gradient' },
    gradientSeed: { required: false, default: null },
    saving: { type: Boolean, required: true },
    textboxLabel: { type: String, required: false, default: '' },
    title: { type: String, required: false, default: '' },
    showForm: { type: Boolean, required: false, default: false },
    noTitle: { type: Boolean, required: false, default: false },
    noSearch: { type: Boolean, required: false, default: false },
    searchPlaceholder: { type: String, required: false, default: 'Search' },
    formValid: {}
  },

  data () {
    return { addText: '', searchQuery: '', showFormLocal: false }
  },

  computed: {
    backgroundGradient () {
      if (this.color === 'gradient') {
        return {
          background: gradient({ seed: this.gradientSeed, direction: 'to left' })
        }
      } else {
        return {}
      }
    },

    colorClasses () {
      const classes = {}
      if (this.color !== 'gradient' && this.color.trim() !== '') {
        const colors = this.color.split(' ')
        for (const color of colors) {
          classes[color] = true
        }
      }
      return classes
    },

    addState () {
      if (this.showFormLocal) {
        if (typeof this.formValid === 'boolean') {
          return this.formValid ? 'valid' : 'invalid'
        } else {
          return this.addText.trim() !== '' ? 'valid' : 'invalid'
        }
      } else {
        return 'closed'
      }
    },

    addColor () {
      switch (this.addState) {
        case 'valid': return this.defaultColors.valid
        case 'invalid': return this.defaultColors.invalid
        case 'closed':
          if (this.color === 'gradient') {
            return GRADIENT_COLORS[this.gradientSeed % GRADIENT_COLORS.length][0]
          }
        default: return this.color
      }
    },

    addButtonIcon () {
      switch (this.addState) {
        case 'valid': return 'done'
        case 'invalid': return 'close'
        case 'closed': return 'add'
        default: return ''
      }
    },

    defaultColors () {
      return {
        valid: this.color.includes('light-green') ? 'cyan darken-1' : 'light-green darken-2',
        invalid: this.color.includes('deep-orange') ? 'deep-orange lighten-2' : 'deep-orange darken-1'
      }
    }
  },
  methods: {
    handleSearchInput (query) {
      this.$emit('search', query)
    },

    handleSearchEnter () {
      this.$emit('search:enter')
    },

    handleAdd () {
      switch (this.addState) {
        case 'valid':
          this.$emit('create', this.addText)
          break
        case 'invalid':
          this.showFormLocal = false
          break
        case 'closed':
          this.showFormLocal = true
          this.addText = this.searchQuery?.trim()
          setTimeout(() => {
            if (this.$refs.addTextbox) {
              this.$refs.addTextbox.focus()
              this.$refs.addTextbox.$refs.input.setSelectionRange(0, 99)
            }
          }, 500)
      }
    }
  },
  watch: {
    saving (val) {
      if (!val) {
        this.addText = ''
        this.showForm = false
      }
    },
    showForm (value) {
      if (value !== this.showFormLocal) {
        this.showFormLocal = value
      }
    },
    showFormLocal (value) {
      if (value !== this.showForm) {
        this.$emit('showFormChanged', value)
      }
    }
  },
  created () {
    this.showFormLocal = this.showForm
  }
}
</script>

<style lang="scss">
.list-header {
  overflow: visible;
  .add-button {
    // bottom: -50px !important;
    .v-progress-circular {
      opacity: 0.9 !important;
    }
  }
  .v-card__title {
    position: relative;
  }

}
</style>
