import { defineModel } from '@/utils'
import { ENTRY_QUERY } from '../queries'
import { ENTRY_CREATE, ENTRY_DELETE, ENTRY_UPDATE } from '../mutations'

const ENTRY = defineModel({
  name: 'Entry',
  fields: { id: 0, data: null, order: 0, value_1: '', value_2: '', value_3: '' },
  queries: {
    show: {
      query: ENTRY_QUERY,
      update ({ entry }) {
        if (entry) {
          if (typeof entry.data === 'string') {
            let data = null
            try {
              data = JSON.parse(entry.data)
            } catch (e) {
              console.log(e)
            } finally {
              entry.data = data
            }
          }
        }
        return entry
      }
    }
  },
  mutations: {
    create: { mutation: ENTRY_CREATE },
    update: { mutation: ENTRY_UPDATE },
    delete: { mutation: ENTRY_DELETE }
  }
})

export default ENTRY
