class Version {
  static compare (a, b) {
    let result = a.major - b.major
    if (result !== 0) {
      return result
    }
    result = a.minor - b.minor
    if (result !== 0) {
      return result
    }
    return a.patch - b.patch
  }

  constructor (str) {
    this.major = 0
    this.minor = 0
    this.patch = 0

    if (typeof str === 'string') {
      const segments = str.toLowerCase().split('.')
      if (segments.length === 3) {
        this.major = parseInt(segments[0].trim(), 10)
        this.minor = parseInt(segments[1].trim(), 10)
        this.patch = parseInt(segments[2].trim(), 10)
      }
    }
  }

  toString () { return `${this.major}.${this.minor}.${this.patch}` }
  toValue () { return this.toString() }

  isHigherThan (that) {
    if (typeof that === 'string') {
      that = new Version(that)
    }
    if (that instanceof Version) {
      return Version.compare(this, that) > 0
    } else {
      return null
    }
  }

  isLowerThan (that) {
    if (typeof that === 'string') {
      that = new Version(that)
    }
    if (that instanceof Version) {
      return Version.compare(this, that) < 0
    } else {
      return null
    }
  }

  isEqualTo (that) {
    if (typeof that === 'string') {
      that = new Version(that)
    }
    if (that instanceof Version) {
      return Version.compare(this, that) === 0
    } else {
      return null
    }
  }
}

export default Version
