<template>
  <article id="config" v-if="typeof company !== 'undefined'">
    <h1 class="display-1 mb-4 text-xs-center">Config</h1>

    <v-card class="mb-4">
      <v-card-title class="title">Company</v-card-title>
      <v-card-text>
        <v-text-field
          :value="company.name"
          @input="handleNameInput"
          label="Company Name"
        ></v-text-field>
        <div class="subheading">Logo</div>
        <v-layout align-content-center class="px-2">
          <input type="file" accept="image/*" @change="handleLogoChange" class="upload__input" ref="logoInput" style="width: 50%; margin-top: 4px; padding-top: 12px;">
          <img v-if="uploadedImage" :src="uploadedImage" alt style="max-height: 50px;">
          <img v-else-if="logo" :src="logo" alt="logo" style="max-height: 50px;">
        </v-layout>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <!-- <v-btn color="amber darken-1" flat="flat">Cancel</v-btn> -->
        <v-btn color="teal lighten-1" flat="flat" @click="save">Save</v-btn>
      </v-card-actions>
    </v-card>

    <v-card  v-if="config.nomenclature" class="mb-4">
      <v-card-title class="title">Nomenclature</v-card-title>
      <v-layout row wrap class="px-3">
        <v-flex sm6>
          <v-text-field
            class="" style=""
            v-model="config.nomenclature.invoice"
            label="Invoice"
          ></v-text-field>
        </v-flex>
        <v-flex sm6>
          <v-text-field
            class="" style=""
            v-model="config.nomenclature.job_identifier"
            label="Job Identifier"
          ></v-text-field>
        </v-flex>
        <v-flex sm6>
          <v-text-field
            class="" style=""
            v-model="config.nomenclature.items"
            label="Items"
          ></v-text-field>
        </v-flex>
        <v-flex sm6>
          <v-text-field
            class="" style=""
            v-model="config.nomenclature.tags"
            label="Item Tags"
          ></v-text-field>
        </v-flex>
      </v-layout>

      <v-card-actions>
        <v-spacer></v-spacer>
        <!-- <v-btn color="amber darken-1" flat="flat">Cancel</v-btn> -->
        <v-btn color="teal lighten-1" flat="flat" @click="save">Save</v-btn>
      </v-card-actions>
    </v-card>

    <v-card class="mb-4">
      <v-card-title class="title">Job Types</v-card-title>
      <v-list>
        <template v-for="(type, key) of jobTypes">
          <v-list-tile v-if="typeof type !== 'undefined' && !type.deleted" :key="key">
            <v-list-tile-content class="">
              <v-text-field
                label="name" hide-details class="" style="width: 100%;"
                v-model="type.name"
              >
                <template #prepend>
                  <span v-text="key + ':'"></span>
                </template>
                <template #append-outer>
                  <v-checkbox
                    label="fixed cost" hide-details class="py-0 my-0"
                    v-model="type.fixed_cost"
                  ></v-checkbox>
                </template>
              </v-text-field>
            </v-list-tile-content>
            <v-list-tile-action>
              <v-btn small icon @click="removeBillingType(key)"><v-icon small>remove</v-icon></v-btn>
            </v-list-tile-action>
          </v-list-tile>
        </template>
      </v-list>
      <v-card-actions>
        <v-btn color="amber darken-1" flat="flat" @click="addBillingType">Add Type</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="teal lighten-1" flat="flat" @click="save">Save</v-btn>
      </v-card-actions>
    </v-card>

    <v-card class="mb-4">
      <v-card-title class="title">Categories</v-card-title>
      <v-list>
        <template v-for="category of categories">
          <v-list-tile :key="category.id">
            <v-list-tile-content class="">
              <v-text-field
                label="name" hide-details class="" style="width: 100%;"
                :value="category.name"
                @blur="handleCategoryBlur(category, $event)"
              >
                <template #prepend>
                  <span v-text="category.id + ':'"></span>
                </template>
                <template #append-outer>
                  <small class="pr-1">Billables:</small>{{ totalBillablesInCategory(category) }}
                </template>
              </v-text-field>
            </v-list-tile-content>
            <v-list-tile-action>
              <v-btn
                :loading="savingCategories.includes(category.id)"
                :disabled="totalBillablesInCategory(category) !== 0"
                small icon
                @click="deleteCategory(category)"
              >
                <v-icon small>remove</v-icon>
              </v-btn>
            </v-list-tile-action>
          </v-list-tile>
        </template>
      </v-list>
      <v-card-actions>
        <v-btn v-if="newCategory === null" color="amber darken-1" flat="flat" @click="addCategory">Add Category</v-btn>
        <v-text-field
          v-else hide-details clearable
          label="name" v-model="newCategory" ref="newCategoryInput"
          @blur="handleNewCategoryBlur" @keypress.enter.prevent="createCategory"
        ></v-text-field>
        <v-spacer></v-spacer>
        <v-btn v-if="typeof newCategory === 'string'" :disabled="newCategory.trim() === ''" color="teal lighten-1" flat="flat" @click="createCategory">Create Category</v-btn>
      </v-card-actions>
    </v-card>

    <v-card>
      <v-card-title class="title">List Templates</v-card-title>

      <v-card-title>
        <v-select
          label="Select a template..."
          :items="listOptions" v-model="selectedListIndex">
        </v-select>
      </v-card-title>

      <v-list v-if="selectedListIndex !== null">
        <v-list-tile>
          <v-list-tile-content style="flex-direction: row; justify-content: space-around;">
            <v-text-field
              v-model="list.name"
              :label="(isManifest ? 'Manifest' : 'Checklist') + ' Template Name'" class="font-weight-bold" height="20px" ref="templateName"
              hide-details>
            </v-text-field>
            <v-switch
              hide-details
              v-model="isManifest"
              color="deep-purple lighten-2'" class="list-type-switch mt-0 pt-0 pr-4 align-self-center" style="flex-grow: 0;">
              <template #prepend>
                <v-icon :color="'deep-purple ' + (isChecklist ? 'lighten-2' : 'lighten-3')" @click="isManifest = false">check</v-icon>
              </template>
              <template #append>
                <v-icon :color="'deep-purple ' + (isManifest ? 'lighten-2' : 'lighten-3')" @click="isManifest = true">format_list_numbered_rtl</v-icon>
              </template>
            </v-switch>
          </v-list-tile-content>
          <v-list-tile-action>
            <v-list-tile-action-text>HEADING</v-list-tile-action-text>
          </v-list-tile-action>
        </v-list-tile>

        <draggable v-model="list.items" draggable=".item" handle=".v-avatar">
          <template v-for="(item, index) in list.items">
            <!-- <template v-if="typeof item !== 'undefined' && !(item.del && item.del.at)"> -->
            <template v-if="typeof item !== 'undefined'">
              <v-list-tile avatar :key="item.uuid" class="item">
                <v-list-tile-avatar :class="{ 'px-0': item.h }" :style="{ minWidth: (item.h ? '40px' : '56px') }" style="cursor: row-resize;">
                  <v-icon>drag_handle</v-icon>
                </v-list-tile-avatar>

                <v-list-tile-content>
                  <v-text-field
                    v-model="item.t" ref="itemTextInputs"
                    @keydown.backspace.capture="handleItemTextBackspace(item, index, $event)"
                    @keyup.enter.passive="add($event, index)"
                    class="mt-0 pt-0" :class="{'font-weight-bold': item.h}" style="width: 100%;"
                    hide-details single-line>
                    <template #append>
                      <v-switch v-model="item.h" class="mt-0 pt-0" hide-details single-line :ripple="false"></v-switch>
                    </template>
                  </v-text-field>
                </v-list-tile-content>
              </v-list-tile>
            </template>

          </template>
        </draggable>

        <v-list-tile>
          <v-list-tile-action>
            <v-icon :color="color + ' lighten-2'">add_box</v-icon>
          </v-list-tile-action>

          <v-list-tile-content>
            <v-text-field
              v-model="newItemText" @keydown.enter="add"
              label="new item" ref="newItemInput"
              class="mt-0 pt-0" style="width: 100%;" :color="color + ' lighten-2'"
              single-line hide-details
            >
            </v-text-field>
          </v-list-tile-content>
          <v-list-tile-action>
            <v-btn icon dark :color="color + ' lighten-1'" @click.stop="add">
              <v-icon>arrow_upward</v-icon>
            </v-btn>
          </v-list-tile-action>
        </v-list-tile>
      </v-list>
      <v-card-actions>
        <v-spacer></v-spacer>
        <!-- <v-btn color="amber darken-1" flat="flat">Cancel</v-btn> -->
        <v-btn :disabled="Array.isArray(list.items) && list.items.length < 1" color="teal lighten-1" flat="flat" @click="save">Save</v-btn>
      </v-card-actions>
    </v-card>

    <!-- <v-card>
      <v-card-title class="title">Quotable Services</v-card-title>

      <v-card-title>
        <v-select
          label="Select a template..."
          :items="listOptions" v-model="selectedListIndex"
          clearable>
        </v-select>
      </v-card-title>

    </v-card> -->

    <v-snackbar v-model="showSnackbar" :color="snackbarColor" top>
      {{ snackbarText }}
      <v-btn dark flat @click="showSnackbar = false">
        Close
      </v-btn>
    </v-snackbar>
  </article>
</template>

<script>
/* eslint-disable camelcase */
/* eslint-disable handle-callback-err */

// import ConfirmAction from '@/components/ConfirmAction.vue'
import { BASE_URL } from '@/constants'
import { v1 as uuidv1 } from 'uuid'
import { clone } from '@/utils'
import draggable from 'vuedraggable'
import { CATEGORY, COMPANY } from '@/graphql/models'

export default {
  name: 'config',
  components: { draggable },
  props: { company: {} },
  data () {
    return {
      selectedListIndex: null,
      list: {},
      newItemText: '',
      showSnackbar: false,
      snackbarText: '',
      snackbarColor: '',
      originalConfig: null,
      companyName: '',
      openJobType: null,
      uploadedImage: false,
      imageFile: false,
      newCategory: null,
      savingCategories: []
    }
  },
  computed: {
    config () { return this.company?.config ? this.company.config : { templates: { lists: [], services: [] } } },
    logo () { return this.company.config?.logo },
    templates () { return this.config?.templates ? this.config.templates : { lists: [], services: [] } },
    lists () { return this.templates?.lists ? this.templates.lists : [] },
    listOptions () {
      const options = this.lists.map((list, index) => {
        return { text: list.name, value: index }
      })
      options.push({ text: 'New List', value: -1 })
      return options
    },
    services () { return this.templates?.services ? this.templates.services : [] },
    // serviceOptions () {
    // },
    jobTypes () { return this.config?.job_billing_types },
    isManifest: {
      get () {
        return this.list.type === 'MANIFEST'
      },
      set (val) {
        this.list.type = val ? 'MANIFEST' : 'LIST'
      }
    },
    isChecklist () { return !this.isManifest },
    color () { return this.isManifest ? 'purple' : 'deep-purple' },
    billables () { return this.$oxide.store.apollo.billables },
    categories () { return this.$oxide.store.apollo.categories },
    dirty () { return this.companyName !== this.company.name || JSON.stringify(this.config) !== this.originalConfig }
  },
  methods: {
    handleLogoChange (file) {
      const fr = new FileReader()
      fr.readAsDataURL(file)
      fr.addEventListener('load', async () => {
        this.uploadedImage = fr.result
        this.imageFile = file
        const data = new FormData()
        data.append('file', file)

        await fetch(
          `${BASE_URL}api/company/${this.company.id}/logo`,
          { body: data, headers: { Authorization: `Bearer ${this.$cache.get('auth_access')}` }, method: 'POST' }
        ).catch(err => {
          this.snackbarText = 'Error uploading file'
          this.snackbarColor = 'red darken-1'
          this.showSnackbar = true
          console.log('s?', err)
        }).then(response => response.text())
          .then(responseText => {
            if (responseText) {
              this.config.logo = responseText
              this.save()
            }
          })
      })
    },
    handleItemTextBackspace (item, index, e, text) {
      this.$nextTick(() => {
        if (item.t === '') {
          if (index) {
            window.setTimeout(() => this.$refs.itemTextInputs[index - 1].focus(), 100)
            this.remove(index)
          }
        }
      })
    },

    add (e, index = -1) {
      if (index === -1 && this.newItemText.trim() === '') {
        // this.newItemText = ''
      } else {
        const item = {
          uuid: uuidv1(),
          del: Object.create(null),
          t: index === -1 ? this.newItemText : '',
          d: false,
          c: this.list.type === 'MANIFEST' ? 0 : false,
          h: e.shiftKey
        }

        this.list.items.splice(index === -1 ? this.list.items.length : index + 1, 0, item)
      }

      if (index === -1) {
        this.newItemText = ''
        this.$refs.newItemInput.focus()
      } else {
        window.setTimeout(() => this.$refs.itemTextInputs[this.$refs.itemTextInputs.length - 1].focus(), 100)
      }
    },

    remove (index) {
      this.list.items.splice(index, 1)
    },

    async save () {
      const config = clone(this.config)
      if (this.selectedListIndex === -1) {
        if (this.list.name.trim() !== '' && this.list.items.length) {
          config.templates.lists.push(clone(this.list))
          this.selectedListIndex = config.templates.lists.length - 1
        }
      } else if (this.selectedListIndex !== null) {
        config.templates.lists[this.selectedListIndex] = clone(this.list)
        console.log(config.templates.lists[this.selectedListIndex])
      }

      await this.$apollo.mutate({
        mutation: COMPANY.mutations.update.mutation,
        variables: { company: { id: this.company.id, name: this.companyName, config: JSON.stringify(config) } }
      }).then(({ data: { updateCompany } }) => {
        this.originalConfig = JSON.stringify(updateCompany.config)
        this.snackbarText = 'Config updated'
        this.snackbarColor = 'deep-purple lighten-1'
        this.showSnackbar = true
      })
    },

    handleNameInput (e) {
      this.companyName = e
    },

    addBillingType () {
      let highestKey = 0
      for (const key of Object.keys(this.jobTypes)) {
        const keyInt = parseInt(key, 10)
        if (keyInt > highestKey) {
          highestKey = keyInt
        }
      }
      const type = Object.create(null)
      type.name = 'new type'
      type.fixed_cost = false
      type.deleted = false
      this.$set(this.config.job_billing_types, highestKey + 1, type)
    },

    removeBillingType (key) {
      this.jobTypes[key].deleted = true
    },

    totalBillablesInCategory (category) {
      return this.billables.filter(billable => billable.category.id === category.id).length
    },

    addCategory () {
      this.newCategory = ''
      this.$nextTick(() => {
        if (this.$refs.newCategoryInput) {
          this.$refs.newCategoryInput.focus()
        }
      })
    },

    createCategory () {
      if (typeof this.newCategory === 'string' && this.newCategory.trim() !== '') {
        this.$apollo.mutate({
          mutation: CATEGORY.mutations.create.mutation,
          variables: { category: { name: this.newCategory, company: { connect: this.company.id } } }
        }).then(({ data: { createCategory } }) => {
          this.categories.push(createCategory)
          this.newCategory = null
        })
      }
    },

    updateCategory (category) {
      this.savingCategories.push(category.id)
      this.$apollo.mutate({
        mutation: CATEGORY.mutations.update.mutation,
        variables: { category: { id: category.id, name: category.name } }
      }).then(({ data: { updateCategory } }) => {
        const index = this.categories.findIndex(c => c.id === updateCategory.id)
        this.categories[index] = updateCategory
        const savingIndex = this.savingCategories.indexOf(updateCategory.id)
        if (savingIndex !== -1) {
          this.savingCategories.splice(savingIndex, 1)
        }
      })
    },

    deleteCategory (category) {
      this.$apollo.mutate({
        mutation: CATEGORY.mutations.delete.mutation,
        variables: { id: category.id }
      }).then(({ data: { deleteCategory } }) => {
        const index = this.categories.findIndex(c => c.id === deleteCategory.id)
        this.categories.splice(index, 1)
      })
    },

    handleNewCategoryBlur (e) {
      if (typeof this.newCategory === 'string' && this.newCategory.trim() === '') {
        this.newCategory = null
      }
    },

    handleCategoryBlur (category, e) {
      console.log(category, e.target.value)
      if (e.target.value !== category.name) {
        this.updateCategory({ ...category, name: e.target.value })
      }
    }
  },
  watch: {
    selectedListIndex (index) {
      if (index === -1) {
        this.list = {
          name: '',
          type: 'LIST',
          items: []
        }
        this.$nextTick(() => {
          this.$refs.templateName.focus()
        })
      } else if (this.lists[index]) {
        const items = clone(this.lists[index].items)
        items.forEach(item => {
          if (typeof item.h !== 'undefined' && typeof item.t === 'undefined') {
            item.t = item.h
            item.h = true
          }
          if (typeof item.uuid !== 'string' || item.uuid.length !== 36) {
            item.uuid = uuidv1()
          }
          if (typeof item.del === 'undefined') {
            item.del = Object.create(null)
          }
        })
        this.list = {
          name: this.lists[index].name,
          type: this.lists[index].type ? this.lists[index].type : 'LIST',
          items
        }
      }
    }
  },
  mounted () {
    window.setTimeout(() => {
      this.companyName = this.company.name
      if (this.originalConfig === null) {
        this.originalConfig = JSON.stringify(this.config)
      }
    }, 500)
  }
}
</script>

<style lang="scss">
#config {
  .list-type-switch {
    margin-top: 0;
    .v-input--switch__thumb,
    .v-input--selection-controls__ripple,
    .v-input--switch__track {
      color: #7e57c2;
    }
    .v-input__slot {
      margin-bottom: 0;
    }
    .v-input--selection-controls__input {
      margin-right: 0;
    }
    & > div:first-child,
    & > div:last-child {
      width: calc(50% - 19px);
      // padding-top: 3px;
    }
    & > div:first-child {
      justify-content: flex-end;
    }
  }
}
</style>
