import { defineModel } from '@/utils'
// import { CONTACT_QUERY, CONTACTS_QUERY } from '../queries'
import { CONTACT_CREATE, CONTACT_UPDATE, CONTACT_DELETE } from '../mutations'

const CONTACT = defineModel({
  name: 'Contact',
  fields: { id: 0, contactable_id: 0, contactable_type: '', label: '', type: '', value: '', order: 0 },
  mutations: {
    create: {
      mutation: CONTACT_CREATE
    },
    update: {
      mutation: CONTACT_UPDATE
    },
    delete: {
      mutation: CONTACT_DELETE
    }
  }
})

export default CONTACT
