import { defineModel } from '@/utils'
// import { SET_QUERY, SETS_QUERY } from '../queries'
import { SET_CREATE, SET_UPDATE, SET_DELETE } from '../mutations'

const SET = defineModel({
  name: 'Note',
  fields: { id: 0, setable_id: 0, setable_type: '', order: 0, title: '', type: '' },
  mutations: {
    create: {
      mutation: SET_CREATE
    },
    update: {
      mutation: SET_UPDATE
    },
    delete: {
      mutation: SET_DELETE
    }
  }
})

export default SET
