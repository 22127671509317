<template>
  <section class="manage-quote">
    <v-toolbar color="blue-grey" dark dense flat>
      <v-icon>request_quote</v-icon>
      <v-toolbar-title>Quote</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon @click="expanded = !expanded" style="margin-left: 40px;">
        <v-badge left color="blue-grey lighten-2 blue-grey--text text--lighten-4" class="v-badge--inline">
          <template #badge><span>{{ quotes.length ? (lines.billables.entries.length + lines.labour.entries.length + lines.permits.entries.length) : '0' }}</span></template>
          <v-icon>{{ expanded ? 'expand_less' : 'expand_more' }}</v-icon>
        </v-badge>
      </v-btn>
    </v-toolbar>

    <v-slide-y-transition>
      <v-card v-show="expanded">
        <template v-if="quotes.length">
          <v-expansion-panel expand flat>
            <v-expansion-panel-content lazy class="manage-quote__billables">
              <template v-slot:header>
                <span class="subheading">Billables</span>
                <span class="text-xs-right pr-4">
                  <span class="grey--text text--lighten-1">{{ totalBillables }}</span>
                </span>
              </template>
              <v-list subheader dense class="striped pb-0 manage-quote__list" :class="{ 'manage-quote__list--admin': admin }">
                <v-list-tile
                  v-for="(line, index) in lines.billables.entries" :key="index"
                  :class="{ 'temp-billable': !line.data.id, 'invalid-billable': !(parseNumber(line.value_1) > 0 && isNumeric(line.value_2)) }">

                  <v-list-tile-action v-if="admin">
                    <confirm-action @confirm="removeEntry(lines.billables, line)">
                      <template v-slot:act="{ on }">
                        <v-btn :loading="saving.includes('Entry:' + line.id)" icon small ripple @click.stop="on.click" class="ma-0">
                          <v-icon color="grey lighten-1">highlight_off</v-icon>
                        </v-btn>
                      </template>
                    </confirm-action>
                  </v-list-tile-action>

                  <v-list-tile-content>
                    <v-list-tile-title>
                      <v-layout row ma-0>

                        <v-flex grow v-if="line.data.id" pa-0 mr-1 style="max-width: calc(72.91667% - 4px); overflow: hidden;">
                          <v-tooltip bottom color="grey lighten-5">
                            <template #activator="data">
                              <span v-on="data.on">
                                <!--  -->
                                <span class="font-weight-bold">{{ line.data.name }}</span>
                                <span v-if="line.data.size" class="grey--text">
                                  | <span class="font-mono grey--text text--lighten-2">{{ line.data.size }}</span>
                                </span>
                                <template v-if="line.data.supplier"><span class="grey--text"> | </span><span class="font-mono grey--text text--lighten-2">{{ line.data.supplier }}</span></template>
                                <template v-if="line.data.unit"><span class="grey--text"> / </span><span class="caption grey--text text--lighten-2">{{ line.data.unit }}</span></template>
                              </span>
                            </template>
                            <span>
                              <span class="category grey--text text--darken-2 font-weight-medium">{{ line.data.category ? (line.data.category.name ? line.data.category.name : line.data.category) : '' }} | </span>
                              <span style="vertical-align: middle;">
                                <v-chip v-for="(tag, tagIndex) of line.data.tags" :key="tagIndex" label outline :color="getColor(tag)" class="v-chip--tiny darken-3 text--darken-3">{{ tag }}</v-chip>
                              </span>
                            </span>
                          </v-tooltip>
                        </v-flex>

                        <!-- Temp Billable -->
                        <template v-else>
                          <v-flex grow pa-0>
                            <v-text-field
                              :value="line.data.name" @input="handleNameInput(line, $event)" :tabindex="index.toString() + 1"
                              placeholder="item description..." class="pt-0" ref="billablesNameInputs" color="teal"
                              single-line hide-details :readonly="!admin"
                            ></v-text-field>
                          </v-flex>

                          <v-flex xs2 pa-0 style="flex-shrink: 1;">
                            <v-text-field
                              :value="line.data.supplier" @input="handleSupplierInput(line, $event)" :tabindex="index.toString() + 1"
                              placeholder="supplier" class="pt-0" color="teal"
                              single-line hide-details :readonly="!admin"
                            ></v-text-field>
                          </v-flex>
                        </template>

                        <v-flex pa-0 class="xs1_50">
                          <v-text-field
                            :value="line.value_1" @input="handleQuantityInput(line, $event)" :tabindex="index.toString() + 1" :error="!(parseNumber(line.value_1) > 0)"
                            class="pt-0 quantity-input" ref="billablesQuantityInputs" color="teal"
                            @submit.prevent.stop="focusBillables" @keydown.enter.prevent.stop="focusBillables"
                            prefix="x" suffix="@" placeholder="0" height="28"
                            single-line hide-details :readonly="!admin"
                          ></v-text-field>
                        </v-flex>

                        <v-flex v-if="admin" pa-0 class="xs1_75">
                          <v-text-field
                            :value="line.value_2" :tabindex="index.toString() + 2" @input="handleCostInput(line, $event)" :error="!isNumeric(line.value_2)"
                            :placeholder="Math.round(line.data.id ? (line.data.cost * markupMultiplier) : line.data.cost) | monetaryPreScaled"
                            class="pt-0 line-cost-input" prefix="$" ref="billablesCostInputs" inputmode="decimal" height="28" color="teal"
                            single-line hide-details :readonly="!admin"
                          ></v-text-field>
                        </v-flex>

                      </v-layout>
                    </v-list-tile-title>
                  </v-list-tile-content>
                </v-list-tile>
              </v-list>

              <billables-search
                v-if="admin"
                :label="'Search for an' + (lines.billables.entries.length ? 'other' : '') + ' item to add'"
                :tabindex="lines.billables.entries.length.toString() + '0'"
                ref="billablesSearch"
                @input="searchText = $event"
                @change="handleAddBillable"
              ></billables-search>

              <v-card-actions v-if="admin" class="pt-0">
                <v-spacer></v-spacer>
                <v-btn small flat color="teal" @click.stop="addEntry(lines.billables)"><v-icon small class="mr-1">hourglass_empty</v-icon> Add Unique</v-btn>
              </v-card-actions>
            </v-expansion-panel-content>

            <v-expansion-panel-content v-if="admin" lazy class="manage-quote__labour">
              <template v-slot:header>
                <span class="subheading">Labour</span>
                <span class="text-xs-right pr-4">
                  <span class="grey--text text--lighten-1">{{ totalLabour }}</span>
                </span>
              </template>
              <v-list subheader dense class="striped pb-0 manage-quote__list" :class="{ 'manage-quote__list--admin': admin }">
                <v-list-tile
                  v-for="(line, index) in lines.labour.entries" :key="index"
                  :class="{ 'invalid-billable': !(line.value_1 > 0) }">

                  <v-list-tile-action>
                    <confirm-action @confirm="removeEntry(lines.labour, line)">
                      <template v-slot:act="{ on }">
                        <v-btn :loading="saving.includes('Entry:' + line.id)" icon small ripple @click.stop="on.click" class="ma-0">
                          <v-icon color="grey lighten-1">highlight_off</v-icon>
                        </v-btn>
                      </template>
                    </confirm-action>

                  </v-list-tile-action>

                  <v-list-tile-content>
                    <v-list-tile-title>
                      <v-layout row ma-0>
                        <v-flex grow pa-0>
                          <v-text-field
                            :value="line.value_3" @input="handleNameInput(line, $event)" :tabindex="index.toString() + 1"
                            placeholder="labour" class="pt-0" ref="labourNameInputs" color="blue"
                            single-line hide-details
                          ></v-text-field>
                        </v-flex>
                        <!-- eslint-disable -->
                        <v-flex pa-0 class="xs2_25">
                          <v-text-field
                            :value="line.value_1" @input="handleQuantityInput(line, $event)" :tabindex="index.toString() + 1" :error="!(parseNumber(line.value_1) > 0)"
                            class="pt-0 quantity-input" ref="labourQuantityInputs" color="blue"
                            @submit.prevent.stop="focusBillables" @keydown.enter.prevent.stop="focusBillables"
                            :suffix="'hour' + ((line.value_1 === 1 || line.value_1 === '1') ? ' ' : 's') + ' @'" placeholder="0" height="28"
                            single-line hide-details
                          ></v-text-field><!-- suffix blank instead of plural 's' is the unicode En Quad character ' ' (U+2000) -->
                        </v-flex>
                        <!-- esline-enable -->

                        <v-flex pa-0 class="xs2_50">
                          <v-text-field
                            :value="line.value_2" :tabindex="index.toString() + 2" @input="handleCostInput(line, $event)" :error="!isNumeric(line.value_2)"
                            placeholder="0.00"
                            class="pt-0 line-cost-input" prefix="$" suffix="/hour" ref="labourCostInputs" inputmode="decimal" height="28" color="blue"
                            single-line hide-details
                          ></v-text-field>
                        </v-flex>
                      </v-layout>
                    </v-list-tile-title>
                  </v-list-tile-content>
                </v-list-tile>
              </v-list>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn small flat color="blue" @click.stop="addEntry(lines.labour)"><v-icon small class="mr-1">engineering</v-icon> Add Labour</v-btn>
              </v-card-actions>
            </v-expansion-panel-content>

            <v-expansion-panel-content v-if="admin" lazy class="manage-quote__permits">
              <template v-slot:header>
                <span class="subheading">Permits</span>
                <span class="text-xs-right pr-4">
                  <span class="grey--text text--lighten-1">{{ totalPermits }}</span>
                </span>
              </template>
              <v-list subheader dense class="striped pb-0 manage-quote__list" :class="{ 'manage-quote__list--admin': admin }">
                <v-list-tile
                  v-for="(line, index) in lines.permits.entries" :key="index"
                  :class="{ 'invalid-billable': !(line.value_1 > 0) }">

                  <v-list-tile-action>
                    <confirm-action @confirm="removeEntry(lines.permits, line)">
                      <template v-slot:act="{ on }">
                        <v-btn :loading="saving.includes('Entry:' + line.id)" icon small ripple @click.stop="on.click" class="ma-0">
                          <v-icon color="grey lighten-1">highlight_off</v-icon>
                        </v-btn>
                      </template>
                    </confirm-action>

                  </v-list-tile-action>

                  <v-list-tile-content>
                    <v-list-tile-title>
                      <v-layout row ma-0>
                        <v-flex grow pa-0>
                          <v-text-field
                            :value="line.value_3" @input="handleNameInput(line, $event)" :tabindex="index.toString() + 1"
                            placeholder="permits" class="pt-0" ref="permitsNameInputs" color="green"
                            single-line hide-details
                          ></v-text-field>
                        </v-flex>

                        <v-flex pa-0 class="xs2_50">
                          <v-text-field
                            :value="line.value_2" :tabindex="index.toString() + 2" @input="handleCostInput(line, $event)" :error="!isNumeric(line.value_2)"
                            placeholder="0.00"
                            class="pt-0 line-cost-input" prefix="$" ref="permitsCostInputs" inputmode="decimal" height="28" color="green"
                            single-line hide-details
                          ></v-text-field>
                        </v-flex>
                      </v-layout>
                    </v-list-tile-title>
                  </v-list-tile-content>
                </v-list-tile>
              </v-list>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn small flat color="green" @click.stop="addEntry(lines.permits)"><v-icon small class="mr-1">grading</v-icon> Add Permit</v-btn>
              </v-card-actions>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-card-actions v-if="admin">
            <v-progress-circular v-if="anySaving" indeterminate color="blue-grey lighten-2" size="25" width="2" class="ml-3"></v-progress-circular>
            <v-spacer></v-spacer>
            <div class="title pt-1 pb-2 pr-4">Total: {{ total }}</div>
          </v-card-actions>
        </template>
        <v-card-actions v-else>
          <v-spacer></v-spacer>
          <v-btn flat color="light-blue" @click="addQuote">
            <v-icon left>add</v-icon> Add Quote
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-slide-y-transition>
  </section>
</template>

<script>
/* eslint-disable */
import { v1 as uuidv1 } from 'uuid'
import { debounce } from 'lodash'
import { COLORS } from '@/constants'
import { BILLABLE, ENTRY, SET, QUOTE } from '@/graphql/models'
import { BillablesSearch, ConfirmAction } from '@/components'
import { clone, cloneExcept, equivalent, formatMonetaryPreScaled, getNestedValue, getUTCTimestamp, isNumeric, itemFromChar, parseNumber, pluralize } from '@/utils'

export default {
  name: 'manage-quote',
  components: { BillablesSearch, ConfirmAction },
  props: {
    jobId: {},
    path: {},
    quotes: { type: Array, required: true }
  },
  data () {
    return {
      expanded: false,
      searching: false,
      searchText: '',
      saving: []
    }
  },
  computed: {
    me () { return this.$oxide.store.apollo.user },
    admin () { return !!this.me?.is_admin },
    client () { return this.$oxide.store.apollo.client },
    company () { return this.$oxide.store.apollo.company },
    config () { return this.company && this.company.config ? this.company.config : [] },
    markupRate () { return getNestedValue(this.config, 'accounting/markup', { defaultValue: 0 }) / 100 },
    markupMultiplier () { return 1 + this.markupRate },
    taxRate () {
      const taxes = getNestedValue(this.config, 'accounting/taxes', { defaultValue: [] })
      return taxes.reduce((rate, tax) => rate + tax.value, 0) / 100
    },
    taxMultiplier () { return 1 + this.taxRate },
    billablesTotalCost () { return this.lines.billables.entries.reduce((total, line) => total + this.lineTotal(line), 0) },
    labourTotalCost () { return this.lines.labour.entries.reduce((total, line) => total + this.lineTotal(line), 0) },
    permitsTotalCost () { return this.lines.permits.entries.reduce((total, line) => total + this.lineTotal(line), 0) },
    allTotalCost () { return this.billablesTotalCost + this.labourTotalCost + this.permitsTotalCost },
    totalBillables () {
      if (this.admin) {
        return '$' + formatMonetaryPreScaled(this.billablesTotalCost)
      } else {
        const t = this.lines.billables.entries.reduce((total, line) => total + (Number(line.value_1) ? Number(line.value_1) : 0), 0)
        return t + ' item' + (t > 1 ? 's' : '')
      }
    },
    totalLabour () { return this.admin ? '$' + formatMonetaryPreScaled(this.labourTotalCost) : '' },
    totalPermits () { return this.admin ? '$' + formatMonetaryPreScaled(this.permitsTotalCost) : '' },
    total () { return this.admin ? '$' + formatMonetaryPreScaled(this.allTotalCost) : '' },
    quote () { return this.quotes.length ? this.quotes[0] : null },
    lines () {
      if (this.quote !== null) {
        const sets = this.quote.sets
        const billables = sets.find(set => set.type === 'Quote/Billable')
        for (const entry of billables.entries) {
          if (typeof entry.data === 'string') {
            try {
              entry.data = JSON.parse(entry.data)
            } catch (e) {
              console.log(e)
            }
          }
        }
        return {
          billables,
          labour: sets.find(set => set.type === 'Quote/Labour'),
          permits: sets.find(set => set.type === 'Quote/Permit')
        }
      } else {
        return null
      }
    },
    anySaving () { return this.saving.length }
  },
  methods: {
    handleSearchInput (query) {
      if (query !== null) {
        this.searching = query && query.length && query.length > 0
        this.updateQuery(query)
      }
    },
    updateQuery: debounce(function (query) {
      this.$nextTick(() => {
        this.searching = false
      })
      if (query !== this.searchText) {
        this.searchText = query
      }
    }, 5),

    handleQuantityInput: debounce(function (entry, quantity) {
      entry.value_1 = quantity === null ? '0' : quantity
      this.updateEntry(entry)
    }, 500),
    handleNameInput: debounce(function (entry, name) {
      if (typeof entry.data === 'object' && entry.data !== null) {
        entry.data.name = name === null ? '' : name
      } else {
        entry.value_3 = name === null ? '' : name
      }
      this.updateEntry(entry)
    }, 500),
    handleSupplierInput: debounce(function (entry, supplier) {
      entry.data.supplier = supplier === null ? '' : supplier
      this.updateEntry(entry)
    }, 500),
    handleCostInput: debounce(function (entry, cost) {
      entry.value_2 = cost === null ? '' : cost
      this.updateEntry(entry)
    }, 500),
    handleNothing (e) {  return false },

    handleAddBillable (input) {
      if (input) {
        const billable = cloneExcept(input, ['__typename', 'created_at', 'updated_at', 'category', '_relevance'])
        billable.category = cloneExcept(input.category, ['__typename', 'created_at', 'updated_at'])
        this.searchText = ''
        this.addEntry(this.lines.billables, billable)
      }
    },

    isNumeric (value) {
      return isNumeric(value, true)
    },

    parseNumber (value) {
      return parseNumber(value)
    },

    lineCost (line) {
      if (line.value_2 !== null && line.value_2 !== '' && typeof line.value_2 !== 'undefined') {
        // line with cost overridden so no markup needed
        // converted from dollars to cents
        return Math.round(parseNumber(line.value_2) * 100)
      } else if (line.data?.id) {
        // line with billable at the default cost
        // gets marked up
        return Math.round(parseNumber(line.data.cost * this.markupMultiplier))
      } else {
        return 0
      }
    },
    lineQuantity (line) {
      // return Number((line.value_1 !== null && line.value_1 !== '') ? line.value_1 : 0)
      return parseNumber(line.value_1)
    },
    lineTotal (line) { return Math.round(this.lineCost(line) * this.lineQuantity(line)) },
    pluralize (str, count = 0) { return pluralize(str, count) },

    getColor (str) { return itemFromChar(COLORS, str) },

    addToSaving (model) {
      const key = `${model.__typename}:${model.id}`
      if (!this.saving.includes(key)) {
        this.saving.push(key)
      }
    },
    removeFromSaving (model) {
      const index = this.saving.indexOf(`${model.__typename}:${model.id}`)
      if (index !== -1) {
        this.saving.splice(index, 1)
      }
    },
    isSaving (model) { return this.saving.includes(`${model.__typename}:${model.id}`) },

    removeEntry(set, entry) {
      this.addToSaving(entry)
      const index = set.entries.indexOf(entry)
      if (index !== -1) {
        this.$apollo.mutate({
          mutation: ENTRY.mutations.delete.mutation,
          variables: { id: entry.id }
        }).then(({ data: { deleteEntry }}) => {
          let type = set.type.substring(6).toLowerCase()
          type = type + (type === 'labour' ? '' : 's')
          this.lines[type].entries.splice(index, 1)
          this.removeFromSaving(deleteEntry)
        })
      }
    },

    addEntry (set, data = null) {
      const entry = cloneExcept(ENTRY.blank, ['id'])

      switch (set.type) {
        case 'Quote/Billable':
          if (data === null) {
            entry.data = cloneExcept(BILLABLE.blank, ['created_at', 'updated_at', 'supplier_desc'])
            entry.data.name = this.searchText.trim()
            this.searchText = ''
            this.$refs.billablesSearch.updateQuery('')
          } else {
            entry.listable_type = 'App\\Billable'
            entry.listable_id = data.id
            entry.data = cloneExcept(data, ['created_at', 'supplier_desc'])
          }
          entry.value_1 = ''
          entry.value_2 = ''
          break
        case 'Quote/Labour':
          entry.value_1 = ''
          entry.value_2 = ''
          entry.value_3 = 'labour'
          break
        case 'Quote/Permit':
          entry.value_1 = 1
          entry.value_2 = ''
          entry.value_3 = 'permit'
          break
      }
      entry.order = set.entries.length + 1
      entry.set = { connect: set.id }

      if (typeof entry.data === 'object' ) {
        if (entry.data === null) {
          entry.data = ''
        } else {
          entry.data = JSON.stringify(entry.data)
        }
      }
      this.addToSaving({ __typename: 'Entry', id: '-1' })
      this.$apollo.mutate({
        mutation: ENTRY.mutations.create.mutation,
        variables: { entry }
      }).then(({ data: { createEntry }}) => {
        let type = set.type.substring(6).toLowerCase()
        type = type + (type === 'labour' ? '' : 's')
        this.lines[type].entries.push(createEntry)
        setTimeout(() => {
          const inputs = this.$refs[`${type}${type === 'billables' ? (entry.listable_id ? 'Quantity' : 'Name') : (type === 'labour' ? 'Quantity' : 'Cost')}Inputs`]
          try {
            const input = inputs[inputs.length - 1]
            input.focus()
            input.$refs.input.setSelectionRange(0, 1)
          } catch (e) {
            console.log('inputs not found')
          }
        }, 500)
        this.removeFromSaving({ __typename: 'Entry', id: '-1' })
      })
    },

    dataToString (data) {
      if (typeof data === 'object' ) {
        if (data === null) {
          return ''
        } else {
          try {
            return JSON.stringify(data)
          } catch (e) {
            return `${data}`
          }
        }
      }
    },

    updateEntry (entry) {
      this.addToSaving(entry)
      const updateEntry = cloneExcept(entry, ['__typename', 'created_at', 'updated_at'])
      updateEntry.data = this.dataToString(entry.data)
      this.$apollo.mutate({
        mutation: ENTRY.mutations.update.mutation,
        variables: { entry: updateEntry }
      }).then(() => {
        this.removeFromSaving(entry)
      })
    },

    addQuote () {
      this.addToSaving({ __typename: 'Quote', id: '-1' })
      const quote = cloneExcept(QUOTE.blank, ['id', 'client_id', 'job_id'])
      quote.title = 'Quote'
      quote.client = { connect: this.client.id }
      quote.job = { connect: this.jobId }
      this.$apollo.mutate({
        mutation: QUOTE.mutations.create.mutation,
        variables: { quote }
      }).then(({ data: { createQuote } }) => {
        this.removeFromSaving({ __typename: 'Quote', id: '-1' })
        const billablesSet = { title: 'Billables', type: 'Quote/Billable', order: 1, setable_type: 'App\\Quote', setable_id: createQuote.id }
        const labourSet = { title: 'Labour', type: 'Quote/Labour', order: 2, setable_type: 'App\\Quote', setable_id: createQuote.id }
        const permitsSet = { title: 'Permits', type: 'Quote/Permit', order: 3, setable_type: 'App\\Quote', setable_id: createQuote.id }
        this.addToSaving({ __typename: 'Set', id: '-1' })
        this.addToSaving({ __typename: 'Set', id: '-2' })
        this.addToSaving({ __typename: 'Set', id: '-3' })
        Promise.all([
          this.$apollo.mutate({ mutation: SET.mutations.create.mutation, variables: { set: billablesSet }}),
          this.$apollo.mutate({ mutation: SET.mutations.create.mutation, variables: { set: labourSet }}),
          this.$apollo.mutate({ mutation: SET.mutations.create.mutation, variables: { set: permitsSet }})
        ]).then((responses) => {
          for (const response of responses) {
            if (!Array.isArray(createQuote.sets)) {
              createQuote.sets = []
            }
            createQuote.sets.push(response.data.createSet)
          }
          this.quotes.push(createQuote)
          this.removeFromSaving({ __typename: 'Set', id: '-1' })
          this.removeFromSaving({ __typename: 'Set', id: '-2' })
          this.removeFromSaving({ __typename: 'Set', id: '-3' })
        })
      })
    }
  }
}
</script>

<style lang="scss">
.manage-quote {
  [readonly=readonly],
  .v-input--is-readonly {
    cursor: not-allowed;
  }
  .v-list--dense {
    &.v-list--two-line {
      & > [role=listitem] > .v-list__tile:not(.v-list__tile--avatar) {
        height: 60px;
      }
    }
  }
  .v-list {
    & > [role=listitem] {
      & > .v-list__tile {
        .v-list__tile__title {
          height: 30px;
          line-height: 30px;
          vertical-align: middle;
          .category {
            // font-size: 85%;
            font-variant: all-small-caps;
          }
        }
        .v-text-field {
          margin-top: 0;
        }
      }
      &.invalid-billable {
        background-color: rgba(255,255,255,0.2);
      }
      &.invoiced {
        .flex {
          opacity: 0.66;
        }
        &.invalid-billable {
          .flex:last-child {
            opacity: 1;
          }
        }
      }
    }
  }

  .manage-quote__list {
    .v-list__tile {
      .v-list__tile__action {
        min-width: 36px;
      }
    }
    &--admin {
      .v-list__tile {
        margin-left: -6px;
      }
    }
  }

  .line-cost-input {
    .v-text-field__prefix {
      padding-left: 4px;
    }
  }

  .quantity-input {
    input {
      text-align: right;
    }
    .input-group--text-field__suffix {
      margin-left: 15px;
    }
    .v-text-field__prefix {
      color: #999999;
    }
  }

  .item-list {
    .v-list > div[role="listitem"]:nth-child(even):not(:last-child) {
      background-color: rgba(0,0,0,0.033);
    }
  }

  .v-list__tile__action {
    min-width: 40px;
  }

  .v-text-field.teal--text:not(.error--text) input::selection {
    color: #fff;
    background-color: #30897d;
  }

  .v-text-field.error--text input::selection {
    color: #fff;
    background-color: #de564b;
  }
}

@media (max-width: 600px) {
  .manage-quote {
    padding-bottom: 0;

    .quantity-input .v-text-field__prefix {
      padding-right: 0;
    }
  }
}
</style>
