import ca$h from './johnny-cache.js'
import Vue from 'vue'

import './plugins/pwa'
import './plugins/catalogger'
import './plugins/vuetify'

// import * as Sentry from '@sentry/browser'
// import * as Integrations from '@sentry/integrations'

import * as Sentry from '@sentry/vue'

import App from './App.vue'
import router from './router'
import './registerServiceWorker'
import Cent$ from './cents.js'

import VueGeolocation from 'vue-browser-geolocation'
import * as VueGoogleMaps from 'vue2-google-maps'
import { IS_PROD, MAPS_API_KEY, VERSION } from './constants.js'

import { client as apolloClient, waitOnCache } from './plugins/apollo.js'
import VueApollo from 'vue-apollo'

Vue.use(VueApollo)
const apolloProvider = new VueApollo({
  defaultClient: apolloClient
})

require('./filters/datetime.js')
require('./filters/monetary.js')
require('./filters/phone.js')
require('./filters/postal.js')
require('./filters/region.js')
require('./filters/title.js')

Vue.config.productionTip = false
Vue.prototype.$cache = ca$h
Vue.prototype.$Cent$ = Cent$

window.$oxide = {
  store: Vue.observable({
    apollo: {
      billables: [],
      categories: [],
      company: {},
      client: {},
      employees: [],
      invoices: [],
      user: ca$h.get('me', {}),
      users: [],
      _meta: {
        client: {}
      },
      jobsByDayLastDate: null
    },
    activeClientId: null,
    clientQuery: null,
    crmModels: null,
    disableScrolling: false,
    snackbar: { color: '', show: false, text: '', timeout: 5000 }
  }),
  bus: Vue.observable({
    authenticated: null,
    beforeinstallprompt: null,
    appinstalled: null,
    hidden: null,
    now: null,
    offline: !navigator.onLine,
    errors: [],
    showSidebar: false
  })
}
Vue.prototype.$oxide = window.$oxide
Vue.prototype.$alert = function (config) {
  if (typeof config.timeout !== 'undefined') {
    window.$oxide.store.snackbar.timeout = config.timeout
  }
  if (typeof config.text !== 'undefined') {
    window.$oxide.store.snackbar.text = config.text
  }
  if (typeof config.color !== 'undefined') {
    window.$oxide.store.snackbar.color = config.color
  }
  window.$oxide.store.snackbar.show = typeof config.show !== 'undefined' ? config.show : true
}

Vue.prototype.router = router

Vue.use(VueGoogleMaps, {
  load: {
    key: MAPS_API_KEY,
    libraries: 'places'
  }
})
Vue.use(VueGeolocation)

Vue.prototype.$version = VERSION

if (IS_PROD) {
  Sentry.init({
    dsn: 'https://472971b2a51f4999a150ac544402d457@sentry.io/1894546',
    release: `oxide-copper@${VERSION}`,
    logErrors: true
  })
  Vue.prototype.$sentry = Sentry
}

waitOnCache.then(() => {
  window._vm = new Vue({
    router,
    apolloProvider,
    render: h => h(App)
  }).$mount('#app')
})
