import { ApolloClient } from 'apollo-client'
import { ApolloLink, from } from 'apollo-link'
import { onError } from 'apollo-link-error'
import { HttpLink } from 'apollo-link-http'
import { RetryLink } from 'apollo-link-retry'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { persistCache } from 'apollo-cache-persist'
import localforage from 'localforage'

import { GRAPH_URL, MINUTES, VERSION } from '@/constants'
import Version from '@/version'
import ca$h from '@/johnny-cache'

localforage.config({
  name: 'Oxide-local'
})

const logout = onError(({ graphQLErrors, networkError, operation }) => {
  console.log('Server Error', graphQLErrors, networkError, operation)
  if (
    (
      graphQLErrors && (graphQLErrors.some(e => e.extensions.code === 'UNAUTHENTICATED') || graphQLErrors.some(e => e.debugMessage === 'Unauthenticated.'))
    ) || (
      networkError?.statusCode === 401
    )
  ) {
    console.log('unauthenticated')
    ca$h.set('auth_access', '')
    window.$oxide.bus.authenticated = false
    client.clearStore()
  }
})

const watchForClientUpdates = new ApolloLink((operation, forward) => {
  return forward(operation).map(response => {
    const lastVersionCheck = parseInt(ca$h.get('last_version_check', { defaultValue: 0 }), 10)
    const now = Date.now()
    if ((now - lastVersionCheck) > 1 * MINUTES) {
      const context = operation.getContext()
      const { response: { headers } } = context

      if (headers) {
        ca$h.set('last_version_check', now)
        const latestVersion = new Version(headers.get('current-version'))

        if (latestVersion.isHigherThan(VERSION)) {
          window.setTimeout(() => {
            window.location.reload()
          }, 7777)
        }
      }
    }
    return response
  })
})

const retry = new RetryLink({
  attempts: { max: Infinity },
  delay: { initial: 5000, jitter: true, max: 500000 }
})

const auth = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers }) => ({
    headers: {
      authorization: ca$h.get('auth_access') ? `Bearer ${ca$h.auth_access}` : '',
      ...headers
    }
  }))
  return forward(operation)
})

const http = new HttpLink({ uri: GRAPH_URL })
const link = from([logout, retry, auth, watchForClientUpdates, http])

const cache = new InMemoryCache()
// const storage = window.localStorage
const storage = localforage
const waitOnCache = persistCache({ cache, storage, maxSize: false, debounce: 10000, debug: true })

const client = new ApolloClient({ cache, link })

export { client, waitOnCache }
