import { defineModel } from '@/utils'
// import { NOTE_QUERY, NOTES_QUERY } from '../queries'
import { NOTE_CREATE, NOTE_UPDATE, NOTE_DELETE } from '../mutations'

const NOTE = defineModel({
  name: 'Note',
  fields: { id: 0, notable_id: 0, notable_type: '', order: 0, user_id: '', title: '', content: '', type: '', visibility: '' },
  mutations: {
    create: {
      mutation: NOTE_CREATE
    },
    update: {
      mutation: NOTE_UPDATE
    },
    delete: {
      mutation: NOTE_DELETE
    }
  }
})

export default NOTE
