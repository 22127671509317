<template>
  <section class="time-list">
    <v-toolbar color="blue" dark dense flat>
      <v-icon>timer</v-icon>
      <v-toolbar-title>Time</v-toolbar-title>
      <v-spacer></v-spacer>
      <span v-if="expanded" class="subheading">
        {{ formatNumber(totalHours) }}<span class="font-weight-thin"> hours</span>
      </span>
      <v-btn icon @click="expanded = !expanded" style="margin-left: 40px;">
        <v-badge left color="blue lighten-2 blue--text text--lighten-4" class="v-badge--inline">
          <template #badge><span>{{ hours ? hours.length : 0 }}</span></template>
          <v-icon>{{ expanded ? 'expand_less' : 'expand_more' }}</v-icon>
        </v-badge>
      </v-btn>
    </v-toolbar>

    <v-slide-y-transition>
      <v-card v-show="expanded" flat>
        <v-slide-y-transition>
          <v-card-actions v-if="admin" v-show="showAddTime">
            <v-select
              :items="employees" v-model="newEntryEmployee"
              :menu-props="{ auto: true, closeOnClick: false, closeOnContentClick: false, openOnClick: false, maxHeight: 300 }"
              item-text="name" label="Employee"
              dense hide-details return-object
            ></v-select>
          </v-card-actions>
        </v-slide-y-transition>
        <v-slide-y-transition>
          <v-card-actions v-show="showAddTime">
            <v-menu lazy offset-y full-width
              ref="dateMenu" max-width="290px" min-width="290px" transition="scale-transition"
              v-model="dateMenuOpen" :close-on-content-click="false">
              <template v-slot:activator="{ on }">
                <v-text-field :value="newEntryDate" v-on="on" label="Date" prepend-icon="event" style="width: 50%;" hide-details></v-text-field>
              </template>
              <v-date-picker v-model="newEntryDate" no-title :min="oldestTimeAllowed" :max="getDate()" @input="dateMenuOpen = false"></v-date-picker>
            </v-menu>
            <v-divider vertical inset class="pl-3 mr-3 my-2"></v-divider>
            <v-select
              v-model="newEntryTime" :items="steps"
              :menu-props="{ auto: true, closeOnClick: false, closeOnContentClick: false, openOnClick: false, maxHeight: 300 }"
              label="Hours" append-icon="timelapse" style="width: 50%;"
              dense hide-details>
            </v-select>
          </v-card-actions>
        </v-slide-y-transition>
        <v-slide-y-transition>
          <v-card-actions v-show="showAddTime">
            <v-textarea label="Description" v-model="newEntryDescription" rows="1" auto-grow hide-details></v-textarea>
          </v-card-actions>
        </v-slide-y-transition>

        <v-card-actions>
          <v-btn :flat="!showAddTime" :depressed="showAddTime" small :color="showAddTime ? 'red' : 'blue'" @click.stop="toggleShowAddTime">
            <v-icon>{{ showAddTime ? 'close' : 'add' }}</v-icon> {{ showAddTime ? 'Cancel' : 'Add Time' }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn v-if="showAddTime" depressed small color="blue" :disabled="!newEntryTime || savingTime" @click.stop="createHour">
            <v-icon v-if="!savingTime">save</v-icon>
            <v-icon v-else class="spinning-loader">cached</v-icon> Add
          </v-btn>
        </v-card-actions>

        <section class="">
          <v-subheader class="title font-weight-bold justify-center">
            Invoiced Time <small class="pl-3 font-weight-thin">{{ formatNumber(totalInvoicedHours) }} hours</small>
          </v-subheader>
          <v-expansion-panel expand flat>
            <v-expansion-panel-content lazy v-for="invoice of billedHourInvoices" :key="invoice.id">
              <template v-slot:header>
                <span class="subheading">{{ invoice.name }}</span>
                <span class="text-xs-right pr-4">
                  <span class="grey--text text--lighten-1">{{ invoice.created_at.split(' ')[0] }}</span>
                </span>
              </template>
              <div class="px-4 pb-2"><router-link :to="`/invoice/${invoice.id}`">view invoice</router-link></div>
              <table class="v-table theme--dark" style="width: 100%;">
                <thead>
                  <tr>
                    <th role="columnheader" scope="col" aria-label="Date: Not sorted." aria-sort="none" class="column text-xs-left px-3">Date</th>
                    <th role="columnheader" scope="col" width="10" aria-label="Hours: Not sorted." aria-sort="none" class="column text-xs-right px-1">Hours</th>
                    <th role="columnheader" scope="col" aria-label="Employee: Not sorted." aria-sort="none" class="column text-xs-right px-3">Employee</th>
                  </tr>
                </thead>
                <tbody class="striped">
                  <tr v-for="hour of invoice.hours" :key="hour.id">
                    <td class="px-3">{{ getFormattedDate(hour.date) }}<br><span class="caption font-weight-thin grey--text text--lighten-2">{{ hour.date }}</span></td>
                    <td class="px-1 text-xs-right" style="font-family: monospace,monospace;">{{ formatHour(hour.time) }}</td>
                    <td class="px-3 text-xs-right font-italic">{{ hour.employee.name }}</td>
                  </tr>
                </tbody>
              </table>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </section>

        <v-subheader class="title font-weight-bold justify-center pt-4 pb-3" style="height: auto;">
          Uninvoiced Time <small class="pl-3 font-weight-thin">{{ formatNumber(totalUninvoicedHours) }} hours</small>
        </v-subheader>
        <v-data-table
          :headers="headers" :items="uninvoicedHours" :pagination.sync="pagination"
          expand must-sort>
          <template v-slot:items="props">
            <tr
              v-if="admin || (me && me.employee && props.item.employee.id === me.employee.id) || props.item.description"
              :active="props.expanded" @click="props.expanded = !props.expanded" :key="props.item.id"
              class="" style="cursor: pointer;">
              <td class="px-3">{{ getFormattedDate(props.item.date) }}<br><span class="caption font-weight-thin grey--text text--lighten-1">{{ props.item.date }}</span></td>
              <td class="px-1 text-xs-right" style="font-family: monospace,monospace;">{{ formatHour(props.item.time) }}</td>
              <td class="px-3 text-xs-right font-italic" :class="{'font-weight-bold':(me.employee && props.item.employee.id === me.employee.id)}">{{ props.item.employee.name }}</td>
            </tr>
            <tr v-else class="grey--text text--lighten-1">
              <td class="px-3">{{ getFormattedDate(props.item.date) }}<br><span class="caption font-weight-thin grey--text">{{ props.item.date }}</span></td>
              <td class="px-1 text-xs-right" style="font-family: monospace,monospace;">{{ formatHour(props.item.time) }}</td>
              <td class="px-3 text-xs-right font-italic">{{ props.item.employee.name }}</td>
            </tr>
          </template>
          <template v-slot:expand="props">
            <v-card
              v-if="admin || (me.employee && props.item.employee.id === me.employee.id)"
              flat tile style="transform: translateY(-1px); box-shadow: 0 3px 1px -1px rgba(0,0,0,.2),0 2px 2px -2px rgba(0,0,0,.14),0 1px 5px -5px rgba(0,0,0,.12);">
              <v-card-actions>
                <v-menu lazy offset-y full-width
                  ref="dateMenu" max-width="290px" min-width="290px" transition="scale-transition"
                  v-model="tableDateMenus[props.item.id]" :close-on-content-click="false">
                  <template v-slot:activator="{ on }">
                    <v-text-field :value="props.item.date" v-on="on" label="Date" prepend-icon="event" style="width: 50%;" hide-details></v-text-field>
                  </template>
                  <v-date-picker v-model="props.item.date" no-title :min="oldestTimeAllowed" :max="getDate()" @input="handleHourEdit(props.item)"></v-date-picker>
                </v-menu>
                <v-divider vertical inset class="pl-3 mr-3 my-2"></v-divider>
                <v-select :items="steps" label="Hours" append-icon="timelapse" v-model="props.item.time" @input="handleHourEdit(props.item)" dense hide-details style="width: 50%;" :menu-props="{ auto: true, closeOnClick: false, closeOnContentClick: false, openOnClick: false, maxHeight: 300 }"></v-select>
              </v-card-actions>
              <v-card-actions>
                <v-textarea label="Description" v-model="props.item.description" @input="handleHourDescriptionInput(props.item, $event)" rows="1" auto-grow hide-details></v-textarea>
              </v-card-actions>
              <v-card-actions v-if="admin">
                <v-select
                  label="Employee" :items="employees" v-model="props.item.employee"
                  :menu-props="{ auto: true, closeOnClick: false, closeOnContentClick: false, openOnClick: false, maxHeight: 300 }"
                  item-text="name" return-object @input="handleHourEmployeeInput(props.item, $event)"
                  dense hide-details style="width: 50%;"></v-select>
              </v-card-actions>
              <v-card-actions v-if="admin">
                <v-text-field label="Role" v-model="props.item.role" hide-details></v-text-field>
                <monetary-input label="Rate" v-model="props.item.rate" hide-details></monetary-input>
              </v-card-actions>
              <v-card-actions>
                <v-spacer></v-spacer>
                <confirm-action @confirm="deleteHour(props.item)">
                  <template v-slot:act="{ on }">
                    <v-btn small outline dark color="red darken-2" v-on="on">delete entry</v-btn>
                  </template>
                </confirm-action>
              </v-card-actions>
            </v-card>

            <v-card v-else-if="props.item.description" flat tile style="transform: translateY(-1px); box-shadow: 0 3px 1px -1px rgba(0,0,0,.2),0 2px 2px -2px rgba(0,0,0,.14),0 1px 5px -5px rgba(0,0,0,.12);">
              <v-card-actions>
                <v-textarea label="Description" v-model="props.item.description" rows="1" readonly auto-grow hide-details></v-textarea>
              </v-card-actions>
            </v-card>
          </template>
        </v-data-table>
      <v-card-actions v-if="admin">
        <v-btn
            v-if="admin" @click.stop="createInvoice"
            :disabled="uninvoicedHours.length === 0"
            color="green" small flat
          >
            <v-icon>vertical_split</v-icon> To Invoice
          </v-btn>
      </v-card-actions>
      </v-card>
    </v-slide-y-transition>
    <v-snackbar v-model="showSnackbar" :color="snackbarColor" top>
      {{ snackbarText }}
      <v-btn dark flat @click="showSnackbar = false">
        Close
      </v-btn>
    </v-snackbar>
  </section>
</template>

<script>
/* eslint-disable camelcase */
/* eslint-disable handle-callback-err */

import { debounce } from 'lodash'
import { HOUR } from '@/graphql/models'
import format from 'date-fns/format'
import formatRelative from 'date-fns/formatRelative'
import differenceInDays from 'date-fns/differenceInDays'
import parseISO from 'date-fns/parseISO'
import startOfWeek from 'date-fns/startOfWeek'
import subWeeks from 'date-fns/subWeeks'
import { clone, cloneOnly, formatDecimal, getDate, getNestedValue, numberFormatter } from '@/utils'
import { ConfirmAction, MonetaryInput } from '@/components'

export default {
  name: 'time-list',
  components: { ConfirmAction, MonetaryInput },
  props: {
    hours: {}, jobId: {}, path: {}
  },
  data () {
    return {
      expanded: false,
      showAddTime: false,
      dateMenuOpen: false,
      newEntryTime: 8,
      newEntryDate: getDate(),
      newEntryDescription: '',
      newEntryEmployee: {},
      savingTime: false,
      headers: [
        { text: 'Date', align: 'left', value: 'date', class: 'px-3', sortable: false },
        { text: 'Hours', align: 'right', value: 'time', width: '10', class: 'px-1', sortable: false },
        { text: 'Employee', align: 'right', value: 'employee.name', class: 'px-3', sortable: false }
      ],
      pagination: { descending: true, rowsPerPage: 25, sortBy: 'date' },
      tableDateMenus: {},
      showSnackbar: false,
      snackbarText: '',
      snackbarColor: ''
    }
  },
  computed: {
    admin () { return this.me?.is_admin },
    billedHourInvoices () {
      const hours = this.sortedHours.filter(hour => !!hour.invoice)
      const invoices = []
      for (const hour of hours) {
        const invoice = invoices.find(inv => inv.id === hour.invoice.id)
        if (invoice) {
          invoice.hours.push(hour)
        } else {
          invoices.push({ id: hour.invoice.id, name: hour.invoice.name, created_at: hour.invoice.created_at, hours: [hour] })
        }
      }
      return invoices
    },
    employees () { return this.$oxide.store.apollo.employees },
    me () { return this.$oxide.store.apollo.user },
    steps () {
      const steps = []
      for (let i = 0.25; i <= 16; i += 0.25) {
        const t = i * 60 / 100
        steps.push({ text: `${this.formatHour(i, 2)} hrs`, value: i, time: `${Math.floor(t)}:${(t % 1) * 100}` })
      }
      return steps
    },
    sortedHours () { return clone(this.hours).sort((a, b) => Date.parse(b.date) - Date.parse(a.date)) },
    totalHours () { return this.hours.reduce((total, hour) => total + hour.time, 0) },
    uninvoicedHours () { return this.sortedHours.filter(hour => !hour.invoice) },
    totalUninvoicedHours () { return this.uninvoicedHours.reduce((total, hour) => total + hour.time, 0) },
    invoicedHours () { return this.sortedHours.filter(hour => !!hour.invoice) },
    totalInvoicedHours () { return this.invoicedHours.reduce((total, hour) => total + hour.time, 0) },
    users () { return this.$oxide.store.apollo.users },
    oldestTimeAllowed () {
      return this.admin ? undefined : getDate(subWeeks(startOfWeek(new Date()), 2))
    }
  },
  methods: {
    formatHour (h) { return formatDecimal(h, 2) },
    getDate () { return getDate() },

    getFormattedDate (date) {
      const now = new Date()
      const then = parseISO(date)
      const diff = Math.abs(differenceInDays(now, then))

      if (diff > 5) {
        return format(then, 'MMM do y')
      } else {
        return formatRelative(then, now).split(' at ')[0].split('last ').pop()
      }
    },

    async createHour () {
      this.showAddTime = false
      this.savingTime = true
      // const timestamp = getUTCTimestamp()
      const employee = this.newEntryEmployee ? this.newEntryEmployee : this.me.employee
      if (!employee) {
        console.log('invalid employee', employee)
      }
      this.$apollo.mutate({
        mutation: HOUR.mutations.create.mutation,
        variables: {
          hour: {
            date: this.newEntryDate,
            time: this.newEntryTime,
            description: this.newEntryDescription,
            rate: employee.rate,
            role: employee.title,
            wage: employee.wage,
            job: { connect: this.jobId },
            employee: { connect: employee.id }
          }
        },
        update: (store, { data: { createHour } }) => {
          const data = store.readQuery(this.$oxide.store.clientQuery)
          const entity = getNestedValue(data, this.path)
          entity.hours.push(clone(createHour))
          store.writeQuery({ ...this.$oxide.store.clientQuery, data })
        }
      }).then(({ data: { createHour } }) => {
        this.savingTime = false
        this.snackbarText = 'Time entry added'
        this.snackbarColor = 'blue'
        this.showSnackbar = true
        this.newEntryDate = getDate()
        this.newEntryTime = 8
        this.newEntryDescription = ''
        this.newEntryEmployee = this.me
      })
    },

    async saveHour (hour, newEmployee = null, newInvoice = null) {
      this.savingTime = true
      const clonedHour = cloneOnly(hour, ['id', 'date', 'time', 'rate', 'role', 'description'])
      if (newEmployee !== null) {
        clonedHour.employee = { connect: newEmployee.id }
        clonedHour.rate = newEmployee.rate
        clonedHour.role = newEmployee.title
      }
      if (newInvoice !== null) {
        clonedHour.invoice = { connect: newInvoice.id }
      }
      await this.$apollo.mutate({
        mutation: HOUR.mutations.update.mutation,
        variables: { hour: clonedHour }
      }).then(({ data: { updateHour } }) => {
        this.savingTime = false
        this.snackbarText = 'Time entry updated'
        this.snackbarColor = 'blue'
        this.showSnackbar = true
      })
    },

    handleHourEdit (hour) {
      this.tableDateMenus[hour.id] = false
      this.saveHour(hour)
    },

    handleHourEmployeeInput (hour, newEmployee = null) {
      this.saveHour(hour, newEmployee)
    },

    handleHourDescriptionInput: debounce(function (hour, e) {
      this.saveHour(hour)
    }, 2222),

    deleteHour (hour) {
      this.$apollo.mutate({
        mutation: HOUR.mutations.delete.mutation,
        variables: { id: hour.id },
        update: (store, { data: { deleteHour } }) => {
          const data = store.readQuery(this.$oxide.store.clientQuery)
          const entity = getNestedValue(data, this.path)
          const index = entity.hours.findIndex(h => h.id === hour.id)
          entity.hours.splice(index, 1)
          store.writeQuery({ ...this.$oxide.store.clientQuery, data })
        }// ,
        // optimisticResponse: { __typename: 'Mutation', deleteHour: { ...hour, updated_at: getUTCTimestamp() } }
      }).then(({ data: { updateHour } }) => {
        this.savingTime = false
        this.snackbarText = 'Time entry removed'
        this.snackbarColor = 'blue'
        this.showSnackbar = true
      })
    },

    createInvoice () {
      this.$emit('create:invoice')
    },

    toggleShowAddTime () {
      this.time = 0
      this.showAddTime = !this.showAddTime
    },

    formatNumber (val) {
      return numberFormatter.format(val)
    }
  },
  watch: {
    showAddTime (showAddTime) {
      if (showAddTime && this.me.employee) {
        this.newEntryEmployee = this.me.employee
      }
    }
  }
}
</script>

<style lang="scss">
.time-list {
  .billed {
    opacity: 0.5;
  }
}
</style>
