import { defineModel } from '@/utils'
import { JOBS_QUERY } from '../queries'
import { JOB_CREATE, JOB_UPDATE, JOB_DELETE } from '../mutations'

const JOB = defineModel({
  name: 'Job',
  fields: { id: 0, client_id: 0, title: '', identifier: '', billing_type: '', description: '', start: null, started: null, finish: null, finished: null, status: '' },
  queries: {
    index: {
      query: JOBS_QUERY,
      update ({ company }) {
        const jobs = company.jobs.map(job => {
          if (typeof job.status === 'string') {
            try {
              job.status = JSON.parse(job.status)
            } catch (e) {
              // console.error(e, 'job.status', job.status)
              job.status = [job.status]
            }
          } else if (!Array.isArray(job.status)) {
            job.status = []
          }
          return job
        })

        company.jobs = jobs
        return company
      }
    }
  },
  mutations: {
    create: {
      mutation: JOB_CREATE
    },
    update: {
      mutation: JOB_UPDATE
    },
    delete: {
      mutation: JOB_DELETE
    }
  }
})

export default JOB
