<template>
  <v-layout id="jobs">
    <v-card style="width: 100%;">
      <v-toolbar flat dark color="deep-orange darken-2" prominent extended>
        <v-toolbar-title class="white--text">Jobs</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-select
          dense hide-details
          suffix="time entries" style="max-width: 25%;" color="grey lighten-4"
          :items="['with or without', 'with', 'without']" v-model="hasTime" @change="updateFilters">
        </v-select>
        <v-spacer></v-spacer>
        <v-select
          dense hide-details
          suffix="invoices" style="max-width: 25%;" color="grey lighten-4"
          :items="['with or without', 'with', 'without']" v-model="hasInvoices" @change="updateFilters">
        </v-select>
        <v-spacer></v-spacer>
        <v-menu :nudge-width="100">
          <template v-slot:activator="{ on }">
            <v-toolbar-title v-on="on">
              <span>View: </span><span style="opacity: 0.8;">{{ view }}</span>
              <v-icon dark>arrow_drop_down</v-icon>
            </v-toolbar-title>
          </template>

          <v-list dark>
            <template v-for="(view, index) of Object.keys(views)">
              <v-list-tile :key="view" :to="index ? `/jobs/${view}` : '/jobs'" exact>
                <v-list-tile-title v-text="view"></v-list-tile-title>
              </v-list-tile>
              <v-divider :key="index" v-if="index === 0"></v-divider>
            </template>
          </v-list>
        </v-menu>
        <template #extension>
          <v-layout row wrap ma-0 pa-0>
            <v-text-field
              label="Search"
              v-model="searchText" hide-details clearable
            ></v-text-field>
            <v-select
              multiple chips deletable-chips small-chips dense hide-details
              label="With Status" style="max-width: 50%;"
              :items="statuses" v-model="withStatus">
            </v-select>
            <v-select
              multiple chips deletable-chips small-chips dense hide-details
              label="Without Status" style="max-width: 50%;"
              :items="statuses" v-model="withoutStatus">
            </v-select>
          </v-layout>
        </template>
      </v-toolbar>

      <v-data-table
        v-bind:headers="headers" :items="filteredJobs" :loading="isLoading"
        :no-data-text="isLoading ? 'Loading...' : 'Nothing added yet...'" class="elevation-1 striped"
        no-results-text="No results for the current search"
        :rows-per-page-items="[25,50,100,{text:'$vuetify.dataIterator.rowsPerPageAll',value:-1}]"
        :pagination="pagination" @update:pagination="handlePaginationUpdate"
      >
        <template slot="items" slot-scope="props">
          <tr>
            <td class="text-xs-right pl-1 pr-2 grey--text text--darken-1">
              <!-- {{ parseInt(props.item._relevance * 100, 10) }} -->
              <v-progress-circular
                :value="Math.min(props.item._relevance * 2, 1) * 100"
                :color="'rgba(3, 169, ' + (Math.min(props.item._relevance * 2, 1) * 222) + ', 1)'"
                size="20"
              ></v-progress-circular>
            </td>

            <td class="text-xs-left px-1">
              {{ props.item.client.name }}
            </td>

            <td class="font-weight-medium text-xs-left px-1">
              <router-link :to="'/crm/client/' + props.item.client.id + '/job/' + props.item.id + '?a=2'" style="text-decoration: none;">{{ props.item.title }}</router-link>
            </td>

            <td class="text-xs-center font-weight-medium px-1">
              <v-chip color="grey lighten-3" v-for="(stat, index) in props.item.status" :key="index" label small outline>{{ stat }}</v-chip>
            </td>

            <td class="text-xs-center px-1">
              {{ props.item.hoursByDate.length }}
            </td>

            <td class="text-xs-center px-1">
              {{ props.item.invoices.length }}
            </td>

            <td class="text-xs-center px-1 grey--text text--lighten-2 caption" style="line-height: 1.2;">
              {{ (props.item.created_at ? props.item.created_at : '') | timespan }}
            </td>

            <td class="text-xs-center px-1 grey--text text--lighten-2 caption" style="line-height: 1.2;">
              {{ (props.item.updated_at ? props.item.updated_at : '') | timespan }}
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
  </v-layout>
</template>

<script>
/* eslint-disable handle-callback-err */
/* eslint-disable camelcase */
/* eslint-disable eqeqeq */

// import { JOB } from '@/graphql/models'
import { clone, getNestedValue } from '@/utils'
import { JOB_STATUSES } from '@/constants'

const NOT_FOUND = Symbol('not_found')

export default {
  name: 'jobs',
  components: {},
  // props: { company: {} },
  data () {
    return {
      filters: [],
      headers: [
        { text: 'Rel', align: 'right', value: '_relevance', width: '50px', class: 'px-1' },
        { text: 'Client', align: 'left', value: 'client.name', class: 'px-1' },
        { text: 'Title', align: 'left', value: 'title', class: 'px-1' },
        { text: 'Status', align: 'center', value: 'status', sortable: false, class: 'px-1' },
        { text: 'Time', align: 'center', value: 'hoursByDate.length', class: 'px-1' },
        { text: 'Invoices', align: 'center', value: 'invoices.length', class: 'px-1' },
        { text: 'Created', align: 'center', value: 'created_at', class: 'px-1' },
        { text: 'Updated', align: 'center', value: 'updated_at', class: 'px-1' }
      ],
      searchText: '',
      statuses: JOB_STATUSES,
      views: {
        default: {
          sort: { field: '_relevance', direction: 'desc' },
          withStatus: [],
          withoutStatus: ['internal']
        },
        'ready-to-bill': {
          sort: { field: 'updated_at', direction: 'desc' },
          withStatus: ['ready-to-bill'],
          withoutStatus: ['billed', 'internal']
        },
        languishing: {
          sort: { field: 'updated_at', direction: 'desc' },
          withStatus: [],
          withoutStatus: ['complete', 'internal']
        }
      },
      hasTime: 'with or without',
      hasInvoices: 'with or without'
      // withStatus: [],
      // withoutStatus: []
    }
  },
  computed: {
    pagination () {
      return {
        sortBy: this.$route.query?.sort_by ? this.$route.query.sort_by : '_relevance',
        descending: this.$route.query?.sort_dir !== 'asc',
        page: Number(this.$route.query?.page ? this.$route.query.page : 1),
        rowsPerPage: Number(this.$route.query?.rows ? this.$route.query.rows : 25)
      }
    },
    withStatus: {
      get () { return this.$route.query?.with ? this.$route.query.with.split(',') : [] },
      set (val) { this.updateQuery({ with: val.join(',') }) }
    },
    withoutStatus: {
      get () { return this.$route.query?.without ? this.$route.query.without.split(',') : [] },
      set (val) { this.updateQuery({ without: val.join(',') }) }
    },
    isLoading () { return !this.$oxide.store.apollo.company },
    jobsCatalog () {
      return this.$catalog(
        this.jobs,
        {
          using: ['client.name', 'title', 'status'],
          relevance: { inject: true, threshold: 0.01 }
        }
      )
    },
    filteredJobs () {
      return this.jobsCatalog.resultsFor(this.searchText).filter(this.filterJob)
    },
    jobs () { return this.$oxide.store.apollo.company?.jobs },
    view () { return this.$route.params?.view }
  },
  methods: {
    applyFilter (job, { key, op, val }) {
      const subject = getNestedValue(job, key, { defaultValue: NOT_FOUND, intsAreIds: false, keySeparator: '.' })
      if (subject !== NOT_FOUND) {
        switch (op) {
          case '==': return subject == val
          case '===': return subject === val
          case '>': return subject > val
          case '>=': return subject >= val
          case '<': return subject < val
          case '<=': return subject <= val
          default:
            if (typeof op === 'function') {
              if (typeof val === 'object' && val !== null) {
                return op(subject, ...val)
              } else {
                return op(subject, val)
              }
            } else if (typeof subject[op] === 'function') {
              if (typeof val === 'object' && val !== null) {
                return subject[op](...val)
              } else {
                return subject[op](val)
              }
            } else {
              console.log('invalid op:\n ', op)
            }
        }
      }
      return false
    },
    filterJob (job) {
      return this.filters.every(this.applyFilter.bind(this, job))
    },
    updateFilters ({ withStatus = null, withoutStatus = null, hasTime = null, hasInvoices = null }) {
      if (withStatus === null) { withStatus = this.withStatus }
      if (withoutStatus === null) { withoutStatus = this.withoutStatus }
      if (hasTime === null) { hasTime = this.hasTime }
      if (hasInvoices === null) { hasInvoices = this.hasInvoices }
      const filters = []
      if (hasTime === 'with' || hasTime === 'without') {
        filters.push({ key: 'hoursByDate.length', op: hasTime === 'with' ? '>' : '===', val: 0 })
      }
      if (hasInvoices === 'with' || hasInvoices === 'without') {
        filters.push({ key: 'invoices.length', op: hasInvoices === 'with' ? '>' : '===', val: 0 })
      }
      for (let i = 0; i < withStatus.length; i++) {
        filters.push({ key: 'status', op: 'includes', val: [withStatus[i]] })
      }
      for (let i = 0; i < withoutStatus.length; i++) {
        filters.push({ key: 'status', op: jobStatus => !jobStatus.includes(withoutStatus[i]) })
      }
      this.filters = filters
    },
    updateView (name = null) {
      if (name === null) {
        name = this.view
        return false
      }
      if (!name || this.views[name] === 'undefined') {
        name = 'default'
      }

      const view = this.views[name]
      this.withStatus = [...view.withStatus]
      this.withoutStatus = [...view.withoutStatus]
      this.pagination.sortBy = view.sort.field
      this.pagination.descending = view.sort?.direction === 'desc'
    },
    handlePaginationUpdate (pagination) {
      this.updateQuery({
        sort_by: pagination.sortBy,
        sort_dir: pagination.descending ? 'desc' : 'asc',
        page: pagination.page,
        rows: pagination.rowsPerPage
      })
    },
    updateQuery (data) {
      const query = clone(this.$route.query)
      let updateRequired = false
      for (const [key, value] of Object.entries(data)) {
        if (query[key] != value) {
          query[key] = value
          updateRequired = true
        }
      }
      if (updateRequired) {
        this.$router.replace({ query }, null, e => {
          console.trace()
          console.log('farts')
          console.log(e)
        })
      }
    }
  },
  watch: {
    view (name) {
      this.updateView(name)
    },
    withStatus (withStatus) {
      if (withStatus.length && this.withoutStatus.length) {
        for (let i = 0; i < this.withoutStatus.length; i++) {
          if (withStatus.includes(this.withoutStatus[i])) {
            this.withoutStatus.splice(i, 1)
            break
          }
        }
      }
      this.updateFilters({ withStatus })
    },
    withoutStatus (withoutStatus) {
      if (withoutStatus.length && this.withStatus.length) {
        for (let i = 0; i < this.withStatus.length; i++) {
          if (withoutStatus.includes(this.withStatus[i])) {
            this.withStatus.splice(i, 1)
            break
          }
        }
      }
      this.updateFilters({ withoutStatus })
    }
  },
  created () {
    //
  },
  mounted () {
    // this.updateView()
    this.updateFilters({})
  },
  beforeDestroy () {
    //
  }
}
</script>

<style lang="scss">
#jobs {
  margin: 0;

  .v-toolbar  .v-select .v-select__selections {
    flex-direction: row-reverse;
  }
}
</style>
