<template>
  <v-flex>
    <v-menu lazy offset-y full-width
      ref="startDateMenu" max-width="290px" min-width="290px" transition="scale-transition"
      v-model="startDateMenuOpen" :close-on-content-click="false">
      <template v-slot:activator="{ on }">
        <v-text-field :value="startDate" v-on="on" label="Start" prepend-icon="event" style="width: 50%;" hide-details></v-text-field>
      </template>
      <v-date-picker v-model="startDate" no-title :max="endDate" @input="startDateMenuOpen = false"></v-date-picker>
    </v-menu>
    <v-menu lazy offset-y full-width
      ref="endDateMenu" max-width="290px" min-width="290px" transition="scale-transition"
      v-model="endDateMenuOpen" :close-on-content-click="false">
      <template v-slot:activator="{ on }">
        <v-text-field :value="endDate" v-on="on" label="End" prepend-icon="event" style="width: 50%;" hide-details></v-text-field>
      </template>
      <v-date-picker v-model="endDate" no-title :min="startDate" @input="endDateMenuOpen = false"></v-date-picker>
    </v-menu>
  </v-flex>
</template>

<script>
import { formatDecimal, getDate } from '@/utils'
import { lastDayOfWeek, startOfWeek, toDate } from 'date-fns'

export default {
  name: 'date-interval',
  props: {
    initialStart: { type: String, required: false, default () { return getDate(toDate(startOfWeek(new Date()))) } },
    initialEnd: { type: String, required: false, default () { return getDate(toDate(lastDayOfWeek(new Date()))) } }
  },
  data () {
    return {
      endDate: '',
      endDateMenuOpen: false,
      startDate: '',
      startDateMenuOpen: false
    }
  },
  computed: {

  },
  methods: {
    formatHour (h) {
      return formatDecimal(h, 2)
    },
    handleInput () {
      this.endDateMenuOpen = false
      this.startDateMenuOpen = false
      this.$emit('changed', { start: this.startDate, end: this.endDate })
    }
  },
  mounted () {
    if (this.endDate === '') {
      this.endDate = this.initialEnd
    }
    if (this.startDate === '') {
      this.startDate = this.initialStart
    }
  }
}
</script>

<style lang="scss">

</style>
