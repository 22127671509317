<template>
  <v-card light id="invoice" v-if="typeof invoice !== 'undefined'" :class="{ 'prices-hidden': hidePrices, 'reordering-lines': reorderLines }">

    <v-navigation-drawer
      v-model="showSidebar" width="350"
      disable-resize-watcher disable-route-watcher fixed hide-overlay left temporary>
      <div class="title py-3 text-xs-center">Invoices</div>
      <v-divider></v-divider>
      <v-text-field
        @input.passive="handleSearchInput"
        label="search" class="ma-0 pa-0"
        clearable full-width hide-details>
      </v-text-field>
      <v-divider></v-divider>
      <v-list>
        <v-list-tile to="/invoice" exact>New Invoice</v-list-tile>
        <template v-if="clientInvoices.length > 1 || invoiceSearch">
          <template v-for="client in clientInvoices">
            <v-subheader :key="'subheader:' + client.id + ':' + client.invoices[0].id">{{ client.name }}</v-subheader>
            <v-list-tile v-for="invoice in client.invoices" :key="'tile:' + client.id + ':' + invoice.id" :to="`/invoice/${invoice.id}`">
              <v-list-tile-title>{{ invoice.number }} {{ invoice.name ? invoice.name.replace(client.name + ' - ', '') : '' }}</v-list-tile-title>
              <v-list-tile-action style="min-width: 60px;">
                <v-list-tile-action-text>{{ invoice.created_at.split(' ')[0] }}</v-list-tile-action-text>
              </v-list-tile-action>
            </v-list-tile>
            <v-divider :key="'divider:' + client.id + ':' + client.invoices[0].id"></v-divider>
          </template>
        </template>
        <template v-else-if="clientInvoices.length === 1">
          <v-divider></v-divider>
          <v-list-tile v-for="invoice in clientInvoices[0].invoices" :key="'invoice:' + invoice.id" :to="`/invoice/${invoice.id}`" class="hover-action">
            <v-list-tile-title>{{ invoice.number }} {{ invoice.name }}</v-list-tile-title>
            <!-- <v-list-tile-action>
              <v-icon>delete</v-icon>
            </v-list-tile-action> -->
          </v-list-tile>
        </template>
      </v-list>
    </v-navigation-drawer>

    <v-layout settings-header row pa-0 ma-0 print-hide>
      <v-flex pa-0>
        <v-autocomplete
          v-if="invoice.job === null || typeof invoice.job === 'undefined'"
          v-model="selectedJob" :items="clientJobs"
          label="Client | Job" clearable dense full-width hide-details
        ></v-autocomplete>
        <div v-else @click.stop="$router.push({ name: 'crm', params: { rel1_type: 'client', rel1_id: invoice.job.client.id, rel2_type: 'job', rel2_id: invoice.job.id } })" class="print-hide" style="cursor: pointer;">
          <v-text-field
            full-width hide-details readonly
            :value="invoice.job.client.name + ' | ' + invoice.job.title"
            label="Client" style="pointer-events: none;"
          ></v-text-field>
        </div>
      </v-flex>
      <v-flex shrink px-3>
        <v-switch v-model="recentOnly" color="cyan" :label="'Only Recent (' + ($apolloData.queries.invoices.loading ? '*' : invoices.length) + ')'"></v-switch>
      </v-flex>
      <v-flex shrink px-3>
        <v-switch v-model="hidePrices" color="cyan" label="Hide prices"></v-switch>
      </v-flex>
      <v-flex shrink px-3 reorder-switch>
        <v-switch v-model="reorderLines" color="deep-orange" label="Reorder Lines"></v-switch>
      </v-flex>
    </v-layout>

    <v-card-title>
      <v-layout row justify-space-between align-center>
        <v-flex style="flex-grow: 0;">
          <template v-if="company">
            <img v-if="company.config && company.config.logo" :src="company.config.logo" style="max-height: 50px;"/>
            <span v-else class="display-2">{{ company.name }}</span>
          </template>
        </v-flex>
        <v-flex class="headline pl-4">
          {{ nomenclature('invoice') }}
        </v-flex>
        <v-flex md8 sm6 xs12>
          <v-layout row justify-end>
            <!-- <v-flex xs4>
              <v-text-field v-model="invoice.number" type="number" class="pt-0" prefix="#" hide-details single-line></v-text-field>
            </v-flex> -->
            <v-flex xs12 text-xs-right>
              <v-textarea v-model="invoice.name" type="text" class="pt-0 print-hide text-xs-right" placeholder="name..." counter="255" single-line auto-grow rows="1"></v-textarea>
              <span class="print-only subheading">{{ invoice.name }}</span>
            </v-flex>
          </v-layout>
        </v-flex>

      </v-layout>
    </v-card-title>

    <v-layout invoice-content row wrap align-end align-content-end style="min-height: 400px;">
      <v-flex sm12 class="item-list" :class="{ 'md8': !hidePrices, 'lg9': !hidePrices }">
        <v-list subheader dense>
          <v-subheader inset>Items</v-subheader>
          <template v-if="reorderLines">
            <draggable v-model="invoice.lines" draggable=".line" handle=".v-avatar">
              <v-list-tile avatar
                v-for="(line, index) in invoice.lines" :key="index"
                class="line" :class="{'temp-billable':!line.billable.id, 'show-category': showCategory(line, index)}"
                :color="!line.billable.id ? 'deep-orange--text text--darken-2' : 'blue'"
                :data-category="showCategory(line, index) ? line.billable.category.name : ''"
              >
                <v-list-tile-avatar class="print-hide" style="min-width: auto; cursor: row-resize; padding: 0 4px;" size="32">
                  <v-icon style="pointer-events: none;">drag_handle</v-icon>
                </v-list-tile-avatar>

                <v-list-tile-content>
                  <v-list-tile-title>
                    <v-layout row wrap>

                      <v-flex xs12 text-truncate v-if="line.billable.id" :class="{ 'md10': hidePrices, 'md6': !hidePrices, 'sm10': hidePrices, 'sm6': !hidePrices }">
                        <div style="padding: 8px 0;">
                          <span class="font-mono grey--text text--darken-2 font-weight-medium">{{ line.billable.name }}{{ line.billable.size ? ' | ' : '' }}</span>
                          {{ line.billable.size }}
                        </div>
                      </v-flex>
                      <template v-else>
                        <v-flex xs8 :class="{ 'md9': hidePrices, 'md5': !hidePrices, 'sm9': hidePrices, 'sm5': !hidePrices }">
                          <v-text-field
                            v-model="line.billable.name" :tabindex="index.toString() + 1"
                            placeholder="description" class="pt-0" ref="tempBillableDescriptions"
                            single-line hide-details readonly
                          ></v-text-field>
                        </v-flex>

                        <v-flex md1 sm1 xs4>
                          <v-text-field
                            v-model="line.billable.supplier" :tabindex="index.toString() + 1"
                            :placeholder="isLabour(line.billable) || isPermit(line.billable) ? '' : 'supplier'"
                            class="temp-billable--supplier pt-0 shrink" readonly
                            single-line hide-details
                          ></v-text-field>
                        </v-flex>
                      </template>

                      <v-flex lg2 md2 sm2 xs6 pr-1>
                        <v-text-field
                          v-model="line.quantity" :tabindex="index.toString() + 1" :error="!(parseFloat(line.quantity) > 0)"
                          class="pt-0 quantity-input" ref="lineQuantityInputs"
                          :prefix="pluralize(line.billable.unit, line.quantity)" placeholder="0" inputmode="decimal" readonly
                          single-line hide-details reverse
                        >
                          <template #prepend-inner><span style="margin-top: 4px; margin-left: 4px;" v-text="hidePrices ? '' : '@'"></span></template>
                        </v-text-field>
                      </v-flex>

                      <v-flex md2 sm2 xs3 v-show="!hidePrices">
                        <v-text-field
                          v-model="line.cost" :tabindex="index.toString() + 2"
                          :placeholder="Math.round(line.billable.id ? (line.billable.cost * markupMultiplier) : line.billable.cost) | monetaryPreScaled"
                          class="pt-0 line-cost-input" prefix="$" ref="lineCostInputs" inputmode="decimal" :readonly="reorderLines"
                          single-line hide-details
                        >
                          <template #append-outer v-if="line.billable.id && line.cost">
                            <confirm-action
                              confirm-title="Update Billable Price"
                              confirm-text="Save this amount as the new pre-markup cost?"
                              @confirm="updateBillableCost(line)">
                              <template v-slot:act="{ on }">
                                <v-btn icon flat small color="error" @click.stop="on.click" class="ma-0 update-cost-button">
                                  <v-tooltip top open-delay="500">
                                    <v-icon small slot="activator">save</v-icon>
                                    Save Price Change
                                  </v-tooltip>
                                </v-btn>
                              </template>
                              <template #text>
                                <div>
                                Save {{ line.cost | monetary }} as price including {{ markupRate * 100 }}% markup<br>
                                  <span class="caption">( {{ Math.round(line.cost / markupMultiplier * 100) | monetaryPreScaled }} your cost )</span>
                                </div>
                              </template>
                            </confirm-action>
                          </template>
                        </v-text-field>
                      </v-flex>

                      <v-flex md2 sm2 xs3 class="text-xs-right subheading" v-if="!hidePrices">
                        $ {{ lineTotal(line) | monetaryPreScaled }}
                      </v-flex>

                    </v-layout>
                  </v-list-tile-title>

                </v-list-tile-content>
              </v-list-tile>
            </draggable>
          </template>
          <template v-else v-for="(line, index) in invoice.lines">
            <v-list-tile v-if="line.billable && line.billable.id && (index === 0 || (!invoice.lines[index - 1].billable.id) || line.billable.category.id !== invoice.lines[index - 1].billable.category.id)" :key="'cat:' + index">
              <span class="grey--text text--darken-2 font-weight-medium">{{ line.billable.category ? (line.billable.category.name ? line.billable.category.name : line.billable.category) : '' }}:</span>
            </v-list-tile>

            <v-list-tile :class="{'temp-billable':!line.billable.id}" :key="index" :color="!line.billable.id ? 'deep-orange--text text--darken-2' : 'blue'">

              <v-list-tile-content>
                <v-list-tile-title>
                  <v-layout row wrap>

                    <v-flex xs12 text-truncate v-if="line.billable.id" :class="{ 'md10': hidePrices, 'md6': !hidePrices, 'sm10': hidePrices, 'sm6': !hidePrices }">
                      <v-tooltip bottom color="grey lighten-5">
                        <template #activator="data">
                          <div v-on="data.on" style="padding: 8px 0;">
                            <span class="font-mono grey--text text--darken-2 font-weight-medium pl-4">{{ line.billable.name }}{{ line.billable.size ? ' | ' : '' }}</span>
                            {{ line.billable.size }}
                          </div>
                        </template>
                        <span>
                          <!-- <span class="font-mono subheading grey--text text--darken-3 mr-3 ml-1 mt-1">{{ line.billable.size }}</span> -->
                          <v-chip
                            v-for="(tag, tagIndex) of line.billable.tags" :key="tagIndex"
                            small label outline :color="getColor(tag)" class="darken-3 text--darken-3"
                          >{{ tag }}</v-chip>
                        </span>
                      </v-tooltip>
                    </v-flex>
                    <template v-else>
                      <v-flex xs8 :class="{ 'md9': hidePrices, 'md5': !hidePrices, 'sm9': hidePrices, 'sm5': !hidePrices }">
                        <v-text-field
                          v-model="line.billable.name" :tabindex="index.toString() + 1"
                          placeholder="description" class="pt-0" ref="tempBillableDescriptions"
                          single-line hide-details
                        ></v-text-field>
                      </v-flex>
                      <!-- <v-flex md2 sm3 xs4 :class="{ 'supplier-empty': (typeof line.billable.supplier !== 'string' || line.billable.supplier.trim() === '') }"> -->
                      <v-flex md1 sm1 xs4>
                        <v-text-field
                          v-model="line.billable.supplier" :tabindex="index.toString() + 1"
                          :placeholder="isLabour(line.billable) || isPermit(line.billable) ? '' : 'supplier'"
                          class="temp-billable--supplier pt-0 shrink"
                          single-line hide-details
                        ></v-text-field>
                      </v-flex>
                    </template>

                    <v-flex lg2 md2 sm2 xs6 pr-1>
                      <v-text-field
                        v-model="line.quantity" :tabindex="index.toString() + 1" :error="!(parseFloat(line.quantity) > 0)"
                        class="pt-0 quantity-input" ref="lineQuantityInputs"
                        :prefix="pluralize(line.billable.unit, line.quantity)" placeholder="0" inputmode="decimal"
                        single-line hide-details reverse
                      >
                        <template #prepend-inner><span style="margin-top: 4px; margin-left: 4px;" v-text="hidePrices ? '' : '@'"></span></template>
                      </v-text-field>
                    </v-flex>

                    <v-flex md2 sm2 xs3 v-show="!hidePrices">
                      <v-text-field
                        v-model="line.cost" :tabindex="index.toString() + 2"
                        :placeholder="Math.round(line.billable.id ? (line.billable.cost * markupMultiplier) : line.billable.cost) | monetaryPreScaled"
                        class="pt-0 line-cost-input" prefix="$" ref="lineCostInputs" inputmode="decimal"
                        single-line hide-details
                      >
                        <template #append-outer v-if="line.billable.id && line.cost">
                          <confirm-action
                            confirm-title="Update Billable Price"
                            confirm-text="Save this amount as the new pre-markup cost?"
                            @confirm="updateBillableCost(line)">
                            <template v-slot:act="{ on }">
                              <v-btn icon flat small color="error" @click.stop="on.click" class="ma-0 update-cost-button">
                                <v-tooltip top open-delay="500">
                                  <v-icon small slot="activator">save</v-icon>
                                  Save Price Change
                                </v-tooltip>
                              </v-btn>
                            </template>
                            <template #text>
                              <div>
                              Save {{ line.cost | monetary }} as price including {{ markupRate * 100 }}% markup<br>
                                <span class="caption">( {{ Math.round(line.cost / markupMultiplier * 100) | monetaryPreScaled }} your cost )</span>
                              </div>
                            </template>
                          </confirm-action>
                        </template>
                      </v-text-field>
                    </v-flex>

                    <!-- <v-spacer></v-spacer> -->

                    <v-flex md2 sm2 xs3 class="text-xs-right subheading" v-if="!hidePrices">
                      $ {{ lineTotal(line) | monetaryPreScaled }}
                    </v-flex>

                  </v-layout>
                </v-list-tile-title>

              </v-list-tile-content>
              <v-list-tile-action style="min-width: 40px;">
                <v-btn icon ripple @click="removeLine(index)">
                  <v-icon color="grey lighten-1">clear</v-icon>
                </v-btn>
              </v-list-tile-action>
            </v-list-tile>
          </template>

        </v-list>

        <billables-search
          :label="'Search for an' + (invoice.lines.length ? 'other' : '') + ' item to add'"
          :tabindex="invoice.lines.length.toString() + '0'"
          @change="handleInput" :style="{ pointerEvents: reorderLines ? 'none' : 'auto', opacity: reorderLines ? 0.5 : 1 }"
        ></billables-search>
      </v-flex>

      <v-flex sm12 md4 lg3 class="totals" v-if="!hidePrices">
        <v-list>

          <template v-if="subtotals.billables">
            <v-list-tile>
              <v-list-tile-content>
                <v-list-tile-title>
                  Materials: <span class="right">{{ subtotals.billables | monetaryPreScaled | monetaryFormatted }}</span>
                </v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
            <v-divider></v-divider>
          </template>

          <template v-if="subtotals.labor">
            <v-list-tile>
              <v-list-tile-content>
                <v-list-tile-title>
                  Labour: <span class="right">{{ subtotals.labor | monetaryPreScaled | monetaryFormatted }}</span>
                </v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
            <v-divider></v-divider>
          </template>

          <template v-if="subtotals.permits">
            <v-list-tile>
              <v-list-tile-content>
                <v-list-tile-title>
                  Permits: <span class="right">{{ subtotals.permits | monetaryPreScaled | monetaryFormatted }}</span>
                </v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
            <v-divider></v-divider>
          </template>

          <template v-if="taxRate">
            <v-list-tile>
              <v-list-tile-content>
                <v-list-tile-title>
                  Tax: <span class="right">{{ taxes | monetaryPreScaled | monetaryFormatted }}</span>
                </v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
            <v-divider></v-divider>
          </template>

          <v-divider></v-divider>
          <v-list-tile>
            <v-list-tile-content>
              <v-list-tile-title>
                <span class="title">Total: <span class="right">{{ total | monetaryPreScaled | monetaryFormatted }}</span></span>
              </v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
        </v-list>
      </v-flex>
    </v-layout>

    <v-speed-dial v-model="actions" fixed bottom right transition="slide-y-reverse-transition" style="z-index: 22;">
      <template v-slot:activator>
        <v-btn v-model="actions" color="secondary darken-1" dark fab>
          <v-icon>more_vert</v-icon>
          <v-icon>close</v-icon>
        </v-btn>
      </template>

      <v-btn fab dark small color="primary" @click="save">
        <v-tooltip left nudge-left="10">
          <v-icon slot="activator">save</v-icon>
          Save Invoice
        </v-tooltip>
      </v-btn>

      <v-btn @click="createTempBillable('')" dark fab small color="secondary lighten-2" class="mb-3">
        <v-tooltip left nudge-left="10">
          <v-icon slot="activator">plus_one</v-icon>
          Add Temporary Item
        </v-tooltip>
      </v-btn>

      <v-btn @click="createTempBillable('labour')" dark fab small color="blue lighten-2" class="mb-3">
        <v-tooltip left nudge-left="10">
          <v-icon slot="activator">engineering</v-icon>
          Add Labour
        </v-tooltip>
      </v-btn>

      <v-btn @click="createTempBillable('permit')" dark fab small color="green lighten-2" class="mb-3">
        <v-tooltip left nudge-left="10">
          <v-icon slot="activator">grading</v-icon>
          Add Permit
        </v-tooltip>
      </v-btn>

      <!-- <v-btn fab dark small color="accent">
        <v-tooltip left nudge-left="10">
          <v-icon slot="activator">edit</v-icon>
          Invoice Details
        </v-tooltip>
      </v-btn> -->

      <confirm-action @confirm="deleteInvoice" v-if="invoiceId">
        <template v-slot:act="{ on }">
          <v-btn fab dark small color="error" @click.stop="on.click">
            <v-tooltip left nudge-left="10">
              <v-icon slot="activator">delete</v-icon>
              Delete Invoice
            </v-tooltip>
          </v-btn>
        </template>
      </confirm-action>

    </v-speed-dial>

    <v-snackbar v-model="showSnackbar" :color="snackbarColor" top>
      {{ snackbarText }}
      <v-btn dark flat @click="showSnackbar = false">
        Close
      </v-btn>
    </v-snackbar>

    <v-dialog v-model="unsavedDialog" max-width="290" hide-overlay light>
      <v-card>
        <v-card-title class="headline">Unsaved Changes</v-card-title>

        <v-card-text>
        </v-card-text>

        <v-card-actions>
          <v-btn color="orange darken-1" flat="flat" @click="resetInvoice">Discard</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="grey darken-1" flat="flat" @click="unsavedDialog = false">Cancel</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" flat="flat" @click="save">Save Now</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-card>

</template>

<script>
/* eslint-disable camelcase */
/* eslint-disable handle-callback-err */

import { COLORS, MINUTE } from '@/constants'
import { clone, cloneExcept, cloneOnly, findKeyByProperty, getNestedValue, getUTCTimestamp, itemFromChar, pluralize } from '@/utils'
import ConfirmAction from '@/components/ConfirmAction.vue'
import BillablesSearch from '@/components/BillablesSearch.vue'
import { BILLABLE, INVOICE } from '@/graphql/models'
import { debounce, sortBy } from 'lodash'
import Timers from '@/timers'
import draggable from 'vuedraggable'
import format from 'date-fns/format'
import startOfYear from 'date-fns/startOfYear'
import subYears from 'date-fns/subYears'

const timers = new Timers()

export default {
  name: 'invoice',
  components: { BillablesSearch, ConfirmAction, draggable },
  props: { company: {} },
  data () {
    return {
      actions: false,
      addedBillable: null,
      hidePrices: false,
      invoice: INVOICE.blank,
      invoiceListOpen: [],
      invoices: [],
      invoiceSearch: '',
      originalInvoice: '',
      recentOnly: true,
      reorderLines: false,
      searchText: '',
      selectedClient: null,
      selectedJob: null,
      showDetailsForm: false,
      showSnackbar: false,
      snackbarText: '',
      snackbarColor: 'green',
      unsavedDialog: false,
      unsavedTargetId: null
    }
  },
  apollo: {
    invoices: {
      query: INVOICE.queries.index.query,
      variables () { return { newerThan: this.onlyShowInvoicesNewerThan } },
      update: INVOICE.queries.index.update,
      pollInterval: 0,
      watchLoading (isLoading, countModifier) {
        if (!isLoading && this.invoices) {
          this.editInvoice(this.invoiceId)
        }
      },
      result: INVOICE.queries.index.result,
      fetchPolicy: 'cache-and-network'
    }
  },
  computed: {
    showSidebar: {
      get () { return this.$oxide.bus.showSidebar },
      set (show) { this.$oxide.bus.showSidebar = show }
    },
    invoiceCatalog () {
      return this.$catalog(
        this.invoices,
        {
          using: ['name', 'number', 'client.name', 'job.title', 'job.client.name'],
          relevance: { inject: true, threshold: 0.01 },
          results: { limit: 50 }
        }
      )
    },
    filteredInvoices () { return this.invoiceCatalog.resultsFor(this.invoiceSearch) },
    clients () { return this.$oxide.store.apollo.clients },
    jobs () { return this.$oxide.store.apollo.company?.jobs },
    clientJobs () {
      if (!this.clients?.length || !this.jobs?.length) { return [] }
      return this.jobs.map(job => ({ value: job.id, text: `${job.client.name} | ${job.title}` }))
    },
    clientInvoices () {
      if (!this.clients?.length) { return [] }

      const invoicesByClient = []

      if (this.invoiceSearch === null || this.invoiceSearch.trim() === '') {
        const clients = sortBy(this.clients, ['name'])

        for (const client of clients) {
          const invoices = [...this.invoices?.filter(invoice => client.id === invoice.client?.id), ...this.invoices?.filter(invoice => client.id === invoice?.job?.client?.id)]
          if (invoices?.length) {
            invoicesByClient.push({ id: client.id, name: client.name, invoices })
          }
        }
        const orphanInvoices = this.invoices?.filter(invoice => !invoice.client && !invoice.job)
        if (orphanInvoices?.length) {
          invoicesByClient.push({ id: 0, name: 'No Client', invoices: orphanInvoices })
        }
      } else {
        for (const invoice of this.filteredInvoices) {
          const client = invoice.client?.id ? invoice.client : (invoice.job?.client?.id ? invoice.job.client : { id: 0, name: 'No Client' })
          if (invoicesByClient.length && invoicesByClient[invoicesByClient.length - 1].id === client.id) {
            invoicesByClient[invoicesByClient.length - 1].invoices.push(invoice)
          } else {
            invoicesByClient.push({ id: client.id, name: client.name, invoices: [invoice] })
          }
        }
      }
      return invoicesByClient
    },
    billables () { return this.$oxide.store.apollo.billables },
    categories () { return this.$oxide.store.apollo.categories },
    invoiceId () {
      return parseInt(this.$route.params.invoiceId ? this.$route.params.invoiceId : 0, 10)
    },
    config () {
      return this.company && this.company.config ? this.company.config : []
    },
    markupRate () {
      return getNestedValue(this.config, 'accounting/markup', { defaultValue: 0 }) / 100
    },
    markupMultiplier () { return 1 + this.markupRate },
    taxRate () {
      const taxes = getNestedValue(this.config, 'accounting/taxes', { defaultValue: [] })
      return taxes.reduce((rate, tax) => rate + tax.value, 0) / 100
    },
    taxMultiplier () { return 1 + this.taxRate },
    billableCatalog () {
      const billables = (this.$apollo.queries.billables && this.$apollo.queries.billables.loading) ? [] : this.billables
      return this.$billableCatalog(
        billables,
        {
          using: ['name', 'size', 'tags'],
          relevance: { inject: true, threshold: 0.001 }
        }
      )
    },
    filteredBillables () {
      let query = this.searchText
      if (!query || (typeof query !== 'string') || query.trim() === '') {
        query = ' '
      }
      return this.billableCatalog.resultsFor(query)
    },
    subtotal () {
      let sub = 0
      for (let i = 0; i < this.invoice.lines.length; i++) {
        sub += this.lineTotal(this.invoice.lines[i])
      }
      return sub
    },
    subtotals () {
      let billables = 0
      let labor = 0
      let permits = 0
      for (const line of this.invoice.lines) {
        if (!line.billable.id) {
          const name = line.billable?.name ? line.billable.name.trim().toLowerCase() : ''
          if (name.includes('labor') || name.includes('labour')) {
            labor += this.lineTotal(line)
          } else if (name.includes('permit')) {
            permits += this.lineTotal(line)
          } else {
            billables += this.lineTotal(line)
          }
        } else {
          billables += this.lineTotal(line)
        }
      }
      return { billables, labor, permits }
    },
    taxes () { return Math.round(this.subtotal * this.taxRate) },
    total () { return this.subtotal + this.taxes },
    dirty () {
      return JSON.stringify(this.invoice) !== this.originalInvoice
    },
    onlyShowInvoicesNewerThan () {
      const startOfTwoYearsAgo = format(subYears(startOfYear(new Date()), 1), 'yyyy-MM-dd HH:mm:ss')
      return this.recentOnly ? startOfTwoYearsAgo : '2018-01-01 00:00:00'
    }
  },
  methods: {
    handleSearchInput (e) {
      if (typeof e === 'string') {
        this.updateInvoiceSearchQuery(e.trim())
      } else if (e === null) {
        this.updateInvoiceSearchQuery('')
      }
    },
    updateInvoiceSearchQuery: debounce(function (value) {
      this.invoiceSearch = value
    }, 1000),

    handleInput (input) {
      if (input) {
        this.focusLastLine('lineQuantityInputs')
        const billable = cloneExcept(input, ['__typename', 'created_at', 'updated_at', 'category'])
        billable.category = cloneExcept(input.category, ['__typename', 'created_at', 'updated_at'])
        this.invoice.lines.push({ billable, cost: null, quantity: '' })
      }
    },

    lineCost (line) {
      if (line.cost !== null && line.cost !== '' && typeof line.cost !== 'undefined') {
        // line with cost overridden so no markup needed
        // converted from dollars to cents
        return Math.round(line.cost * 100)
      } else if (line.billable.id) {
        // line with billable at the default cost
        // gets marked up
        return Math.round(line.billable.cost * this.markupMultiplier)
      } else {
        // labor cost does not get marked up
        // only labor has a billable cost but no id
        return line.billable.cost
      }
    },
    lineQuantity (line) { return Number((line.quantity !== null && line.quantity !== '') ? line.quantity : 0) },
    lineTotal (line) { return Math.round(this.lineCost(line) * this.lineQuantity(line)) },
    pluralize (str, count = 0) { return pluralize(str, count) },

    isLabour (billable) {
      if (typeof billable?.name === 'string') {
        const name = billable.name.toLowerCase()
        return name.includes('labour') || name.includes('labor')
      } else {
        return false
      }
    },
    isPermit (billable) { return billable?.name?.toLowerCase().includes('permit') },

    createTempBillable (name = '') {
      const billable = cloneExcept(BILLABLE.blank, ['__typename', 'created_at', 'updated_at'])
      billable.name = name

      this.focusLastLine(name === 'permit' ? 'lineCostInputs' : 'tempBillableDescriptions')
      this.invoice.lines.push({ billable, cost: null, quantity: name === 'permit' ? '1' : '' })
    },

    focusLastLine (target, lines = null) {
      if (lines === null) {
        lines = Array.isArray(this.$refs[target]) ? this.$refs[target].length : 0
      }
      if (Array.isArray(this.$refs[target]) && this.$refs[target].length > lines) {
        const lastIndex = this.$refs[target].length - 1
        const input = this.$refs[target][lastIndex]
        input.focus()
        if (target === 'lineQuantityInputs') {
          input.$refs.input.setSelectionRange(0, 0)
        }
      } else {
        setTimeout(this.focusLastLine.bind(this, target, lines), 300)
      }
    },

    removeLine (index) {
      this.invoice.lines.splice(index, 1)
    },

    getColor (str) {
      return itemFromChar(COLORS, str)
    },

    nomenclature (str) {
      return getNestedValue(this.config, `nomenclature/${str}`, { defaultValue: str })
    },

    getCategoryName (id) {
      if (!this.$apollo.queries?.categories?.loading) {
        const category = this.categories.find(category => parseInt(category.id, 10) === parseInt(id, 10))
        if (category) {
          return category.name
        }
      }
      return ''
    },

    async save () {
      // const timestamp = getUTCTimestamp()

      const invoice = {
        ...cloneOnly(this.invoice, ['name', 'number', 'paid', 'due_at', 'paid_at']),
        lines: JSON.stringify(this.invoice.lines),
        total: this.total ? this.total : 0
      }
      this.unsavedDialog = false
      const oldJobId = this.invoice.job?.id ? this.invoice.job.id : null
      const newJob = this.selectedJob === null ? null : this.jobs.find(job => job.id === this.selectedJob)
      if (this.selectedJob !== oldJobId) {
        if (!this.selectedJob && oldJobId) {
          invoice.job = { disconnect: true }
          invoice.client = { disconnect: true }
        } else if (newJob) {
          invoice.job = { connect: this.selectedJob }
        }
      }

      if (!this.invoice.id) {
        // create
        invoice.company = { connect: this.company.id }
        this.$apollo.mutate({
          mutation: INVOICE.mutations.create.mutation,
          variables: { invoice },
          update: INVOICE.mutations.create.update
        }).then(({ data }) => {
          if (typeof data?.createInvoice?.id !== 'undefined') {
            const route = { params: { invoiceId: data.createInvoice.id } }
            if (this.$route.name !== 'invoice') {
              route.name = 'invoice'
            }
            this.$router.push(route)
            this.snackbarText = 'Invoice Added'
            this.snackbarColor = 'green'
            this.showSnackbar = true
          }
        })
      } else {
        // update
        invoice.id = this.invoice.id
        this.$apollo.mutate({
          mutation: INVOICE.mutations.update.mutation,
          variables: { invoice },
          update: INVOICE.mutations.update.update
        }).then(({ data }) => {
          this.invoice.job = newJob
          this.snackbarText = 'Invoice Saved'
          this.snackbarColor = 'green'
          this.showSnackbar = true
          this.originalInvoice = JSON.stringify(this.invoice)
          if (this.unsavedTargetId !== null) {
            const tempId = this.unsavedTargetId
            this.unsavedTargetId = null
            setTimeout(() => {
              this.$router.push({ params: { invoiceId: tempId } })
            }, 3000)
          }
        })
      }
    },

    deleteInvoice () {
      this.actions = false
      if (this.invoice.id) {
        const timestamp = getUTCTimestamp()
        const invoice = clone(this.invoice)
        this.$apollo.mutate({
          mutation: INVOICE.mutations.delete.mutation,
          variables: { id: this.invoice.id },
          update: (store, { data: { deleteInvoice } }) => {
            const data = store.readQuery({ query: INVOICE.queries.index.query })
            const index = findKeyByProperty(data.invoices, 'id', deleteInvoice.id, { castToInt: true })
            data.invoices.splice(index, 1)
            store.writeQuery({ query: INVOICE.queries.index.query, data })
          },
          optimisticResponse: { __typename: 'Mutation', deleteInvoice: { ...invoice, created_at: timestamp, updated_at: timestamp } }
        }).then(({ data }) => {
          this.$router.go(-1)
        })
      }
    },

    closeAddBillable () {
      if (this.$refs.addBillable) {
        this.$refs.addBillable.blur()
      }
    },

    getInvoice (id) {
      return this.invoices ? this.invoices.find(invoice => parseInt(invoice.id, 10) === id) : undefined
    },

    async editInvoice (id) {
      const currentId = this.invoice?.id ? parseInt(this.invoice.id, 10) : 0

      if (id !== currentId) {
        let invoice = this.getInvoice(id)
        this.unsavedTargetId = null
        if (typeof invoice === 'undefined') {
          invoice = INVOICE.blank
          this.selectedJob = null
        } else {
          this.selectedJob = invoice.job === null ? null : invoice.job.id
        }
        this.showSidebar = false
        this.originalInvoice = JSON.stringify(invoice)
        this.$set(this, 'invoice', invoice)
      }
    },

    async updateBillableCost (line) {
      // const timestamp = getUTCTimestamp()
      const cost = Math.round(Math.round(line.cost / this.markupMultiplier * 100))
      this.$apollo.mutate({
        mutation: BILLABLE.mutations.update.mutation,
        variables: { billable: { id: line.billable.id, cost } }
      }).then(({ data: { updateBillable } }) => {
        line.billable = updateBillable
        line.cost = null
        this.snackbarText = 'Billable saved'
        this.snackbarColor = 'deep-purple'
        this.showSnackbar = true
      })
    },

    startDataPolling () {
      this.$apollo.queries.invoices.refetch()
      timers.loadClients = self.setInterval(() => { this.$apollo.queries.invoices.refetch() }, 1 * MINUTE)
    },

    resetInvoice () {
      this.invoice = JSON.parse(this.originalInvoice)
      if (this.unsavedTargetId !== null && this.unsavedTargetId !== this.invoiceId) {
        const id = this.unsavedTargetId
        this.unsavedTargetId = null
        this.unsavedDialog = false
        this.$nextTick(() => {
          this.$router.push({ params: { invoiceId: id } })
        })
      }
    },

    showCategory (line, index) {
      return line.billable && line.billable.id && (index === 0 || (!this.invoice.lines[index - 1].billable.id) || line.billable.category.id !== this.invoice.lines[index - 1].billable.category.id)
    }
  },

  watch: {
    invoiceId (id, old) {
      if (this.dirty && old && id !== old) {
        const original = JSON.parse(this.originalInvoice)
        if (!original.id || parseInt(original.id, 10) === id) {
          return null
        }
        this.unsavedTargetId = id
        this.unsavedDialog = true
        this.$nextTick(() => {
          this.$router.push({ params: { invoiceId: old } })
        })
        return null
      }
      this.editInvoice(id)
    },
    searchText (changed, old) {
      const list = document.querySelector('.billable-select-items')
      if (list.scrollTop) {
        list.scrollTo(0, 0)
      }
    },
    invoices (invoices, old) {
      if (!this.$apollo.queries.invoices.loading && this.invoiceId && Array.isArray(invoices) && invoices.length) {
        this.$nextTick(() => {
          this.editInvoice(this.invoiceId)
        })
      }
    },
    recentOnly () {
      this.$apollo.queries.invoices.refetch()
    }

  },

  created () {
    this.startDataPolling()
  },

  mounted () {
    this.originalInvoice = JSON.stringify(this.invoice)
    this.editInvoice(this.invoiceId)
  },

  beforeDestroy () {
    this.closeAddBillable()
    timers.clear()
  }
}
</script>

<style lang="scss">
.quantity-input {
  input {
    text-align: right;
  }
  .input-group--text-field__suffix {
    margin-left: 15px;
  }
}

#addBillableTile {
  .v-select {
    width: 100%;
  }
  .v-autocomplete.v-text-field--enclosed:not(.v-text-field--solo):not(.v-text-field--single-line) .v-select__slot>input.v-autocomplete.v-text-field--enclosed:not(.v-text-field--solo):not(.v-text-field--single-line) .v-select__slot>input.v-autocomplete.v-text-field--enclosed:not(.v-text-field--solo):not(.v-text-field--single-line) .v-select__slot>input.v-autocomplete.v-text-field--enclosed:not(.v-text-field--solo):not(.v-text-field--single-line) .v-select__slot>input.v-autocomplete.v-text-field--enclosed:not(.v-text-field--solo):not(.v-text-field--single-line) .v-select__slot>input.v-autocomplete.v-text-field--enclosed:not(.v-text-field--solo):not(.v-text-field--single-line) .v-select__slot>input.v-autocomplete.v-text-field--enclosed:not(.v-text-field--solo):not(.v-text-field--single-line) .v-select__slot>input.v-autocomplete.v-text-field--enclosed:not(.v-text-field--solo):not(.v-text-field--single-line) .v-select__slot>input {
    margin-top: 0;
  }
}

.billable-select-items {
  .v-list {
    & > [role="listitem"]:nth-child(even) {
      background-color: #fafafa;
    }
    .v-list__group__header:hover,
    .v-list__tile--highlighted,
    .v-list__tile--link:hover {
      background-color: #fbe9e7;
    }
    .v-list__tile__title {
      height: 30px;
    }
  }

  ul.list li:nth-child(even) {
    background-color: #fafafa;
  }

  .v-chip--small {
    height: 20px;
  }

  .v-chip {
    height: 20px;

    .v-chip__content {
      font-size: 11px;
      padding: 0 6px;
      padding-right: 6px;
      height: 20px;
    }
  }
}

#invoice {
  padding-bottom: 10px;

  [readonly=readonly],
  .v-input--is-readonly {
    cursor: not-allowed;
  }

  .v-list--dense {
    .v-list__tile:not(.v-list__tile--avatar) {
      height: auto;
    }
  }

  .v-list {
    .temp-billable {
      color: #ff5722;
    }

    .v-list__tile {
      height: auto;
      .v-list__tile__title {
        height: auto;
        min-height: 30px;
        line-height: unset;
      }

      .v-text-field {
        margin-top: 0;

        & > .v-input__control>.v-input__slot::before {
          border: none;
        }
        &.quantity-input {
          .v-text-field__prefix {
            font-size: 13px;
          }
          &:not(.v-input--is-focused) .v-text-field__prefix {
            opacity: 0.75;
          }
        }

        &:not(.v-input--is-focused):not(:hover) .update-cost-button {
          opacity: 0.25;
        }
      }
    }

    .line {
      & > .v-list__tile {
        padding-left: 0;
      }
      &.show-category {
        & > [data-category] {
          flex-wrap: wrap;
          &::before {
            content: attr(data-category);
            display: block;
            width: 100%;
            padding-left: 16px;
          }
        }
      }
    }
  }

  .item-list {
    & > .v-list div[role="listitem"]:nth-child(even):not(:last-child) {
      background-color: rgba(0,0,0,0.033);
    }
  }

  &.reordering-lines {
    & > .settings-header > div:not(.reorder-switch),
    & > .v-card__title,
    & > .invoice-content > .totals,
    & > .v-speed-dial {
      pointer-events: none;
      opacity: 0.25;
    }
  }
}

@media screen {
  #invoice {
    .v-list {
      .temp-billable {
        color: #90A4AE; // blue-grey lighten-2
        input {
          color: #E64A19; // deep-orange darken-2
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .v-content > .v-content__wrap > .container {
    padding: 0;
  }

  #invoice {
    .item-list > .v-list {
      .v-list__tile {
        height: auto !important;
        .v-list__tile__title {
          height: auto;

          & > .layout {
            flex-wrap: wrap;
            // justify-content: flex-end;

            // & > .flex {
            //   flex-grow: 1;

            //   &:first-child {
            //     max-width: 100%;
            //     flex-basis: 100%;
            //   }

            //   // &:not(:first-child) {
            //   //   &.
            //   // }

            // }
          }
        }
        .v-list__tile__action {
          min-width: 34px;
        }
      }
    }
    #addBillableTile {
      padding: 0;
      .v-list__tile__avatar {
        min-width: 34px;
      }
    }
  }
}

@media print {
  .v-card {
    box-shadow: none;
  }

  .v-list__tile {
    break-inside: avoid;
  }

  nav.v-toolbar,
  .v-navigation-drawer,
  #addBillableTile,
  .billables-search,
  .v-btn,
  #invoice .v-list__tile__action,
  #invoice .v-list .temp-billable .temp-billable--supplier,
  #invoice .item-list > .v-list .quantity-input .v-text-field__prefix,
  #invoice .item-list > .v-list .line-cost-input .v-input__append-outer {
    display: none;
  }

  #invoice {
    orphans: 3;
    widows: 3;
    .item-list > .v-list {
      .v-list__tile__title {
        border-bottom: 1px dotted #ddd;
        // height: 25px !important;
      }
    }

    .v-input {
      font-size: 13px;
    }
    .totals {
      display: block !important;
      & > .v-list {
        display: inline-table;
        width: 100%;
        break-inside: avoid;
        break-inside: avoid-page;
      }
    }
  }

  .theme--light.v-input input::placeholder,
  .theme--light.v-input textarea::placeholder,
  .theme--light .input-group--text-field input::placeholder,
  .application .theme--light.input-group--text-field input::placeholder,
  .theme--light .input-group--text-field textarea::placeholder,
  .application .theme--light.input-group--text-field textarea::placeholder {
    color: inherit;
  }
}

@page {
  margin: 2cm 1cm;
}
</style>
