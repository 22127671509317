<template>
  <section class="invoice-list">
    <v-toolbar dark dense flat color="green" style="z-index: 2;">
      <v-icon>vertical_split</v-icon>
      <v-toolbar-title>Invoices</v-toolbar-title>

      <v-spacer></v-spacer>
      <v-btn icon @click="expanded = !expanded">
        <v-badge left color="green lighten-1 green--text text--lighten-4" class="v-badge--inline">
          <template #badge><span>{{ invoices ? invoices.length : 0 }}</span></template>
          <v-icon>{{ expanded ? 'expand_less' : 'expand_more' }}</v-icon>
        </v-badge>
      </v-btn>
    </v-toolbar>
    <v-slide-y-transition>
      <v-card v-show="expanded">
        <v-list subheader dense class="striped">
          <v-list-tile>
            <v-list-tile-content>
              <v-list-tile-title class="d-flex">
                <span class="subheading">Title</span>
                <span class="subheading text-xs-right">Added</span>
              </v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
          <v-list-tile v-for="(invoice, index) in invoices" :key="index">
            <v-list-tile-content>
              <v-list-tile-title class="d-flex">
                <router-link :to="'/invoice/' + invoice.id">{{ invoice.name }}</router-link>
                <span class="text-xs-right">{{ timespan(invoice.created_at) }}</span>
              </v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
        </v-list>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              @click.stop="createInvoice"
              :disabled="!unbilledItems"
              color="green" small flat
            >
              <v-icon>vertical_split</v-icon> Add To Invoice
            </v-btn>
        </v-card-actions>
      </v-card>
    </v-slide-y-transition>
    <v-snackbar v-model="showSnackbar" :color="snackbarColor" top>
      {{ snackbarText }}
      <v-btn dark flat @click="showSnackbar = false">
        Close
      </v-btn>
    </v-snackbar>
  </section>
</template>

<script>
/* eslint-disable camelcase */
/* eslint-disable handle-callback-err */

import { formatTimespan } from '@/utils'

export default {
  name: 'invoice-list',
  props: {
    invoices: { type: Array, required: false, default () { return [] } },
    parentId: { type: Number, required: true },
    parentType: { type: String, required: true },
    parentPath: { type: String, required: true },
    unbilledItems: { type: Boolean, required: false, default: false }
  },
  data () {
    return {
      expanded: false,
      showSnackbar: false,
      snackbarText: '',
      snackbarColor: ''
    }
  },
  computed: {
    billables () { return this.$oxide.store.apollo.billables },
    categories () { return this.$oxide.store.apollo.categories },
    me () { return this.$oxide.store.apollo.user }
  },
  methods: {
    createInvoice () {
      this.$emit('create:invoice')
    },
    timespan (val) {
      return formatTimespan(val)
    }
  },

  watch: {
  },
  created () {

  }
}
</script>

<style lang="scss">
.invoice-list {
  padding-bottom: 0;
  // padding-bottom: 10px;
}

@media (max-width: 600px) {
  .invoice-list {
    padding-bottom: 0;
  }
}
</style>
