<template>
  <section class="note-list" ref="noteList">
    <v-toolbar dark dense flat color="amber darken-1" style="z-index: 2;">
      <v-icon>description</v-icon>
      <v-toolbar-title>Notes</v-toolbar-title>

      <v-spacer></v-spacer>
      <v-btn icon @click="expanded = !expanded">
        <v-badge left color="amber lighten-1 amber--text text--lighten-4" class="v-badge--inline">
          <template #badge><span>{{ notes ? notes.length : 0 }}</span></template>
          <v-icon>{{ expanded ? 'expand_less' : 'expand_more' }}</v-icon>
        </v-badge>
      </v-btn>
    </v-toolbar>

    <v-card>
      <v-slide-y-transition>
        <v-container fluid grid-list-lg v-if="expanded" pa-0>
          <v-layout row wrap ma-0>
            <v-flex pa-0 class="" style="z-index: 1;">
              <v-layout row ma-0 style="">

                <v-item-group v-model="addNoteWindow" class="add-note-type-menu shrink" mandatory tag="v-flex">
                  <v-item>
                    <div slot-scope="{ active, toggle }">
                      <v-btn :color="active ? 'indigo lighten-1 white--text' : 'white--text'" :input-value="active" icon @click="toggle">
                        <v-icon :color="active ? '' : 'indigo lighten-1'">insert_comment</v-icon>
                      </v-btn>
                      <v-progress-circular v-if="active && savingNote" indeterminate size="40" color="white"></v-progress-circular>
                    </div>
                  </v-item>
                  <v-item>
                    <div slot-scope="{ active, toggle }">
                      <v-btn :color="active ? 'light-green darken-1 white--text' : 'white--text'" :input-value="active" icon @click="toggle">
                        <v-icon :color="active ? '' : 'light-green darken-1'">insert_photo</v-icon>
                      </v-btn>
                      <v-progress-circular v-if="active && savingNote" indeterminate size="40" color="white"></v-progress-circular>
                    </div>
                  </v-item>
                  <v-item>
                    <div slot-scope="{ active, toggle }">
                      <v-btn :color="active ? 'red lighten-1 white--text' : 'white--text'" :input-value="active" icon @click="toggle">
                        <v-icon :color="active ? '' : 'red lighten-1'">attach_file</v-icon>
                      </v-btn>
                      <v-progress-circular v-if="active && savingNote" indeterminate size="40" color="white"></v-progress-circular>
                    </div>
                  </v-item>
                </v-item-group>

                <v-flex style="overflow: hidden;" pa-2>
                  <v-window v-model="addNoteWindow" vertical class="add-note-content" :class="{'saving-note': savingNote }">
                    <v-window-item>
                      <v-textarea
                        v-model="newText" @keyup.ctrl.enter="addTextNote"
                        class="add_text_note_input" ref="addTextNoteInput" color="indigo lighten-3" label="add note"
                        auto-grow rows="4" outline  hide-details :readonly="savingNote" :loading="savingNote">
                        <template #append>
                          <v-layout column fill-height align-end justify-space-between my-0>
                            <v-btn icon small my-0 @click="newText = ''" :disabled="newText.trim() === ''">
                              <v-icon small color="grey lighten-2">clear</v-icon>
                            </v-btn>
                            <v-btn icon small my-0 @click="addTextNote" :disabled="newText.trim() === ''">
                              <v-icon small color="indigo lighten-3">save_alt</v-icon>
                            </v-btn>
                          </v-layout>
                        </template>
                      </v-textarea>
                    </v-window-item>

                    <v-window-item>
                      <figure class="upload upload--image" :class="{ 'upload--active': uploadActive }" :style="imageUploadStyle">
                        <input type="file" accept="image/*,video/*" @change="handleImageInputChange" class="upload__input" ref="imageUploadInput" multiple>
                        <figcaption class="upload__caption">
                          <v-icon large :color="uploadActive ? 'white' : 'light-green darken-1'">add_photo_alternate</v-icon><br>
                          {{ uploadActive ? 'uploading...' : 'click here to upload an image/video' }}
                        </figcaption>
                        <v-progress-linear
                          :value="uploadProgress" :active="uploadActive" indeterminate
                          color="white" height="3" style="position: absolute; bottom: 0; margin: 0;"
                        ></v-progress-linear>
                      </figure>
                    </v-window-item>

                    <v-window-item>
                      <figure class="upload upload--file" :class="{ 'upload--active': uploadActive }" style="background-color: rgba(255,255,255,0.75);">
                        <input type="file" accept="application/pdf,.pdf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.zip,.7zip,.xls,.csv,.xlsx,.txt" @change="handleFileInputChange" class="upload__input" ref="fileUploadInput" multiple>
                        <figcaption class="upload__caption">
                          <v-icon large :color="uploadActive ? 'white' : 'red lighten-1'">attachment</v-icon><br>
                          {{ uploadActive ? 'uploading...' : 'click here to upload a file' }}
                        </figcaption>
                        <v-progress-linear
                          :value="uploadProgress" :active="uploadActive" indeterminate
                          color="white" height="3" style="position: absolute; bottom: 0; margin: 0;"
                        ></v-progress-linear>
                      </figure>
                    </v-window-item>
                  </v-window>
                  <div v-if="me.is_admin" class="py-2">
                    <v-switch v-model="uploadAdminVisibility" label="Admin Only" hide-details class="mt-0 justify-center"></v-switch>
                  </div>
                </v-flex>
              </v-layout>
            </v-flex>

            <v-flex xs12 pa-0 class="notes-timeline">
              <v-tabs v-model="filter" dark centered grow class="elevation-2" :slider-color="colors[filter]" color="rgba(255,255,255,0.05)">
                <v-tab class="font-weight-bold" :class="{'accent--text': filter === 0}">All</v-tab>
                <v-tab><v-icon :color="filter === 1 ? colors[1] : ''">insert_comment</v-icon></v-tab>
                <v-tab><v-icon :color="filter === 2 ? colors[2] : ''">insert_photo</v-icon></v-tab>
                <v-tab><v-icon :color="filter === 3 ? colors[3] : ''">attach_file</v-icon></v-tab>
              </v-tabs>
              <v-timeline align-top dense class="pt-0">
                <v-timeline-item small fill-dot
                  v-for="note of filteredNotes" :key="note.id"
                  :color="note.type === 'TEXT' ? 'indigo' : (note.type === 'IMAGE' ? 'light-green darken-3' : 'red')"
                  class="note" :class="{ 'note--active': note.id === activeNote }"
                >
                  <template #icon>
                    <confirm-action v-if="note.id === activeNote && (admin || me.id === note.user.id)" @confirm="deleteNote(note)">
                      <template v-slot:act="{ on }">
                        <v-btn small dark icon :color="activeNote === note.id ? 'deep-orange lighten-1' : ''" v-on="on">
                          <v-icon small>delete_outline</v-icon>
                        </v-btn>
                      </template>
                    </confirm-action>

                    <v-icon small v-else color="white">
                      {{ note.type === 'TEXT' ? 'insert_comment' : (note.type === 'IMAGE' ? 'insert_photo' : 'attach_file') }}
                    </v-icon>
                  </template>

                  <v-layout ma-0 @click="toggleActiveNote(note)">
                    <v-flex xs3 pl-0 py-0>
                      <v-layout column fill-height ma-0>
                        <v-flex shrink pa-0>{{ note.user ? note.user.name : '' }}</v-flex>
                        <v-flex shrink class="caption pa-0" v-if="note.created_at">{{ note.created_at | timespan }}</v-flex>
                        <!-- <v-flex shrink class="caption pa-0" v-if="note.created_at && note.id === activeNote && typeof note.created_at === 'string'">{{ note.created_at.split(' ')[0] }}</v-flex> -->
                        <v-flex shrink class="caption pa-0 grey--text text--lighten-1" v-if="note.created_at && typeof note.created_at === 'string'">{{ note.created_at.split(' ')[0] }}</v-flex>
                        <v-spacer></v-spacer>
                        <v-flex v-if="admin" shrink px-0 pb-0 pt-2 style="transform: translateX(-22px);" @click.stop="">
                          <template v-if="note.id === activeNote">
                            <v-switch v-model="note.visibility" label="Admin Only" hide-details class="mt-0 justify-center" false-value="FULL" true-value="ADMIN" @change.passive="updateNoteVisibility(note, $event)"></v-switch>
                          </template>
                          <template v-else-if="note.visibility === 'ADMIN'">
                            <v-icon small style="vertical-align: baseline;">lock</v-icon> ADMIN ONLY
                          </template>
                        </v-flex>
                        <v-spacer></v-spacer>
                        <v-flex shrink pa-0>
                          <!--  -->
                        </v-flex>
                      </v-layout>
                    </v-flex>

                    <v-flex xs9 pl-0 py-0>

                      <template v-if="note.type === 'TEXT'">
                        <strong v-if="note.title">{{ note.title }}</strong>
                        <pre class="caption">{{ note.content }}</pre>
                      </template>

                      <template v-else-if="note.type === 'IMAGE'">
                        <div v-if="isVideo(note.content)" @click.stop="toggleLightbox(note)" class="elevation-2" style="cursor: pointer; width: 100%; position: relative;">
                          <video v-if="isVideo(note.content)" :src="note.content" style="pointer-events: none; max-width: 100%; margin-bottom: -6px;"></video>
                          <aside style="position: absolute; top: 0; right: 0; bottom: 0; left: 0; display: flex; align-items: center; justify-content: center; background-color: rgba(0,0,0,0.25); opacity: 0.5;">
                            <v-icon color="white" large>play_circle_outline</v-icon>
                          </aside>
                        </div>

                        <div v-else-if="isResource(note.content)" style="max-width: 100%; max-width: 100%; position: relative;">
                          <v-system-bar color="primary" lights-out absolute v-show="(!showLightbox) && note.id === activeNote" class="px-0">
                            <v-btn small icon @click.stop="rotate(note, 90)" v-if="note.content.toLowerCase().endsWith('.jpg') || note.content.toLowerCase().endsWith('.jpeg')" :disabled="rotatingImage">
                              <v-icon class="ml-0">rotate_90_degrees_ccw</v-icon>
                            </v-btn>
                            <v-spacer></v-spacer>
                            <v-btn small icon :href="baseUrl + 'dl/' + note.content.replace('https://signaltree.sfo2.cdn.digitaloceanspaces.com/oxide/company/', '')" :disabled="rotatingImage">
                              <v-icon class="ml-0">save_alt</v-icon>
                            </v-btn>
                            <v-spacer></v-spacer>
                            <v-btn small icon @click.stop="rotate(note, 270)" v-if="note.content.toLowerCase().endsWith('.jpg') || note.content.toLowerCase().endsWith('.jpeg')" :disabled="rotatingImage">
                              <v-icon class="ml-0" style="transform: rotateY(180deg);">rotate_90_degrees_ccw</v-icon>
                            </v-btn>
                          </v-system-bar>
                          <img :src="note.content" @click.stop="toggleLightbox(note)" class="elevation-2" :class="{blurred: (rotatingImage && note.id === activeNote)}" style="cursor: pointer; max-width: 100%;" @error="imageBorked(note, $event)">
                          <div v-if="rotatingImage && note.id === activeNote" class="d-flex rotating-overlay">
                            <v-icon x-large>hourglass_empty</v-icon>
                          </div>
                        </div>
                        <div v-else>error loading image</div>

                        <v-dialog v-if="showLightbox" :value="showLightbox && activeNote === note.id" fullscreen hide-overlay lazy>
                          <v-card dark style="border-radius: 0;" @click.self="hideLightbox">
                            <v-system-bar fixed color="primary" lights-out v-show="showLightboxToolbar" class="px-0">
                              <v-btn small icon :href="baseUrl + 'dl/' + note.content.replace('https://signaltree.sfo2.cdn.digitaloceanspaces.com/oxide/company/', '')" :disabled="rotatingImage">
                                <v-icon class="ml-0">save_alt</v-icon>
                              </v-btn>
                              <v-spacer></v-spacer>
                              <template v-if="note.content.toLowerCase().endsWith('.jpg') || note.content.toLowerCase().endsWith('.jpeg')">
                                <v-btn small icon @click.stop="rotate(note, 90)" :disabled="rotatingImage">
                                  <v-icon class="ml-0">rotate_90_degrees_ccw</v-icon>
                                </v-btn>
                                <v-spacer></v-spacer>
                                <v-btn small icon @click.stop="rotate(note, 270)" :disabled="rotatingImage">
                                  <v-icon class="ml-0" style="transform: rotateY(180deg);">rotate_90_degrees_ccw</v-icon>
                                </v-btn>
                                <v-spacer></v-spacer>
                              </template>
                              <v-btn small icon @click.stop="hideLightbox">
                                <v-icon>close</v-icon>
                              </v-btn>
                            </v-system-bar>
                            <video v-if="isVideo(note.content)" :src="note.content" controls @click.stop="showLightboxToolbar = !showLightboxToolbar" style="max-width: 100%;"></video>
                            <v-img
                              v-else-if="isResource(note.content)"
                              :src="note.content" contain
                              @click.stop="showLightboxToolbar = !showLightboxToolbar"
                              :class="{blurred: (rotatingImage && note.id === activeNote)}"></v-img>

                            <div v-if="rotatingImage && note.id === activeNote" class="d-flex rotating-overlay">
                              <v-icon x-large>hourglass_empty</v-icon>
                            </div>
                          </v-card>
                        </v-dialog>
                      </template>

                      <template v-else-if="note.type === 'LINK'">
                        <div>
                          <v-btn :title="note.title" :href="note.content" color="red" small depressed class="ml-0" dark target="_blank" rel="noopener">
                            <v-icon>open_in_new</v-icon> open
                          </v-btn>
                        </div>
                      </template>

                      <template v-if="note.type === 'IMAGE' || note.type === 'LINK'">
                        <v-textarea @click.stop="" @blur="saveNote(note)"
                          v-if="note.id === activeNote && (admin || me.id === note.user.id)"
                          v-model="note.title" label="Caption"
                          class="caption" hide-details auto-grow :rows="1"
                        ></v-textarea>
                        <pre class="caption pt-2" v-else-if="note.title">{{ note.title }}</pre>
                      </template>

                    </v-flex>
                  </v-layout>
                </v-timeline-item>
              </v-timeline>
            </v-flex>
          </v-layout>
        </v-container>
      </v-slide-y-transition>
    </v-card>
    <v-snackbar v-model="showSnackbar" :color="snackbarColor" top>
      {{ snackbarText }}
      <v-btn dark flat @click="showSnackbar = false">
        Close
      </v-btn>
    </v-snackbar>
  </section>
</template>

<script>
/* eslint-disable camelcase */
/* eslint-disable handle-callback-err */

import { kebabCase } from 'lodash'
import { BASE_URL } from '@/constants'
import { clone, cloneOnly, getNestedValue, toDataURL } from '@/utils'
// import UploadButton from 'vuetify-upload-button'
import ConfirmAction from './ConfirmAction.vue'
import { NOTE } from '@/graphql/models'

export default {
  name: 'note-list',
  components: {
    // 'upload-btn': UploadButton,
    ConfirmAction
  },
  props: {
    notableId: { type: Number, required: true },
    notableType: { type: String, required: true },
    notes: {},
    path: { type: String, required: true },
    query: { type: Object, required: false, default () { return {} } }
  },
  data () {
    return {
      activeNote: 0,
      addNoteWindow: 0,
      expanded: false,
      filter: 0,
      newText: '',
      noteTypes: ['', 'TEXT', 'IMAGE', 'LINK'],
      rotatingImage: false,
      savingNote: false,
      showLightbox: false,
      showLightboxToolbar: true,
      showSnackbar: false,
      snackbarText: '',
      snackbarColor: '',
      uploadedImage: false,
      uploadLoaded: 0,
      uploadTotal: 0,
      uploadActive: false,
      uploadAdminVisibility: false,
      videoFileExtensions: ['avi', 'gifv', 'm4v', 'mkv', 'mov', 'mp4', 'ogg', 'ogv', 'qt', 'webm']
    }
  },
  computed: {
    me () { return this.$oxide.store.apollo.user },
    users () { return this.$oxide.store.apollo.users },
    admin () { return this.me?.is_admin },
    imageUploadStyle () {
      return this.uploadedImage ? { backgroundImage: `url('${this.uploadedImage}')` } : { backgroundColor: 'rgba(255,255,255,0.75)' }
    },
    uploadProgress () {
      return this.uploadTotal ? Math.round(this.uploadLoaded / this.uploadTotal) : 100
    },
    colors () { return ['accent', 'indigo lighten-2', 'light-green lighten-1', 'red lighten-2'] },
    textColors () {
      return ['accent--text', 'indigo--text text--lighten-2', 'light-green--text text--lighten-1', 'red--text text--lighten-2']
    },
    filteredNotes () {
      return this.notes.filter(note => {
        if (note.visibility === 'ADMIN' && !this.me.is_admin) {
          return false
        }
        return this.filter === 0 ? true : note.type === this.noteTypes[this.filter]
      })
    },
    baseUrl () {
      return BASE_URL
    }
  },
  methods: {
    async createNote (note) {
      this.savingNote = true
      // const timestamp = getUTCTimestamp()

      return this.$apollo.mutate({
        mutation: NOTE.mutations.create.mutation,
        variables: { note: { ...note, user: { connect: this.me.id } } },
        update: (store, { data: { createNote } }) => {
          const data = store.readQuery(this.query)
          const entity = getNestedValue(data, this.path)
          entity.notes.push(clone(createNote))
          store.writeQuery({ ...this.query, data })
        }//,
        // optimisticResponse: {
        //   __typename: 'Mutation',
        //   createNote: {
        //     __typename: 'Note', id: 0, ...note, user: this.me, created_at: timestamp, updated_at: timestamp
        //   }
        // }
      }).then(() => {
        this.savingNote = false
        this.snackbarText = 'Note added'
        this.snackbarColor = 'amber darken-1'
        this.showSnackbar = true
      })
    },

    addTextNote () {
      const content = this.newText

      this.createNote({
        notable_id: this.notableId,
        notable_type: this.notableType,
        title: '',
        content,
        type: 'TEXT',
        visibility: this.uploadAdminVisibility ? 'ADMIN' : 'FULL',
        order: 0
      }).catch((error) => {
        console.error(error)
        this.newText = content
      }).then(() => {
        this.newText = ''
      })
    },

    async handleImageInputChange (e) {
      if (e?.target?.files) {
        for (const file of e.target.files) {
          await this.upload(file, 'image')
        }
      }
    },

    async handleFileInputChange (e) {
      if (e?.target?.files) {
        for (const file of e.target.files) {
          await this.upload(file, 'file')
        }
      }
    },

    async rotate (note, degrees) {
      this.rotatingImage = true
      const data = new FormData()
      data.append('imagepath', note.content.replace('https://signaltree.sfo2.cdn.digitaloceanspaces.com/oxide/company/', ''))
      data.append('degrees', degrees)

      const response = await fetch(
        `${BASE_URL}api/image/rotate`,
        { body: data, headers: { Authorization: `Bearer ${this.$cache.get('auth_access')}` }, method: 'POST' }
      ).then(resp => {
        // console.log(resp)
        return resp.text()
      }).catch(err => {
        console.log(err)
      })
      if (this.isResource(response)) {
        note.content = response
        this.saveNote(note)
      } else {
        this.rotatingImage = false
        console.log('error rotating image', response)
        this.snackbarText = 'Error rotating image'
        this.snackbarColor = 'red darken-1'
        this.showSnackbar = true
      }
    },

    async upload (file, type) {
      this.savingNote = true
      const fr = new FileReader()
      fr.readAsDataURL(file)
      await fr.addEventListener('load', async () => {
        this.uploadedImage = fr.result
        this.uploadActive = true

        const data = new FormData()
        data.append('file', file)

        await fetch(
          `${BASE_URL}api/${this.notableType.replace('App\\', '').toLowerCase()}/${this.notableId}/note/upload`,
          { body: data, headers: { Authorization: `Bearer ${this.$cache.get('auth_access')}` }, method: 'POST' }
        ).catch(err => {
          this.snackbarText = 'Error uploading file'
          this.snackbarColor = 'red darken-1'
          this.showSnackbar = true
          this.uploadTotal = 0
          this.uploadLoaded = 0
          console.log('s?', err)
        }).then(response => response.text())
          .then(responseText => {
            this.uploadTotal = 0
            this.uploadLoaded = 0

            if (responseText && responseText.startsWith('http')) {
              this.createNote({
                notable_id: this.notableId,
                notable_type: this.notableType,
                title: type === 'file' ? file.name : '',
                content: responseText,
                type: type === 'image' ? 'IMAGE' : 'LINK',
                visibility: this.uploadAdminVisibility ? 'ADMIN' : 'FULL',
                order: 0
              })
            } else {
              console.log('response:', responseText)
              this.snackbarText = 'Error uploading note: ' + responseText
              this.snackbarColor = 'red darken-1'
              this.showSnackbar = true
            }
          })
        this.uploadedImage = false
        this.uploadActive = false
      })
    },

    async downloadNote (note) {
      const a = document.createElement('a')
      const extension = note.content.substring(note.content.lastIndexOf('.'))
      const dataURL = await toDataURL(note.content)
      a.href = dataURL
      // console.log(a.href)
      a.download = kebabCase('toast') + extension
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
      setTimeout(() => {
        URL.revokeObjectURL(dataURL)
      }, 30000)
    },

    updateNoteVisibility (note, visibility) {
      if (typeof visibility === 'string') {
        note.visibility = visibility
      }
      this.saveNote(note)
    },

    async saveNote (note) {
      const copiedNote = cloneOnly(note, ['id', 'title', 'content', 'visibility'])
      await this.$apollo.mutate({
        mutation: NOTE.mutations.update.mutation,
        variables: { note: copiedNote }//,
        // optimisticResponse: { __typename: 'Mutation', updateNote: { ...note, updated_at: getUTCTimestamp() } }
      }).catch(error => {
        this.snackbarText = 'Error saving note'
        this.snackbarColor = 'red darken-1'
        this.showSnackbar = true
      }).then(() => {
        this.snackbarText = 'Note saved'
        this.snackbarColor = 'amber darken-1'
        this.showSnackbar = true
      })
      this.rotatingImage = false
    },

    async deleteNote (note) {
      const copiedNote = clone(note)
      await this.$apollo.mutate({
        mutation: NOTE.mutations.delete.mutation,
        variables: { id: copiedNote.id },
        update: (store, { data: { deleteNote } }) => {
          const data = store.readQuery(this.query)
          const noteId = parseInt(copiedNote.id, 10)
          const entity = getNestedValue(data, this.path)
          const index = entity.notes.findIndex(n => parseInt(n.id, 10) === noteId)
          entity.notes.splice(index, 1)
          store.writeQuery({ ...this.query, data })
        }//,
        // optimisticResponse: { __typename: 'Mutation', deleteNote: copiedNote }
      }).catch(error => {
        this.snackbarText = 'Error deleting note'
        this.snackbarColor = 'red darken-1'
        this.showSnackbar = true
      }).then(() => {
        this.snackbarText = 'Note deleted'
        this.snackbarColor = 'amber darken-1'
        this.showSnackbar = true
      })
    },

    toggleActiveNote (note) {
      if (this.activeNote === note.id) {
        this.activeNote = 0
      } else {
        this.activeNote = note.id
      }
    },

    toggleLightbox (note) {
      this.showLightbox = !this.showLightbox
      this.showLightboxToolbar = true
      if (this.activeNote !== note.id) {
        this.activeNote = note.id
      }
    },

    hideLightbox () {
      this.showLightbox = false
    },

    isVideo (fileName) {
      const extension = fileName.split('.').pop()
      return this.videoFileExtensions.includes(extension)
    },

    isResource (str) {
      // console.log(str, str.substring(0, 51) === 'https://signaltree.sfo2.cdn.digitaloceanspaces.com/')
      return str.substring(0, 51) === 'https://signaltree.sfo2.cdn.digitaloceanspaces.com/'
    },

    imageBorked (note, e) {
      console.log(note, e)
      note._error = e
    },

    async handlePaste (e) {
      if (!e.clipboardData?.files?.length) {
        return false
      } else if (this.path === 'client') {
        if (this.$route.query.a !== '1') {
          return false
        }
      } else if (this.path.startsWith('client/jobs/')) {
        if (this.$route.query.a !== '2') {
          return false
        }
      } else {
        console.log('What\'s going on?')
      }
      // console.log(e.clipboardData.files, this, this.$refs.noteList)
      for (const file of e.clipboardData.files) {
        const type = (e.clipboardData.files[0].type.startsWith('image/') || e.clipboardData.files[0].type.startsWith('video/')) ? 'image' : 'file'
        this.addNoteWindow = type === 'image' ? 1 : 2
        await this.upload(file, type)
      }
    }
  },
  watch: {
    addNoteWindow (val, old) {
      if (val === 0) {
        window.setTimeout(() => {
          this.$refs.addTextNoteInput.focus()
        }, 350)
      }
    }
  },
  mounted () {
    window.addEventListener('paste', this.handlePaste)
  },
  beforeDestroy () {
    window.removeEventListener('paste', this.handlePaste)
  }
}
</script>

<style lang="scss">
.note-list {
  .add-note-type-menu {
    & > div {
      position: relative;
      .v-progress-circular {
        position: absolute;
        top: 50%; left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  .add-note-content {
    border-radius: 4px;
    position: relative;
    overflow: hidden;
    &.saving-note::after {
      content: " ";
      position: absolute;
      top: 0; right: 0; bottom: 0; left: 0;
      cursor: wait;
      background-color: rgba(0,0,0,0.1);
    }
  }

  .add_text_note_input.v-text-field .v-input__append-inner {
    margin: 0;
    padding: 5px 0 5px 0;
    align-self: stretch;
  }

  .upload {
    border-width: 2px;
    border-radius: 4px;
    border-style: dashed;
    position: relative;
    background-size: cover;

    &.upload--image {
      color: #6a9949;
      border-color: #6a9949;
      background-size: cover;
      background-position: center;
      background-clip: padding-box;
    }

    &.upload--file {
      color: #f55a4f;
      border-color: #f55a4f;
    }

    &::before {
      display: block;
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.01);
      transition: background-color 250ms ease;
    }

    .upload__input {
      min-height: 124px;
      width: 100%;
      opacity: 0;
      cursor: pointer;
    }

    .upload__caption {
      position: absolute;
      pointer-events: none;
      font-size: 16px;
      font-weight: 500;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      padding: 16px;
      text-align: center;

    }

    &:hover {
      &::before {
        background-color: rgba(0, 0, 0, 0.05);
      }
    }

    &.upload--active {
      color: #fff;
      background-color: rgba(0, 0, 0, 0.25);

      &:hover {
        &::before {
          background-color: rgba(0, 0, 0, 0.5);
        }
      }
    }
  }

  .rotating-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0,0,0,0.5);
  }

  .blurred {
    filter: blur(3px);
    padding: 5px;
  }

  pre {
    font-family: Roboto,sans-serif;
    white-space: pre-wrap;
  }

  .notes-timeline {
    max-height: calc(100vh - 56px);
    overflow-y: scroll;
  }

  .v-timeline--dense {
    .v-timeline-item.note {
      padding: 16px 0;
      .v-timeline-item__dot--small {
        top: 16px;
      }
      &:first-child {
        padding-top: 32px;
        .v-timeline-item__dot--small {
          top: 32px;
        }
      }
      .v-timeline-item__body {
        max-width: calc(100% - 52px);
      }
      background-color: rgba(0, 0, 0, 0);
      transition: background-color .3s cubic-bezier(0.25, 0.8, 0.5, 1);
      &.note--active {
        background-color: rgba(0, 0, 0, 0.04);
      }
    }

  }
}
</style>
