import gql from 'graphql-tag'
import fragments from './fragments'

const BILLABLES_QUERY = gql`
  query BILLABLES {
    billables {
      ...billableFields
      category {
        id
        name
      }
    }
  }
  ${fragments.billable.fields}
`

const BILLABLE_TAGS_QUERY = gql`
  query TAGS {
    tags
  }
`

const CATEGORIES_QUERY = gql`
  query CATEGORIES {
    categories {
      ...categoryFields
    }
  }
  ${fragments.category.fields}
`

const CLIENT_QUERY = gql`
  query CLIENT ($id: ID!) {
    client (id: $id) {
      ...clientFields
      addresses {
        ...addressFields
      }
      contacts {
        ...contactFields
      }
      notes {
        ...noteFields
        user {
          id
          name
        }
      }
      jobs {
        ...jobFields
        addresses {
          ...addressFields
        }
        contacts {
          ...contactFields
        }
        hours {
          ...hourFields
          employee {
            ...employeeFields
          }
          invoice {
            id
            name
            created_at
          }
        }
        invoices {
          ...invoiceFields
        }
        notes {
          ...noteFields
          user {
            id
            name
          }
        }
        quotes {
          ...quoteFields
          sets {
            ...setFields
            entries {
              ...entryFields
            }
          }
        }
        sets {
          ...setFields
          entries {
            ...entryFields
          }
        }
      }
    }
  }
  ${fragments.address.fields}
  ${fragments.contact.fields}
  ${fragments.client.fields}
  ${fragments.contact.fields}
  ${fragments.employee.fields}
  ${fragments.entry.fields}
  ${fragments.hour.fields}
  ${fragments.invoice.fields}
  ${fragments.job.fields}
  ${fragments.note.fields}
  ${fragments.quote.fields}
  ${fragments.set.fields}
`

const CLIENTS_QUERY = gql`
  query CLIENTS {
    clients {
      ...clientFields
      addresses {
        ...addressFields
      }
      contacts {
        ...contactFields
      }
    }
  }
  ${fragments.address.fields}
  ${fragments.client.fields}
  ${fragments.contact.fields}
`

const COMPANY_QUERY = gql`
  query COMPANY {
    company {
      ...companyFields
      addresses {
        ...addressFields
      }
      contacts {
        ...contactFields
      }
      jobs {
        ...jobFields
        addresses {
          ...addressFields
        }
        contacts {
          ...contactFields
        }
        hoursByDate {
          date
          hours
        }
        client {
          id
          name
        }
        invoices {
          id
          name
        }
      }
    }
  }
  ${fragments.address.fields}
  ${fragments.company.fields}
  ${fragments.contact.fields}
  ${fragments.job.fields}
`

const EMPLOYEE_QUERY = gql`
  query EMPLOYEE ($id: ID!) {
    employee (id: $id) {
      ...employeeFields
      addresses {
        ...addressFields
      }
      contacts {
        ...contactFields
      }
      notes {
        ...noteFields
        user {
          id
          name
        }
      }
      hours {
        ...hourFields
        job {
          id
          title
          client {
            id
            name
          }
        }
      }
      user {
        ...userFields
      }
    }
  }
  ${fragments.address.fields}
  ${fragments.contact.fields}
  ${fragments.employee.fields}
  ${fragments.hour.fields}
  ${fragments.note.fields}
  ${fragments.user.fields}
`

const EMPLOYEES_QUERY = gql`
  query EMPLOYEES {
    employees {
      ...employeeFields
      addresses {
        ...addressFields
      }
      contacts {
        ...contactFields
      }
      notes {
        ...noteFields
        user {
          id
          name
        }
      }
      user {
        ...userFields
      }
    }
  }
  ${fragments.address.fields}
  ${fragments.contact.fields}
  ${fragments.employee.fields}
  ${fragments.note.fields}
  ${fragments.user.fields}
`

const ENTRY_QUERY = gql`
  query ENTRY ($id: ID!) {
    entry (id: $id) {
      ...entryFields
      listable_id
      listable_type
    }
  }
  ${fragments.entry.fields}
`

const JOBS_QUERY = gql`
  query JOBS {
    company {
      id
      jobs {
        ...jobFields
        addresses {
          ...addressFields
        }
        contacts {
          ...contactFields
        }
        client {
          id
          name
        }
        hours {
          ...hourFields
          employee {
            ...employeeFields
          }
          invoice {
            id
            name
            created_at
          }
        }
        invoices {
          ...invoiceFields
        }
        notes {
          ...noteFields
          user {
            id
            name
          }
        }
      }
    }
  }
  ${fragments.address.fields}
  ${fragments.contact.fields}
  ${fragments.employee.fields}
  ${fragments.hour.fields}
  ${fragments.invoice.fields}
  ${fragments.job.fields}
  ${fragments.note.fields}
`

const INVOICES_QUERY = gql`
  query INVOICES ($newerThan: DateTime = "2018-01-01 00:00:00") {
    invoices (updated_at: $newerThan) {
      ...invoiceFields
      client {
        id
        name
      }
      job {
        id
        title
        client {
          id
          name
        }
      }
    }
  }
  ${fragments.invoice.fields}
`

const AUTHENTICATED_USER_QUERY = gql`
  query ME {
    me {
      ...userFields,
      employee {
        ...employeeFields
      }
    }
  }
  ${fragments.employee.fields}
  ${fragments.user.fields}
`

const USERS_QUERY = gql`
  query USERS {
    users {
      ...userFields
    }
  }
  ${fragments.user.fields}
`

export {
  BILLABLES_QUERY,
  BILLABLE_TAGS_QUERY,
  CATEGORIES_QUERY,
  CLIENT_QUERY,
  CLIENTS_QUERY,
  COMPANY_QUERY,
  EMPLOYEE_QUERY,
  EMPLOYEES_QUERY,
  ENTRY_QUERY,
  JOBS_QUERY,
  INVOICES_QUERY,
  AUTHENTICATED_USER_QUERY,
  USERS_QUERY
}
