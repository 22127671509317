import { clone, defineModel, recordQueryMeta, store } from '@/utils'
import { DEFAULT_CONFIG } from '@/constants'
import { COMPANY_QUERY } from '../queries'
import { COMPANY_UPDATE } from '../mutations'
import { NetworkStatus } from 'apollo-client'
import ca$h from '@/johnny-cache.js'

const COMPANY = defineModel({
  name: 'Company',
  fields: { id: 0, name: '', logo: '', markup: 0, tax: 0, config: {} },
  queries: {
    show: {
      query: COMPANY_QUERY,
      update ({ company }) {
        if (company) {
          company.id = parseInt(company.id, 10)
          if (typeof company.config === 'string') {
            let config = null
            try {
              config = JSON.parse(company.config)
            } catch (e) {
              console.error(e, 'company.config', company.config)
              config = clone(DEFAULT_CONFIG)
            } finally {
              // const jobBillingTypes = Object.create(null)
              // for (const [key, type] of Object.entries(config.job_billing_types)) {
              //   jobBillingTypes[parseInt(key, 10)] = type
              // }
              // config.job_billing_types = jobBillingTypes

              company.config = config
            }
          }
          if (company.name) {
            document.title = `Oxide | ${company.name}`
          }
          const jobs = company.jobs.map(job => {
            if (typeof job.status === 'string') {
              try {
                job.status = JSON.parse(job.status)
              } catch (e) {
                // console.error(e, 'job.status', job.status)
                job.status = [job.status]
              }
            } else if (!Array.isArray(job.status)) {
              job.status = []
            }
            return job
          })

          company.jobs = jobs
          store(company, 'company', 'apollo')
        }
        return company
      },
      result ({ data, loading, networkStatus }) {
        if (!loading && networkStatus === NetworkStatus.ready) {
          if (typeof data === 'undefined' || typeof data.company === 'undefined') {
            ca$h.set('auth_access', '')
            window.$oxide.bus.authenticated = false
          } else {
            recordQueryMeta('company', data.company)
          }
        }
      }
    }
  },
  mutations: {
    update: {
      mutation: COMPANY_UPDATE
    }
  }
})

export default COMPANY
