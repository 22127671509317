<template>
  <v-list subheader class="manage-list pb-0">
    <v-list-group v-model="expanded" :class="{ 'elevation-2': expanded }">
      <template #activator>
        <v-list-tile @click.stop="">
          <v-list-tile-content>
            <v-list-tile-title v-if="!expanded" @click.stop="expanded = true">
              {{ list.title ? list.title : 'Unnamed List' }}
            </v-list-tile-title>
            <v-text-field v-else hide-details
              v-model="list.title" @click.stop @input="handleListNameInput" @keydown.enter="$refs.newEntryInput.focus()"
              ref="listTitle" placeholder="list name" :loading="saving.includes('Set:' + list.id)" :disabled="saving.includes('Set:' + list.id)"
              color="deep-purple lighten-2" class="mt-0 pt-2" style="width: 100%;"
            ></v-text-field>
          </v-list-tile-content>

          <v-progress-circular v-if="addingEntriesTotal" indeterminate></v-progress-circular>
          <v-chip v-else-if="entries.length" dark :color="backgroundColor">
            {{ checked.length }}/{{ enabled.length }}
          </v-chip>
          <v-btn v-else-if="expanded" icon small dark color="red" @click.stop="deleteList">
            <v-icon>delete_forever</v-icon>
          </v-btn>
        </v-list-tile>
      </template>

      <v-list-tile v-if="expanded">
        <v-list-tile-content class="justify-space-around" style="flex-direction: row;">
          <v-btn @click="massEditMode = !massEditMode" icon :dark="massEditMode" :flat="!massEditMode" color="amber darken-2" class="ma-0">
            <v-icon>edit_attributes</v-icon>
          </v-btn>
          <!-- <div v-else style="width: 36px"></div> -->

          <confirm-action
            v-if="entries.length"
            @confirm="toggleListType"
            :confirm-title="'Convert to ' + (isManifest ? 'check list' : 'manifest') + '?'"
            confirm-text="This will clear the values for each item in the list">
            <template v-slot:act="{ on }">
              <div v-on="on" style="padding-top: 6px; cursor: pointer;" class="align-self-center">
                <v-switch
                  readonly hide-details
                  v-model="isManifest"
                  color="purple lighten-1'" class="list-type-switch mt-0 pt-0 align-self-center" style="pointer-events: none;">
                  <template #prepend>
                    <v-icon :color="'deep-purple ' + (isChecklist ? 'lighten-4' : 'lighten-3')" @click="isManifest = false">check</v-icon>
                  </template>
                  <template #append>
                    <v-icon :color="'purple ' + (isManifest ? 'lighten-4' : 'lighten-3')" @click="isManifest = true">format_list_numbered_rtl</v-icon>
                  </template>
                </v-switch>
              </div>
            </template>
          </confirm-action>
          <v-switch
            v-else hide-details
            v-model="isManifest"
            color="purple lighten-1'" class="list-type-switch mt-0 pt-0 align-self-center" style="flex-grow: 0;">
            <template #prepend>
              <v-icon :color="'deep-purple ' + (isChecklist ? 'darken-1' : 'lighten-4')" @click="isManifest = false">check</v-icon>
            </template>
            <template #append>
              <v-icon :color="'purple ' + (isManifest ? 'darken-1' : 'lighten-4')" @click="isManifest = true">format_list_numbered_rtl</v-icon>
            </template>
          </v-switch>

          <confirm-action v-if="admin && entries.length" @confirm="deleteList">
            <template v-slot:act="{ on }">
              <v-btn icon flat color="red" class="ma-0" v-on="on">
                <v-icon>delete</v-icon>
              </v-btn>
            </template>
          </confirm-action>
          <div v-else style="width: 36px"></div>
        </v-list-tile-content>
      </v-list-tile>

      <template v-if="massEditMode">
        <v-list-tile>
          <v-list-tile-content></v-list-tile-content>
          <v-list-tile-action>
            <v-list-tile-action-text>HEADING</v-list-tile-action-text>
          </v-list-tile-action>
        </v-list-tile>

        <draggable v-model="massEditEntries" draggable=".entry" handle=".v-avatar">
          <template v-for="(entry, index) in massEditEntries">
            <v-list-tile avatar :key="'entry: ' + index" class="entry">
              <v-list-tile-avatar :class="{ 'px-0': entry.data.h }" :style="{ minWidth: (entry.data.h ? '40px' : '56px') }" style="cursor: row-resize;">
                <v-icon>drag_handle</v-icon>
              </v-list-tile-avatar>

              <v-list-tile-content>
                <v-text-field
                  v-model="entry.value_1" @keydown.backspace.passive="handleEntryTextKeydown(entry, index, $event)" hide-details single-line
                  class="mt-0 pt-0" :class="{'font-weight-bold': entry.data.h}" style="width: 100%;">
                  <template #append>
                    <v-switch v-model="entry.data.h" class="mt-0 pt-0" hide-details single-line :ripple="false"></v-switch>
                  </template>
                </v-text-field>
              </v-list-tile-content>
            </v-list-tile>

          </template>
        </draggable>
      </template>

      <template v-else v-for="entry in entries">
        <v-divider v-if="entry.data.h" :key="'divider:' + entry.id"></v-divider>

        <v-list-tile v-if="entry.data.h" :key="'entry:' + entry.id" avatar class="manage-list__heading">
          <v-list-tile-content>
            <v-list-tile-title class="font-weight-medium">{{ entry.value_1 }}</v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>

        <v-list-tile
          v-else-if="isChecklist" :key="entry.id"
          @click="handleNothing" @mouseup="toggleEntry(entry)"
          class="manage-list__item" avatar
          :class="{ 'manage-list__item--checked': entry.value_2 === '1', 'manage-list__item--disabled': entry.data.d }">

          <v-list-tile-action v-if="entry.data.d">
            <v-btn icon :loading="saving.includes('Entry:' + entry.id)" @click.stop="deleteEntry(entry)" @mouseup.stop="">
              <v-icon color="grey lighten-2">delete_outline</v-icon>
            </v-btn>
          </v-list-tile-action>

          <v-list-tile-action v-else>
            <v-icon>{{ entry.value_2 === '1' ? 'check_box' : 'check_box_outline_blank' }}</v-icon>
          </v-list-tile-action>

          <v-list-tile-content>
            <v-list-tile-title>{{ entry.value_1 }}</v-list-tile-title>
          </v-list-tile-content>

          <v-list-tile-action v-if="!entry.data.h">
            <v-btn icon @click.stop="toggleEntryDisabled(entry)" @mouseup.stop="">
              <v-icon color="grey lighten-1">remove_circle</v-icon>
            </v-btn>
          </v-list-tile-action>
        </v-list-tile>

        <v-list-tile v-else :key="entry.id" avatar class="manage-list__item" :class="{ 'manage-list__item--disabled': entry.data.d, 'manage-list__item--editing': editing && editing.id === entry.id }">

          <v-list-tile-content>
            <v-layout row ma-0 style="width: 100%;">
              <v-text-field
                v-model="entry.value_2" type="text" inputtype="decimal" min="0" :disabled="entry.data.d"
                @input="handleManifestCountInput(entry, $event)"
                class="mt-0 pt-0 pr-4 font-mono text-xs-right" style="width: 60px; text-align: right;" height="30px"
                single-line hide-details>
              </v-text-field>
              <v-list-tile-title>{{ entry.value_1 }}</v-list-tile-title>
            </v-layout>
          </v-list-tile-content>

          <v-list-tile-action v-if="!entry.data.h">
            <v-btn icon :loading="saving.includes('Entry:' + entry.id)" @click.stop="toggleEntryEdit(entry)" @mouseup.stop="" class="edit-item--button">
              <v-icon color="grey lighten-1">remove_circle</v-icon>
            </v-btn>
          </v-list-tile-action>
        </v-list-tile>

        <v-list-tile avatar v-if="editing && editing.id === entry.id" :key="'edit:' + entry.id" class="manage-list__item-edit-controlls">
          <v-list-tile-content>
            <v-layout row ma-0 style="width: 100%;">
              <v-flex shrink pl-0 pr-3 py-1>
                <v-btn icon :loading="saving.includes('Entry:' + entry.id)" @click.stop="entry.data.d = !entry.data.d" @mouseup.stop="">
                  <v-icon :color="entry.data.d ? 'amber darken-2' : ''">strikethrough_s</v-icon>
                </v-btn>
              </v-flex>
              <v-text-field v-model="entry.data.n" label="Task Notes" hide-details></v-text-field>
              <v-flex shrink pr-0 pl-2 py-1>
                <v-btn icon @click.stop="deleteEntry(entry)" @mouseup.stop="">
                  <v-icon color="red lighten-1">delete_outline</v-icon>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-list-tile-content>
        </v-list-tile>

        <v-list-tile v-else-if="entry.data.n" :key="'note:' + entry.id" class="manage-list__item-note">
          <v-list-tile-action></v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-sub-title>{{ entry.data.n }}</v-list-tile-sub-title>
          </v-list-tile-content>
        </v-list-tile>

      </template>

      <v-list-tile>
        <v-list-tile-action>
          <v-icon :color="color + ' lighten-2'">add_box</v-icon>
        </v-list-tile-action>

        <v-list-tile-content>
          <v-text-field
            v-model="newEntryText" @keydown.enter="addEntry"
            label="new item" ref="newEntryInput" :disabled="saving.includes('Entry:-1')"
            class="mt-0 pt-0" style="width: 100%;" :color="color + ' lighten-2'"
            single-line hide-details
          >
          </v-text-field>
        </v-list-tile-content>
        <v-list-tile-action>
          <v-btn icon dark :loading="saving.includes('Entry:-1')" :color="color + ' lighten-1'" @click.stop="addEntry">
            <v-icon>arrow_upward</v-icon>
          </v-btn>
        </v-list-tile-action>
      </v-list-tile>

    </v-list-group>

  </v-list>
</template>

<script>
/* eslint-disable camelcase */
/* eslint-disable handle-callback-err */

import { debounce } from 'lodash'
import { clone, cloneExcept, equivalent } from '@/utils'
import { ENTRY, SET } from '@/graphql/models'
import ConfirmAction from './ConfirmAction.vue'
import draggable from 'vuedraggable'

export default {
  name: 'manage-list-set',
  components: { ConfirmAction, draggable },
  props: {
    startsOpen: { type: Boolean, required: false, default: false },
    list: {},
    addingEntriesCurrent: { type: Number, required: true },
    addingEntriesTotal: { type: Number, required: true }
  },
  data () {
    return {
      countdownInterval: null,
      expanded: this.startsOpen,
      editing: null,
      newEntryText: '',
      dirty: false,
      massEditMode: false,
      massEditEntries: [],
      saving: []
    }
  },
  computed: {
    me () { return this.$oxide.store.apollo.user },
    admin () { return this.me?.is_admin },
    entries () { return this.list?.entries ? this.list.entries : [] },
    headers () { return this.entries.filter(entry => !!entry.data.h) },
    items () { return this.entries.filter(entry => !entry.data.h) },
    enabled () { return this.items.filter(entry => !entry.data.d) },
    disabled () { return this.items.filter(entry => entry.data.d) },
    checked () { return this.enabled.filter(entry => entry.value_2 !== '0') },
    unchecked () { return this.enabled.filter(entry => entry.value_2 === '0') },
    backgroundColor () {
      const min = this.checked.length
      const max = this.entries.length
      const options = ['blue-grey darken-4', 'blue-grey darken-3', 'blue-grey darken-2', 'blue-grey darken-1', 'blue-grey', 'blue-grey lighten-1', 'blue-grey lighten-2', 'blue-grey lighten-3']
      if (max === 0) {
        return options[options.length - 1]
      }
      return options[Math.round((min / max) * (options.length - 1))]
    },
    isManifest: {
      get () { return this.list.type === 'Job/List/Manifest' },
      set (val) {
        this.list.type = val ? 'Job/List/Manifest' : 'Job/List/Check'
        this.updateList()
      }
    },
    isChecklist () { return !this.isManifest },
    color () { return this.isManifest ? 'purple' : 'deep-purple' },
    addingEntriesPercentage () {
      if (this.addingEntriesTotal === 0) {
        return 0
      }
      // console.log(this.addingEntriesCurrent, this.addingEntriesTotal, (this.addingEntriesCurrent / this.addingEntriesTotal) * 100)
      return (this.addingEntriesCurrent / this.addingEntriesTotal) * 100
    }
  },
  methods: {
    handleNothing () { /* nothing */ },
    handleListNameInput: debounce(function () { this.updateList() }, 1111),
    findEntry (id) { return this.entries.find(entry => entry.id === id) },

    toggleEntry (entry) {
      if (entry.data.d) {
        entry.data.d = false
      } else if (this.isChecklist) {
        entry.value_2 = entry.value_2 === '1' ? '0' : '1'
      }
      this.updateEntry(entry)
    },

    toggleEntryDisabled (entry) {
      entry.data.d = !entry.data.d
      this.updateEntry(entry)
    },

    toggleEntryEdit (entry) {
      if (this.editing === null) {
        if (entry !== null) {
          this.editing = clone(entry)
        }
      } else {
        if (entry.id === this.editing.id) {
          if (entry.updated_at !== this.editing.updated_at) {
            // server side updated first
          } else if (!equivalent(entry, this.editing, { ignore: ['__typename', 'created_at', 'updated_at', 'id'] })) {
            this.updateEntry(entry)
          }
          this.editing = null
        } else {
          this.editing = clone(entry)
        }
      }
    },

    toggleListType () {
      this.list.type = this.list.type === 'Job/List/Manifest' ? 'Job/List/Check' : 'Job/List/Manifest'
      this.updateList()
    },

    addToSaving (model) {
      const key = `${model.__typename}:${model.id}`
      if (!this.saving.includes(key)) {
        this.saving.push(key)
      }
    },
    removeFromSaving (model) {
      const index = this.saving.indexOf(`${model.__typename}:${model.id}`)
      if (index !== -1) {
        this.saving.splice(index, 1)
      }
    },
    isSaving (model) { return this.saving.includes(`${model.__typename}:${model.id}`) },

    addEntry (e) {
      const text = this.newEntryText.trim()
      if (text !== '') {
        const lastEntry = this.entries[this.entries.length - 1]
        const order = lastEntry ? lastEntry.order + 1 : 1

        if (this.massEditMode) {
          const entry = ENTRY.blank
          entry.id = -1
          entry.value_1 = text
          entry.value_2 = '0'
          entry.data = { d: false, h: !!e.shiftKey, n: '' }
          entry.order = order
          this.massEditEntries.push(entry)
          this.newEntryText = ''
          this.$refs.newEntryInput.focus()
        } else {
          this.addToSaving({ __typename: 'Entry', id: '-1' })
          this.$apollo.mutate({
            mutation: ENTRY.mutations.create.mutation,
            variables: {
              entry: {
                set: { connect: this.list.id },
                value_1: text,
                value_2: '0',
                data: JSON.stringify({ d: false, h: !!e.shiftKey, n: '' }),
                order
              }
            },
            update: (store, { data: { createEntry } }) => {
              const data = clone(store.readQuery(this.$oxide.store.clientQuery))
              const jobIndex = data.client.jobs.findIndex(j => j.id === this.$route.params.rel2_id)
              if (jobIndex !== -1) {
                const setIndex = data.client.jobs[jobIndex].sets.findIndex(s => s.id === this.list.id)
                if (setIndex !== -1) {
                  if (typeof createEntry.data === 'string') {
                    createEntry.data = JSON.parse(createEntry.data)
                  }
                  data.client.jobs[jobIndex].sets[setIndex].entries.push(createEntry)
                  store.writeQuery({ ...this.$oxide.store.clientQuery, data })
                }
              }
            }
          }).then(({ data: { createEntry } }) => {
            // if (typeof createEntry.data === 'string') {
            //   createEntry.data = JSON.parse(createEntry.data)
            // }
            // this.list.entries.push(createEntry)
            this.removeFromSaving({ __typename: 'Entry', id: '-1' })
            this.newEntryText = ''
            this.$nextTick(() => {
              this.$refs.newEntryInput.focus()
            })
          })
        }
      }
    },

    editEntry (entry) {
      this.editing = clone(entry)
    },

    deleteEntry (entry) {
      this.addToSaving(entry)
      this.$apollo.mutate({
        mutation: ENTRY.mutations.delete.mutation,
        variables: { id: entry.id },
        update: (store, { data: { deleteEntry } }) => {
          const data = clone(store.readQuery(this.$oxide.store.clientQuery))
          const jobIndex = data.client.jobs.findIndex(j => j.id === this.$route.params.rel2_id)
          if (jobIndex !== -1) {
            const setIndex = data.client.jobs[jobIndex].sets.findIndex(s => s.id === this.list.id)
            if (setIndex !== -1) {
              const entryIndex = data.client.jobs[jobIndex].sets[setIndex].entries.findIndex(e => e.id === deleteEntry.id)
              if (entryIndex !== -1) {
                data.client.jobs[jobIndex].sets[setIndex].entries.splice(entryIndex, 1)
                store.writeQuery({ ...this.$oxide.store.clientQuery, data })
              }
            }
          }
        }
      }).then(({ data: { deleteEntry } }) => {
        this.removeFromSaving(deleteEntry)
        this.editing = null
      })
    },

    updateEntry (input) {
      this.addToSaving(input)
      const entry = cloneExcept(input, ['__typename', 'created_at', 'updated_at', 'data'])
      entry.data = JSON.stringify(input.data)
      return this.$apollo.mutate({
        mutation: ENTRY.mutations.update.mutation,
        variables: { entry }
      }).then(({ data: { updateEntry } }) => {
        this.removeFromSaving(updateEntry)
      })
    },

    updateList () {
      this.addToSaving(this.list)
      this.$apollo.mutate({
        mutation: SET.mutations.update.mutation,
        variables: { set: cloneExcept(this.list, ['__typename', 'created_at', 'updated_at', 'entries', 'setable_id', 'setable_type']) }
      }).then(({ data: { updateSet } }) => {
        this.removeFromSaving(updateSet)
        this.$alert({ color: 'deep-purple lighten-1', text: 'List saved' })
      })
    },

    deleteList () {
      // this.$apollo.mutate({
      //   mutation: SET.mutations.delete.mutation,
      //   variables: { id: this.list.id }
      // }).then(({ data: { deleteSet } }) => {
      //   this.snackbarText = 'List deleted'
      //   this.snackbarColor = 'deep-orange darken-2'
      //   this.showSnackbar = true
      // })
      this.$emit('set:delete', this.list)
    },

    handleManifestCountInput: debounce(function (entry, quantity) {
      entry.value_2 = quantity === null ? '0' : quantity
      this.updateEntry(entry)
    }, 500),

    handleEntryTextKeydown (entry) {
      this.$nextTick(() => {
        if (entry.value_1 === '') {
          this.deleteEntry(entry)
        }
      })
    }
  },
  watch: {
    massEditMode (massEditMode) {
      if (massEditMode) {
        this.massEditEntries = clone(this.entries)
      } else {
        // save changed entries
        this.$alert({ color: 'deep-purple lighten-1', text: 'Updating ' + this.massEditEntries.length + ' Items' })
        let order = 0
        const promises = []
        for (const editEntry of this.massEditEntries) {
          let entry = this.findEntry(editEntry.id)
          editEntry.order = ++order
          if (entry) {
            if (!equivalent(editEntry, entry)) {
              promises.push(this.updateEntry(editEntry))
              entry = clone(editEntry)
            }
          } else {
            promises.push(
              this.$apollo.mutate({
                mutation: ENTRY.mutations.create.mutation,
                variables: {
                  entry: {
                    set: { connect: this.list.id },
                    value_1: editEntry.value_1,
                    value_2: '0',
                    data: JSON.stringify(editEntry.data),
                    order: editEntry.order
                  }
                }
              }).then(({ data: { createEntry } }) => {
                if (typeof createEntry.data === 'string') {
                  createEntry.data = JSON.parse(createEntry.data)
                }
                this.list.entries.push(createEntry)
                // this.$alert({ color: 'deep-purple lighten-1', text: 'Updating Items: 1 of ' + order })
              })
            )
          }
        }

        Promise.all(promises).then(() => {
          this.$alert({ color: 'deep-purple lighten-1', text: 'Updating Items Complete' })
          this.massEditEntries = []
        })
      }
    }
  },
  mounted () {
    if ((this.list.title === null || this.list.title === '') && this.expanded) {
      this.$nextTick(function () {
        if (typeof this.$refs.listTitle !== 'undefined') {
          this.$refs.listTitle.focus()
        }
      })
    }
  }
}
</script>

<style lang="scss">
.manage-list {
  .v-list__group {
    .v-divider {
      margin-top: 10px;
    }
    .v-list__group__header {
      height: 56px;
      .v-list__tile {
        text-decoration: none !important;
        padding-right: 0;
        &.v-list__tile--link:hover {
          background-color: rgba(0, 0, 0, 0);
        }
        .v-chip {
          margin-left: 10px;
          margin-right: 0;
        }
      }
      // .v-list__tile__title {
      // }
    }
    &:after {
      display: none;
    }
    .v-list__group__items {
      [role="listitem"] {
        & > .v-list__tile {
          height: auto;
          min-height: 40px;
          .v-list__tile__title {
            height: auto;
            white-space: normal;
            line-height: 20px;
            padding: 6px 0;
          }
        }

        &.manage-list__item {
          & > a {
            text-decoration: none !important;
          }
          &.manage-list__item--checked {
            .v-list__tile__title,
            .v-list__tile__action {
              opacity: 0.5;
            }
          }
          &.manage-list__item--editing {
            // height: 80px;
            background-color:rgba(255, 255, 255, 0.1);
            .edit-item--button {
              transform: rotate(90deg);
            }
          }
        }

        &.manage-list__item-edit-controlls {
          background-color:rgba(255, 255, 255, 0.1);
          .v-list__tile {
            padding: 0 2px 0 5px;
          }
        }

        &.manage-list__item-note > .v-list__tile {
          align-items: flex-start;
        }

        &.manage-list__item.manage-list__item--disabled {
          .v-list__tile__title,
          .v-list__tile__action:last-child {
            opacity: 0.5;
          }
          .v-list__tile__title {
            text-decoration: line-through;
          }
        }
        &:last-child > .v-list__tile {
          height: 56px;
          padding-bottom: 12px;
        }
        &:not(:last-child) {
          .v-list__tile__action:first-child {
            .v-btn:not(.v-btn--depressed):not(.v-btn--flat):hover {
              background-color: #ff5722 !important;
              .v-icon.grey--text {
                color: #fff !important;
              }
            }
          }
          .v-list__tile__action:last-child {
            .v-btn:not(.v-btn--depressed):not(.v-btn--flat):hover {
              .v-icon.grey--text {
                color: #fff !important;
              }
            }
          }
        }
      }
      .manage-list__heading {
        padding-top: 6px;
        height: 44px;
        .v-list__tile__title {
          height: 28px;
        }
      }
      .v-subheader {
        display: flex;
        align-items: flex-end;
        height: 30px;
      }
    }
    // .v-list__group__items [role="listitem"] > .v-list__tile {
    //   height: 40px;
    // }
    // .v-list__group__items [role="listitem"]:last-child > .v-list__tile {
    //   height: 56px;
    //   padding-bottom: 12px;
    // }
    // .v-list__group__items [role="listitem"]:not(:last-child) .v-btn:not(.v-btn--depressed):not(.v-btn--flat):hover {
      // color: rgba(0,0,0,0);
      // .v-icon {
      //   color: #fff !important;
      // }
    // }
    .list-type-switch {
      margin-top: 0;
      .v-input--switch__thumb,
      .v-input--selection-controls__ripple,
      .v-input--switch__track {
        color: #7e57c2;
      }
      .v-input__slot {
        margin-bottom: 0;
      }
      .v-input--selection-controls__input {
        margin-right: 0;
      }
      & > div:first-child,
      & > div:last-child {
        width: calc(50% - 19px);
        // padding-top: 3px;
      }
      & > div:first-child {
        justify-content: flex-end;
      }
    }
  }

}
</style>
