<template>
  <v-dialog :value="show" fullscreen lazy content-class="omni-search-dialog">
    <v-card color="" dark>
      <v-card-title>
        <v-text-field
          label="search clients and jobs"
          @click:clear="handleClear"
          @input.passive="handleInput"
          autofocus
          ref="omniSearchBox"
          clearable></v-text-field>
      </v-card-title>
      <v-list three-line class="results">
        <v-list-tile
          v-for="item of filteredItems" :key="item.t + ':' + item.m.id"
          :to="'/crm/client/' + (item.t === 'c' ? item.m.id + '?a=1' : item.m.client.id + '/job/' + item.m.id + '?a=2')"
          avatar>
          <template v-if="item.t === 'c'">
            <v-list-tile-avatar class="cyan--text text--lighten-2">Client</v-list-tile-avatar>
            <v-list-tile-content>
              <v-list-tile-title>
                <span class="cyan--text text--lighten-2">{{ item.p }}</span>
                <span class="right caption"><span class="cyan--text text--lighten-4">last activity: </span>{{ item.m.updated_at }}</span>
              </v-list-tile-title>
              <v-list-tile-sub-title class="">
                <span>{{ item.m.addresses[0] ? item.m.addresses[0].street : '' }}</span>
                <span class="right">{{ item.m.contacts[0] ? item.m.contacts[0].value : '' }}</span>
              </v-list-tile-sub-title>
            </v-list-tile-content>
            <!-- <v-list-tile-action>
              <v-list-tile-action-text>{{ item.h }}</v-list-tile-action-text>
            </v-list-tile-action> -->
          </template>
          <template v-else-if="item.t === 'j'">
            <v-list-tile-avatar class="deep-orange--text">JOB</v-list-tile-avatar>
            <v-list-tile-content>
              <v-list-tile-title>
                <span class="deep-orange--text">{{ item.p }}</span>
                <span class="right caption">{{ item.m.client.name }}</span>
              </v-list-tile-title>
              <v-list-tile-sub-title>{{ item.s }}</v-list-tile-sub-title>
              <v-list-tile-sub-title class="deep-orange--text text--lighten-4">
                <span>{{ item.m.status.join(' ') }}</span>
                <span class="right">added: {{ item.m.created_at.split(' ')[0] }}</span>
              </v-list-tile-sub-title>
            </v-list-tile-content>
            <!-- <v-list-tile-action>
              <v-list-tile-action-text>{{ item.h }}</v-list-tile-action-text>
            </v-list-tile-action> -->
          </template>
        </v-list-tile>
      </v-list>
    </v-card>
  </v-dialog>
</template>

<script>
import { debounce } from 'lodash'

export default {
  name: 'omni-search',
  model: {
    prop: 'show',
    event: 'change'
  },
  props: {
    show: { type: Boolean, required: false, default: false }
  },
  data () {
    return {
      query: ''
    }
  },
  computed: {
    clients () {
      return [...(this.$oxide.store.apollo.clients ? this.$oxide.store.apollo.clients : [])].map(client => {
        return {
          t: 'c',
          p: client.name,
          s: '',
          h: '',
          a: (client.addresses.length ? `${client.addresses[0].street} ${client.addresses[0].city}` : ''),
          m: client
        }
      })
    },
    jobs () {
      return [...(this.$oxide.store.apollo.company?.jobs ? this.$oxide.store.apollo.company.jobs : [])].map(job => {
        return {
          t: 'j',
          p: job.title,
          s: job.description,
          h: job.identifier,
          a: (job.addresses.length ? `${job.addresses[0].street} ${job.addresses[0].city}` : ''),
          m: job
        }
      })
    },
    catalog () {
      const items = [...this.clients, ...this.jobs]
      const collator = new Intl.Collator(undefined, { caseFirst: 'false', ignorePunctuation: false, localeMatcher: 'best fit', numeric: true, sensitivity: 'base', usage: 'sort' })
      items.sort(collator.compare)
      return this.$catalog(items, { using: ['p', 's', 'h', 'a'], results: { limit: 10 }, relevance: { threshold: 0.05 } })
    },
    filteredItems () {
      return this.query === '' ? [] : this.catalog.resultsFor(this.query)
    }
  },
  methods: {
    closeDialog () {
      this.$emit('change', false)
    },
    async handleClear () {
      // this.closeDialog()
    },
    async handleInput (e) {
      // ignore duplicate calls of type event
      if (typeof e === 'string' || e === null) {
        this.updateQuery(e)
      }
    },
    updateQuery: debounce(function (text) {
      this.query = typeof text === 'string' ? text.trim() : ''
    }, 1000)
  },
  watch: {
    '$route.fullPath' (fullPath) {
      if (this.show) {
        this.closeDialog()
      }
    },
    show (value) {
      if (value) {
        this.$nextTick(() => {
          this.$refs.omniSearchBox.focus()
        })
      }
    }
  }
}
</script>
<style lang="scss">
.omni-search-dialog {
  height: calc(100% - 42px);
  .results {
    & > [role=listitem] {
      &:nth-child(even) {
        background-color: rgba(255,255,255,0.05);
      }
      & > a {
        text-decoration: none !important;
      }
      .v-list__tile__sub-title {
        -webkit-line-clamp: 1;
      }
    }
  }
}
</style>
