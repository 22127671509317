<template>
  <v-bottom-sheet v-model="show">
    <v-card>
      <v-card-title>
        <span class="title">Edit Hour</span>
      </v-card-title>
      <v-form>
        <v-container grid-md>
          <v-layout row wrap>
            <v-flex xs12 sm6 md4>
              <v-menu :close-on-content-click="false" :nudge-right="40" lazy transition="scale-transition" offset-y full-width max-width="290px" min-width="290px">
                <v-text-field slot="activator" v-model="model.date" prepend-icon="access_time" readonly></v-text-field>
                <v-date-picker v-model="model.date" full-width @input="handleInput"></v-date-picker>
              </v-menu>
            </v-flex>
            <v-flex xs12 sm6 md4>
              <v-autocomplete
                v-model="model.employee_id"
                :items="employees"
                :search-input.sync="employeeSearch"
                item-value="id" item-text="name"
                label="Employee"
                placeholder="Start typing to Search"
                prepend-icon="mdi-database-search"
                @input="handleInput"
                :readonly="model.id > 0"
              ></v-autocomplete>
            </v-flex>
            <v-flex xs12 sm6 md4>
              <v-text-field label="Role" v-model="model.role" @input="handleInput"></v-text-field>
            </v-flex>
            <v-flex xs12 sm6 md4>
              <v-text-field label="Hours" v-model="model.time" @input="handleInput"></v-text-field>
            </v-flex>
            <v-flex xs12 sm6 md4>
              <v-text-field label="Rate" v-model="model.rate" @input="handleInput"></v-text-field>
            </v-flex>
            <v-flex xs3 sm2 md1>
              <v-switch label="Billable" v-model="model.billable" @change="handleInput"></v-switch>
            </v-flex>
          </v-layout>
        </v-container>
      </v-form>
    </v-card>
  </v-bottom-sheet>
</template>

<script>
import { clone } from '@/utils'
import { HOUR } from '@/graphql/models'
import { debounce } from 'lodash'

export default {
  name: 'edit-hour',
  data () {
    return {
      employeeSearch: '',
      model: HOUR.blank
    }
  },
  computed: {
    hour () {
      return this.$store.getters['hour/value']
    },
    show: {
      get () {
        return this.$store.getters['hour/editing']
      },
      set (value) {
        this.$store.dispatch('hour/editing', value)
      }
    },
    employees () {
      return this.$store.getters['company/employees/list']
    }
  },
  methods: {
    resetModel () {
      this.model = this.$store.getters['hour/blank']
    },
    handleInput: debounce(function () {
      const model = clone(this.model)
      this.$store.dispatch('hour/update', model)
      this.$store.commit('job/hours/update', model)
    }, 1000)
  },
  watch: {
    hour: {
      handler (newModel, oldModel) {
        this.model = clone(newModel)
      },
      deep: true
    }
  }
}
</script>
