<template>
  <v-card dark class="job-list">
    <list-header
      color="deep-orange darken-2"  textbox-label="new job title"
      :title="title" :show-form="showAddJobForm" :saving="savingJob"
      @create="createJob" @search="handleSearch" @showFormChanged="handleShowFormChange"
    >
      <template #toggle>
        <v-checkbox
          v-model="hideCompleted" :true-value="true" :false-value="false"
          label="only show active jobs" style="width: 100%;" class="mt-2" color="white"
          hide-details
        ></v-checkbox>
      </template>
    </list-header>

    <v-sheet dark>
      <v-data-iterator
        :items="filteredJobs"
        :rows-per-page-items="rowsPerPageItems"
        :pagination.sync="pagination"
        content-tag="v-list" :content-props="{ 'dense': true, 'three-line': true }"
      >
        <template v-slot:item="{ item, index }">
          <v-list-tile v-if="isFiltered && (index === 0 || (item._relevance < 0.1 && filteredJobs[index - 1]._relevance >= 0.1))" class="search-result-heading">
            <v-list-tile-content>
              <v-list-tile-title
                class="text-xs-center font-weight-light font-italic"
                v-text="(item._relevance < 0.1 && (index === 0 || filteredJobs[index - 1]._relevance >= 0.1) ? 'Loose' : '') + ' Matches'">
              </v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
          <v-list-tile :to="'/crm/client/' + parentId + '/job/' + item.id + '?a=2'"  :class="{ 'item--active': parseInt(item.id, 10) === activeJobId }">
            <v-list-tile-content>
              <v-list-tile-title>{{ item.title }}</v-list-tile-title>
              <v-list-tile-sub-title v-if="item.description" class="text--primary text-truncate" style="max-width: 240px;">{{ item.description }}</v-list-tile-sub-title>
              <v-list-tile-sub-title class="text--primary font-weight-light d-flex" style="">
                <div>added: <span style="text-decoration: none;">{{ item.created_at.split(' ')[0] }}</span></div>
                <div>updated: <span style="text-decoration: none;">{{ item.updated_at.split(' ')[0] }}</span></div>
              </v-list-tile-sub-title>
            </v-list-tile-content>

            <v-list-tile-action>
              <v-list-tile-action-text v-if="item.status && item.status.charAt(0) === '['">
                <v-chip small v-for="(status, statusIndex) of JSON.parse(item.status)" :key="statusIndex">{{ status }}</v-chip>
              </v-list-tile-action-text>
              <v-list-tile-action-text v-else>{{ item.status }}</v-list-tile-action-text>
            </v-list-tile-action>
          </v-list-tile>
          <v-divider v-if="index + 1 < jobs.length"></v-divider>
        </template>

      </v-data-iterator>
    </v-sheet>

  </v-card>
</template>

<script>
/* eslint-disable camelcase */

import { clone, navigate } from '@/utils'
import { debounce } from 'lodash'
import { JOB } from '@/graphql/models'
import ListHeader from '@/components/ListHeader.vue'

export default {
  name: 'job-list',
  components: { ListHeader },
  props: {
    title: { type: String, required: false, default: 'Jobs' },
    parentId: { type: Number, required: true },
    jobs: { type: Array }
  },
  data () {
    return {
      hideCompleted: true,
      pagination: { rowsPerPage: 100 },
      rowsPerPageItems: [100, 250, 500],
      savingJob: false,
      search: '',
      showAddJobForm: false
    }
  },
  computed: {
    activeJobId () { return this.$cache.crmModels?.job },
    isFiltered () { return this.search.length !== 0 },
    jobCatalog () {
      let jobs = [...(this.jobs ? this.jobs : [])]
      if (this.hideCompleted) {
        // jobs = jobs.filter(job => (!(job.status?.includes('"billed"') && job.status?.includes('"complete"'))) && !job.status?.includes('"cancelled"'))
        jobs = jobs.filter(job => this.shouldJobBeShown(job))
      }
      return this.$catalog(jobs, { using: 'title', sort: { fields: [{ key: 'updated_at', direction: 'DESC' }] } })
    },
    filteredJobs () { return this.jobCatalog.resultsFor(this.search) },
    totalFilteredMatches () {
      if (this.isFiltered) {
        return this.filteredJobs.length
      } else {
        return undefined
      }
    },
    company () { return this.$oxide.store.apollo.company },
    config () { return this.company && this.company.config ? this.company.config : [] },
    billingTypes () { return this.config?.job_billing_types ? this.config.job_billing_types : {} }
  },
  methods: {
    shouldJobBeShown (job) {
      if (!job || typeof job.status !== 'string') {
        return false
      }
      if (job.status.includes('"cancelled"')) {
        return false
      }
      if (job.status.includes('"complete"')) {
        if (this.billingTypes[job.billing_type]?.name?.toLowerCase() === 'no charge' || job.status.includes('"billed"') || job.status.includes('"warranty"')) {
          return false
        }
      }
      return true
    },
    handleSearch: debounce(function (query) { this.search = query.trim() }, 350),

    editJob (job) {
      navigate({
        name: 'crm',
        params: { rel1_type: 'client', rel1_id: this.parentId, rel2_type: 'job', rel2_id: job.id },
        query: { a: 2 }
      }, this.$router)
    },

    createJob (title) {
      this.savingJob = true
      const job = { title, description: '', identifier: '', billing_type: 1, status: '[]', start: null, started: null, finish: null, finished: null }
      this.$apollo.mutate({
        mutation: JOB.mutations.create.mutation,
        variables: { job: { ...job, client: { connect: this.parentId } } },
        update: (store, { data: { createJob } }) => {
          const data = store.readQuery(this.$oxide.store.clientQuery)
          data.client.jobs.push(clone(createJob))
          store.writeQuery({ ...this.$oxide.store.clientQuery, data })
        }
      }).then(({ data: { createJob } }) => {
        this.savingJob = false
        this.editJob(createJob)
      })
    },

    handleShowFormChange (show) {
      this.showAddClientForm = show
    }
  }
}
</script>

<style lang="scss">
.job-list {
  .item--active {
    background-color: rgba(0, 0, 0, 0.04);
    .v-list__tile__title {
      color: #ffffff;
      font-weight: bold;
    }
  }
  .v-list {
    .search-result-heading,
    .search-result-heading > .v-list__tile {
      height: 36px;
      .v-list__tile__content {
        justify-content: flex-end;
      }
    }
  }
}
</style>
