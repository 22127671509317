<template>
  <section class="import-billables">
    <v-stepper v-model="step" non-linear>
      <v-stepper-header>
        <v-stepper-step color="purple" :editable="!headersMapped" :complete="file && headersMapped" step="1">Select CSV File</v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step color="teal" :editable="file && headersMapped" step="2">Filter Billables<small>optional</small></v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step color="green" :editable="file && headersMapped && canStart" step="3">Match Data to Billables</v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <v-container pb-2>
            <v-layout row ma-0>
              <v-flex grow>
                <input type="file" accept=".csv" @change="handleFileInputChange" class="upload__input" ref="csvInput" style="width: 50%; padding-top: 16px;">
              </v-flex>
              <v-flex shrink pr-3 style="min-width: 185px;">
                <v-text-field v-model="distributor" label="Distributor / Supplier"></v-text-field>
              </v-flex>
              <v-flex shrink>
                <v-checkbox v-model="firstRowIsHeader" hide-details label="First row is header" class=""></v-checkbox>
              </v-flex>
            </v-layout>
            <v-layout row ma-0 pt-4 v-if="preview.rows.length">
              <v-flex text-xs-left headline>Preview</v-flex>
              <v-flex text-xs-right d-flex style="align-items: flex-end;">
                <div>Rows 1 - {{ preview.totalRows > 5 ? 5 : preview.totalRows }} of {{ preview.totalRows }}</div>
              </v-flex>
            </v-layout>
          </v-container>

          <table class="v-table theme--dark preview--table" :class="{ 'truncated': preview.totalRows > 5 }">
            <thead v-if="preview.headers.length">
              <tr>
                <th
                  v-for="(header, index) of preview.headers" :key="index"
                  role="columnheader" scope="col" class="column text-xs-center"
                >{{ header }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(row, index) of preview.rows" :key="index">
                <td v-for="(field, header) of row" :key="index + ':' + header" class="text-xs-center">{{ field }}</td>
              </tr>
            </tbody>
          </table>

          <v-container py-2 v-if="preview.headers.length">
            <div class="headline pb-1">Column Mapping</div>
            <v-layout row wrap>
              <v-flex xs6 sm3>
                <v-select
                  v-model="mapping.actual.name" :items="actualHeaders"
                  label="Part Name"
                ></v-select>
              </v-flex>
              <v-flex xs6 sm3>
                <v-select
                  v-model="mapping.actual.description" :items="actualHeaders"
                  label="Description"
                ></v-select>
              </v-flex>
              <v-flex xs6 sm3>
                <v-select
                  v-model="mapping.actual.cost" :items="actualHeaders"
                  label="Cost"
                ></v-select>
              </v-flex>
              <v-flex xs6 sm3>
                <v-select
                  v-model="mapping.actual.unit" :items="actualHeaders"
                  label="Unit"
                ></v-select>
              </v-flex>
            </v-layout>
            <v-layout row>
              <v-spacer></v-spacer>
              <v-flex shrink>
                <v-btn small color="teal" @click="step = '2'" :disabled="!(file && headersMapped)">
                  Next
                  <v-icon small right>arrow_forward</v-icon>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-container>
        </v-stepper-content>

        <v-stepper-content step="2">
          <v-container v-if="selectedBillable === null">
            <billables-search
              label="Search for a matching billable" show-price
              ref="billablesSearch" theme="dark"
              :filtered-billables="filteredBillables"
              :value="query"
              @input="query = $event"
              @change="handleBillableSelected"
            ></billables-search>
          </v-container>
          <template v-else>
            <div class="title text-xs-center pb-3">Current Data</div>
            <table class="v-table theme--dark mb-4">
              <thead>
                <tr>
                  <th class="px-1 text-xs-center">Category</th>
                  <th class="px-1 text-xs-left">Trade Names</th>
                  <th class="px-1 text-xs-left">Part #</th>
                  <th class="px-1 text-xs-center">Desc</th>
                  <th class="px-1 text-xs-center">Unit</th>
                  <th class="px-1 text-xs-left" style="width: 30px;">Supplier</th>
                  <th class="px-1 text-xs-center">Cost</th>
                  <th class="px-1 text-xs-center">Updated</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="font-weight-medium text-xs-center px-1">{{ selectedBillable.category.name }}</td>
                  <td class="edit-materials-table-tags px-1">
                    <v-chip v-for="(tag, index) in selectedBillable.tags" :key="index" :color="getColor(tag, selectedBillable)" label small outline class="">{{ tag }}</v-chip>
                  </td>
                  <td class="text-xs-left font-weight-medium px-1">{{ selectedBillable.name }}</td>
                  <td class="text-xs-center px-1">{{ selectedBillable.size }}</td>
                  <td class="text-xs-center px-1">{{ selectedBillable.unit }}</td>
                  <td class="text-xs-center px-1">{{ selectedBillable.supplier }}</td>
                  <td class="text-xs-right px-1 red--text text--lighten-2 font-weight-medium">${{ selectedBillable.cost | monetaryPreScaled }}</td>
                  <td class="text-xs-center px-1 grey--text text--lighten-1 caption" style="line-height: 1.2;">
                    {{ (selectedBillable.updated_at ? selectedBillable.updated_at : '') | timespan }}
                  </td>
                </tr>
              </tbody>
            </table>
            <v-container>
              <v-layout row>
                <v-flex xs3>
                  <v-text-field label="Current Price" :value="formatMonetaryPreScaled(selectedBillable.cost)" hide-details></v-text-field>
                </v-flex>
                <v-flex xs3>
                  <v-text-field label="Imported Price" :value="currentRow[mapping.actual.cost]" hide-details></v-text-field>
                </v-flex>
              </v-layout>
            </v-container>
          </template>

          <div class="title text-xs-center pb-3">Imported Data</div>
          <table class="v-table theme--dark visible--table">
            <thead v-if="preview.headers.length">
              <tr>
                <th v-for="(header, index) of preview.headers" :key="index" role="columnheader" scope="col" class="column text-xs-center">{{ header }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(row, index) of visibleRows" :key="index">
                <td v-for="(field, header) of row" :key="index + ':' + header" class="text-xs-center">{{ field }}</td>
              </tr>
            </tbody>
          </table>
          <v-container>
            <v-layout row>
              <v-flex shrink>
                <v-btn icon :disabled="row < 2" @click="row--"><v-icon>expand_less</v-icon></v-btn>
              </v-flex>
              <v-flex shrink>
                <v-btn icon :disabled="row > rows.length - 1" @click="row++"><v-icon>expand_more</v-icon></v-btn>
              </v-flex>
            </v-layout>
          </v-container>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </section>
</template>

<script>
import Papa from 'papaparse'
import { COLORS } from '@/constants'
import { clone, formatMonetaryPreScaled, itemFromString } from '@/utils'
import BillablesSearch from '@/components/BillablesSearch.vue'

const indigoIndex = COLORS.indexOf('indigo')
COLORS[indigoIndex] = 'yellow'

export default {
  name: 'import-billables',
  components: { BillablesSearch },
  data () {
    return {
      step: '1',
      file: null,
      firstRowIsHeader: true,
      distributor: '',
      errors: [],
      fatalError: false,
      preview: { parsing: false, headers: [], rows: [], totalRows: 0 },
      mapping: {
        guesses: { name: null, description: null, cost: null, unit: null },
        actual: { name: null, description: null, cost: null, unit: null }
      },
      filters: [],
      fields: ['name', 'size', 'unit', 'cost', 'supplier', 'updated_at'],
      operators: ['>', '>=', '=', '<=', '<'],
      row: 0,
      rows: [],
      matches: [],
      selectedBillable: null,
      query: ''
    }
  },
  computed: {
    actualHeaders () { return this.preview.headers.slice(this.firstRowIsHeader ? 1 : 0) },
    canStart () { return (!this.fatalError) && this.file && (!this.preview.parsing) && (this.preview.totalRows > (this.firstRowIsHeader ? 1 : 0)) },
    headersMapped () { return Object.values(this.mapping.actual).every(map => map !== null) },
    allBillables () { return [...this.$oxide.store.apollo.billables] },
    filteredBillables () {
      const filters = this.filters.filter(filter => filter.field !== null && filter.operator !== null && filter.value !== null)
      const billables = [...this.$oxide.store.apollo.billables]
      const collator = new Intl.Collator(undefined, { caseFirst: 'false', ignorePunctuation: false, localeMatcher: 'best fit', numeric: true, sensitivity: 'base', usage: 'sort' })
      return billables.filter(billable => filters.every(filter => {
        const comparison = collator.compare(`${billable[filter.field]}`, filter.value)
        switch (filter.operator) {
          case '>': return comparison > 0
          case '>=': return comparison >= 0
          case '=': return comparison === 0
          case '<=': return comparison <= 0
          case '<': return comparison < 0
          default: return true
        }
      }))
    },
    currentRow () { return this.row > 0 ? this.rows[this.row - 1] : {} },
    visibleRows () {
      if (this.row > 0) {
        const rows = this.rows.slice(this.row - 1, this.row + 1)
        if (this.firstRowIsHeader) {
          return rows.map((row, index) => ({ 'Row #': this.row + index, ...row }))
        } else {
          return rows.map((row, index) => [this.row + index, ...row])
        }
      } else {
        return []
      }
    }
  },
  methods: {
    getColor (str) { return itemFromString(COLORS, str) },
    handleFileInputChange (e) {
      if (e && e.target && e.target.files && e.target.files.length) {
        this.file = e.target.files[0]
        this.updatePreview(this.file)
      }
    },
    handleBillableSelected (billable) { this.selectedBillable = billable },
    updatePreview (file) {
      this.preview.headers = []
      this.preview.rows = []
      this.preview.totalRows = 0
      this.preview.parsing = true
      this.errors = []
      this.row = 0
      Papa.parse(file, {
        header: this.firstRowIsHeader,
        skipEmptyLines: true,
        worker: true,
        step: row => {
          this.preview.totalRows++
          if (this.preview.totalRows < 6) {
            if (this.preview.totalRows === 1) {
              if (this.firstRowIsHeader && row.meta?.fields) {
                this.preview.headers = ['Row #', ...row.meta.fields]
              }
            }
            if (this.firstRowIsHeader && this.preview.totalRows === 1 && row.meta?.fields) {
              this.preview.headers = ['Row #', ...row.meta.fields]
            }
            if (this.firstRowIsHeader) {
              this.preview.rows.push({ 'Row #': this.preview.totalRows, ...row.data })
            } else {
              this.preview.rows.push([this.preview.totalRows, ...row.data])
            }
          }
        },
        error: (error, file) => {
          this.errors.push(error)
          this.preview.parsing = false
          this.fatalError = true
        },
        complete: () => {
          this.preview.parsing = false
          const fieldCriteria = {
            name: ['name', 'part', 'part name', 'part #', 'part num', 'part number', 'item', 'item name', 'item #', 'item num', 'item number', 'number'],
            description: ['desc', 'description'],
            cost: ['cost', 'price'],
            unit: ['unit']
          }
          if (this.firstRowIsHeader) {
            const headers = this.preview.headers.slice(1).map(h => h.trim().toLowerCase())
            for (const [field, criteria] of Object.entries(fieldCriteria)) {
              const guessIndex = headers.findIndex(h => criteria.some(c => h === c))
              if (guessIndex !== -1) {
                this.mapping.guesses[field] = this.preview.headers[guessIndex + 1]
              }
            }
            this.$set(this.mapping, 'actual', clone(this.mapping.guesses))
          }
        }
      })
    },
    parseCSV (file) {
      Papa.parse(file, {
        header: this.firstRowIsHeader,
        skipEmptyLines: true,
        worker: true,
        complete: ({ data }) => {
          this.rows = [...data]
        }
      })
    },
    addFilter () { this.filters.push({ field: null, operator: null, value: null }) },
    removeFilter (index) { this.filters.splice(index, 1) },

    start () {
      this.parseCSV(this.file)
      this.row = 1
    },
    formatMonetaryPreScaled (val) { return formatMonetaryPreScaled(val) }
  },
  watch: {
    firstRowIsHeader (val) {
      if (this.$refs.csvInput.files?.length) {
        this.updatePreview(this.$refs.csvInput.files[0])
      }
    },
    step (val) {
      if (this.row === 0) {
        this.start()
      }
    },
    currentRow (row) {
      this.selectedBillable = null
      if (row) {
        this.query = `${row[this.mapping.actual.name]} ${row[this.mapping.actual.description]}`.trim()
      }
    }
  },
  mounted () {
    // console.log(Papa)
  }
}
</script>

<style lang="scss">
.import-billables {
  height: calc(100vh - 64px);
  .v-stepper {
    background: none;
    height: 100%;
    .v-stepper__header {
      box-shadow: none;
      .v-divider {
        flex-grow: 1;
        margin: 0;
      }
      .v-stepper__step {
        .v-stepper__step__step {
          padding-right: 1px;
          padding-bottom: 4px;
        }
      }
    }
    .v-stepper__items {
      box-shadow: none;
      height: calc(100% - 72px);
      .v-stepper__content {
        height: 100%;
        position: relative;
        &:last-child {
          overflow: visible;
          display: flex;
          align-items: flex-end;
          .v-stepper__wrapper {
            width: 100%;
            overflow: visible;
          }
        }
      }
    }
  }

  table.truncated tbody {
    tr {
      pointer-events: none;
    }
    tr:last-child {
      &::after {
        content: " ";
        position: absolute;
        left: 0;
        width: 100%;
        height: 48px;
        background: linear-gradient(0deg, rgba(10,53,80,1) 0%, rgba(10,53,80,0) 100%);
      }
    }
  }

  table.visible--table tbody {
    tr {
      pointer-events: none;
    }
    tr:first-child {
      margin-top: 24px;
      &::after {
        content: " ";
        position: absolute;
        left: 0;
        width: 100%;
        height: 48px;
        background: linear-gradient(180deg, rgba(10,53,80,1) 0%, rgba(10,53,80,0) 60%);
      }
    }
    tr:last-child {
      &::after {
        content: " ";
        position: absolute;
        left: 0;
        width: 100%;
        height: 48px;
        background: linear-gradient(0deg, rgba(10,53,80,1) 40%, rgba(10,53,80,0) 100%);
      }
    }
  }

}
</style>
