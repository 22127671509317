<template>
  <v-dialog v-model="show" persistent max-width="500px">
    <v-card id="login_form">
      <v-card-title class="headline">Hello there!</v-card-title>
      <v-card-text style="padding-top: 0; padding-bottom: 0;">
        <v-container grid-list-md>
          <v-layout wrap>
            <v-flex xs12 v-html="greetingText" :class="{'red--text': authFailed}"></v-flex>
            <v-flex xs12 sm6 v-if="!tokenSent">
              <v-text-field
                v-model="email" :disabled="working" @keyup.enter="authenticate"
                name="email" type="email" label="Email" placeholder="Your email address" :readonly="resetting"
                required
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm6 v-if="!passwordForgotten">
              <v-text-field
                v-model="password" :disabled="working" @keyup.enter="authenticate"
                :append-icon="showPassword ? 'visibility_off' : 'visibility'"
                @click:append="showPassword = !showPassword"
                :type="showPassword ? 'text' : 'password'"
                name="password"
                :label="(resetting ? 'New ' : '') + 'Password'" :placeholder="resetting ? 'Your new password' : 'Your password'"
                counter required
              ></v-text-field>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-text>
      <v-card-actions v-if="!tokenSent">
        <v-btn flat color="amber" v-if="!resetting && !passwordForgotten" @click="passwordForgotten = true">Forgot My Password</v-btn>
        <v-spacer></v-spacer>
        <v-progress-circular
          v-show="working"
          indeterminate
          color="primary"
        ></v-progress-circular>
        <v-btn v-if="passwordForgotten" flat color="amber" @click="sendResetLink" :disabled="!emailIsValid || working">Reset Password</v-btn>
        <v-btn
          v-else :color="authFailed ? 'red darken-1' : 'green darken-1'" :disabled="!emailIsValid || password === '' || working"
          flat @click="authenticate"
        >
            {{ working ? 'Ok let me check...' : authenticateText }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { BASE_URL, PASSPORT } from '@/constants'
export default {
  name: 'login-form',
  data () {
    return {
      email: '',
      password: '',
      showPassword: false,
      working: false,
      authFailed: false,
      show: true,
      passwordForgotten: false,
      tokenAttempted: false,
      tokenSent: false,
      resetting: false,
      resetToken: ''
    }
  },
  // 'I\'ve sent an email to ' + this.email + '. Click the link in the message in the next 60 minutes to reset your password'
  // 'Seems to be a problem with the email address you entered. Can you try that again?'
  computed: {
    greetingTitle () {
      return (
        this.resetting
          ? 'Password Reset'
          : (
            this.passwordForgotten
              ? (this.tokenAttempted ? (this.tokenSent ? 'Reset link sent' : 'Something\'s wrong...') : 'Checking...')
              : (this.authFailed ? 'Hmmm...' : 'Hello there!')
          )
      )
    },
    greetingText () {
      return (
        this.resetting
          ? 'Please enter a new password'
          : (
            this.passwordForgotten
              ? (this.tokenAttempted ? (this.tokenSent ? 'I\'ve sent an email to ' + this.email + '. Click the link in the message in the next 60 minutes to reset your password' : 'Seems to be a problem with the email address you entered. Can you try that again?') : '')
              : (this.authFailed ? 'No, I don\'t see that email and password on my list. Are you sure that\'s what you\'re registered as?' : 'Please enter your email and password and I\'ll see if you\'re on the list')
          )
      )
    },
    authenticateText () {
      return this.authFailed ? 'Try this instead' : 'Here\'s my ' + (this.resetting ? 'new password' : (this.passwordForgotten ? 'email' : 'info'))
    },
    emailIsValid () {
      return /^\S+@\S+$/.test(this.email)
    }
  },
  methods: {
    authenticate () {
      this.working = true

      if (this.resetting) {
        const params = new URLSearchParams()
        params.append('email', this.email)
        params.append('password', this.password)
        params.append('password_confirmation', this.password)
        params.append('token', this.resetToken)
        fetch(`${BASE_URL}api/password/reset`, {
          method: 'POST',
          cache: 'no-cache',
          headers: {
            Accept: 'application/json, text/plain, */*',
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          body: params
        })
          .then(response => {
            if (!response.ok) throw Error(response.status)
            return response
          })
          .then(response => response.text())
          .then(response => {
            this.working = false
            console.log(response)
            switch (response) {
              case 'passwords.reset':
                // success
                this.resetting = false
                this.authenticate()
                break
              case 'passwords.user':
                // user not found
                break
              case 'passwords.password':
                // password not valid
                break
              case 'passwords.token':
                // old token
                break
              default:
                // ???
                break
            }
          })
          .catch(error => {
            console.error(error)
            this.working = false
          })
      } else {
        const params = new URLSearchParams()
        params.append('grant_type', 'password')
        params.append('client_id', PASSPORT.id)
        params.append('client_secret', PASSPORT.secret)
        params.append('username', this.email)
        params.append('password', this.password)
        params.append('scope', '')

        fetch(`${BASE_URL}oauth/token`, {
          method: 'POST',
          cache: 'no-cache',
          headers: {
            Accept: 'application/json, text/plain, */*',
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          body: params
        })
          .then(response => {
            if (!response.ok) throw Error(response.status)
            return response
          })
          .then(response => response.json())
          .then(response => {
            this.working = false
            if (response.access_token) {
              this.$cache.set('auth_email', this.email)
              this.$cache.set('auth_access', response.access_token)
              this.$cache.set('auth_refresh', response.refresh_token)
              this.$cache.set('auth_expires', response.expires_in)

              this.authFailed = false

              window.setTimeout(() => {
                location.reload()
              }, 1000)
              // window.setTimeout(() => {
              //   location.reload()
              // }, 3000)
              this.$oxide.bus.authenticated = true
              this.$emit('authenticated')
              this.$apollo.getClient().resetStore()
            } else {
              this.authFailed = true
            }
          })
          .catch(error => {
            console.error(error)
            this.working = false
            this.authFailed = true
          })
      }
    },

    failAuthentication () {
      this.working = true
      setTimeout(function () {
        this.working = false
        this.authFailed = true
        this.$oxide.bus.authenticated = false
      }.bind(this), 5000)
    },

    sendResetLink () {
      const params = new URLSearchParams()
      params.append('email', this.email)

      fetch(`${BASE_URL}api/password/forgot`, {
        method: 'POST',
        cache: 'no-cache',
        headers: {
          Accept: 'application/json, text/plain, */*',
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: params
      })
        .then(response => {
          if (!response.ok) throw Error(response.status)
          return response
        })
        .then(response => response.text())
        .then(response => {
          console.log(response)
          this.tokenAttempted = true
          if (response === 'passwords.sent') {
            this.tokenSent = true
          } else {
            this.tokenSent = false
          }
        })
        .catch(error => {
          console.error(error)
        })
    }
  },
  mounted () {
    if (window.location.hash.startsWith('#/password/reset/')) {
      this.resetting = true
      this.resetToken = window.location.hash.substring(17, 81)
      this.email = window.location.hash.substring(88).replace('%40', '@')
    } else {
      this.resetting = false
      this.resetToken = ''
    }
  }
}
</script>

<style>
/* #login_form input:-internal-autofill-previewed,
#login_form input:-internal-autofill-selected,
#login_form textarea:-internal-autofill-previewed,
#login_form textarea:-internal-autofill-selected,
#login_form select:-internal-autofill-previewed,
#login_form select:-internal-autofill-selected {
  -webkit-box-shadow: 0 0 0 100px white inset;
  -webkit-text-fill-color: rgba(0,0,0,.87);
} */
</style>
