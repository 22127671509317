<template>
  <v-list subheader class="manage-list pb-0">
    <v-list-group v-model="expanded" :class="{ 'elevation-2': expanded }">
      <template #activator>
        <v-list-tile @click.stop="">
          <v-list-tile-content>
            <v-list-tile-title v-if="!expanded" @click.stop="expanded = true">
              {{ list.title ? list.title : 'Unnamed List' }}
            </v-list-tile-title>
            <v-text-field v-else hide-details
              v-model="list.title" @click.stop @input="rename" @keydown.enter="$refs.newTaskInput.focus()"
              ref="listTitle" placeholder="list name"
              color="deep-purple lighten-2" class="mt-0 pt-2" style="width: 100%;"
            ></v-text-field>
          </v-list-tile-content>

          <v-chip v-if="tasks.length" dark :color="backgroundColor">
            {{ checked.length }}/{{ enabled.length }}
          </v-chip>
          <v-btn v-else-if="expanded" icon small dark color="red" @click.stop="deleteList">
            <v-icon>delete_forever</v-icon>
          </v-btn>
        </v-list-tile>
      </template>

      <v-list-tile v-if="expanded">
        <v-list-tile-content class="justify-space-around" style="flex-direction: row;">
          <v-btn @click="editMode = !editMode" icon :dark="editMode" :flat="!editMode" color="amber darken-2" class="ma-0">
            <v-icon>edit_attributes</v-icon>
          </v-btn>
          <!-- <div v-else style="width: 36px"></div> -->

          <confirm-action
            v-if="tasks.length"
            @confirm="toggleType"
            :confirm-title="'Convert to ' + (isManifest ? 'check list' : 'manifest') + '?'"
            confirm-text="This will clear the values for each item in the list">
            <template v-slot:act="{ on }">
              <div v-on="on" style="padding-top: 6px; cursor: pointer;" class="align-self-center">
                <v-switch
                  readonly hide-details
                  v-model="isManifest"
                  color="purple lighten-1'" class="list-type-switch mt-0 pt-0 align-self-center" style="pointer-events: none;">
                  <template #prepend>
                    <v-icon :color="'deep-purple ' + (isChecklist ? 'lighten-4' : 'lighten-3')" @click="isManifest = false">check</v-icon>
                  </template>
                  <template #append>
                    <v-icon :color="'purple ' + (isManifest ? 'lighten-4' : 'lighten-3')" @click="isManifest = true">format_list_numbered_rtl</v-icon>
                  </template>
                </v-switch>
              </div>
            </template>
          </confirm-action>
          <v-switch
            v-else hide-details
            v-model="isManifest"
            color="purple lighten-1'" class="list-type-switch mt-0 pt-0 align-self-center" style="flex-grow: 0;">
            <template #prepend>
              <v-icon :color="'deep-purple ' + (isChecklist ? 'darken-1' : 'lighten-4')" @click="isManifest = false">check</v-icon>
            </template>
            <template #append>
              <v-icon :color="'purple ' + (isManifest ? 'darken-1' : 'lighten-4')" @click="isManifest = true">format_list_numbered_rtl</v-icon>
            </template>
          </v-switch>

          <confirm-action v-if="admin && tasks.length" @confirm="deleteList">
            <template v-slot:act="{ on }">
              <v-btn icon flat color="red" class="ma-0" v-on="on">
                <v-icon>delete</v-icon>
              </v-btn>
            </template>
          </confirm-action>
          <div v-else style="width: 36px"></div>
        </v-list-tile-content>
      </v-list-tile>

      <template v-if="editMode">
        <v-list-tile>
          <v-list-tile-content></v-list-tile-content>
          <v-list-tile-action>
            <v-list-tile-action-text>HEADING</v-list-tile-action-text>
          </v-list-tile-action>
        </v-list-tile>

        <draggable v-model="tasks" draggable=".task" handle=".v-avatar">
          <template v-for="(task, index) in tasks">
            <template v-if="typeof task !== 'undefined' && !task.del.at">
              <v-list-tile avatar :key="'task: ' + index" class="task">
                <v-list-tile-avatar :class="{ 'px-0': task.h }" :style="{ minWidth: (task.h ? '40px' : '56px') }" style="cursor: row-resize;">
                  <v-icon>drag_handle</v-icon>
                </v-list-tile-avatar>

                <v-list-tile-content>
                  <v-text-field
                    v-model="task.t" @keydown.backspace.passive="handleTaskTextKeydown(task, index, $event)" hide-details single-line
                    class="mt-0 pt-0" :class="{'font-weight-bold': task.h}" style="width: 100%;">
                    <template #append>
                      <v-switch v-model="task.h" class="mt-0 pt-0" hide-details single-line :ripple="false"></v-switch>
                    </template>
                  </v-text-field>
                </v-list-tile-content>
              </v-list-tile>
            </template>

          </template>
        </draggable>
      </template>

      <template v-else v-for="(task, index) in filteredTasks">
        <v-divider v-if="task.h" :key="'divider: ' + index"></v-divider>

        <v-list-tile v-if="task.h" :key="'task: ' + index" avatar class="manage-list__heading">
          <v-list-tile-content>
            <v-list-tile-title class="font-weight-medium">{{ task.t }}</v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>

        <v-list-tile
          v-else-if="isChecklist" :key="index"
          @click="handleNothing" @mouseup="toggle(index)"
          class="manage-list__item" avatar
          :class="{ 'manage-list__item--checked': task.c, 'manage-list__item--disabled': task.d }">

          <v-list-tile-action v-if="task.d">
            <v-btn icon @click.stop="remove(index)" @mouseup.stop="">
              <v-icon color="grey lighten-2">delete_outline</v-icon>
            </v-btn>
          </v-list-tile-action>

          <v-list-tile-action v-else>
            <v-icon>{{ task.c ? 'check_box' : 'check_box_outline_blank' }}</v-icon>
          </v-list-tile-action>

          <v-list-tile-content>
            <v-list-tile-title>{{ task.t }}</v-list-tile-title>
          </v-list-tile-content>

          <v-list-tile-action v-if="!task.h">
            <v-btn icon @click.stop="toggleDisabled(index)" @mouseup.stop="">
              <v-icon color="grey lighten-1">remove_circle</v-icon>
            </v-btn>
          </v-list-tile-action>
        </v-list-tile>

        <v-list-tile v-else :key="index" avatar class="manage-list__item" :class="{ 'manage-list__item--disabled': task.d, 'manage-list__item--editing': editing === index }">

          <v-list-tile-content>
            <v-layout row ma-0 style="width: 100%;">
              <v-text-field
                v-model="task.c" type="text" inputtype="decimal" min="0" :disabled="task.d"
                @input="handleManifestCountInput(index)"
                class="mt-0 pt-0 pr-4 font-mono text-xs-right" style="width: 60px; text-align: right;" height="30px"
                single-line hide-details>
              </v-text-field>
              <v-list-tile-title>{{ task.t }}</v-list-tile-title>
            </v-layout>
          </v-list-tile-content>

          <v-list-tile-action v-if="!task.h">
            <v-btn icon @click.stop="toggleEdit(index)" @mouseup.stop="" class="edit-item--button">
              <v-icon color="grey lighten-1">remove_circle</v-icon>
            </v-btn>
          </v-list-tile-action>
        </v-list-tile>

        <v-list-tile avatar v-if="editing === index" :key="'edit:' + index" class="manage-list__item-edit-controlls">
          <v-list-tile-content>
            <v-layout row ma-0 style="width: 100%;">
              <v-flex shrink pl-0 pr-3 py-1>
                <v-btn icon @click.stop="task.d = !task.d" @mouseup.stop="">
                  <v-icon :color="task.d ? 'amber darken-2' : ''">strikethrough_s</v-icon>
                </v-btn>
              </v-flex>
              <v-text-field v-model="task.n" label="Task Notes" hide-details></v-text-field>
              <v-flex shrink pr-0 pl-2 py-1>
                <v-btn icon @click.stop="deleteTask(index)" @mouseup.stop="">
                  <v-icon color="red lighten-1">delete_outline</v-icon>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-list-tile-content>
        </v-list-tile>

        <v-list-tile v-else-if="task.n" :key="'note:' + index" class="manage-list__item-note">
          <v-list-tile-action></v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-sub-title>{{ task.n }}</v-list-tile-sub-title>
          </v-list-tile-content>
        </v-list-tile>

        <!-- <v-list-tile v-if="index < filteredTasks.length -2 && filteredTasks[index + 1].h" :key="'new:' + index">
          <v-list-tile-action>
            <v-icon :color="color + ' lighten-2'">add_box</v-icon>
          </v-list-tile-action>

          <v-list-tile-content>
            <v-text-field
              v-model="newTaskText" @keydown.enter="add"
              label="new item" ref="newTaskInput"
              class="mt-0 pt-0" style="width: 100%;" :color="color + ' lighten-2'"
              single-line hide-details
            >
            </v-text-field>
          </v-list-tile-content>
          <v-list-tile-action>
            <v-btn icon dark :color="color + ' lighten-1'" @click.stop="add">
              <v-icon>arrow_upward</v-icon>
            </v-btn>
          </v-list-tile-action>
        </v-list-tile> -->
      </template>

      <v-list-tile>
        <v-list-tile-action>
          <v-icon :color="color + ' lighten-2'">add_box</v-icon>
        </v-list-tile-action>

        <v-list-tile-content>
          <v-text-field
            v-model="newTaskText" @keydown.enter="add"
            label="new item" ref="newTaskInput"
            class="mt-0 pt-0" style="width: 100%;" :color="color + ' lighten-2'"
            single-line hide-details
          >
          </v-text-field>
        </v-list-tile-content>
        <v-list-tile-action>
          <v-btn icon dark :color="color + ' lighten-1'" @click.stop="add">
            <v-icon>arrow_upward</v-icon>
          </v-btn>
        </v-list-tile-action>
      </v-list-tile>

    </v-list-group>

    <v-list-tile v-if="savingCountdown > 0 || saving">
      <v-list-tile-content>
        <v-btn block :loading="saving" small flat color="deep-orange" @click.stop="update()">
          <v-icon class="pr-1">save_alt</v-icon> Save Now - {{ Math.ceil(savingCountdown / 1000) }}
        </v-btn>
      </v-list-tile-content>
    </v-list-tile>

    <!-- <v-progress-linear
      :active="savingCountdown > 0" v-model="countdown"
      color="deep-purple lighten-3" height="5"></v-progress-linear> -->

    <v-snackbar v-model="showSnackbar" :color="snackbarColor" top>
      {{ snackbarText }}
      <v-btn dark flat @click="showSnackbar = false">
        Close
      </v-btn>
    </v-snackbar>
  </v-list>
</template>

<script>
/* eslint-disable camelcase */
/* eslint-disable handle-callback-err */

import { v1 as uuidv1 } from 'uuid'
import { debounce } from 'lodash'
import { clone, cloneExcept, getUTCTimestamp } from '@/utils'
import { NOTE } from '@/graphql/models'
import ConfirmAction from './ConfirmAction.vue'
import draggable from 'vuedraggable'

const SAVE_DELAY = 5000

export default {
  name: 'manage-list',
  components: { ConfirmAction, draggable },
  props: {
    startsOpen: { type: Boolean, required: false, default: false },
    notableId: { type: Number, required: true },
    notableType: { type: String, required: true },
    list: {},
    listsCount: { type: Number, required: false, default: 0 },
    listsIndex: { type: Number, required: false, default: 0 },
    parentPath: { type: String, required: true }
  },
  data () {
    return {
      countdownInterval: null,
      expanded: this.startsOpen,
      editedTask: null,
      editing: -1,
      newTaskText: '',
      tasks: [],
      dirty: false,
      editMode: false,
      showSnackbar: false,
      snackbarText: '',
      snackbarColor: '',
      savingCountdown: 0,
      saving: false
    }
  },
  computed: {
    me () { return this.$oxide.store.apollo.user },
    admin () { return this.me?.is_admin },
    active () { return this.tasks.filter(task => !task.del.at) },
    headers () { return this.active.filter(task => !!task.h) },
    items () { return this.active.filter(task => !task.h) },
    enabled () { return this.items.filter(task => !task.d) },
    disabled () { return this.items.filter(task => task.d) },
    checked () { return this.enabled.filter(task => task.c) },
    unchecked () { return this.enabled.filter(task => !task.c) },
    backgroundColor () {
      const min = this.checked.length
      const max = this.tasks.length
      const options = ['blue-grey darken-4', 'blue-grey darken-3', 'blue-grey darken-2', 'blue-grey darken-1', 'blue-grey', 'blue-grey lighten-1', 'blue-grey lighten-2', 'blue-grey lighten-3']
      if (max === 0) {
        return options[options.length - 1]
      }
      return options[Math.round((min / max) * (options.length - 1))]
    },
    isManifest: {
      get () {
        return this.list.type === 'MANIFEST'
      },
      set (val) {
        this.list.type = val ? 'MANIFEST' : 'LIST'
        this.save()
      }
    },
    isChecklist () { return !this.isManifest },
    color () { return this.isManifest ? 'purple' : 'deep-purple' },
    countdown () {
      const percentage = ((SAVE_DELAY - this.savingCountdown) / SAVE_DELAY) * 100
      if (percentage === 100) {
        return this.dirty ? percentage : 0
      } else {
        return percentage
      }
    },
    filteredTasks () {
      return this.tasks.filter(task => !task?.del?.at)
    }
  },
  methods: {
    handleNothing () { /* nothing */ },
    rename () {
      this.save()
    },

    findTask (uuid) {
      return this.tasks.find(task => task.uuid === uuid)
    },

    toggle (index) {
      if (this.filteredTasks[index].d) {
        this.filteredTasks[index].d = false
      } else {
        this.filteredTasks[index].c = !this.filteredTasks[index].c
      }
      this.list.content = JSON.stringify(this.tasks)
      this.save()
    },

    toggleDisabled (index) {
      this.filteredTasks[index].d = !this.filteredTasks[index].d
      this.list.content = JSON.stringify(this.tasks)
      this.save()
    },

    toggleEdit (index) {
      this.editing = index === this.editing ? -1 : index
      if (this.editing === -1) {
        if (this.editedTask !== JSON.stringify(this.filteredTasks[index])) {
          this.list.content = JSON.stringify(this.tasks)
          this.save()
        }
        this.editedTask = null
      } else {
        this.editedTask = JSON.stringify(this.filteredTasks[index])
      }
    },

    toggleType () {
      this.list.type = this.list.type === 'MANIFEST' ? 'LIST' : 'MANIFEST'
      this.update()
    },

    add (e) {
      const text = this.newTaskText.trim()
      if (text !== '') {
        const tasks = this.tasks
        this.newTaskText = ''
        if (e.shiftKey) {
          tasks.push({ c: false, d: false, h: true, n: '', t: text, uuid: uuidv1(), del: Object.create(null) })
        } else {
          tasks.push({ c: this.list.type === 'MANIFEST' ? 0 : false, d: false, h: false, n: '', t: text, uuid: uuidv1(), del: Object.create(null) })
        }
        this.list.content = JSON.stringify(tasks)
        this.save()
      }
      this.$refs.newTaskInput.focus()
    },

    edit (index) {
      this.editing = index
    },

    deleteTask (index) {
      this.editedTask = null
      this.editing = -1
      this.filteredTasks[index].del = { at: getUTCTimestamp(), by: `User:${this.me.id}` }

      this.list.content = JSON.stringify(this.tasks)
      this.save()
    },

    remove (index) {
      // const tasks = clone(this.tasks)
      if (this.filteredTasks[index].d) {
        this.filteredTasks[index].del = { at: getUTCTimestamp(), by: `User:${this.me.id}` }
        // tasks.splice(index, 1)
      } else {
        this.filteredTasks[index].c = false
        this.filteredTasks[index].d = true
      }
      // this.tasks = tasks
      this.list.content = JSON.stringify(this.tasks)
      this.save()
    },

    save () {
      this.dirty = true
      this.saving = false
      if (!(this.countdownInterval === null)) {
        window.clearInterval(this.countdownInterval)
      }
      this.savingCountdown = SAVE_DELAY
      this.countdownInterval = window.setInterval(() => {
        if (this.savingCountdown > 0) {
          this.savingCountdown -= 100
        } else {
          this.savingCountdown = 0
        }
      }, 100)
      this.handleSave()
    },

    handleSave: debounce(function () {
      window.clearInterval(this.countdownInterval)
      this.savingCountdown = 0
      if (this.newTaskText) {
        this.save()
      } else {
        this.update()
      }
    }, SAVE_DELAY),

    update () {
      window.clearInterval(this.countdownInterval)
      this.saving = true
      const note = cloneExcept(this.list, ['__typename', 'content', 'user', 'created_at', 'updated_at'])
      note.content = JSON.stringify(this.tasks)
      this.dirty = false
      this.$apollo.mutate({
        mutation: NOTE.mutations.update.mutation,
        variables: { note },
        update: (store, { data: { updateNote } }) => {
          const data = store.readQuery(this.$oxide.store.clientQuery)
          const tempNote = clone(updateNote)
          const jobId = parseInt(this.notableId, 10)
          const job = data.client.jobs.find(job => parseInt(job.id, 10) === jobId)
          const noteId = parseInt(tempNote.id, 10)
          const index = job.notes.findIndex(n => parseInt(n.id, 10) === noteId)
          job.notes[index] = tempNote
          store.writeQuery({ ...this.$oxide.store.clientQuery, data })
        },
        optimisticResponse: {
          __typename: 'Mutation',
          updateNote: {
            ...this.list,
            updated_at: getUTCTimestamp()
          }
        }
      }).then(({ data: { updateNote } }) => {
        window.clearInterval(this.countdownInterval)
        this.saving = false
        this.dirty = false
        this.savingCountdown = 0
        this.$alert({ color: 'deep-purple', text: 'List saved' })
      })
    },

    deleteList () {
      const note = cloneExcept(this.list, 'user')
      this.$apollo.mutate({
        mutation: NOTE.mutations.delete.mutation,
        variables: { id: note.id },
        update: (store, { data: { deleteNote } }) => {
          const data = store.readQuery(this.$oxide.store.clientQuery)
          const tempNote = clone(deleteNote)
          const jobId = parseInt(this.notableId, 10)
          const job = data.client.jobs.find(job => parseInt(job.id, 10) === jobId)
          const noteId = parseInt(tempNote.id, 10)
          const index = job.notes.findIndex(n => parseInt(n.id, 10) === noteId)
          job.notes.splice(index, 1)
          store.writeQuery({ ...this.$oxide.store.clientQuery, data })
        },
        optimisticResponse: { __typename: 'Mutation', deleteNote: this.list }
      }).then(({ data: { deleteNote } }) => {
        this.snackbarText = 'List deleted'
        this.snackbarColor = 'deep-orange darken-2'
        this.showSnackbar = true
      })
    },

    parseTaskContent (type = null) {
      if (type === null) {
        type = this.list.type
      }
      const tasks = JSON.parse(this.list.content)
      tasks.forEach(task => {
        if (typeof task.uuid !== 'string' || task.uuid.length !== 36) {
          task.uuid = uuidv1()
        }
        if (typeof task.del === 'undefined') {
          task.del = Object.create(null)
        }
      })
      if (type === 'MANIFEST') {
        tasks.forEach(task => { task.c = Number(task.c) })
      } else {
        tasks.forEach(task => { task.c = Boolean(task.c) })
      }
      const content = JSON.stringify(tasks)
      if (this.list.content !== content) {
        this.list.content = content
        this.save()
      }
    },

    handleManifestCountInput (index) {
      this.list.content = JSON.stringify(this.tasks)
      this.save()
    },

    updateTasksFromList (content = null) {
      if (content === null) {
        content = this.list.content
      }
      let tasks = []
      try {
        tasks = JSON.parse(content)
        if (!Array.isArray(tasks)) {
          tasks = []
        }
        tasks.forEach(task => {
          if (typeof task.h !== 'undefined' && typeof task.t === 'undefined') {
            task.t = task.h
            task.h = true
          }
          if (typeof task.uuid !== 'string' || task.uuid.length !== 36) {
            task.uuid = uuidv1()
          }
          if (typeof task.del === 'undefined') {
            task.del = Object.create(null)
          }
        })
      } catch (error) {
        tasks = []
      }
      this.tasks = tasks
    },

    handleTaskTextKeydown (task, index) {
      this.$nextTick(() => {
        if (task.t === '') {
          this.remove(index)
        }
      })
    }
  },
  watch: {
    editMode (editMode) {
      if (!editMode) {
        this.update()
      }
    },
    'list.type' (type) {
      this.parseTaskContent(type)
    },
    'list.content' (content) {
      if (!this.dirty) {
        this.updateTasksFromList(content)
      }
    }
  },
  mounted () {
    this.updateTasksFromList()
    this.parseTaskContent()

    if ((this.list.title === null || this.list.title === '') && this.listsIndex === (this.listsCount - 1)) {
      this.$nextTick(function () {
        if (typeof this.$refs.listTitle !== 'undefined') {
          this.$refs.listTitle.focus()
        }
      })
    }
  }
}
</script>

<style lang="scss">
.manage-list {
  .v-list__group {
    .v-divider {
      margin-top: 10px;
    }
    .v-list__group__header {
      height: 56px;
      .v-list__tile {
        text-decoration: none !important;
        padding-right: 0;
        &.v-list__tile--link:hover {
          background-color: rgba(0, 0, 0, 0);
        }
        .v-chip {
          margin-left: 10px;
          margin-right: 0;
        }
      }
      // .v-list__tile__title {
      // }
    }
    &:after {
      display: none;
    }
    .v-list__group__items {
      [role="listitem"] {
        & > .v-list__tile {
          height: auto;
          min-height: 40px;
          .v-list__tile__title {
            height: auto;
            white-space: normal;
            line-height: 20px;
            padding: 6px 0;
          }
        }

        &.manage-list__item {
          & > a {
            text-decoration: none !important;
          }
          &.manage-list__item--checked {
            .v-list__tile__title,
            .v-list__tile__action {
              opacity: 0.5;
            }
          }
          &.manage-list__item--editing {
            // height: 80px;
            background-color:rgba(255, 255, 255, 0.1);
            .edit-item--button {
              transform: rotate(90deg);
            }
          }
        }

        &.manage-list__item-edit-controlls {
          background-color:rgba(255, 255, 255, 0.1);
          .v-list__tile {
            padding: 0 2px 0 5px;
          }
        }

        &.manage-list__item-note > .v-list__tile {
          align-items: flex-start;
        }

        &.manage-list__item.manage-list__item--disabled {
          .v-list__tile__title,
          .v-list__tile__action:last-child {
            opacity: 0.5;
          }
          .v-list__tile__title {
            text-decoration: line-through;
          }
        }
        &:last-child > .v-list__tile {
          height: 56px;
          padding-bottom: 12px;
        }
        &:not(:last-child) {
          .v-list__tile__action:first-child {
            .v-btn:not(.v-btn--depressed):not(.v-btn--flat):hover {
              background-color: #ff5722 !important;
              .v-icon.grey--text {
                color: #fff !important;
              }
            }
          }
          .v-list__tile__action:last-child {
            .v-btn:not(.v-btn--depressed):not(.v-btn--flat):hover {
              .v-icon.grey--text {
                color: #fff !important;
              }
            }
          }
        }
      }
      .manage-list__heading {
        padding-top: 6px;
        height: 44px;
        .v-list__tile__title {
          height: 28px;
        }
      }
      .v-subheader {
        display: flex;
        align-items: flex-end;
        height: 30px;
      }
    }
    // .v-list__group__items [role="listitem"] > .v-list__tile {
    //   height: 40px;
    // }
    // .v-list__group__items [role="listitem"]:last-child > .v-list__tile {
    //   height: 56px;
    //   padding-bottom: 12px;
    // }
    // .v-list__group__items [role="listitem"]:not(:last-child) .v-btn:not(.v-btn--depressed):not(.v-btn--flat):hover {
      // color: rgba(0,0,0,0);
      // .v-icon {
      //   color: #fff !important;
      // }
    // }
    .list-type-switch {
      margin-top: 0;
      .v-input--switch__thumb,
      .v-input--selection-controls__ripple,
      .v-input--switch__track {
        color: #7e57c2;
      }
      .v-input__slot {
        margin-bottom: 0;
      }
      .v-input--selection-controls__input {
        margin-right: 0;
      }
      & > div:first-child,
      & > div:last-child {
        width: calc(50% - 19px);
        // padding-top: 3px;
      }
      & > div:first-child {
        justify-content: flex-end;
      }
    }
  }

}
</style>
